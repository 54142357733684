import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const IRAG = (props) => {
    return <section>
	<h1 className="pageTitle">IRAG - Cautious SB 1st Venturer</h1>
    <p className="normalText">An IRAG often possesses an acquired but unconscious level of venturing expertise,
		usually gained through steady and successful past experience.&nbsp; The IRAG 
        is a conservative venturer who possesses both resources and intuition, but who lacks a complete understanding 
		of the full expert "script" required to successfully undertake all aspects of a new venture 
        over time.&nbsp; </p>
	<p className="normalText">For the IRAG, the main drawback is that the "entrepreneurial script" has little 
		meaning -- yet.&nbsp; Hence this type should be particularly aware that there are better 
        alternatives than guesswork, but they require deep knowledge; IRAG's require expert assistance to minimize 
		the very avoidable risks.</p>
    <MatrixDiagram selected="IRAG" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">IRAG's may benefit from the assistance of an <b>expert</b> consultant, a <b>venture capital 
		partner</b> with experience in the domain to be entered, or an <b>internship</b>.&nbsp; It is unlikely that 
		an IRAG will become more willing to venture until his or her consciousness of the venturing success 
		"script" and discernment capability is enhanced, or verifiable probabilities of success are very 
		high.&nbsp; This enhancement may be "jump-started" through specific, new venture domain-based 
		knowledge structure induction.&nbsp; Reluctance may then diminish somewhat, as venturing skills are acquired 
		and overall ability to venture and perceived probability of success improves.</p>
                            
    <p className="normalText">Preliminary indications show that 5 or 6% of people fit this profile.&nbsp; 
        Such activities as taking a course in entrepreneurship, or systematic 
        exposure to expert venturers should speed the process whereby the IRAG 
        - Cautious 1st Time Venturer - may become an expert in venturing.</p>
    </section>
}
