import React, { useContext } from 'react';
import {AuthenticationContext} from '../context';
import {PermissionsRequired} from './PermissionsRequired.component';


export function HasPermission(props) {
    const {user} = useContext(AuthenticationContext);

    if (!props.requiredPermissions) return props.children;
    if (props.requiredPermissions) {
        let userHasPermissions = true;
        props.requiredPermissions.forEach(requiredPermission => {
            if (userHasPermissions) {
                if (requiredPermission === 'nvt') userHasPermissions = user.nvt_access === 1
                if (requiredPermission === 'nvp') userHasPermissions = user.nvp_access === 1
                if (requiredPermission === 'admin') userHasPermissions = user.user_type === "admin"
            }
        })
        if (userHasPermissions) return props.children;
        else return <PermissionsRequired />
    }
}
