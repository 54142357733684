import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const LifestyleSmallBusiness = () => {
    return <section>
	<h1 className="pageTitle">The Lifestyle Small Business Prototype</h1>
	<p className="normalText">Some people prefer lifestyle to money, and choose the vehicle of a small business to get
		them there. For example, someone might want to live in a picturesque location and choose a venture
        that permits them this lifestyle choice, such as owning and operating a small hotel in a hamlet near
		the ocean.&nbsp; Essentially, the <b>New Venture Template™</b> illustrates the venture characteristics of such businesses.</p>
    <p className="normalText">As shown, the prototype Lifestyle small business depends upon <Link className="contentLink" to="/template/viability/repetitive">Purchase Frequency</Link> and <Link className="contentLink" to="/template/viability/long-term-need">Product Longevity</Link> (e.g. the need for a
		bed and breakfast in a scenic location) for its viability.</p>
    <p className="normalText">As also shown, some sacrifices are required in all other areas, trading money
		for lifestyle.&nbsp; Especially important to the Lifestyle small business, however, is the opportunity to
		vary the level of <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link> and the level of <Link className="contentLink" to="/template/viability/non-imitable">Imitability</Link>.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 13: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="lsb" />
    <p className="sectionHeading2">Figure 14: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype="Lifestyle Small Business" />
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">If you want a Lifestyle small business that produces a bit of extra money too, then it
		may pay to give attention to <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link> and <Link className="contentLink" to="/template/viability/non-imitable">Imitability</Link> in the product/ service.&nbsp;
		Protectable innovations are the sure way to distinguish your business, and justify higher <Link className="contentLink" to="/template/viability/volume">Volumes</Link>, better <Link className="contentLink" to="/template/viability/margin">Margins</Link>, etc.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Michelle's Bed and Breakfast -- <i>Lifestyle Business</i></p>
	<p className="normalText">Michelle Rowen had a vision during a trip to Victoria, B.C. when she was 17 years old.&nbsp; She saw herself
        living on the coast, operating a first class bed and breakfast, with an atmosphere that drew people back and
        left them with wonderful memories of their visit.&nbsp; Michelle pictured an elegant house in Victoria, with several
        beautifully decorated rooms, exquisite meals, and fresh cut flowers everywhere.&nbsp; Romantic couples would
        come to Michelle's to get away from the hustle of their daily lives, and to enjoy the coast on their own terms in luxury and
		comfort beyond their expectations.</p>
    <p className="normalText">Her weekend departures to the coast from Edmonton helped develop contacts in the industry, and magnified
                                the vision in her mind.&nbsp; She researched prominent bed and breakfasts in Victoria and around the island,
                                adding things she liked to her vision, and noting areas she would rather avoid.&nbsp; Everything was to be first
                                class, fulfilling her vision and creating a lifestyle that became ever more vivid with each visit to the coast.&nbsp; Naming the establishment 'Michelle's' added a final touch of class to the vision. </p>
    <p className="normalText">Michelle completed a B.A. at the University of Alberta in 1989, and took a job in Ottawa.&nbsp; She tried to save
                                money for start up capital, but the job couldn't provide her with enough cash to live an acceptable lifestyle
                                and save for the business.&nbsp; She estimated she would require $1 million to purchase the house, remodel it,
                                furnish it, and open the doors.&nbsp; After several disappointments that prevented her from putting away the money required, she decided to move to Victoria anyway, and continue pursuing her dream.&nbsp; Due to her
                                lack of business experience, she enrolled in the MBA program at the University of Victoria.&nbsp; Whenever
                                appropriate, she took the opportunity to work on her business.&nbsp; Each of her MBA, marketing, finance, and co-op assignments were centered on the Bed and Breakfast industry and the development of her business
                                plan. </p>
    <p className="normalText">To fulfill her vision, rooms would have to be rented out for a sizable rate.&nbsp; She estimated that to ensure
                                adequate cash flow, and to allow her the lifestyle she envisioned for herself, rooms would have to rent for between $300 to $500 per night.&nbsp; Michelle's would not allow children; it would be a getaway for
                                adults-only.&nbsp; She had a very specific clientele in mind, and every decision would be subverted to providing
                                an exceedingly comfortable, romantic experience.&nbsp; This focus was due less to market forces than to her own personal preference.</p>
    <p className="normalText">Michelle planned to market her Bed and Breakfast through channels that matched the commitment and
                                customer service she envisioned for her services.&nbsp; Like everything else in her business, the promotional
                                message and the vehicles she used to deliver it would have to match her high standards.&nbsp; However, she anticipated that her business would very quickly become reliant upon word of mouth, a development that
                                suited her plans just fine.&nbsp; </p>
    <p className="normalText">Michelle worked in a number of Bed and Breakfasts during her years in the MBA program.&nbsp; During the
                                program, she decided that she would not take the venture capital route; having someone else with a
                                significant stake in her vision might pollute the end product.&nbsp; Michelle had the experience, training, and vision,
                                and she wasn't interested in having to answer to investors.&nbsp; A large mortgage and debt financing were less
                                than ideal alternatives as well.&nbsp; Everything about her Bed and Breakfast would be well timed, and completed
                                to her satisfaction.&nbsp; Significant outside financing just wasn't in the big picture.&nbsp; She wasn't interested in anything that would compromise her dream.</p>
    <p className="normalText">Upon completion of her MBA, Michelle took an administration position in Victoria.&nbsp; The vision of Michelle's
                                Bed and Breakfast remained prominent in her mind.&nbsp; She kept her network of contacts in the industry, and
                                remained up to date on developments in the area.&nbsp; She had been presented with a number of opportunities to enter the business, but had turned them down due to financial constraints, and her own personal desires
                                for the business.&nbsp; </p>
    <p className="normalText">Michelle felt that the best way to enter the industry would be to redevelop an existing bed and breakfast,
                                rather than going to the expense and trouble of constructing an entirely new building, or renovating an existing house.&nbsp; Financial analyses and her own personal experience had shown her that the minimum number
                                of rooms she could afford to offer would be around 10 per night.&nbsp; Volume would have to be capped though,
                                in the interests of service and quality.&nbsp; Insurance was a primary consideration; full protection would be taken
                                to protect her business, and her lifestyle.&nbsp; This personal stance added to the costs, but served to protect her interests.</p>
    </section>
}