import React from 'react';

export const LongTermNeed = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Is There a Long-term Need?</h1>
    <p className="normalText">A long term need for a product extends the benefits of repetitive purchases, 
            and therefore enhances its persistence over time.&nbsp; For example, for centuries there was a repetitive need for the shoeing of horses.&nbsp; Eventually, however, other modes of transportation replaced the horse 
            as one of the primary engines of human movement over long distances, and the repetitive need for the services of the blacksmith was severely diminished.&nbsp; For years, the answer to the long term nature of 
                                "smithy services" was "yes." But at some point, it changed to "no." <br/><br/>Venturers must therefore be aware of the stage of their entrepreneurial discovery in the product life 
                                cycle.&nbsp; The four most commonly cited stages of the product life-cycle are: (1) product introduction, (2) growth, (3) maturity, (4) decline.&nbsp; Most often, by answering yes to the new combination question 
                                that is a part of Question A -- Is it innovative?, a venturer can take some comfort that the product or service will be at an early stage in the product life cycle. <br/><br/>Then, the venturer must consider the speed 
                                with which the life cycle is completed.&nbsp; The time horizon for personalized small-group transportation represented by the automobile, appears to be quite wide.&nbsp; The time horizon for the vacuum tube, the 
                                transistor, and now the silicon chip, is much shorter--with the need for particular varieties of chip being even shorter still. <br/><br/>Accordingly, if the volume targets that are necessary to achieve value are 
                                possible to achieve in light of your assessment of the "term" of life for your product or service, then the answer to the sub-question: Is it long term? can be yes.&nbsp; If the lifecycle stage or 
                                iteration time for the product or service is not long enough for you to achieve your volume target, then the answer to Question C: Is it Persistent Over Time? is NO--which means DON'T GO ON with a new venture until 
                                you have corrected the problem. <br/><br/>If you are assessing an operating business, you should seriously evaluate the premise upon which the business is based, since it is highly unwise to proceed with a venture 
                                where the time horizon during which the product or service will be purchased, is shorter than needed to meet the financial objectives of the venture.&nbsp; Your choice is to either lower ambitions (treat the venture 
                                as temporary, i.e. market the product as a fad), or to walk away. <br/><br/>(One hint: Where you do decide to create a venture to market a fad, you will [once time runs out] be required to either throw away all the 
                                effort that you expended in building the organization of your venture, or have something else for the company to do.) <br/>&nbsp;</p>
    </section>
}