import React from 'react';
import './NVTemplateResultsGraph.css';
import { CrossMark } from './CrossMark.component';


const getClassNameForLine = (line) => {
  switch (line) {
    case 1:
      return "emphasized-cartesian-line"
    case 5:
      return "emphasized-cartesian-line";
    case 9:
      return "emphasized-cartesian-line";
    default:
      return "cartesian-line"
  }
}


export const NVTemplateResultsBase = props => {
  const { b, k } = props;
  const lines = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const xLines = lines.map((line, i, arr) => {
    return (
      <line
        key={i}
        x1={'10%'}
        y1={`${10 * arr[i]}%`}
        x2={'90%'}
        y2={`${10 * arr[i]}%`}
        className={getClassNameForLine(line)}
      ></line>
    );
  });
  const yLines = lines.map((line, i, arr) => {
    return (
      <line
        key={i}
        x1={`${10 * arr[i]}%`}
        y1={'10%'}
        x2={`${10 * arr[i]}%`}
        y2={'90%'}
        className={getClassNameForLine(line)}
      ></line>
    );
  });

  const xAxisLabel = <g>
      {lines.map((line, i, arr) => {
        return (
          <React.Fragment key={'x'+line+i}>
            <text x={`${line * 10}%`} y="95%" className="text-anchor-middle">
              {line}
            </text>
            <line
              x1={`${line * 10}%`}
              y1="92%"
              x2={`${line * 10}%`}
              y2="90%"
              className="cartesian-line"
            ></line>
          </React.Fragment>
        );
      })}
      {lines.map((line, i, arr) => {
        return (
          <React.Fragment key={'y'+line + i}>
            <text
              x="7%"
              y={`${(10 - line) * 10 + 1}%`}
              className="text-anchor-middle"
            >
              {line}
            </text>
            <line
              x1="8%"
              y1={`${(10 - line) * 10}%`}
              x2="10%"
              y2={`${(10 - line) * 10}%`}
              className="cartesian-line"
            ></line>
          </React.Fragment>
        );
      })}
    </g>;

  const title = (
    <text x="2%" y="5%" className="title">
      New Venture Template Results
    </text>
  );

  const prototypePoints = Object.keys(prototypes).map(item => {
    const prototype = prototypes[item];
    const selectedPrototype =
      prototype.matchSelectedPrototypeName === props.selectedPrototype;

    return (
      <React.Fragment key={prototype.labelText}>
        {prototype.labelPosition.multiLineK &&
          prototype.labelText.split(/ {2}/g).map((word, i) => {
            return (
              <text
                key={word}
                x={positionBValue(prototype.b, prototype.labelPosition.adjustB)}
                y={positionKValue(prototype.k, prototype.labelPosition.adjustK)}
                dx={i > 0 ? `${prototype.labelPosition.multiLineB * i}%` : undefined}
                dy={i > 0 ? `${prototype.labelPosition.multiLineK * i}%` : undefined}
                className={`label text-anchor-${prototype.labelPosition.initial}`}
              >
                {word}
              </text>
            );
          })}
        {!prototype.labelPosition.multiLineK && (
          <text
            x={positionBValue(prototype.b, prototype.labelPosition.adjustB)}
            y={positionKValue(prototype.k, prototype.labelPosition.adjustK)}
            className={`label text-anchor-${prototype.labelPosition.initial}`}
          >
            {prototype.labelText}
          </text>
        )}
        <circle
          cx={positionBValue(prototype.b)}
          cy={positionKValue(prototype.k)}
          r={selectedPrototype ? '2%' : '1%'}
          className={`${
            selectedPrototype ? 'graph-selected-prototype' : 'circle'
          }`}
        />
      </React.Fragment>
    );
  });

  return (
    <svg className="nvt-results" width="500px" height="500px" preserveAspectRatio="xMidYMid meet">
      {xAxisLabel}
      {xLines}
      {yLines}
      {title}
      {prototypePoints}
      {b && k && <CrossMark b={b} k={k} positionBValue={positionBValue} positionKValue={positionKValue} />}
    </svg>
  );
};

const positionKValue = (k, adjust = 0) => {
  // 10 - k is to turn the coordinate plane
  const result = `${(10 - k) * 10 + adjust}%`;
  return result;
};

const positionBValue = (b, adjust = 0) => {
  // for x axis
  // value of 9 needs to return 90%
  // 8 80%
  // 7 70%
  return `${b * 10 + adjust}%`;
};

// Note: Double spaces are treated as line breaks in the label on the graph
// If you want to split a label onto multiple lines, you need to provide a value
// other than 0 for multiLineK.
const prototypes = {
  buyAJobSmallBusiness: {
    k: 3,
    b: 3,
    matchSelectedPrototypeName: 'bajsb',
    labelText: 'Buy-a-job small business',
    labelPosition: {
      initial: 'start',
      adjustK: 2,
      adjustB: 2,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  charity: {
    k: 5,
    b: 1,
    matchSelectedPrototypeName: 'ch',
    labelText: 'Charity',
    labelPosition: {
      initial: 'start',
      adjustK: -2,
      adjustB: 1,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  competenceBasedSuccess: {
    k: 7,
    b: 9,
    matchSelectedPrototypeName: 'cbs',
    labelText: 'Competence-  based "Success"',
    labelPosition: {
      initial: 'end',
      adjustK: 6.5,
      adjustB: 7,
      multiLineB: 3,
      multiLineK: 4
    }
  },
  competenceBasedTroubled: {
    k: 7,
    b: 5,
    matchSelectedPrototypeName: 'cbt',
    labelText: 'Competence-based  "Troubled"',
    labelPosition: {
      initial: 'end',
      adjustK: -2,
      adjustB: 0,
      multiLineB: -4,
      multiLineK: 5
    }
  },
  fad: {
    k: 2,
    b: 9,
    matchSelectedPrototypeName: 'f',
    labelText: 'Fad',
    labelPosition: {
      initial: 'start',
      adjustK: -2,
      adjustB: -3,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  highPotential: {
    k: 7,
    b: 7,
    matchSelectedPrototypeName: 'hp',
    labelText: 'High  potential',
    labelPosition: {
      initial: 'middle',
      adjustK: -6,
      adjustB: 0,
      multiLineB: 0,
      multiLineK: 4
    }
  },
  hobby: {
    k: 9,
    b: 1,
    matchSelectedPrototypeName: 'hob',
    labelText: 'Hobby',
    labelPosition: {
      initial: 'start',
      adjustK: 4,
      adjustB: 1,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  hostage: {
    k: 2,
    b: 5,
    matchSelectedPrototypeName: 'hos',
    labelText: 'Hostage',
    labelPosition: {
      initial: 'start',
      adjustK: 2,
      adjustB: 2,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  lifestyleSmallBusiness: {
    k: 5,
    b: 5,
    matchSelectedPrototypeName: 'lsb',
    labelText: 'Lifestyle  small business',
    labelPosition: {
      initial: 'middle',
      adjustK: -6,
      adjustB: 0,
      multiLineB: 0,
      multiLineK: 4
    }
  },
  lowCompetence: {
    k: 2,
    b: 2,
    matchSelectedPrototypeName: 'lc',
    labelText: 'Low  Competence',
    labelPosition: {
      initial: 'start',
      adjustK: -2,
      adjustB: 2,
      multiLineB: 0,
      multiLineK: 4
    }
  },
  modelVenture: {
    k: 9,
    b: 9,
    matchSelectedPrototypeName: 'mv',
    labelText: 'Model  Venture',
    labelPosition: {
      initial: 'middle',
      adjustK: 4,
      adjustB: 0,
      multiLineB: 0,
      multiLineK: 4
    }
  },
  researchProject: {
    k: 1,
    b: 1,
    matchSelectedPrototypeName: 'rp',
    labelText: 'Research project',
    labelPosition: {
      initial: 'start',
      adjustK: -1,
      adjustB: 1,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  strugglingProprietary: {
    k: 9,
    b: 5,
    matchSelectedPrototypeName: 'sp',
    labelText: 'Struggling proprietary',
    labelPosition: {
      initial: 'middle',
      adjustK: 5,
      adjustB: 0,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  technologyBasedSuccess: {
    k: 5,
    b: 9,
    matchSelectedPrototypeName: 'tbs',
    labelText: 'Technology-  based success',
    labelPosition: {
      initial: 'middle',
      adjustK: 5,
      adjustB: -2,
      multiLineB: 0,
      multiLineK: 4
    }
  }
};
