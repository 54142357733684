import React from 'react';
import { Link } from 'react-router-dom';

export const Flexibility = () => {
    return <section>
	<h1 className="pageTitle">The Fundamental of Flexibility</h1>
    <p className="normalText">
        One of the few certainties in venturing is that the business plan that
        you prepare for your venture will not describe what actually happens as
        you start and run it.&nbsp; Despite all the care taken in planning, projecting,
        and preparing to start a business, there are a sufficient number of "unplannable"
        events that occur to ensure that your plans will not come to fruition
        exactly as you have laid them out. <br/>
        <br/>
        This raises the question: But isn't there something that I can do to minimize
        this problem?&nbsp; The answer is: Yes--build flexibility into your venture.
        <br/>
        <br/>
        But as anyone knows who has, for example, tried to plan a "flexible
        vacation": with flexibility comes added complexity.&nbsp; Flexible
        plans can require much more effort to prepare than do the arbitrary, linear
        "to do" lists that we often mistake for flexible plans.&nbsp;
        It helps, therefore, to understand first, the nature of the "unplannable
        events" that create the need for flexibility, and second, what steps
        can be taken that deal effectively with each type of event--while minimizing
        the added complexity.&nbsp; How can you keep your venture flexible, with
        minimal added complexity? <br/>
	</p>
    <p className="sectionHeading1">Unplannable Events</p>
	<p className="normalText">Two types of unplannable events make our business plans unpredictable.&nbsp;
        The first set of events, we can classify under the heading: Uncertain
        Events.&nbsp; When we think of uncertainty, we think of "not knowing,"
        but what we really mean is "not knowing for sure."&nbsp; We
        may know that an event will eventually occur, but we may not know when.&nbsp;
        For example, on a production line we may know that every so often a defective
        product will be produced, but not which one or exactly when.&nbsp; Or,
        in the case of driving we may know that at some point in our life we will
        be probably involved in an accident, but we don't know the details of
        its occurrence in advance.&nbsp; Where there is a known event, but we
        are not sure of the timing (when) or the magnitude (how much), we refer
        to this as uncertainty. <br/>
        <br/>
        However, "not knowing for sure" is different from "not
        knowing at all."&nbsp; The second set of events that make our business
        plans unpredictable fall under the heading: Ambiguous Events.&nbsp; Events
        that have ambiguity surrounding their occurrence are those that cannot
        be anticipated, or that have very unclear consequences when they do occur.&nbsp;
        For example, it was not at all clear when the "horseless carriage"
        appeared, that it would totally replace the horse and buggy; or that the
        1973 conflict between Arab states and Israel would lead from oil embargo,
        to massive OPEC price hikes, and eventually to serious economic consequences
        throughout the world.&nbsp; Where there is an unknown event, or where
        known events may have unlikely consequences, we refer to this as ambiguity.
        <br/>
        <br/>
        The ability to adapt to changes in circumstances we call flexibility.&nbsp;
        Accordingly, the two questions that should be asked about a venture regarding
        flexibility are: <br/>
        <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A. <Link className="contentLink" to="/template/viability/uncertainty">Is uncertainty minimized?</Link> <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; B. <Link className="contentLink" to="/template/viability/ambiguity">Is ambiguity reduced?</Link> <br/>
        <br/>
        Since venture positioning is not a static exercise, the relaxation of
        "certainty constants" in strategic assumptions requires that
        entrepreneurs must deal also with uncertainty and ambiguity--hence the
        need for flexibility.&nbsp; Where the shape of the probability curve is
        known (Ghemawat, 1991), uncertainties may be managed through statistical
        procedures and plans, with the range of flexibility required somewhat
        restricted. <br/>
        <br/>
        Where there is disagreement upon the shape of the probability curve(s),
        ambiguity markedly increases the requirements for strategic flexibility
        (Ghemawat, 1991).&nbsp; The structural response (type of organizational
        relationships undertaken or used) to uncertainty and ambiguity (equivocality)
        has been reported by Galbraith (1977), and by Daft and Lengel (1986).&nbsp;
        Where uncertainty is low, routine seems to suffice.&nbsp; Where uncertainty
        is high and ambiguity is high unscheduled meetings and group problem solving
        are indicated. <br/>
        <br/>
        Even more importantly, the flexibility that comes through the proper management
        of uncertainty and ambiguity must yield adaptive responses in an uncertain
        and ambiguous world.&nbsp; Without such variations, changing environmental
        conditions which once supported the venture will become its enemy. "Selection
        FOR" will be replaced by "selection AGAINST."&nbsp; Without
        flexibility, inertial organizations become the dinosaurs of evolutionary
        economic history.&nbsp; Entrepreneurs who concentrate only upon building
        from a unique initial product or vision will find that products and ideas
        soon go out of date.&nbsp; It is only through the flexibility that comes
        with a commitment to consider the ADAPTIVE ORGANIZATION as their ULTIMATE
        CREATION, (Collins &amp; Porras, 1995) that entrepreneurs build sufficient
        flexibility into their ventures. <br/>
        <br/>
        Another way to look at "staying flexible" is to draw a mental
        line between this step in your New Venture Template™ analysis, and
        steps "A" through "E" (a feedback loop, if you will).&nbsp;
        Periodically reexamining the currency and validity of your answers to
        A: Innovativeness, B: Value, C: Persistence Over Time, D: Scarcity, and
        E: Non-Appropriability is another way for you to become aware of the adaptations
        that you will need to make for your venture to survive and prosper over
        the long term.<br/>
        &nbsp;</p>
    </section>
}