import React from 'react'
import { Link } from 'react-router-dom';

export const Landing = (props) => {
  return <><h1 className="pageTitle">Welcome</h1>
    <p className="normalText">The New Venture Web site is the academic version of the
        award winning expert assessment tools The NewVenture Template and the NewVenture Profile.
        These online tools can help anyone involved with starting a venture, anyone involved
       in improving an on-going business and any corporation evaluating new business opportunities.
    </p>
    <p className="normalText">These proprietary assessment tools dramatically improve the chances for venture success:
        </p><ul>
            <li className="normalText">The New Venture <Link to="/template" className="contentLink">Template</Link> evaluates and builds the business venture in terms of specific high-value criteria and provides targeted venture engineering recommendations.</li>
            <li className="normalText">The New Venture <Link to="/profile" className="contentLink">Profile</Link> evaluates your entrepreneurial readiness.</li>
        </ul>
    <p></p>
    <p className="normalText">The NewVenture assessment tools are accessible only to members of the site.</p>
    </>
}