import React from 'react';

export const Competence = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - The Level of Core Competence</h1>
    <p className="normalText">The core competence of the <i>Venturing Team</i> addresses the overarching question: "Can you do it?" It's an 
		examination of management's experience and specialization in venturing.&nbsp; Ideally, key individuals have:
    </p>
    <ul style={{ listStyleType: "disc", listStyle: "inside"}}>
		<li className="normalText">Started three or more business ventures—at least one of which is a profitable on-going entity<br/>&nbsp;</li>
        <li className="normalText">Started at least one business venture that has been in existence for two years or more<br/>&nbsp;</li>
        <li className="normalText">Significant career experience and knowledge—highly familiar with new venture formation<br/>&nbsp;</li>
        <li className="normalText">Elaborate knowledge about new businesses—due to observation of many variations <br/>&nbsp;</li>
        <li className="normalText">Awareness of many new venture situations—some which succeeded, others which failed, and why<br/>&nbsp;</li>
        <li className="normalText">Highly-developed contacts in the venturing community<br/>&nbsp;</li>
        <li className="normalText">Failed in at least 1 new venture (<i>ideally small-scale w/o huge investment/loss</i>)</li>
    </ul>
    <p className="normalText">In addition, the Venturing Team has experience and specialization in the venture itself:</p>
    <ul>
        <li className="normalText">Familiar with the industry<br/>&nbsp;</li>
        <li className="normalText">Worked for at least five years in the industry<br/>&nbsp;</li>
        <li className="normalText">Can perform specialized tasks critical to the venture's success</li>
    </ul>
    </section>
}