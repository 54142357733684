import React, {useState, useEffect, useContext} from 'react';
import {rootUrl} from '../utils/rootUrl';
import {Redirect} from 'react-router-dom';
import "./Login.css"
import {AuthenticationContext} from '../context';
import {Loader} from '../Loader/Loader.component';

export function Login(props) {
  const [userName, setUserName] = useState("");
  const [userPass, setUserPass] = useState("");
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [errors, setErrors] = useState(false);

  const {jwtVerfied, isVerifyingJwt, setUser, setJwtVerified, setShouldVerifyJwt, setIsVerifyingJwt, loggedIn, setLoggedIn} = useContext(AuthenticationContext);

  useEffect(() => {
    if (triggerSubmit) {
      setTriggerSubmit(false);
      submitLogin(userName, userPass);

      async function submitLogin(userName, userPass) {
        try {
          const response = await fetch(`${rootUrl}/api/login`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              username: userName,
              password: userPass
            })
          })
          const parsedResponse = await response.json();
          if (parsedResponse.authorized) {
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME, parsedResponse.token);
            setLoggedIn(true);
            if (parsedResponse.user) {
              setUser(parsedResponse.user);
              setJwtVerified(true);
              setLoggedIn(true);
              setShouldVerifyJwt(false);
            }
            return setIsVerifyingJwt(false)
          } else {
            if (parsedResponse.error) setErrors(parsedResponse.error);
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [triggerSubmit, userName, userPass, setIsVerifyingJwt, setJwtVerified, setLoggedIn, setShouldVerifyJwt, setUser]);

  if (isVerifyingJwt) return <Loader>Authenticating ... </Loader>
  if (jwtVerfied || loggedIn) return <Redirect to="/authenticated" />;
  return <section style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
    <div className="pageTitle" >The New Venture Login</div>
    <p className="normalText no-indent">Registered users, login below.</p>
    <div
      name="login-form"
      className="login-form"
      style={{display: "flex", flexDirection: "column"}}
    >
      <form>
      <label>
        <input
          autoComplete="username"
          className="login-input"
          type="text"
          placeholder="Username"
          onChange={e => setUserName(e.target.value)}
        >
        </input>
      </label>
      <label>
        <input
          autoComplete="current-password"
          className="login-input"
          type="password"
          onChange={e => setUserPass(e.target.value)}
          placeholder="Password"
        ></input>
      </label>
      </form>
      <button
        className="login-button"
        onClick={() => setTriggerSubmit(true)}
      >
        Login
      </button>
    </div>
    {errors && <div><h2 className="login-form-error">{errors}</h2></div>}
    <p className="normalText no-indent" style={{marginTop: "4rem"}}>If you have forgotten your password, please contact your instructor.</p>
  </section>
}