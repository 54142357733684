import React from 'react';

export function ProfileQuestionnaire(props) {

	return <><section>
		<h1 className="pageTitle">New Venture Profile Questionnaire</h1>
		<table width="100%" cellpadding="0" cellspacing="0" border="0">
			<tbody>
				<tr bgcolor="#BFB4A2"> {/* <!---#B0AFAF--->} */}
					<td className="normalText">Step 1</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 2</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 3</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 4</td>
					<td width="50%">&nbsp;&nbsp;</td>
				</tr>
			</tbody>
		</table>
		<form name="experience" action="nvpQuestionnaire2.asp?NewInput=Yes" method="post">
			<table width="100%" cellpadding="0" cellspacing="0" border="0">
				<tbody>
					<tr bgcolor="#BFB4A2">
						<td className="normalText" valign="top">1.</td>
						<td className="normalText" colspan="3">I am rarely surprised by:</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q1" value="0" /></td>
						<td className="normalText">developments in a new business</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q1" value="1" /></td>
						<td className="normalText">human nature</td>
					</tr>
					<tr>
						<td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td>
					</tr>
					<tr bgcolor="#BFB4A2">
						<td className="normalText" valign="top">2.</td>
						<td className="normalText" colspan="3">Are you more attracted to people who are:</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q2" value="0" /></td>
						<td className="normalText">ready to take action</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q2" value="1" /></td>
						<td className="normalText">thoroughly informed</td>
					</tr>
					<tr>
						<td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td>
					</tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">3.</td><td className="normalText" colspan="3">I have more highly developed contacts in the:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q3" value="0" /></td>
						<td className="normalText">new venture area specifically</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q3" value="1" /></td>
						<td className="normalText">community generally</td>
					</tr>
					<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">4.</td>
						<td className="normalText" colspan="3">If asked to give my time to a new business I would decide
					based on how this venture fits:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q4" value="0" /></td>
						<td className="normalText">into my past experience</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q4" value="1" /></td>
						<td className="normalText">my values</td>
					</tr>
					<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">5.</td><td className="normalText" colspan="3">There are:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q5" value="0" /></td>
						<td className="normalText">times when after I finish a job I wish that I had done it better,
					or worked harder at it</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q5" value="1" /></td>
						<td className="normalText">never any jobs or tasks I complete which need more work</td>
					</tr>
					<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">6.</td><td className="normalText" colspan="3">My knowledge about new businesses:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q6" value="0" /></td>
						<td className="normalText">is fairly elaborate, due to the many variations I have observed</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q6" value="1" /></td>
						<td className="normalText">comes from my intuition; each new business has a "personality"
					which can be sensed</td>
					</tr>
					<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">7.</td><td className="normalText" colspan="3">When investing in a new venture, I think it is worse to:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q7" value="0" /></td>
						<td className="normalText">wait too long, and miss a great opportunity</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q7" value="1" /></td>
						<td className="normalText">plunge in without enough information to know the real risks</td>
					</tr>
					<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">8.</td><td className="normalText" colspan="3">I own assets such as:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q8" value="0" /></td>
						<td className="normalText">proprietary technology, patents, or an operating business</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q8" value="1" /></td>
						<td className="normalText">mutual funds, real estate, or savings accounts</td>
					</tr>
					<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">9.</td><td className="normalText" colspan="3">When confronted with a new venture problem I can:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q9" value="0" /></td>
						<td className="normalText">recall quite vividly the details of similar situations I know about</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q9" value="1" /></td>
						<td className="normalText">usually figure out what to do, even if it is by trial and error</td>
					</tr>
					<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">10.</td><td className="normalText" colspan="3">I have:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q10" value="0" /></td>
						<td className="normalText">occasionally divulged a confidence when I shouldn't have</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q10" value="1" /></td>
						<td className="normalText">never gossiped or told embarrassing things I know about other people</td>
					</tr>
					<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
					<tr bgcolor="#BFB4A2"><td className="normalText" valign="top">11.</td><td className="normalText" colspan="3">When someone describes a problem with a new business I:</td></tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q11" value="0" /></td>
						<td className="normalText">recognize key features of the problem quickly, and can suggest alternatives
					from examples I can cite</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q11" value="1" /></td>
						<td className="normalText">use my instincts to suggest questions which should be asked to solve the problem</td>
					</tr>
					<tr>
						<td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td>
					</tr>
					<tr bgcolor="#BFB4A2">
						<td className="normalText" valign="top">12.</td>
						<td className="normalText" colspan="3">Is it worse to:</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q12" value="0" /></td>
						<td className="normalText">waste your time thinking over an opportunity</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td className="normalText"><input type="radio" name="Q12" value="1" /></td>
						<td className="normalText">commit time and money to a cause that may not succeed</td>
					</tr>
				</tbody>
			</table>
			<p className="normalText" align="center">
				<input type="submit" value="Goto Step 2" name="Submit" onclick="return ValidateProfileData(this.form)" />
			</p>
		</form>
	</section>
		<section>
			{/* second page */}
			<h1 className="pageTitle">New Venture Profile Questionnaire</h1>
			<table width="100%" cellpadding="0" cellspacing="0" border="0">
				<tbody>
					<tr bgcolor="#BFB4A2"> {/* <!---#B0AFAF---> */}
						<td className="normalText">Step 1</td>
						<td className="normalText">&gt;&gt;</td>
						<td className="normalText">Step 2</td>
						<td className="normalText">&gt;&gt;</td>
						<td className="normalText">Step 3</td>
						<td className="normalText">&gt;&gt;</td>
						<td className="normalText">Step 4</td>
						<td width="50%">&nbsp;&nbsp;</td>
					</tr>
				</tbody>
			</table>
			<form name="experience" action="nvpQuestionnaire3.asp?NewInput=Yes" method="post">
				<table width="100%" cellpadding="0" cellspacing="0" border="0">
					<tbody><tr bgcolor="#BFB4A2">
						<td className="normalText" valign="top">13.</td>
						<td className="normalText" colspan="3">I have personally:</td>
					</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q1" value="0" /></td>
							<td className="normalText">earned 150% compounded return per year on at least 3 ventures over
					3 years, in cash</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q1" value="1" /></td>
							<td className="normalText">not earned 150% compounded return per year on at least 3 ventures
					over 3 years, in cash</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">14.</td>
							<td className="normalText" colspan="3">My new venture is/will be:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q2" value="0" /></td>
							<td className="normalText">protected from competition by patent, secret technology or knowledge</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q2" value="1" /></td>
							<td className="normalText">based on a product or service with no "barriers to entry"</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">15.</td>
							<td className="normalText" colspan="3">I have:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q3" value="0" /></td>
							<td className="normalText">sometimes said mean, spiteful or hateful things to people close to me</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q3" value="1" /></td>
							<td className="normalText">never spoken in anger to close associates, friends or people I love</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">16.</td>
							<td className="normalText" colspan="3">It is more important to know about:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q4" value="0" /></td>
							<td className="normalText">creating new ventures</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q4" value="1" /></td>
							<td className="normalText">business in general - staying diversified</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">17.</td>
							<td className="normalText" colspan="3">I want to get:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q5" value="0" /></td>
							<td className="normalText">a piece of the big money</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q5" value="1" /></td>
							<td className="normalText">through life financially in one piece</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">18.</td>
							<td className="normalText" colspan="3">I presently:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q6" value="0" /></td>
							<td className="normalText">control acquisition or expansion funds in an ongoing business, or
					have my own funds available for venturing</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q6" value="1" /></td>
							<td className="normalText">will need to raise financing for my venture from third parties</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">19.</td>
							<td className="normalText" colspan="3">New ventures, small business, and entrepreneurship:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q7" value="0" /></td>
							<td className="normalText">are distinctly different disciplines</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q7" value="1" /></td>
							<td className="normalText">have much in common, especially the need for sharp guesswork</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">20.</td>
							<td className="normalText" colspan="3">In the last 3 years:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q8" value="0" /></td>
							<td className="normalText">the size of the pool of people and assets I control has grown</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q8" value="1" /></td>
							<td className="normalText">I have not extended my business control over people or assets</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">21.</td>
							<td className="normalText" colspan="3">I have:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q9" value="0" /></td>
							<td className="normalText">occasionally felt envious enough of the possessions of other people
					to think about stealing</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q9" value="1" /></td>
							<td className="normalText">never thought about committing a dishonest act</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">22.</td>
							<td className="normalText" colspan="3">I like to read:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q10" value="0" /></td>
							<td className="normalText">periodicals which deal specifically with new ventures and start-up businesses</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q10" value="1" /></td>
							<td className="normalText">a wide variety of periodicals which keep me up to date on potential
					business or investment opportunities</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">23.</td>
							<td className="normalText" colspan="3">Imagine you have just funded a new venture: Would you be worried about:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q11" value="0" /></td>
							<td className="normalText">not investing enough</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q11" value="1" /></td>
							<td className="normalText">the strength of the competition</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">24.</td>
							<td className="normalText" colspan="3">I have:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q12" value="0" /></td>
							<td className="normalText">started at least 3 successful new ventures</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q12" value="1" /></td>
							<td className="normalText">not started at least 3 successful new ventures</td>
						</tr>
					</tbody></table>
				<p className="normalText" align="center">
					<input type="submit" value="Goto Step 3" name="Submit" onclick="return ValidateProfileData(this.form)" />
				</p>
			</form>



			{/* /// section 3 */}
			<h1 className="pageTitle">New Venture Profile Questionnaire</h1>

			<table width="100%" cellpadding="0" cellspacing="0" border="0">
				<tbody><tr bgcolor="#BFB4A2">
					{/* <!---#B0AFAF---> */}
					<td className="normalText">Step 1</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 2</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 3</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 4</td>
					<td width="50%">&nbsp;&nbsp;</td>
				</tr></tbody></table>
			<form name="experience" action="nvpQuestionnaire4.asp?NewInput=Yes" method="post">
				<table width="100%" cellpadding="0" cellspacing="0" border="0">
					<tbody><tr bgcolor="#BFB4A2">
						<td className="normalText" valign="top">25.</td>
						<td className="normalText" colspan="3">I value:</td>
					</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q1" value="0" /></td>
							<td className="normalText">high payoffs; intelligent craftsmanship; being one-up; well-organized
					projects; dependability</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q1" value="1" /></td>
							<td className="normalText">action; optimism; generosity; responsibility; feedback; pleasing people</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">26.</td>
							<td className="normalText" colspan="3">During the last 3 years, it is the general consensus that my
					performance as an entrepreneur:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q2" value="0" /></td>
							<td className="normalText">has increased</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q2" value="1" /></td>
							<td className="normalText">has stayed about the same or decreased</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">27.</td>
							<td className="normalText" colspan="3">I am more:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q3" value="0" /></td>
							<td className="normalText">aware of many new venture situations; some which succeeded, and others
					which failed, and why</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q3" value="1" /></td>
							<td className="normalText">familiar with my own affairs, but keep up on business in general</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">28.</td>
							<td className="normalText" colspan="3">If you had additional money to put to work, would you put it into a venture:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q4" value="0" /></td>
							<td className="normalText">where you have a "say," even if there is no track record</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q4" value="1" /></td>
							<td className="normalText">managed by those you trust, who have a proven track record</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">29.</td>
							<td className="normalText" colspan="3">New venture success:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q5" value="0" /></td>
							<td className="normalText">follows a particular script</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q5" value="1" /></td>
							<td className="normalText">depends heavily on the pluses and minuses in a given situation</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">30.</td>
							<td className="normalText" colspan="3">If I try to assess the condition of a new business:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q6" value="0" /></td>
							<td className="normalText">a few questions lead to the relevant information</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q6" value="1" /></td>
							<td className="normalText">total immersion in the business most effectively leads to relevant information</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">31.</td>
							<td className="normalText" colspan="3">I don't mind:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q7" value="0" /></td>
							<td className="normalText">being committed to meet a regular payroll if it means that I can have
					a chance at greater financial success</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q7" value="1" /></td>
							<td className="normalText">giving a little of the value I create to the company that hired me</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">32.</td>
							<td className="normalText" colspan="3">I am looking for a:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q8" value="0" /></td>
							<td className="normalText">place to invest my resources</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q8" value="1" /></td>
							<td className="normalText">better way to manage my resources</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">33.</td>
							<td className="normalText" colspan="3">Would you say you are more:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q9" value="0" /></td>
							<td className="normalText">action oriented</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q9" value="1" /></td>
							<td className="normalText">accuracy oriented</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">34.</td>
							<td className="normalText" colspan="3">I have:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q10" value="0" /></td>
							<td className="normalText">failed in at least 1 new venture</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q10" value="1" /></td>
							<td className="normalText">never failed in a new venture</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">35.</td>
							<td className="normalText" colspan="3">My new venture is/will be:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q11" value="0" /></td>
							<td className="normalText">protected from competition by franchise or other territory restrictions</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q11" value="1" /></td>
							<td className="normalText">based on a product or service which may experience a lot of competition
					within a territory</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">36.</td>
							<td className="normalText" colspan="3">I could:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q12" value="0" /></td>
							<td className="normalText">raise money for a venture if I didn't have enough</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q12" value="1" /></td>
							<td className="normalText">provide an investor with a lot of very good ideas for a new venture</td>
						</tr>
					</tbody></table>
				<p className="normalText" align="center">
					<input type="submit" value="Goto Step 4" name="Submit" onclick="return ValidateProfileData(this.form)" />
				</p>
			</form>


			{/* ///      section 4 */}
			<h1 className="pageTitle">New Venture Profile Questionnaire</h1>

			<table width="100%" cellpadding="0" cellspacing="0" border="0">
				<tbody><tr bgcolor="#BFB4A2">
					{/* <!---#B0AFAF---> */}
					<td className="normalText">Step 1</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 2</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 3</td>
					<td className="normalText">&gt;&gt;</td>
					<td className="normalText">Step 4</td>
					<td width="50%">&nbsp;&nbsp;</td>
				</tr></tbody></table>
			<form name="experience" action="nvpResults.asp?NewInput=Yes" method="post">
				<table width="100%" cellpadding="0" cellspacing="0" border="0">
					<tbody><tr bgcolor="#BFB4A2">
						<td className="normalText" valign="top">37.</td>
						<td className="normalText" colspan="3">Do you want things:</td>
					</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q1" value="0" /></td>
							<td className="normalText">open to the possibilities</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q1" value="1" /></td>
							<td className="normalText">settled and decided</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">38.</td>
							<td className="normalText" colspan="3">I have:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q2" value="0" /></td>
							<td className="normalText">enormous drive, but sometimes need others' help to complete projects</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q2" value="1" /></td>
							<td className="normalText">a high respect for service, generosity, and harmony</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">39.</td>
							<td className="normalText" colspan="3">I understand how to:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q3" value="0" /></td>
							<td className="normalText">buy low and sell high</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q3" value="1" /></td>
							<td className="normalText">build a terrific team</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">40.</td>
							<td className="normalText" colspan="3">The new venture stories I recall:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q4" value="0" /></td>
							<td className="normalText">illustrate principles necessary for success</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q4" value="1" /></td>
							<td className="normalText">are a telling commentary on the foibles of human nature which can
					rarely be predicted</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">41.</td>
							<td className="normalText" colspan="3">Are you more comfortable in:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q5" value="0" /></td>
							<td className="normalText">new situations</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q5" value="1" /></td>
							<td className="normalText">familiar territory</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">42.</td>
							<td className="normalText" colspan="3">I feel more confident:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q6" value="0" /></td>
							<td className="normalText">that I know a lot about creating new ventures</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q6" value="1" /></td>
							<td className="normalText">in my overall business sense</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">43.</td>
							<td className="normalText" colspan="3">I like:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q7" value="0" /></td>
							<td className="normalText">getting buyers and sellers together</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q7" value="1" /></td>
							<td className="normalText">dealing with the surprises which come as a part of everyday operations</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">44.</td>
							<td className="normalText" colspan="3">When I see a business opportunity I decide to invest based upon:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q8" value="0" /></td>
							<td className="normalText">how closely it fits my "success scenario"</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q8" value="1" /></td>
							<td className="normalText">whether I sense that it is a good investment</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">45.</td>
							<td className="normalText" colspan="3">I:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q9" value="0" /></td>
							<td className="normalText">can often see opportunities for my plans to fit with those of other people</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q9" value="1" /></td>
							<td className="normalText">rarely find that results match what I expect</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">46.</td>
							<td className="normalText" colspan="3">If you have a lot of free time available, is it more desirable to:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q10" value="0" /></td>
							<td className="normalText">find a new venture to put your time and expertise into</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q10" value="1" /></td>
							<td className="normalText">take the opportunity for some well deserved recreation or travel</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">47.</td>
							<td className="normalText" colspan="3">I am very:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q11" value="0" /></td>
							<td className="normalText">good at a specialty that is in high demand</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q11" value="1" /></td>
							<td className="normalText">well-rounded, with broad expertise in a variety of areas</td>
						</tr>
						<tr><td colspan="3"><img class="navImageSelected" src="../images/lineSpacer.jpg" alt="spacer" /></td></tr>
						<tr bgcolor="#BFB4A2">
							<td className="normalText" valign="top">48.</td>
							<td className="normalText" colspan="3">I often:</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q12" value="0" /></td>
							<td className="normalText">see ways in which a new combination of people, materials, or products
					can be of&nbsp; value</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td className="normalText"><input type="radio" name="Q12" value="1" /></td>
							<td className="normalText">find differences between how I see situations and others' perspective</td>
						</tr>
					</tbody></table>
				<p className="normalText" align="center">
					<input type="submit" value="Goto Results" name="Submit" onclick="return ValidateProfileData(this.form)" />
				</p>
			</form>

		</section></>
}