import React from 'react';

export const Ambiguity = (props) => {
    return <>
    <h1 className="pageTitle">Viability - Is Ambiguity Reduced?</h1>
<p className="normalText">First the bad news: Ambiguity in a venture is virtually infinite, and capably dealing 
with it is almost impossible.&nbsp; Yet having said this, we must observe that there are still benefits from trying to deal with ambiguity.&nbsp; Because, failing to attempt to reduce ambiguity separates the 
ventures that continue over the long term, from those that become extinct.</p>
<p className="normalText">There is a very persuasive argument made by population ecology scholars (Hannan &amp; Freeman, 1989) that the characteristics of a 
venture at or near the time it is founded will vary little over its life, and that because of this "inertia," the venture will eventually be "selected out" of existence through the ecological 
mechanisms of the market system.</p>
<p className="normalText">This is not an encouraging observation.&nbsp; In fact, it leads many to the conclusion that it is "luck" in the face of ambiguity that accounts for the survival of 
some organizations over others.&nbsp; This section suggests methods that make this type of "luck" more likely, and offers methods to mitigate the tyranny of inertia. <br/><br/>
As human beings, we are limited (at least) by: <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.our individuality (we have limited access to the minds of others), and by <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.our mortality (just about the time we get a useful collection of insights we die).</p>
<p className="normalText">These limitations are especially applicable in dealing with ambiguity in new ventures, 
since organizations involve the collectivization of knowledge and action, and (theoretically at least) are capable of existing in perpetuity.&nbsp; The challenge, it seems, is to use the inherent benefits of 
organization to reduce the negative impacts of inertia (in the case of organizations), and of human limitations (in the case of us as individuals)--a tall order.&nbsp; The key to reducing ambiguity, then, lies 
within particular decisions with regard to organizing.&nbsp; That is, how you organize will affect how long you survive.</p>
<p className="normalText">Take, for example, the type of organization that the Romans used to revolutionize 
warfare.&nbsp; Called the "cohort," it consisted of approximately 50 soldiers in an enclosed rectangle with shields up, 
and spears extended through small, defensible openings.&nbsp; Against most 
"barbarians" on foot it was formidable.&nbsp; Against the sweeping mobility of Asian hordes on horseback, it was laughable.</p>
<p className="normalText">This example illustrates the weakness of isomorphism (becoming 
"like" your surroundings) when drastic changes are taking place and your surroundings change significantly and unexpectedly.&nbsp; 
Where organizations are homogeneous, they tend to be very effective in 
times of stability (Murray, 1989), but incapable under conditions of ambiguity.&nbsp; However, where organizations are heterogeneous, they tend to do better in ambiguous and turbulent times (ibid.).&nbsp; So the 
first idea that you can use to reduce ambiguity is to include some heterogeneity (differences) in your organization. </p>
<p className="normalText">Another decision that must be made when organizing, is the structure of your 
decision-making mechanisms.&nbsp; Decisions involving low ambiguity are better made by individuals who refer to procedures and policies for guidance.&nbsp; Decisions that are increasingly ambiguous may benefit from 
heterogeneous perspectives, and will therefore be improved through group processes that are increasingly unstructured (Daft &amp; Lengel, 1986; Galbraith, 1977). <br/><br/>What does this all mean for reducing 
ambiguity?&nbsp; It means that somewhere in your organizational structure you should have a high-level group that is heterogeneous in nature.&nbsp; It doesn't necessarily mean that it should be your management team, 
or your board of directors, though it could be, if sufficiently broad in its perspective.&nbsp; More likely it should take the form of an "advisory board," a group of individuals chosen for their breadth 
of experience and uniqueness of perspective, who are familiar with the organization and its aims, and who have direct access to decision makers (mainly the CEO and top management team). <br/><br/>Included in this 
group could be people from a variety of ethnic, gender, and geographical backgrounds; a wide range of professions (from education, the arts, home makers, and clergy, to science, medicine, entertainment, and of 
course business and politics); and a smattering of loyalists (mentors who have known you--and your weaknesses and blindspots--for years).&nbsp; It takes great courage to expose your plans and dreams, your successes 
and failures to a group such as this.&nbsp; But it seems that it is only through this kind of broad scope exposure that the leading indicators of the "sea changes" that are the drivers of ambiguity can be 
foreseen, and acted upon in anywhere close to a timely manner. </p>
<p className="normalText">For a beginning venture, DON'T STOP, until you have built your ambiguity management "screens" into your system.&nbsp; For in the end 
you will not be able to continue if you do not manage ambiguity, because your venture will be "selected out" due to its inertial nature and the insularity of its leaders.&nbsp; Forming a heterogeneous 
advisory board that meets at least semi-annually is a small and actually quite pleasant price to pay for long term survival, and for the capability to "estimate the shape of the probability curves" that 
will operate as the world surrounding your venture continues to change.</p>
</>

}