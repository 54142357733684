import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import {NVTemplateResultsGraph} from '../../svg/NVTemplateResultsGraph.component';
import {Link} from 'react-router-dom';

export const Charity = () => {

    return <section>
        <h1 className="pageTitle">The Charity Prototype</h1>
        <p className="normalText">When a venture lacks many of the elements that make it a business, but still has "staying
        power" in society, we generally find a Charity.&nbsp; A Charity is uneconomic without help
        because, though a long-term benefit to society (notice in the prototype--target or "bulls-eye" diagram --that <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link> is medium to high) there is insufficient <Link className="contentLink" to="/template/viability/volume">Volume</Link> and <Link className="contentLink" to="/template/viability/margin">Margins</Link> to make it a self-sustaining business (evidenced in the prototype by low <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match)</Link>.
	</p>
        <p className="normalText">So we find as charities organizations that vary from opera and symphony companies to other community, educational, or religious groups that exist because they depend upon the "charity" of society to make up the shortfalls that come from lack of profitable delivery of their product or service.</p>
        <p className="normalText">You will also notice in the same prototype diagram that (1) the <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link> level is immaterial to marketability, being insufficient to propel the product/service into profit-making status; (2) <Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link> and <Link className="contentLink" to="/template/fundamentals/non-appropriability">Appropriability</Link> are medium, due to weak economic standing; (3) <Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link> is medium due to community support, with <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link> somewhere in the medium range due to the basically uneconomic nature of the profit-making lack in the organization.</p>
        <div className="prototype-diagrams">
            <p className="sectionHeading2">Figure 11: "B/K" Diagram</p>
            <NVTemplateResultsGraph selectedPrototype="ch" />
            <p className="sectionHeading2">Figure 12: Target "Bulls-eye" Diagram</p>
            <BullsEye prototype={"Charity"} />
        </div>
        <p className="sectionHeading1">ADVICE:</p>
        <p className="normalText">To turn a Charity into a business, it is necessary to address the fundamental problems that render the organization unable to exist without help from society.&nbsp; These are: (1) problems with the <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link> [there's just not a big market for this stuff]; (2) because of the low <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link> there are problems with the <Link className="contentLink" to="/template/viability/margin">Margins</Link> [although people value the product/service, the costs are much higher than the few who value it highly can fund by themselves]; which explains (3) problems with the <Link className="contentLink" to="/template/viability/volume">Volume</Link>.&nbsp; Though many "purists" resist, the solution is to "commercialize" the product or service, i.e. make it accessible to and desirable for larger portions of society.</p>
        <p className="normalText">Where an organization is likely to depend upon society over the long term, it is an error to promise
        supporters a return on their contributions beyond that of "recognition" for their help.&nbsp; If used properly, the
        "sale" of recognition is a "second product" that if properly marketed, can guarantee a Charity the <Link className="contentLink" to="/template/viability/margin">Margins</Link> and <Link className="contentLink" to="/template/viability/volume">Volume</Link> that come from this NEW <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>. The key is to fund such organizations as charities, and NOT as businesses.</p>
        <p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
        <p className="sectionHeading2">The Supreme Opera Company (SOC) -- <i>The Charity</i></p>
        <p className="normalText">
            Jerry Grant was reading the headlines of the Financial Post. He nodded his head noting an article about new
            limitations on public funding of the non-profit organizations. During the last 10 years Jerry had voluntarily completed the
            financial planning and management of the Supreme Opera Company, on a part time basis. It
            was the first week of October and Jerry was deep in the process of preparing the next year's budget. Winter had arrived
		early and Jerry was concerned about the adverse effects of an early, cold winter on ticket sales.</p>
        <p className="sectionHeading2">BACKGROUND</p>
        <p className="normalText">SOC is located in a mid-mountain state. Hockey, basketball, deer hunting and 'Monster Trucks' are the
        main social attractions. The company's objective is to produce world class European Opera. As the social environment is not receptive to diverse varieties, SOS carries the popular opera such as Madame Butterfly
                                and The Merry Widow. Some previous experiences with modern Italian music were disastrous and only 15% of the seats were sold.</p>
        <p className="normalText">The company performs opera productions in fall, winter and spring, for total 3 productions per year.&nbsp; Each
        production lasts 10 days, running four performances each session. Most performers and choristers are from the local community. They take lessons and practice months in advance of each performance. About 3
        weeks before each performance the 'big stars' come to town for 2-3 days of partial-dress rehearsals. One week before each performance, they return again and do a full-dress rehearsal with the local choirs and
                                performers before the actual stage performances.</p>
        <p className="sectionHeading2">THE THEATRE</p>
        <p className="normalText">The theatre has over 1800 seats on the main floor and nearly 100 seats in the grand tiers and balconies. It was originally built with public funding. A recent up-grade and renovation has put the theatre in a perfect
                                shape and fit for superior performances.&nbsp; </p>
        <p className="normalText">Ticket prices are $15 a seat on average. This price is not sufficient to cover the costs: A sold out house
        would cover only 60 % of the expenditure.&nbsp; Market research had indicated that it was not possible to increase price as it would lead to dramatic reductions in demand and revenues. The municipality contributes
                                to the Opera Company in order to compensate their deficits.&nbsp; Last year's budget was $1 million including $400,000 provided by the municipality.</p>
        <p className="normalText">Charles Moser, the local company director, is doing a superb job. He studied theater and opera in Austria
        and performed in all the major opera houses in Europe. He is widely acclaimed, and has come to the Supreme Opera Company to build a superb opera house at his hometown. Charles is an expert in listening
                                to, coaching and directing a voice. He and his team have superior capabilities and can perform at a world class competitive level.</p>
        <p className="sectionHeading2">PERIPHERAL BENEFITS</p>
        <p className="normalText">Although SOC is a non-profit organization, it has a positive impact on the town's economy. About 30% of the audience travels from the surrounding towns. Their transport and accommodation expenditures boost the
                                local economy. The cultural atmosphere created by the Opera Company has an influence on the people's choice of clothing and their spending on items such as books and musical records.</p>
        <p className="sectionHeading2">SALES AND MARKETING</p>
        <p className="normalText">SOC uses the simplest possible methods for generating ticket sales and membership subscriptions. Tickets are sold for single programs, and small discounts are given for yearly subscriptions. Ticket reservation and
        sales are handled manually.&nbsp; The added expense of an automated system simply isn't in the budget, although
                                Jerry felt modern information technology could provide customers with better services and reduce personnel costs. </p>
        <p className="normalText">As he drove to the office, Jerry considered the benefits of a more effective marketing and soliciting system.
        He felt that placing a sales manager in this area would at least provide interesting results. He decided to ask Steve Robinson, the Human Resources Manager, to evaluate an organizational change. In his mind, Jerry
        was searching for revenue generating ideas. He was concerned that the municipality might not continue fully
        compensating their future deficits. An idea came to his mind to ask the local businesses to give donations to the opera company. Another idea was to render the theatre for different occasions special events such as
                                schools' graduation ceremonies. Such events could take place only when the building was idle. A high degree of selectivity would be essential to avoid any damage to the reputation of the opera house. </p>
        <p className="normalText">The next meeting of the board of directors of the Supreme Opera Company was a month away. Jerry was
        aiming to complete his final analysis and propose the new budget encompassing new revenue generating and organizational change schemes to the board of directors at the meeting. His ideas were beginning to take
                                shape as he reached the office parking lot.</p>
    </section>
}