import React from 'react';

export const Margins = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Are There Margins?</h1>
	<p className="normalText">The second type of value is the value produced for the business.&nbsp; Value to the 
        venture is termed profit margin, the difference between the price charged for the product-service, and the cost of getting 
		it to your customer.&nbsp; Value to the venture, is best determined by computing the margin PER UNIT of product/service.</p>
	<p className="normalText">Price per unit is simple to compute as compared to computing cost per unit.&nbsp; It is simply the amount per unit that 
		your customer will pay, and this is usually readily available as a part of the purchase transaction (usually printed right 
		on the invoice).&nbsp; Computing the cost per unit, however, is much more involved.</p>
	<p className="normalText">Cost per unit is really a combination of three types of costs: <br/>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.direct costs--the actual cost of producing an actual unit of the product/service <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.indirect costs--costs related to marketing and delivering that unit, and <br/>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.overhead costs--a per unit allocation of the fixed expenses of the business that are not included in the prior two categories.&nbsp; </p>
	<p className="normalText">To some extent the allocation among these categories may be arbitrary, as long as the costs are fully absorbed, meaning that 
		ALL costs are allocated to the product.</p>
	<p className="normalText">There is virtually no substitute for knowing cost per unit.&nbsp; Without this information, you will always be flying a 
		bit blind on the question of value to the venture.&nbsp; (Incidentally, you would be surprised how many actual operating 
		businesses do not have this information, and are therefore using a portion of their "luck" allotment to survive.)&nbsp; 
		Here it is essential to have access to a competent accountant.&nbsp; If you take the time to establish procedures that will 
		yield cost per unit while your venture is relatively small, then over time, your business will become progressively better than 
        those ventures that don't.</p>
	<p className="normalText">Value to the venture, then, is the margin per unit (price per unit less ALL costs per unit).&nbsp; Where these margins are 
		large, there is room to sustain price pressure.&nbsp; That is, in a "price war" your venture has room to maneuver.&nbsp; 
		And if your fully absorbed costs are less than those of your competitors, then you can still be making a profit, while they lose money.</p>
	<p className="normalText">A rule of thumb (and this will vary widely depending upon industry) is for the income breakdown to look like this:</p>
	<p className="normalText">
        <table align="CENTER" border="1" cellspacing="3" cellpadding="1" width="359">
			<tbody><tr class="tableHeader">
            	<td width="52" className="normalText">%</td>
                <td width="288" className="normalText">Per Unit Income Satement Names</td>
            </tr>
            <tr>
                <td width="52" className="normalText">100</td>
                <td width="288" className="normalText">Gross Revenues (Price)</td>
            </tr>
            <tr>
                <td width="52" className="normalText">50</td>
                <td width="288" className="normalText">Direct and Indirect costs (Cost of Goods Sold)</td>
            </tr>
            <tr>
                <td width="52" className="normalText">30</td>
                <td width="288" className="normalText">Overhead (General and Administrative)</td>
            </tr>
            <tr>
                <td width="52" className="normalText">20</td>
                <td width="288" className="normalText">Margin (Pre-Tax Net Profit)</td>
            </tr>
        </tbody></table>
	</p>
    <p className="normalText">In some ventures the percentage breakdown is as high as (100, 12, 38, 50). Other ventures have margins that drop below a 20 percent pre-tax margin.&nbsp; Generally, new ventures with pre-tax margins that go much 
                                below the 20 percent threshold should be classified as low margin ventures.&nbsp; And, if the margins are low for a new venture, the answer to Question B: Is it Valuable? is NO--which means DON'T GO ON until 
                                margins are improved. 
	</p><p className="normalText">If you are operating a low margin business your course is equally clear: Fix it, or walk away.&nbsp; Low margin 
                                ventures are exponentially more difficult to manage, finance, and earn from.&nbsp; Spare yourself the experience of unproductive business failure because of the compounding problems caused by low margins per unit. 
                                </p>
    </section>
}