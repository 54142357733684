import React from 'react';

export const Volume = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Is There Sufficient Volume?</h1>
	<p className="normalText">Beyond "Net Buyer Benefit" and "Margins" as components of Question B: Value, there is a third dimension that 
		must be considered: Is volume sufficient? </p>
	<p className="normalText">Sufficient volume depends upon the absolute size (in dollars) of the margin in question compared to the objectives of the venturer.&nbsp; 
		To illustrate, consider two extremes, with a pre-tax profit objective of $5 million. </p>
	<p className="normalText">In the first case, the entrepreneurial discovery is a new type of paper clip that is tighter gripping, will never break, won't mark the 
		paper, and is easier to use.&nbsp; Pre-tax margins per unit on this new combination are $0.0025.&nbsp; This means that the venturer will 
		meet his or her goal only after 2 billion of these are sold.&nbsp; Here sufficient volume is a very large number. </p>
	<p className="normalText">In the second case, the entrepreneurial discovery is to find a buyer for a $100 million office tower, where the commission rate for the 
		venturer/salesperson is five percent.&nbsp; This means that the venturer/salesperson will meet his or her objectives in just one transaction. 
		Here sufficient volume is attained on the first sale. </p>
	<p className="normalText">If the volume is insufficient, and you are evaluating a new venture, the answer to Question B: Is it Valuable? is NO--which means 
		DON'T GO ON until the low volume issue is resolved. <br/><br/>If you are operating a business, and the venture has insufficient volume, 
		then find a way to generate the volume needed, because the venture will not meet your venturing objectives--and as such will be a 
		disappointment.&nbsp; Generally, the solution to volume problems is linked to Product Market Match and Net Buyer Benefit:&nbsp; It 
        is a MARKETING problem, especially a DISTRIBUTION one.&nbsp; If you don't know how to solve it, the solution is to obtain skilled 
		help from marketing professionals WHO HAVE EXTENSIVE EXPERIENCE WITHIN YOUR TARGET NICHE.</p>
    </section>
}