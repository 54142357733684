import React from 'react';
import { Disclaimer } from './Disclaimer.component';

export const Venturer = (props) => {
    return <section>
	<h1 className="pageTitle">Venturer Role</h1>
        <img src={require('../../assets/roles-venturer.gif')} alt="Venturer" />
	<p className="normalText">
        An individual who scores high on all three New Venture Formation (NVF)
        component scales can be expected to form new ventures.&nbsp; This type
        of individual has the arrangements or NV infrastructure in place, the
        willingness to venture, and the ability to recognize, capture, and protect
        NV opportunities.</p>
    <p className="normalText">In terms of Expert Information Processing Theory (EIPT), a lack of expertise
        indicated by script failure (Leddo &amp; Abelson, 1986) is unlikely, since
        both script "entry" and script "doing" are possible
        for this type of individual.&nbsp; Although not every successful venture
        initiator will fit this type, it is expected that a significant proportion
        of NVF experts will fall into this category.</p>
    <p className="normalText">&nbsp;</p>
        <Disclaimer />
    </section>
}