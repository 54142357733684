import React from 'react';

export function Hostage(props){
  return <><p className="sectionHeading1">Hostage Venture</p>

	<p className="normalText">All too often in venturing the circumstance arises where "talent" meets
		"power," and talent loses.&nbsp; The hostage venture possesses core competence,
		a new combination/product-market match with high net buyer benefit, purchase frequency and
		product longevity.&nbsp; But, due to high appropriability, margins are low, resources are
		few, volume is restricted, and ambiguity is high.&nbsp; Essentially, the hostage venture
		"makes it off the ground," only to be consumed in trying to redress an adverse
		small numbers bargaining position. </p>

	<p className="normalText">In a hostage venture, supplier and buyer power is uncontained.&nbsp; The venture is
		"held-up" by sole suppliers of critical components of the product or service, or
		by sole purchasers (dominant customers).&nbsp; Hence, the size of the "slice" of
		the economic pie that remains for the venture is insufficient to permit the venture to
		achieve its full potential. </p>

	<p className="normalText">ADVICE: Since the main problem is hold-up, it is essential that the 4 methods of
		reducing or controlling holdup be utilized.&nbsp; These include norms, contracts,
		bargaining, and posturing.&nbsp; If slack is also a problem, then it must also be
		addressed through the alignment of incentives.&nbsp; Together, these sets of actions
		should decrease appropriability.&nbsp; Once accomplished, this change implies increased
		margins and resources, with corresponding increases in scarcity effected by the added
		capability to deter imitators and make strategic alliances with the providers of
		substitutes attractive.</p>
                </>
}
