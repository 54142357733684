import React from 'react';
import { arc } from 'd3-shape';
import "./BullsEye.css";
import { getPrototypeConfig } from "./BullsEye.prototypes";


const BullsEye = ({ config, prototype, userSubmission }) => {
  // need to plot a config against a prototype
  if (prototype) config = getPrototypeConfig(prototype)

  const getSelected = (dimension, level) => {
    if (config[dimension - 1][level - 1] === 1) return 'selected'
    return ''
  }

  const oneAngle = Math.PI / 15
  const levelThickness = 24
  const graphLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const getAngle = (position, span = null) => {
    const oneSlice = 2 * oneAngle * position - (oneAngle * 3)
    if (!span) return oneSlice
    return oneSlice * span;
  }

  const getInnerRadius = (level) => {
    let innerRadius;
    switch (level) {
      case 1:
        innerRadius = 0;
        break;
      default:
        innerRadius = (level + 1) * levelThickness
        break;
    }
    return innerRadius
  }

  const getOuterRadius = (level) => {
    let outerRadius;
    switch (level) {
      case 1:
        outerRadius = 2 * levelThickness
        break;
      default:
        outerRadius = level * levelThickness + 1
        break;
    }
    return outerRadius;
  }

  const getClassName = (prototypeSelection) => {
    let className = '';
    if (prototypeSelection) className += ' selected'
    return className.trim();
  };

  const correctLabels = (path, endAngle) => {
    // http://bl.ocks.org/nbremer/7b051187fe329d705ee9
    // proper centering
    const firstArcSection = /(^.+?)L/;
    let newArc = firstArcSection.exec(path);
    newArc = newArc[1].replace(/,/g, " ");

    if (endAngle >= getAngle(6) && endAngle <= getAngle(13)) {
      // flipping the labels that are upside-down
      let startLoc = /M(.*?)A/;		// Everything between the first capital M and first capital A
      const middleLoc = /A(.*?)0 0 1/;	//Everything between the first capital A and 0 0 1
      const endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
      // Flip the direction of the arc by switching the start en end point (and sweep flag)
      // of those elements that are below the horizontal line
      var newStart = endLoc.exec(newArc)[1];
      var newEnd = startLoc.exec(newArc)[1];
      var middleSec = middleLoc.exec(newArc)[1];

      //   //Build up the new arc notation, set the sweep-flag to 0
      newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
      // newArc = firstArcSection.exec(newArc);
    }
    return newArc;
  }

  const getDy = (endAngle, i) => {
    if (endAngle >= getAngle(6) && endAngle <= getAngle(13)) {
      return `${i > 0 ? -1 : -2}em`
    }
    return `${i > 0 ? 3 : 2}em`
  };

  const getOuterDy = (endAngle, i) => {
    if (endAngle >= getAngle(6) && endAngle <= getAngle(13)) {
      return `${i > 0 ? 0 : -.5}em`
    }
    return `${i > 0 ? 0 : 1}em`
  };

  // Dimension categories: The groupings of dimensions that make up the bulls-eye graph.
  const dimensionCategories = [
    {
      startPosition: 1,
      label: "Core Competence",
      span: 1,
      rotate: 0,
      translate: "0, 0"
    },
    {
      startPosition: 2,
      label: "Innovation",
      span: 2,
      rotate: 34,
      translate: "0, 0"
    },
    {
      startPosition: 4,
      label: "Value",
      span: 3,
      rotate: 90,
      translate: "0, 0"
    },
    {
      startPosition: 7,
      label: "Persistence",
      span: 3,
      rotate: -12,
      translate: "0, 0"
    },
    {
      startPosition: 10,
      label: "Scarcity",
      span: 2,
      rotate: 48,
      translate: "0, 0"
    },
    {
      startPosition: 12,
      label: "Non-Appropriability",
      span: 2,
      rotate: -85,
      translate: "-200, -200"
    },
    {
      startPosition: 14,
      label: "Flexibility",
      span: 2,
      rotate: -40,
      translate: "0, 0"
    },
  ]

  // Dimension: The pie pieces making up the bulls-eye graph.
  const dimensions = [
    {
      position: 1,
      label: "Core  Competence",
    },
    {
      position: 2,
      label: "New  Combination",
    },
    {
      position: 3,
      label: "Product/Market  Match",
    },
    {
      position: 4,
      label: "Net Buyer  Benefit",
    },
    {
      position: 5,
      label: "Margins",
    },
    {
      position: 6,
      label: "Volume",
    },
    {
      position: 7,
      label: "Purchase  Frequency",
    },
    {
      position: 8,
      label: "Product  Longevity",
    },
    {
      position: 9,
      label: "Resources",
    },
    {
      position: 10,
      label: "Non-  Imitable",
    },
    {
      position: 11,
      label: "Non-  Substitutable",
    },
    {
      position: 12,
      label: "No Slack",
    },
    {
      position: 13,
      label: "No Holdup",
    },
    {
      position: 14,
      label: "Uncertainty",
    },
    {
      position: 15,
      label: "Ambiguity",
    },
  ]

  const arcGenerator = arc();



  const bullsEye = graphLevels.map(graphLevel => {
    return dimensions.map(dimension => {
      const startAngle = getAngle(dimension.position);
      const endAngle = getAngle(dimension.position + 1)
      const arc = arcGenerator
        .padAngle(.01)
        .padRadius(100)({
          startAngle,
          endAngle,
          innerRadius: getInnerRadius(graphLevel),
          outerRadius: getOuterRadius(graphLevel),
        })

      const userSubmissionPosition = arcGenerator
        .centroid({
          startAngle,
          endAngle,
          innerRadius: getInnerRadius(graphLevel),
          outerRadius: getOuterRadius(graphLevel)
        })
      const prototypeSelection = getSelected(dimension.position, graphLevel);

      // This function takes the value from the database and reverses it for visualization. 
      // For example, a score of 9 in the database is high. Without reversal, 
      // this would be marked at the outside of the graph, meaning a "low" score.
      // The model venture should be at the middle of the bulls-eye diagram, so higher scores
      // should be in the center, and have a lower value. 
      const reverseScore = (level) => {
        const reversedScore = Math.abs(level - 10)
        return reversedScore;
      }

      const getUserSubmission = (dimension, level) => {
        const matchingLevel = reverseScore(level);
        if (userSubmission[dimension - 1] === matchingLevel) return 'user-selected';
        return '';
      }

      const userSelection = userSubmission ? getUserSubmission(dimension.position, graphLevel) === 'user-selected' : '';
      return <React.Fragment key={'path' + graphLevel + dimension.label + userSelection}>
        <path d={arc} className={getClassName(prototypeSelection)}></path>
        {userSelection && <text className="plus-sign" x={userSubmissionPosition[0] - 5} y={userSubmissionPosition[1] + 5}>+</text>}
      </React.Fragment>
    })
    
  })

  const dimensionLabels = dimensions.map((dimension) => {
    
    const startAngle = getAngle(dimension.position)
    const endAngle = getAngle(dimension.position + 1)

    const labelPosition = arcGenerator
      .padAngle(.01)
      .padRadius(100)({
        startAngle,
        endAngle,
        innerRadius: 10 * levelThickness + 1,
        outerRadius: dimension.label === "Core  Competence" ? 13.5 * levelThickness : 12.5 * levelThickness,
      })

    const arc = arcGenerator
      .padAngle(.01)
      .padRadius(100)({
        startAngle,
        endAngle,
        innerRadius: 10 * levelThickness + 1,
        outerRadius: dimension.label === "Core  Competence" ? 13.5 * levelThickness : 12.5 * levelThickness
      })

    return <React.Fragment key={dimension.label + dimension.position}>
      <path id={dimension.position} d={correctLabels(labelPosition, endAngle)}></path>
      <path id={dimension.position} key={dimension.position + labelPosition} d={arc} className={`label-text-bg ${dimension.label === "Core  Competence" ? 'core-competence' : ''}`}></path>
      <text
        key={dimension.label}
        className="bullseye-label"
      >

        {dimension.label.split("  ").map((word, i) => {
          return <textPath
            key={'word'+i}
            href={`#${dimension.position}`}
            startOffset="50%"
          ><tspan
            dy={getDy(endAngle, i)}
          >
              {word}
            </tspan>
          </textPath>
        })
        }
      </text>
    </React.Fragment>
  })

  const dimensionCategoryLabels = dimensionCategories.map((category) => {
    const startAngle = getAngle(category.startPosition);
    const endAngle = getAngle(category.startPosition + category.span);


    const arc = arcGenerator
      .padAngle(.01)
      .padRadius(100)({
        startAngle,
        endAngle,
        innerRadius: 12.5 * levelThickness + 1,
        outerRadius: 13.5 * levelThickness
      })

    if (category.label === "Core Competence") return null
    return <React.Fragment key={category.label}>
      <path id={category.label} d={correctLabels(arc, endAngle)}></path>
      <path d={arc}></path>
      <text className="bullseye-label">
        {category.label.split("  ").map((word, i) => {
          return <textPath
            key={word}
            href={`#${category.label}`}
            startOffset="50%">
            <tspan
              dy={getOuterDy(endAngle, i)}
            >{word}</tspan>
          </textPath>
        })
        }
      </text>
    </React.Fragment>
  })



  return <svg width="600px" height="600px" viewBox="-325 -325 650 650" preserveAspectRatio="xMidYMid meet">
      {dimensionCategoryLabels}
      {dimensionLabels}
      {bullsEye}
    </svg>
}

export default BullsEye