import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const Hostage = () => {
    return <section>
	<h1 className="pageTitle">The Hostage Prototype</h1>
	<p className="normalText">All too often in venturing the circumstance arises where "talent" meets "power," and loses.&nbsp; The hostage venture possesses <Link className="contentLink" to="/template/viability/core-competence">Core Competence</Link>, a <Link className="contentLink" to="/template/viability/new-combination">New Combination</Link>/<Link className="contentLink" to="/template/viability/product-market-match">Product-Market Match</Link> with high <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link>, <Link className="contentLink" to="/template/viability/repetitive">Purchase Frequency</Link> and <Link className="contentLink" to="/template/viability/long-term-need">Product Longevity</Link>. But, due to high <Link className="contentLink" to="/template/fundamentals/non-appropriability">Appropriability</Link>, <Link className="contentLink" to="/template/viability/margin">Margins</Link> are low, <Link className="contentLink" to="/template/viability/resources">Resources</Link> are few, <Link className="contentLink" to="/template/viability/volume">Volume</Link> is restricted, and <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link> is high.</p>
	<p className="normalText">Essentially, the Hostage Venture "makes it off the ground," only to be consumed
		in trying to redress an adverse small numbers bargaining position.&nbsp; In a hostage venture,
        supplier and buyer power is uncontained.&nbsp; The venture is "<Link className="contentLink" to="/template/viability/no-holdup">held-up</Link>
		" by sole suppliers of critical components of the product or service, or by sole purchasers (dominant
		customers).&nbsp; Hence, the size of the "slice" of the economic pie that remains for the
		venture is insufficient to permit the venture to achieve its full potential.</p>
        <div className="prototype-diagrams">
	<p className="sectionHeading2">Figure 5: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="hos" />
    <p className="sectionHeading2">Figure 6: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype="Hostage" />
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">Since the main problem is <Link className="contentLink" to="/template/viability/no-holdup">Hold-up</Link>, it is essential that the 4 methods of reducing or controlling <Link className="contentLink" to="/template/viability/no-holdup">Hold-up</Link> be utilized. These include <i>norms</i>, <i>contracts</i>, <i>bargaining</i>, and <i>posturing</i>.</p>
	<p className="normalText">If <Link className="contentLink" to="/template/viability/no-slack">Slack</Link> (waste and inefficiency) is also a problem,then it must also be addressed through the alignment of incentives.&nbsp; Together, these sets of actions should decrease <Link className="contentLink" to="/template/fundamentals/non-appropriability">Appropriability</Link>.&nbsp; Once accomplished, this change implies increased <Link className="contentLink" to="/template/viability/margin">Margins</Link> and <Link className="contentLink" to="/template/viability/resources">Resources</Link>, with corresponding increases in <Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link> effected by the added capability to deter <Link className="contentLink" to="/template/viability/non-imitable">Imitators</Link> and make strategic alliances with the providers of <Link className="contentLink" to="/template/viability/non-substitutible">Substitutes</Link> attractive.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Upper Prairie Recreational Estates -- <i>A Hostage Venture</i></p>
	<p className="normalText">Lance Jones was relaxing on the porch after a very stressful day, wondering how his once in a life time idea
        that was so good could have gone so badly.&nbsp; Lance was a very successful land developer.&nbsp; He was a visionary in choosing developments that people wanted.&nbsp; He had gained this expertise through work,
        fastidious planning and lots of practice.&nbsp; </p>
    <p className="normalText">In 1978, the McTavish family approached Lance for ideas about selling a piece of property.&nbsp; The McTavish
                                family owned the 4,000-hectare Upper Prairie Cattle Ranch, located 20 minutes south of Denver.&nbsp; It had been a working ranch for the last 50 years, but the parents had passed away and none of the potential heirs
                                had any desire to spend the rest of their lives tied to the land.&nbsp; The ranch was located in a picturesque valley with lakes, open range land and mixed forests.</p>
    <p className="normalText">Lance knew the area well and felt that there were thousands of people who would love to be in the
                                McTavish's position.&nbsp; The thought of owning a small piece of nature would have wide appeal.&nbsp; Lance had
                                recently done some research into the concept of time-sharing and the Upper Prairie Ranch seemed about as
                                close a fit to a successful time-share prospect as there was.&nbsp; He knew that others would follow soon but that he should have first mover advantage.</p>
	<p className="sectionHeading2">THE DEAL</p>
	<p className="normalText">Time-sharing was a new concept in 1978.&nbsp; The idea behind it was that investors would pay $10,000 for a
                                trust unit that would give them the privilege for year around access to the private ranch.&nbsp; 10,000 trust units
                                would be sold, and the revenues used to develop the area into a year-round recreational paradise.&nbsp; In the
                                summer it would offer golf, tennis, horse back riding, hiking and swimming.&nbsp; During the winter, share owners
                                would enjoy snowmobiling, cross country skiing, sleigh rides and ice skating.&nbsp; Lance felt these opportunities
                                would appeal to a large segment of the population in Colorado.&nbsp; There would be 200 condominium units
                                built on the site that members could use for 3 weeks per year for a small maintenance fee.&nbsp; This was great
                                value for the $10,000 purchase price.&nbsp; Comparable holidays were only available at expensive guest ranches, which failed to meet the needs of the majority of prospective buyers.</p>
    <p className="normalText">The deal would raise $100 million dollars when all the units were sold.&nbsp; Lance estimated that the McTavish
                                family would get $20 million for their land, which was well above the $3 million if they sold it in one piece.&nbsp;
                                The development would be self-sufficient once it gained momentum.&nbsp; The users would pay to maintain the
                                facilities through user fees.&nbsp; As more units sold, additional amenities would be added to accommodate the
                                increasing membership.&nbsp; Once the members paid their money they were committed for the long term unless
                                they wanted to sell their unit, which would be permitted.&nbsp; Every time they returned they would spend more money to enjoy the facilities, thus ensuring the future of the investment</p>
    <p className="normalText">After ironing out the details about payment schedules, the deal was struck and the McTavish family folded
                                their land into the trust.&nbsp; As security, the McTavish's held the mortgage and would be able to foreclose if the
                                agreement wasn't lived up to. This gave the trust the needed resources to proceed, although they still required the money from the sale of the units to fully finance the project.</p>
	<p className="sectionHeading2">THE PROJECT</p>
	<p className="normalText">The project was launched with great fan fare in 1980.&nbsp; Promotions were handled by Mountain High
                                Promotions Ltd., a well known local firm that had a reputation for doing things on a grand scale.&nbsp; They organized several events, including a ceremonial ribbon cutting attended by high profile politicians,
                                presentations at the most expensive hotels in the city, lots of free food and drink and air tours of the property
                                for interested buyers.&nbsp; Promotional videos depicted a summer and winter paradise with unlimited access to
                                grand facilities and large condominium complexes.&nbsp; Investors were told that they could start enjoying these pleasures in 18 months.</p>
    <p className="normalText">Lance arranged a $2 million line of credit with a local bank to get the project rolling, and to keep them
                                underway until the units started to sell.&nbsp; Lance gave Mountain High the freedom to design the promotional program, and along with his newly hired general manager were heading up the ground works.&nbsp; Lance
                                worked hard lining up consultants and contractors to design and build the roads, water systems, sewer systems, recreational facilities and condominiums.&nbsp; He hadn't been able to attend any of the promotional
                                events, but he was becoming uncomfortable from what he was hearing about the promises of facilities and
                                time lines.&nbsp; But the units were selling better than planned and Lance was so busy with his tasks that he never
                                questioned Mountain High about their promises. Much of his time was spent working with the banks, and securing loans based on commitments made by purchasers.&nbsp; He sold the unit sales agreements to the bank at
                                a discount, securing the funds to move ahead with the project.</p>
	<p className="sectionHeading2">THE PROBLEMS</p>
	<p className="normalText">The project was proceeding a little slower than planned and the unit holders getting anxious; they wanted to
                                enjoy what Mountain High had promised.&nbsp; The promotional expenses, which were planned at 20% of the
                                project, had gone over budget.&nbsp; The discount rates on the sale of the unit sales contracts were higher than
                                planned.&nbsp; Construction costs became higher because they tried to speed up the development by working
                                through spring when the ground was wet, making every portion of the construction project more expensive.&nbsp; It was beginning to become apparent that Mountain High had committed to supply far more amenities than
                                the budget could support.&nbsp; The total actual costs to-date on phase 1 now exceeded the total revenues from
                                the sell out of the phase.&nbsp; This was an egregious situation, considering that the construction was not completed, and the units were not completely sold out.</p>
    <p className="normalText">Due to the budget problems, many suppliers and contractors who were promised quick payment in
                                exchange for low prices were not getting paid.&nbsp;&nbsp; The banks were getting nervous as the operating line started
                                to exceed the limit and the McTavish's began looking for the installment payments for their land.</p>
    <p className="normalText">For Lance, today was the culmination of many problems.&nbsp; It started with news that 250 feet of a section of
                                road built during the wet spring had washed into a nearby fish stream.&nbsp; On top of the costs associated with fixing the road, an EPA official enacted a "Stop Work" order on all current road construction until an
                                engineer could attest to the quality of the roads already built.</p>
    <p className="normalText">Lance had just talked the EPA into a one week reprieve on the stop work order when the McTavish's
                                arrived en mass with the paperwork to begin foreclosure proceedings.&nbsp; Lance had failed to meet the agreed
                                upon payment schedule. At least the lawyers were making money from his dream.&nbsp; By the end of the day he was served a writ for a law suit filed by a group of angry unit purchasers: They were suing because it had
                                been two years since they bought their units and nothing was ready.&nbsp; Three lawsuits in one day, a new record!</p>
    <p className="normalText">In hindsight, Lance figured he should have spent more time cross checking the costs to see if there was any money to be made.&nbsp; But it was such a good idea!</p>
    </section>
}