import React from 'react';

export function CompetenceBasedSuccess(props){
  return <><p className="sectionHeading1">Competence-based Venture</p>

  <p className="normalText">In this type of venture, high competency, accompainied by PMM, NBB, margins, volume,
          frequency of purchase, and the availability of resources indicate long-term success
          potential.&nbsp; Medium product longevity implies increased ambiguity and medium scarcity,
          with a DECAYING level of innovation. </p>

  <p className="normalText">ADVICE: Need to build a business that replicates key behaviors and competences. &nbsp;
          This means that these successful entrepreneurs should spend sufficient time working
          "on" the business (v. "in" the business) to ensure that the factors
          that have lead to its success can be replicated as circumstances change.&nbsp; Because the
          business is based upon key competences, there should be ample effort expended (while the
          resources are still plentiful), on continuing education and the upgrading of skills--that
          in turn lead to ongoing innovation. </p>
          </>

}