import React, {useEffect, useState} from 'react';
import {AuthRequired} from '../utils/AuthRequired.component'
import {Loader} from '../Loader/Loader.component';
import {rootUrl} from '../utils/rootUrl';
import {HasPermission} from '../utils/HasPermission.component';



export function ManageAdmins(props) {
  const [adminUsers, setAdminUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [passwordResetUserId, setPasswordResetUserId] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [shouldUpdatePassword, setShouldUpdatePassword] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [triggerFetchAdminList, setTriggerFetchAdminList] = useState(false);
  const [passwordUpdateMessage, setPasswordUpdateMessage] = useState('');
  const [newAdminUserName, setNewAdminUserName] = useState('');
  const [newAdminPassword, setNewAdminPassword] = useState('');
  const [newAdminInstructorName, setNewAdminInstructorName] = useState('');
  const [shouldCreateNewAdmin, setShouldCreateNewAdmin] = useState(false);
  const [adminCreatedMessage, setAdminCreatedMessage] = useState('');

  useEffect(() => {
    fetchAdminList()
    async function fetchAdminList() {
      if (isLoading || triggerFetchAdminList) {
        try {
          const response = await fetch(`${rootUrl}/api/admins`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
              )}`
            }
          });
          if (!response.ok) throw response;
          const parsedResponse = await response.json();
          setAdminUsers(parsedResponse);
          setIsLoading(false);
        } catch (err) {
          setError(err);
        }
      }
    }
  }, [isLoading, triggerFetchAdminList]);

  useEffect(() => {
    updatePassword()

    async function updatePassword() {
      if (shouldUpdatePassword) {
        try {
          const response = await fetch(`${rootUrl}/api/admin/update-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
              )}`
            },
            body: JSON.stringify({
              user_id: shouldUpdatePassword.user_id,
              newPassword
            })
          });
          if (!response.ok) {throw new Error(response)}
          const parsedResponse = await response.json();
          setPasswordUpdateMessage(`Password updated for ${shouldUpdatePassword.username} (new password: ${parsedResponse})`);
          setShouldUpdatePassword(false);
        } catch (err) {
          setError(err);
        }
      }
    }
  }, [shouldUpdatePassword, newPassword])

  useEffect(() => {
    if (selectedAdmin.username) {
      disableAdmin()
      async function disableAdmin() {
        try {
          const response = await fetch(`${rootUrl}/api/admin/students/disable`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
              )}`
            },
            body: JSON.stringify(selectedAdmin)
          });
          if (!response.ok) {
            throw new Error(response.error);
          }
          setTriggerFetchAdminList(true);
        } catch (error) {
          setError(error);
        } finally {
          return setSelectedAdmin({});
        }
      }
    }
  }, [selectedAdmin]);

  useEffect(() => {
    createNewAdmin();
    async function createNewAdmin() {
      if (shouldCreateNewAdmin) {
        try {
          const response = await fetch(`${rootUrl}/api/admin/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
              )}`
            },
            body: JSON.stringify({
              username: newAdminUserName,
              password: newAdminPassword,
              instructorLast: newAdminInstructorName
            })
          });
          if (!response.ok) {
            throw new Error(response.error);
          }
          setShouldCreateNewAdmin(false);
          setAdminCreatedMessage(`Account for ${newAdminInstructorName} created (${newAdminUserName})`)
          setTriggerFetchAdminList(true);
        } catch (error) {
          setError(error);
        } finally {
          setNewAdminInstructorName('');
          setNewAdminPassword('');
          setNewAdminPassword('');
        }
      }
    }
  }, [shouldCreateNewAdmin, newAdminUserName, newAdminPassword, newAdminInstructorName])


  return <AuthRequired>
    <HasPermission requiredPermissions={["admin"]}>
    <section className="class-list-page-container">
      <h1>Admin Users</h1>
      <section className="class-list-page-main-container" style={{display: 'flex', flexDirection: "column"}}>
        {error && JSON.stringify(error)}
        {isLoading ? <Loader>Retrieving list of admin users</Loader>
          :
          <table style={{width: "100%"}}>
            <tbody>
              <tr key='header'>
                <td><b>Username</b></td>
                <td><b>Instructor</b></td>
                <td><b>Reset Password</b></td>
                <td><b>Disable</b></td>
              </tr>
              {adminUsers && adminUsers.map(admin => {
                return <tr key={admin.user_id}>
                  <td>{admin.username}</td>
                  <td>{admin.instructor_last}</td>
                  <td>{passwordResetUserId === admin.user_id ?
                    <>
                      <input placeholder="Enter new password" onChange={(evt) => setNewPassword(evt.target.value)} value={newPassword}></input>
                      <button onClick={() => {
                        if (newPassword.trim() === "") return window.alert("Entered password is blank.")
                        return setShouldUpdatePassword(admin)
                      }}>Change Password</button>
                      <button onClick={() => setPasswordResetUserId(null)}>Cancel</button>
                    </>
                    : <>
                      <button onClick={() => setPasswordResetUserId(admin.user_id)}>Reset Password</button>
                      </>}
                    </td>
                  <td>{admin.disabled ? 'Disabled' : <button onClick={() => setSelectedAdmin(admin)}>Disable</button>}</td>
                </tr>
              })}
            </tbody>
          </table>
        }
        {passwordUpdateMessage && <h2>{passwordUpdateMessage}</h2>}
      </section>
      <h1 style={{ marginTop: '2rem' }}>Create Admin Users</h1>
      <section style={{ display: 'flex', flexDirection: "column", margin: '1rem' }}>
        <input placeholder="Username" onChange={(evt) => setNewAdminUserName(evt.target.value)} value={newAdminUserName} className="input-font-size" style={{ margin: '.5rem'}}></input>
        <input placeholder="Password" onChange={(evt) => setNewAdminPassword(evt.target.value)} value={newAdminPassword} className="input-font-size"  style={{ margin: '.5rem'}}></input>
        <input placeholder="Instructor name" onChange={(evt) => setNewAdminInstructorName(evt.target.value)} value={newAdminInstructorName} className="input-font-size" style={{ margin: '.5rem'}}></input>
        <button className="class-list-general-button create-new-class-button" onClick={() => setShouldCreateNewAdmin(true)} style={{ margin: '.5rem'}}>Create New Admin</button>
      </section>
      {adminCreatedMessage && <h2>{adminCreatedMessage}</h2>}
    </section>
    </HasPermission>
  </AuthRequired>
};
