import React from 'react';

export const TemplateReferences = (props) => {
  return <section>
    <h1>References</h1>
	<p className="normalText">
        Barney, J.B. (1991). Firm resources and sustainable competitive advantage. 
        <u>Journal of Management</u>, <u>17</u>, 99-120.</p>
    <p className="normalText">Daft, R.L., &amp; Lengel, R.H. (1986). Organizational information requirements, media richness and structural design. <u>Management Science</u>, <u>32</u>(5), 554-569.</p>
    <p className="normalText">Etzioni, A. (1988). <u>The moral dimension</u>. New York: The Free Press.</p>
    <p className="normalText">Galbraith, J.R. (1977). Organizing modes: An information processing model.&nbsp; In: Jay Galbraith, <u>Organization design</u>, Chapter 3, 33-57.</p>
    <p className="normalText">Ghemawat, B. (1991). <u>Commitment</u>. Cambridge, MA: Harvard University Press.</p>
    <p className="normalText">Grant, R.M. (1991). <u>Contemporary strategy analysis: Concepts, techniques, applications</u>. Cambridge, MA: Basil Blackwell, Ltd.</p>
    <p className="normalText">Hannan, M.T., &amp; Freeman, J. (1989). <u>Organizational ecology</u>. Cambridge, MA: Harvard University Press.</p>
    <p className="normalText">Hayek, F.A. von (1937). Economics and knowledge. <u>Economica</u>, New Series, <u>4</u>, reprinted in F.A. von Hayek (1949), <u>Individualism and Economic Order</u>, London: Routledge and Kegal Paul.</p>
    <p className="normalText">Jensen, M., &amp; Meckling, W. (1976). Theory of the firm: Managerial behavior, agency costs, and ownership structure. <u>Journal of Financial Economics</u>, <u>3</u>, 305-360.</p>
    <p className="normalText">Maslow, A.H. (1954). <u>Motivation and personality</u>. New York: Harper.</p>
    <p className="normalText">Murray, A.I. (1989). Top management group heterogeneity and firm performance. <u>Strategic Management Journal</u>, <u>10</u>, 125-141.</p>
    <p className="normalText">Porter, M.E. (1980). <u>Competitive strategy</u>. New York: Free Press.</p>
    <p className="normalText">Porter, M.E. (1985). <u>Competitive advantage</u>. New York: Free Press.</p>
    <p className="normalText">Rubin, P.H. (1990). <u>Managing business transactions</u>. New York: Free Press.</p>
    <p className="normalText">Rumelt, R.P. (1987). Theory, strategy, and entrepreneurship. In D.J. Teese (Ed.), <u>The competitive challenge: Strategies for industrial innovation and renewal</u> (pp. 136-152). Cambridge, MA: Ballinger.
                            </p>
    <p className="normalText">Schumpeter, J. (1934). <u>The theory of economic development</u>. Boston, MA: Harvard University Press.</p>
    <p className="normalText">Stinchcombe, A. (1965). Social structures and organizations. In James G. March (Ed.) <u>Handbook of organizations</u> (pp. 142-193). Chicago, IL: University of Chicago Press.</p>
    <p className="normalText">Tushman, M.L., &amp; Anderson, P. (1986). Technological discontinuities and organizational environments. <u>Administrative Science Quarterly</u>, <u>31</u>, 439-465.</p>
    <p className="normalText">Williamson, O.E. (1985). <u>The economic institutions of capitalism</u>. New York: Free Press.</p>
    <p className="normalText">Williamson, O.E. (1991). Strategizing, economizing, and economic organization. <u>Strategic Management Journal</u>, <u>12</u>(S), 75-94.</p>
  </section>
}