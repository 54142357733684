import React from 'react';

export const NoSlack = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Is There No Slack?</h1>
	<p className="normalText">Slack, waste and inefficiency, occurs in a venture where the size of the 
                                pie is reduced through the actions of economic actors in the "vertical" relationship with the firm.&nbsp; Vertical relationships are the production/distribution relationships.&nbsp; Vertical relationships 
                                usually consist of the supplier-firm relationship on one hand, and the customer-firm relationship, on the other.&nbsp; Suppliers and customers are the two main types of market actor that can "jump your 
                                claim" and cut down the overall size of your pie. </p>
	<p className="normalText">Generally, the size of the pie available to all parties in the vertical relationship is larger or smaller, depending upon the amount of waste in the 
                                production and distribution process.&nbsp; Where production and distribution has little inefficiency (low scrap, rework, waste etc.) there is little slack.&nbsp; Where scrap, rework, and waste are high, then slack 
                                is large and the pie thereby shrinks. </p>
	<p className="normalText">Thus, when a customer mis-specifies an order, and you have to "eat" the correction costs, slack increases.&nbsp; When a supplier wastes material or labor 
                                through mismanagement, slack increases.&nbsp; When your advertising department runs ads that don't bring in business, slack increases. When people within the firm waste time, materials or funds, slack increases. </p>
    <p className="normalText">How does one cut slack?&nbsp; The answer lies in the alignment of incentives.&nbsp; That is, where it is in the interest of the players in the production/distribution game to cut slack, then slack is generally 
                                low. </p>
	<p className="normalText">Hence, programs that link employee pay to low slack, reduce appropriability.&nbsp; Programs that link supplier rewards to timeliness and quality of component deliveries, reduce appropriability.&nbsp; 
                                Programs that emphasize safety, thereby reducing lost-time accidents, reduce appropriability.&nbsp; Programs that cut scrap and rework, reduce appropriability. These reductions occur because, where waste is low, the 
                                pie is larger. </p>
	<p className="normalText">So, where the incentive alignments are put in place to reduce slack, then the answer to the sub-question: Is there limited slack?, can be yes.&nbsp; If not, then the answer to Question E: Is 
                                it non-appropriable? is NO--which implies DON'T GO ON with a new venture until you resolve this. </p>
                                <p className="normalText">If you are trying to reduce appropriability in an ongoing business, look for the opportunity to reward actors 
                                in the vertical relationship for low slack conditions.&nbsp; Begin by looking at your reward systems and then move on to consider the incentives that you offer both suppliers and buyers to eliminate slack.
                            </p>
    </section>
}