import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const CRAD = (props) => {
    return <section>
	<h1 className="pageTitle">CRAD - Institutional Expert</h1>
    <p className="normalText">This particular type of expert (preliminarily about 4% fit this profile) is distinguished by 
		his or her risk aversion.&nbsp; The CRAD is usually found in an investment bank, university, commercial bank 
		small business department, Small Business Development Center, or venture capital firm.&nbsp; CRAD's make 
		excellent advisors and/or consultants, although their advice is most often colored by caution and the need to 
		ensure that undue violence is not done to the status quo.</p>
                            
	<p className="normalText">The CRAD's script is loaded with a set of highly-analyzed cases where 
        the venturing process got off track, complete with reasons why.&nbsp; 
        For this reason, a CRAD is indispensable on deal review panels, venture 
        boards of directors etc..&nbsp; Where a CRAD is in a position to make 
        final venture decisions, they will likely be overly conservative.&nbsp; 
        Alone, then, a CRAD does little venturing.&nbsp; When paired with other 
        AD's or with CE's a CRAD augments any venture team (although a CEAD is 
        unlikely to see the need for such augmentation).</p>
    <MatrixDiagram selected="CRAD" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">As a CRAD, the your most valuable role should not often be found as the primary 
		venturer/entrepreneur.&nbsp; However, since your expertise is likely to be considerable, you are in an ideal 
        position to help to <b>orchestrate the resource flow</b> to new ventures.&nbsp; This implies close involvement 
		with new ventures on your part: advising, teaching, analyzing etc..</p>
    <p className="normalText">Further, you should consider making yourself available to such organizations as a small 
		business development center (SBDC), local or regional venture group, deal review panel in the small business 
		department of a bank or venture capital firm, or at the entrepreneurial center at your local university (if 
        available).&nbsp; You have much to offer.&nbsp; Put it to work.</p>
    </section>
}
