import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const CRLD = (props) => {
    return <section>
	<h1 className="pageTitle">CRLD - Idea Person</h1>
    <p className="normalText">The CRLD understands the entrepreneurial success script and the zone of activity, but often 
		lacks the momentum (desire + resources) to get off the ground.&nbsp; Accordingly, he or she "talks" a 
		great line, but may find it difficult to "deliver" under the pressures of a new venture.&nbsp; </p>
	<p className="normalText">Appearing in (preliminarily) about 7% of the business population, the CRLD is great in a 
		social or planning setting where results or action are not the paramount objective.&nbsp; Here will 
        be found a wealth of new ideas, many of them excellent -- but in need of a "doer".&nbsp; For this 
		reason, many CRLD's may complain of having their ideas "stolen" by such and such an 
        entrepreneur.&nbsp; In truth, the ideas may have simply found a better home.</p>
    <p className="normalText">The most sure transition out of this zone is the development of an action orientation.&nbsp; 
		Although this may not often occur, it is worth trying or encouraging as the case may be.</p>
    <MatrixDiagram selected="CRLD" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">If you are a CRLD who wants to get off the sideline, you have your choice.&nbsp; You can either 
		refine your abilities by reducing your venturing limitations, or you can reevaluate your risk aversion and get 
		involved in venturing.&nbsp; Preliminary studies have shown a correlation between venturing ability and venturing 
		willingness.&nbsp; So which ever end of the "candle" you decide to burn should affect the other, and 
		you should have a good possibility of moving into the expert zone.</p>
    <p className="normalText">If you choose to refine your ability to venture, first you may wish to start positioning 
		yourself for a venture -- looking for proprietary technology or other valuable, rare, inimitable, 
		non-substitutable resources which could command an "entrepreneurial rent" in a venture.</p>
    <p className="normalText">If you choose to reevaluate your risk aversion, you should reassess your true need for 
		information (try <i>not</i> to study something to death), identify assets which you could lose if necessary and 
		still sleep at night, and raise your own belief in yourself such that you can trust your own fairly well 
		developed knowledge structure to lead to sound new venture decisions.&nbsp; Participation on a venture team 
		might be a way to start.&nbsp; This all boils down to <i>pushing</i> yourself to enhance your <b>accountability</b> and to set for yourself new venture <b>milestones</b>.</p>
    </section>
}
