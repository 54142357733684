import React, {useState, useContext} from 'react';
import {Redirect} from 'react-router-dom';
import {AuthenticationContext} from '../context';


export function EULA(props) {
  const {logout} = useContext(AuthenticationContext)
  const [userAccepted, setUserAccepted] = useState(null);

  if (userAccepted === null) {
  return <section>
    <h1>End-user License Agreement</h1>
    <p className="normalText">This End-User License Agreement ("EULA") is a legal agreement between you and Dr. Ronald K. Mitchell (“Author”). By continuing with the New Venture Template (“NVT”) analysis you agree to the terms of this EULA. If you do not agree to the terms of this EULA please exit this program.</p>

    <p className="normalText">The NVT program is designed to provide expert assistance to trained users. In a sense, this program is a skill enhancer-designed to magnify your skills and experience, to help you to make wise venturing related decisions. However, without proper training this program may offer results that are not meaningfully interpretable, or simply wrong. A user acts on these results at their own peril. Remember Garbage in - Garbage out. Thus, unless otherwise agreed in writing with the Author, your use of this program and website is for educational purposes only.</p>

    <p className="normalText">This program can help a user to define 15 attributes that have been found necessary for successful ventures, however it cannot define the sufficient set of attributes that guarantee success. By continuing with this program you acknowledge that no promise, representation, warranty or undertaking has been made or given as to the profitability of success or any other consequences or benefits to be obtained from the use of this software program and/or website.</p>

    <p className="normalText">You may not reverse engineer, decompile, disassemble or reproduce any part of this software product and/or website. Further, the text may not be copied or reproduced without the express written consent of the Author.</p>
    
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <button
        className="nvt-submission-button"
        style={{margin: '1rem '}}
        onClick={() => {
          logout();
          localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_EULA_ACCEPTED, 'false')
          setUserAccepted(false);
        }}>
        I do not accept
    </button>
    <button
      className="nvt-submission-button"
      style={{margin: '1rem '}}
      onClick={() => {
        localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_EULA_ACCEPTED, 'true');
        setUserAccepted(true)
      }}>
      I accept
      </button>
    </div>
  </section >
  }

  if (userAccepted === true) return <Redirect to="/authenticated" />;
  if (userAccepted === false) return <Redirect to="/login" />;
}