import React from 'react';

export const NewCombination = (props) => {
    return <section>
        <h1 className="pageTitle">Viability - Is it a New Combination?</h1>
        <p className="normalText">According to Schumpeter, "Everyone is an entrepreneur when he actually
        carries out new combinations, and loses that character as soon as he has
        built up his business, when he settles down to running it as other people
        run their businesses" (1936: 78). Finding new combinations is a process
        of entrepreneurial discovery that will become the engine that will drive
        your entrepreneurial career for as long as you desire. </p>
        <p className="normalText">This new approach to venturing (that is, venturing success through failure prevention) begins with understanding entrepreneurial discovery within the context of the free enterprise system. In the free enterprise system, two major categories of entrepreneurial discovery exist. They are: </p>
        {/* <ul> */}
        <ol className="ordered-list">
            <li className="normalText">Scientific discovery, and </li>
            <li className="normalText">Circumstantial discovery. </li>
        </ol>
        {/* </ul> */}
        <p className="normalText">Scientific discovery occurs when a physical / technological observation is made. That observation leads to an invention that applies the discovery to develop a product or service in a new and valuable way. </p>
        <p className="normalText">Circumstantial discovery refers to insight that is obtained though specific knowledge of time, place, or circumstance. Kaiser Sand and Gravel in Northern California has a logo on their ready-mix trucks that reads: "Find a need and fill it". That is the principle here: <b>Circumstantial discovery yields an opportunity because of "who-what-when-where-or-how" you know something of economic value.</b></p>
        <p className="normalText">Profit opportunities occur because there are "imperfections" in the marketplace. The two basic market imperfections are: </p>
        <ul>
            <ol>
                <li className="normalText">Demand without supply, and </li>
                <li className="normalText">Supply without demand </li>
            </ol>
        </ul>
        <p className="normalText">A map of the four possibilities for entrepreneurial discovery that stem from this discussion is shown in Figure A-1 below. An explanation of each of the possibilities follows:</p>
        {/* <p className="normalText">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody><tr>
                                        <td align="CENTER"><img id="Picture16" src="../images/figurea.gif" border="0"></td>
                                    </tr>
                                </tbody></table>
	</p> */}
        <div style={{display: "flex", justifyContent: "center"}}>
            <img src={require('../../assets/possibilities-for-entrepreneurial-discovery.gif')} alt="Possibilities for Entrepreneurial Discovery"></img>
        </div>
        <p className="sectionHeading1">1. Invention I</p>
        <p className="normalText">Where there is excess demand the job of the observer seeking an entrepreneurial opportunity is to a means to satisfy that excess demand.&nbsp; Take, for example, controlled flight.&nbsp; For millennia, man (observing birds) has desired to fly.&nbsp; By constant experimentation, a means to satisfy this demand was eventually discovered.&nbsp;
        However, timing of this type of discovery is constrained by the scientific environment.&nbsp; New developments in
        one field of discovery have ripple effects in others.&nbsp; Thus, without the internal combustion engine, the capability
        to refine fuel, the ability to produce lightweight fabrics etc., the Wright brothers could not have "invented" a solution to the physical problem of controlled flight -- until the "timing was right".
                            </p>
        <p className="sectionHeading1">2. Observation</p>
        <p className="normalText">While the Wright Brothers proved that man could fly, it was left to others to observe that "barnstorming" was not the only use for the new flying machines.&nbsp; First came military applications.&nbsp; Then came freight and passenger
        service.&nbsp; The airline industry of today is built upon the insights of time, place, and circumstance that came from
        the careful observations of entrepreneurs who took the scientific discoveries and applied them to specific uses.&nbsp;
                                But observation is not restricted to the application of scientific inventions.&nbsp; One short story involving entrepreneurial discovery is told as follows:</p>
        <p className="normalText">Many years ago in downtown London, there was a man who was terribly bored with his job as a store clerk.&nbsp;
        One day, he was so frustrated with his "going nowhere" job that instead of going to the lunch room on his noon
        break, he went for a walk to think things over.&nbsp; As he walked, he looked for a place to buy tobacco for his
        pipe.&nbsp; He walked one block, then two, then three full blocks looking for a tobacconist's shop and found none.&nbsp;
        Then, with that special insight that comes from individual knowledge of time, place and circumstance, he&nbsp; realized that by not finding a place to buy tobacco, he had discovered the solution to his job problem.
                            </p>
        <p className="normalText">He returned to work, quit his job, and opened a modest tobacco shop on the street he had previously strolled.&nbsp;
        The shop was an instant success.&nbsp; Soon, he began strolling the streets of London looking for other opportunities
        to ply his trade.&nbsp; Eventually he became the owner of a chain of these shops, and a wealthy man.&nbsp; Thus, an
        individual observing place, time, and circumstance, discovered an entrepreneurial opportunity.&nbsp; Of course, such
                                discovery is not the only thing that is necessary for a successful new venture, but it does form the foundation of a new business. </p>
        <p className="sectionHeading1">3. Invention II</p>
        <p className="normalText">Another way that scientific discovery can lead to entrepreneurial opportunity, is where inventions create
        demand for an existing excess of supply.&nbsp; For example, an abundant supply of silica sand has been around since
        time began.&nbsp; Yet only recently has science discovered ways to process sand so that its value increases
        astronomically.&nbsp; Today, several million transistors can be placed on a single silicon chip.&nbsp; Here scientific
                                discovery lead to entrepreneurial opportunity from the market imperfection: supply without demand.</p>
        <p className="sectionHeading1">4. Coordination</p>
        <p className="normalText">The knowledge of time, place and circumstance that permitted Sam Walton to see the vision of Wal-Mart, is an
        excellent illustration of how non-scientific entrepreneurial discovery can capitalize upon the market imperfection:&nbsp;
        supply without demand.&nbsp; As a small discount retailer, Walton realized that product manufacturers were not
        operating at peak capacity (they had over-supply without a demand to equal the supply).&nbsp; Manufacturers had
        the ability to make more than consumers were buying.&nbsp; He felt that if he could get them to sell their excess
        supply, cut his costs and maintain a healthy margin, then he would have the foundation of a healthy business -- which could help the manufacturer and the consumer at the same time.&nbsp; He also planned to locate in
        communities where his lower prices could generate a satisfactory level of volume. Sam Walton's success at coordinating excess supply with the demand that he knew existed (because of circumstantial information) is
		legendary.</p>
        <p className="normalText">Where a market imperfection occurs, and where individuals possess either the scientific or circumstantial
                                knowledge to utilize that imperfection, a profit opportunity results.&nbsp; Discovering these matches is the essence of entrepreneurship. </p>
        <p className="normalText">The new combinations mentioned earlier can occur in each of these areas of entrepreneurial discovery.&nbsp; Schumpeter (1936) describes five kinds of new combination: </p>
        <ul>
            <ol>
                <li className="normalText">The introduction of a new good or a new quality of a good,</li>
                <li className="normalText">The introduction of a new method of production, </li>
                <li className="normalText">The opening of a new market, </li>
                <li className="normalText">The conquest of a new source of supply of raw materials or components, or </li>
                <li className="normalText">The reorganization of any industry. </li>
            </ol>
        </ul>
        <p className="normalText">Each type of new combination is explained below: </p>
        <p className="sectionHeading2">New or improved</p>
        <p className="normalText">The most common innovations are products/services that are new to the marketplace.&nbsp; A new combination may
        also occur when the products/services have been improved--usually that means that they are improved from a
        quality standpoint.&nbsp; In the case of the micro-chip, the creation of the first chip made it a new combination.&nbsp; The
        continuous redesign and enhancements that have made these chips successively faster and smaller are improvements that also
		qualify as new combinations.</p>
        <p className="normalText">Also worth noting--the current emphasis on "quality" can often result in new combinations.&nbsp; Where
                                product/service quality is dramatically increased through improvements to the quality system of a business, a new combination may result. </p>
        <p className="normalText">It is important, however, to make the distinction in our minds between "true" advances in product/service or
                                quality, and "promotional" differences.&nbsp; Examples of "truly new" would be the introduction of the automobile.&nbsp; An example of "truly improved" would be the addition of air conditioning <i>into</i> the automobile.&nbsp; Claims of
                                "smoother ride" or "new and improved styling" are somewhat dubious and subjective as new combinations, and therefore, they would be considered low in innovation. </p>
        <p className="sectionHeading2">New method of production</p>
        <p className="normalText">A "new combination" occurred when Henry Ford introduced the assembly line method of production in the early years of the automobile industry.&nbsp; This act was innovative because, with the added efficiency of the
        assembly line, the cost of producing cars dropped dramatically. According to Drucker's definition, the added
        efficiency endowed existing resources with new wealth-producing capacity and qualified mass production as an innovation.</p>
        <p className="normalText">This type of new combination is often underrated by the venturing community because many of these improvements are made by engineers.&nbsp; But, using Schumpeter's definition of the entrepreneur, these new
                                combinations are just as important--and everyone involved should be considered to be acting entrepreneurially during the time of production innovation. </p>
        <p className="sectionHeading2">The opening of a new market</p>
        <p className="normalText">Was Christopher Columbus a business innovator?&nbsp; Certainly he "opened a new market" by discovering the Americas.&nbsp; But
        was that enough to make his discovery a new combination?&nbsp; Perhaps a better definition of opening markets is needed.&nbsp; Since we
		are trying to describe the kinds of innovations that propel new ventures forward, we need a comprehensive definition.</p>
        <p className="normalText">The opening of markets involves more than just discovery.&nbsp; In fact, the opening of a market in an increasingly
        globalized society is often a very complex undertaking.&nbsp; In addition to discovery, it requires an understanding of
                                local culture, laws, buyer preferences, business practices, and a host of contact, communication, and transportation details -- all of which we refer to as "setting up a distribution channel." </p>
        <p className="normalText">Thus, when Honda began to market its small, modestly powered motorbikes to US and Canadian consumers, it established a distribution channel to a group of buyers who would not even consider purchasing a heavy,
                                high-powered motorcycle.&nbsp; By opening this market, Honda created a new combination, and was therefore innovative. </p>
        <p className="normalText">This function is so important that it justifies its own sub-question under the innovation heading.&nbsp; More will be
        said later about the importance of the product-market match, which is really a question about whether or not a
        new market exists.&nbsp; Without a market for the product/service, a venture really cannot be considered a business.&nbsp;
                                Instead, it is more properly classified as a "research project," or a "charity." </p>
        <p className="sectionHeading2">The conquest of a new source of supply of raw materials or components </p>
        <p className="normalText">A new combination is present any time the cost structure of a product/service can be reduced significantly.&nbsp; A
        new combination is also manifest when availability is enhanced without compromising quality.&nbsp; As human beings,
        our wants, and therefore the opportunities for business are quite predictable.&nbsp; Our needs have been said to
        revolve around food, shelter, safety, social, ego and self-actualization (Maslow, 1954).&nbsp; Thus, anyone who can
                                find a way to supply the basic ingredients for satisfying these wants for less money or in more abundance (which often amounts to the same thing) has created a new combination.</p>
        <p className="normalText">For example, the discovery and securing of access to the huge petroleum reserves of the Persian Gulf region
        was a new source of supply combination that helped to satisfy human needs for food and shelter.&nbsp; (In fact, an
        in-depth look at the petrochemical industry would likely reveal ways in which petroleum affects each level in
        Maslow's needs hierarchy.)&nbsp; The movement throughout the world of manufacturing facilities from areas of high
        labor costs to those of lower labor costs, would also qualify as a new source of supply combination. Another
        example of a new combination is the development of word-processing technology to replace the typewriter.&nbsp; Each of these examples illustrates how new sources of supply or new components can be provided in more
                                abundance, thus endowing existing resources with new wealth producing capacity.&nbsp; Therefore, they qualify as innovations. </p>
        <p className="sectionHeading2">The reorganization of an industry</p>
        <p className="normalText">When the US Department of Justice undertook to "break up the Bell System," did it create a new combination?&nbsp;
                                Undeniably, the marketplace was reorganized.&nbsp; But whether or not its wealth producing capacity was enhanced is still a question in some circles.</p>
        <p className="normalText">So when Schumpeter asserts that reorganizing an industry constitutes a new combination, he is likely pointing to
        a more basic, and market-driven activity than government intervention in an economic system.&nbsp; A better example of a "new combination" would probably be a re-organization similar to the one that took place when
        Jobs and Wozniak, in&nbsp; developing Apple computer, also launched a fundamental change in the computer
        industry.&nbsp; Clearly, there were many others involved in this reorganization, but the net resulting change in emphasis--away from the inaccessible mainframe computer systems, to the relatively accessible personal
                                computer--can properly be classified as a new combination. </p>
        <p className="sectionHeading1">New Combinations: Summary</p>
        <p className="normalText">Of course each new combination may vary in the level of innovation present.&nbsp;
        Recognizing the degree of innovation is a judgment call, made by comparing
        the idea to products/services in the same category.&nbsp; So if you are
        looking at a new computer game, you could judge its new combination level
        by examining how closely it imitates existing products/services of the
        same type.&nbsp; If the "true" (not imagined) differences are
        great, then the new combination level is high.&nbsp; If the computer game
        idea is similar to games that are already on the market, then its new
        combination level is low. </p>
        <p className="normalText">Wherever the new combination level is low, the answer to Question A: Is it Innovative? is NO -- meaning DON'T GO ON if you are STARTING a venture, until the new combination problem is resolved! </p>
        <p className="normalText">If your venture is already an operating business, and the level of "new combination" is low, this is the place to
        begin to improve your venture.&nbsp; Use the possibilities identified in Figure A-1, to "discover" combinations of
        products and services that "improve" upon, or even make a "break through" in your industry.&nbsp; If you find
        nothing, look for "circumstances" or "science" that fit the market imperfections (supply without demand, and
                                demand without supply).&nbsp; This could mean that you improve your education, or hire staff with complementary skills to yours. </p>
        <p className="normalText">Don't settle for a low "new combination" rating, because a strong "new combination" is the foundation of a
        thriving venture.&nbsp; The questions and suggestions that follow, assume that you have achieved a medium to high
        level of uniqueness in your product or service.&nbsp; So if your business is already in operation, before continuing,
                                you're better off trying to discover the new or better combination that will help you to build a better venture.</p>
    </section>
}