import React from 'react';

export function BuyAJob(props){
  return <><p className="sectionHeading1">Buy Job Venture</p>

	<p className="normalText">Though this business appears to be perilously close to the "low competence" venture, it is really
		substantially different.  Whereas the low competence venture is weak in all areas, this prototype venture
		is strong in the "persistence" variables, of frequency and product longevity.</p>

	<p className="normalText">Yet it should be emphasized that this strength does NOT come from the entrepreneur, but rather is
		a feature of the marketplace. This is where the "selfemployed" jobs fit in.  Thus, anyone
		who operates a personal service small business might fit into this type of venture.</p>

	<p className="normalText">This type of venture is referred to as a "buy-a-job" small business because of its weaknesses,
		which lead to the mere substitution with a long-hours "grind" working for oneself (one low-paying
		customer at a time), as opposed for working for a company (one low-paying boss at a time). This
		is a useful "fallback" plan for times of high unemployment, but it is not entreprneurial, because it does
		not create jobs or wealth for others.</p>

	<p className="normalText">In a buy-a-job venture, weak core competence =&gt; (implies) low NC, low-scarcity, high hold-up,
		uncertainty and ambiguity with medium slack.  Low innovation and medium PMM lead to low NBB and margins.</p>


	<p className="normalText">ADVICE: Start by increasing core competence and with it innovation, to move (at a minimum) to a
		lifestyle venture.  This essentially involves "pruning" the rough edges of the buy-a-job business
		(please press the "View" button to examine the outer rings of the target diagram that should be
		moved toward the "bull's eye.")</p>

</>
}