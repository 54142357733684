import React from 'react';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const PrototypesOverview = (props) => {
  return <>
  <h1 className="pageTitle">The New Venture Template™ Prototypes</h1>
<p className="normalText">Over the past 25 years, two disciplines—the discipline of business <b><i>planning</i></b>, and the discipline of business <b><i>strategy</i></b>—have developed a set of concepts that when combined, offer a powerful template to help build high-potential business ventures.</p>
<p className="normalText">From business <b><i>planning</i> </b>we can compile a list of criteria that answer the question: "Is it a business?"&nbsp; The field of business <b><i>strategy</i> </b>contributes criteria that answer the question: "Can you keep it?" The criteria in these two main steps are expressed in 15 sub-questions, and together form a venture "template" that is especially useful to new ventures, and a very powerful tool to help to diagnose and improve ongoing or troubled ventures.</p>
<p className="normalText">Essentially, the <b>New Venture Template</b>™ utilizes a sample set of <i>viable venture attributes</i> combined into venture viability templates to identify and observe necessary venture characteristics.&nbsp; The process is somewhat similar to the forensic evaluation of a partial strand of human DNA that supports a legal judgment.
                      </p>
<p className="normalText">The assessment of a partial set of <i>venture</i> characteristics such as those found in the<b> New Venture Template™</b>
                           —as a sample of a venture's genetic material—can provide entrepreneurs and investors with the capability to make the business judgments that distinguish viable from less-viable ventures.</p>
<p className="normalText"><NVTemplateResultsGraph data={[1, 2, 3, 4, 5]}/></p>
<p className="normalText">There are common or "prototypical"
                          configurations of sets of answers to the fifteen questions.&nbsp; These are known as <i>Venture Prototypes</i>. They are positioned on "Business (B) / Keep It (K)" graphs and range from "Research
                          Project" on the low end, up to "Model Venture" at the top.</p>
<p className="normalText">The <b>New Venture Template™</b> examines the pattern of answers to the fifteen questions and identifies the degree to which the venture is similar to each of the prototypes. A 100%
                          correlation to a prototype would represent an exact or perfect match.&nbsp; The closest that ventures correlate to any of the prototypes is typically 65-85% — meaning that the venture is <u>NOT</u> actually one of these
                          business types, but is most like the prototype to which it correlates most closely.</p>
<p className="normalText">The fundamental characteristics of each venture prototype are described, along with specific knowledge and targeted strategies for its improvement (<i>where venture attributes are either weak or missing</i>).&nbsp;
                          Mapping a venture against its closest known prototype leverages this knowledge into a powerful and prioritized approach to moving the company toward becoming a "HIGH-POTENTIAL" venture.</p>
<p className="normalText">In a perfect world these fundamentals of a well-built venture would be in place at the outset.&nbsp; In a practical
                          application, the venturer and investors should put substantial effort toward addressing these fundamental venture variables <u>prior</u> to the investment of significant<b> financial </b>and <b>human resources</b>
                          .&nbsp; If not, then additional risk is inherent in the venture.</p>
<p className="normalText">Ultimately a script can be developed for the venture, outlining what actions will be taken to keep the venture on course from launch to maturity.</p>
<ul>
<li className="normalText"><Link className="contentLink" to="/template/prototypes/research-project">Research Project</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/low-competence">Low-Competence Venture</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/hostage">Hostage Venture</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/fad">Fad</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/buy-a-job-small-business">Buy-A-Job Small Business</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/charity">Charity</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/lifestyle-small-business">Lifestyle Small Business</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/technology-based-success">Technology-based Success</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/high-potential">High Potential Venture</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/competence-based-success">Competence-based Success</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/hobby">Hobby</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/struggling-proprietary">Struggling Proprietary</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/competence-based-troubled">Competence-based Troubled</Link></li>
  <li className="normalText"><Link className="contentLink" to="/template/prototypes/model-venture">Model Venture</Link></li>
</ul>
</>
}