import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const Hobby = () => {
    return <section>
	<h1 className="pageTitle">The Hobby Prototype</h1>
	<p className="normalText">Based on interest in an area that borders on or is "love," a Hobby is a high <Link className="contentLink" to="/template/viability/core-competence">Core Competence</Link> activity. <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link> levels for hobbies vary depending upon the individual and the hobby.</p>
	<p className="normalText">Because of the uniqueness of each individual, <Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link> and <Link className="contentLink" to="/template/fundamentals/non-appropriability">Appropriability</Link> are virtually non-existent and <Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link> and <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link> are low.</p>
  <p className="normalText">But a Hobby is NOT a business, because there is no <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>, <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link>, <Link className="contentLink" to="/template/viability/margin">Margin</Link>, <Link className="contentLink" to="/template/viability/volume">Volume</Link>, <Link className="contentLink" to="/template/viability/repetitive">Frequency</Link>, <Link className="contentLink" to="/template/viability/long-term-need">Long-term Need</Link>, or business <Link className="contentLink" to="/template/viability/resources">Resources</Link>.</p>
        <div className="prototype-diagrams">
	<p className="sectionHeading2">Figure 21: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="hob" />
    <p className="sectionHeading2">Figure 22: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype={"Hobby"} />
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">To convert a Hobby into a venture, the 1st three sub-questions need to be addressed.&nbsp; In other words, the BUSINESS a
		spects of the activity must be the focus.</p>
    <p className="normalText">Start with <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link> and <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>.&nbsp; If there is a market, then you can start to focus on the cost/volume/profit calculation.&nbsp; Once these are attended to, you can then work on the <Link className="contentLink" to="/template/fundamentals/persistence">"Persistence"</Link> items of <Link className="contentLink" to="/template/viability/repetitive">Frequency</Link>, <Link className="contentLink" to="/template/viability/long-term-need">Long-term Need</Link>, and ongoing <Link className="contentLink" to="/template/viability/resources">Resources</Link>.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Red Pipers &amp; Radio Controllers -- <i>The Hobby</i></p>
	<p className="normalText">John Klippenstein was lying on the beach, quenching his thirst with a cool drink while flipping through the
        pages of his favorite magazine – 'Red Pipers &amp; Radio Controllers.'&nbsp; John was so excited about radio
        controlled aircraft that he had always considered the idea of building one for someone else, for money.&nbsp;
        Over the years his passion for building and flying model radio controlled aircraft had resulted in a substantial
        and valuable collection of masterpieces, particularly in the Red Piper models.&nbsp; He had developed a highly refined
		'knack' for producing them.</p>
    <p className="normalText">John's love for his hobby did not end on the workbench where he put his airplanes together, nor at the local
                                speedway where he flew them once or twice month on the weekends.&nbsp; He wanted to share his experience with others; he wanted to build planes people could keep.&nbsp; The truth of the matter was that John simply
                                needed a reason to devote more of his time to the one thing he loved most in life – his Red Piper model airplanes.</p>
	<p className="sectionHeading2">THE PRODUCT</p>
	<p className="normalText">John reached over to his backpack and grabbed a notepad, pen, and a calculator.&nbsp; He started on a plan to determine the feasibility of his new idea.&nbsp; He decided that each new model plane would be entirely unique --
                                some consisting of basic designs and others incorporating many different features, gizmos, and gadgets.</p>
    <p className="normalText">He also developed a rudimentary financial analysis to determine how much money he would need to charge
                                for his planes.&nbsp; Given his skill at building radio-controlled model aircraft, he figured that his time was worth
                                about $20 per hour.&nbsp; Since it took two months to build one plane, John figured that he would need 320
                                hours of work for a total of $6,720.&nbsp; When material costs, parts and other supplies were included, John's calculations totaled $7,300.</p>
    <p className="normalText">Pausing for moment, John wondered if anyone would really be willing to pay $7,300 for a finished model
                                when one could purchase the kit and build it oneself at a fraction of the cost.&nbsp; How could he create enough
                                value to warrant a purchase for that amount?&nbsp; He couldn't.&nbsp; Even if he dropped his price dramatically, he
                                would only be able to build six airplanes in any one year.&nbsp; John became uncomfortable with the whole idea, but continued on with the investigation anyhow.&nbsp;&nbsp;&nbsp; </p>
	<p className="sectionHeading2">PRODUCTION</p>
	<p className="normalText">The fact that John could only make several airplanes a year actually didn't bother him.&nbsp; He was in it for the
                                hobby, not for the money.&nbsp; And it really wouldn't matter if he made any sales at all – he would build his Red
                                Pipers for his own enjoyment.&nbsp; John always chuckled to himself when friends and relatives came to visit and
                                ask where he found time to fly all of his airplanes.&nbsp; He was amused by the fact that he enjoyed building the
                                planes more than flying them.&nbsp; He didn't even need the airplanes -- just the satisfaction of building them;
                                beveling edges, sanding corners, gluing pieces together, the smell of the resin and paint, fine grains of multicoloured sawdust on the floor, a recently crashed model in the corner awaiting repair, the workbench
                                littered with precision instruments and tools – all combined to create his own personal Utopian world.&nbsp;
                                Never would he abandon his airplanes.&nbsp; Suddenly John awoke.&nbsp; The sun was down now, but the pain from the burn would linger for days!</p>
    </section>
}