import React from 'react';
import "./CrossMark.css"
export function CrossMark(props) {
  const { positionKValue, positionBValue} = props;
  const positioned = positionKValue && positionBValue
  return <path
  d="M9.077,25.99h14v14c0,0.553,0.448,1,1,1s1-0.447,1-1v-14h14c0.552,0,1-0.447,1-1s-0.448-1-1-1h-14v-14c0-0.553-0.448-1-1-1  s-1,0.447-1,1v14h-14c-0.552,0-1,0.447-1,1S8.525,25.99,9.077,25.99z"
  style={positioned ? {
    transform: `translate(${positionBValue(
      props.b,
      -5
    )}, ${positionKValue(props.k, -5)})`
  } : {}}
  className="cross-mark"
/>
}