import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const TechnologyBasedSuccess = () => {
    return <section>
	<h1 className="pageTitle">The Technology-based Success Prototype</h1>
	<p className="normalText">The success of this type of venture comes from high levels of <Link className="contentLink" to="/template/viability/core-competence">Core Competence</Link>, <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>, <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link>, <Link className="contentLink" to="/template/viability/margin">Margins</Link>, <Link className="contentLink" to="/template/viability/volume">Volume</Link>, <Link className="contentLink" to="/template/viability/repetitive">Purchase Frequency</Link>, <Link className="contentLink" to="/template/viability/long-term-need">Long-term Need</Link>, and access to <Link className="contentLink" to="/template/viability/resources">Resources</Link>.</p>
	<p className="normalText">&nbsp;Ironically, the reason that it is not higher on the "<b>Keep It</b>" axis stems from its source of strength: technology.&nbsp; And, since the pace at which many technologies change is rapid, levels of <Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link> and <Link className="contentLink" to="/template/fundamentals/non-appropriability">Non-appropriability</Link> erode as the "newness" of an initial <Link className="contentLink" to="/template/viability/new-combination">New Combination</Link> fades.&nbsp; Thus, this type of venture most likely will have medium levels of <Link className="contentLink" to="/template/viability/new-combination">New Combination</Link> and <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link>, supported by quite low levels of <Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link> (insurable risk).</p>
        <div className="prototype-diagrams">
	<p className="sectionHeading2">Figure 15: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="tbs" />
    <p className="sectionHeading2">Figure 16: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype="Technology-based Success" />
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">
		To foster the longevity of successful technology-based ventures, the key is to utilize presently plentiful
		esources to work "on" the business--creating a venture that encourages more <Link className="contentLink" to="/template/viability/new-combination">New Combination</Link>-based <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link>,
		which in turn forms the foundation of <Link className="contentLink" to="/template/fundamentals/scarcity">Scarce</Link>, <Link className="contentLink" to="/template/fundamentals/non-appropriability">Non-appropriable</Link> products and/or services.</p>
	<p className="normalText">This approach starts with an aggressive approach toward the management of <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link>, which if addressed while the venture is thriving, will continue to generate adaptations of the venture to its rapidly changing environment.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Millennium Software -- <i>Technology-Based Success</i></p>
	<p className="normalText">Eric Schmidt was reflecting on the tremendous success that his high tech software company had enjoyed
        from humble beginnings.&nbsp; But even in success, he could not let up, and this was beginning to tire him out.&nbsp;
        Millennium produced software for the high flying telecommunications industry.&nbsp; Millennium products were part
		of the computer telephony business; a new but tremendously competitive market, growing exponentially,
        with five or six firms competing to be the industry leader.</p>
    <p className="normalText">After graduating in computer science in 1989, Eric thought that a rest was in order and took up residence in
                                Colorado to devote his main efforts to skiing and mountain biking.&nbsp; To help pay for his choice of lifestyle he
                                took a job in a local computer store selling computers and keeping his skills current.&nbsp; During this time, Eric
                                began to appreciate the substantial changes on the horizon when telephones and computers would merge.&nbsp; </p>
    <p className="normalText">The merging of telephony and computers became his passion and he devoted most of his spare time towards
                                developing software for the integration.&nbsp; His first real business break came when he was able to convince a fellow classmate who worked for a large telecom supply company, that his software could open up new
                                markets.&nbsp; The telecom supply company liked the idea and within two years Millennium had four other major accounts under development.&nbsp; Although his product was new to the market when it was introduced, the
                                competition was close behind and had developed a comparable product within months.&nbsp; The battle for development of the best product was fierce.&nbsp; The field was revolutionary and would be around for a long
                                while, but the individual products had a relatively short life cycle.&nbsp; The customers loved Eric's products because they incorporated greator flexibility at a lower cost.</p>
    <p className="normalText">Millennium generated large profits, increasing substantially each year.&nbsp; Although competition was intense, the
                                demand was incredible.&nbsp; Rivals competed on product and not price.&nbsp; Eric felt that if Millennium stumbled and
                                didn't keep near the top of the heap with their product, they would run out of clients quickly.&nbsp; Competition,
                                industry mergers, and government policy could create major threats very quickly.&nbsp;&nbsp; Eric generated a network
                                of allies who made a point of keeping him up to date on competitors' products, government policies and corporate gossip.&nbsp; There was no room for complacency in this business.</p>
    <p className="normalText">Millennium's software was not copyright protected.&nbsp; Protection in software was a lengthy process and by
                                the time a competitor could reverse engineer the product it would be outdated and replaced with a new
                                version.&nbsp; Millennium had a little breathing room with its clients, as compatibility and retraining issues made
                                switching products costly.&nbsp; However, Millennium only had a few very large customers, so there was always
                                a risk that a merger or strategic alliance could reduce the number of clients and lead to demands for lower pricing.</p>
	<p className="normalText">Eric was slightly uncomfortable with the work habits of his crew, there seemed to be a lot of paid break time
    	and unnecessary perks.&nbsp; But, projects were always changing, crews were switching personnel to adapt to
        the new projects, and the stress level was high, so Eric kept his thoughts to himself and took consolation in the high
		profits Millennium was making.</p>
    </section>
}