import React from 'react';
import { Link } from 'react-router-dom';

export const Scarcity = () => {

return <section>
	<h1 className="pageTitle">The Fundamental of Scarcity</h1>
      <p className="normalText">It is only through market imperfections that profit opportunities occur. The two basic market imperfections are: <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.Demand without supply, and <br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.Supply without demand. <br/><br/>A market imperfection means that scarcity exists.&nbsp; This section is NOT about recognizing scarcity (as is Section A-1 on New Combinations), it is
                                about preserving it.&nbsp; Though you have built a business, you will not necessarily be able to keep it.&nbsp; Preserving scarcity is important, maybe even MORE IMPORTANT than creating it in the first place.&nbsp;
                                So take the time to "do the homework" that is necessary to ensure that the value of your entrepreneurial discovery is preserved. <br/><br/>Preserving scarcity has always been the goal of monopolists.&nbsp;
                                The market imperfection that monopolists focus on, is the first one: demand without supply (the second--supply without demand--being primarily a function of marketing acumen, for which there is a relatively perfect
                                market). Monopolists, then, prevent the erosion of the "demand without supply" imperfection by (1) restricting supply, and (2) by preventing reductions in demand. <br/><br/>Prior to the enactment of the
                                "anti-trust" laws, monopolists preserved scarcity by restraint of trade.&nbsp; They accomplished this through "trusts" or agreements among firms in an industry that would (1) keep new competitors
                                out (restrict supply), and would (2) effectively prevent substitute products from reaching the customer (prevent reductions in demand). <br/><br/>Two main strategies were used to create this monopoly position.&nbsp;
                                Under the first, prices were "fixed" by a group of firms that colluded together to prevent new entrants from coming into the market.&nbsp; These firms, acting together, formed a monopoly that could force
                                the price so low that a new company couldn't afford to stay long in the market, and would therefore be "squeezed out."&nbsp; In the second, a given firm would acquire all of the competitors, potential new
                                entrants, and providers of substitutes, to create an absolute monopoly. <br/><br/>Presently, however, with the enactment and enforcement of the anti-trust laws, the monopolistic methods of creating relatively
                                "permanent" monopoly are not available to firms (especially in the US, but also in varying degrees throughout the Western economic world).&nbsp; Yet through the insight of strategy scholars (Porter, 1980,
                                1985; Rumelt, 1987) several less permanent but legal methods for preserving the "demand without supply" market imperfection have been discovered and articulated.&nbsp; These are the methods that form the
                                basis for the type of scarcity preservation that is essential for you to keep your business. <br/><br/>The first set of methods address restricting supply.&nbsp; Generally supply can be restricted when imitators can
                                be kept out of the marketplace.&nbsp; The second set of methods deals with the approaches to take to prevent demand reduction.&nbsp; Generally demand reduction can be prevented when the impact of substitute products
                                or services, on the demand for your products or services, can be minimized. <br/><br/>How, then, can you distinguish between a venture that preserves scarcity, and one that does not?&nbsp; There are two sub-questions
                                that must be asked to answer this question. They are: <br/><br/>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A. <Link className="contentLink" to="/template/viability/non-imitable">Is it non-imitable?</Link> (Can supply increases by the competition be prevented?)
                                <br/>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; B. <Link className="contentLink" to="/template/viability/non-substitutible">Is it non-substitutable?</Link> (Can demand decreases by the competition be prevented?) <br/><br/>Where
                                answers to the questions about imitability and substitutability can both be answered yes, then the venture has great value--so much so, that it is then in great danger of being "stolen" from its creators.
                                <br/>&nbsp;</p>
</section>
}