import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import './TemplateQuestionnaire.css';
import {rootUrl} from '../utils/rootUrl';
import {AuthRequired} from '../utils/AuthRequired.component';
import {HasPermission} from '../utils/HasPermission.component';
import {Loader} from '../Loader/Loader.component';

const ScaleLegend = () => {
  return (
    <div>
      <div
        className="labels-container"
      >
        <div className="label">Low</div>
        <div className="label">Medium</div>
        <div className="label">High</div>
      </div>
    </div>
  );
};

const ResponseOption = props => {
  return <div className="nvt-radio-input-container" onClick={() => {props.setQ()}}>
    <input type="radio" className="nvt-radio-input" name={props.groupName} checked={props.checked} onChange={() => {props.setQ()}} />
  </div>
}

const Question = props => {
  return <section>
    <div>{props.questionJSX}</div>
    <div className="nvt-question-container">
      <ScaleLegend />
      <section className="nvt-radio-inputs-container">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => {
          return <ResponseOption key={props.q + item} setQ={() => props.setQ(item)} val={item} checked={props.q === item} />
        })}
      </section>
    </div>
    <hr />
  </section>
}

export const TemplateQuestionnaire = props => {
  const [q1, setQ1] = useState(null);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [q4, setQ4] = useState(null);
  const [q5, setQ5] = useState(null);
  const [q6, setQ6] = useState(null);
  const [q7, setQ7] = useState(null);
  const [q8, setQ8] = useState(null);
  const [q9, setQ9] = useState(null);
  const [q10, setQ10] = useState(null);
  const [q11, setQ11] = useState(null);
  const [q12, setQ12] = useState(null);
  const [q13, setQ13] = useState(null);
  const [q14, setQ14] = useState(null);
  const [q15, setQ15] = useState(null);
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultsReady, setResultsReady] = useState(false);

  useEffect(() => {
    if (triggerSubmit) {
      setLoading(true)
      setTriggerSubmit(false);
      const nvt = {
        q1,
        q2,
        q3,
        q4,
        q5,
        q6,
        q7,
        q8,
        q9,
        q10,
        q11,
        q12,
        q13,
        q14,
        q15,
      }

      submitNVT(nvt);

      async function submitNVT(nvt) {
        try {
          if (!nvt) throw new Error("No NVT passed in.")
          const response = await fetch(`${rootUrl}/api/nvt`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME)}`
            },
            body: JSON.stringify({
              nvt
            })
          })
          if (!response.ok) {
            if (response.statusText === "Forbidden") {
              return <Redirect to="/login"></Redirect>
            }
            throw new Error("Problem submitting NVT");
          } else {
            const json = await response.json();
            if (json.template_id) {
              localStorage.setItem('nvt.template_id', json.template_id);
            }
            setLoading(false)
            return setResultsReady(true)
          }
        } catch (error) {
          console.error(error);
          setLoading(false)
        }
      }
    }
  }, [triggerSubmit, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15])

  const ableToSubmit = q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8 && q9 && q10 && q11 && q12 && q13 && q14 && q15

  if (!loading && resultsReady) return <Redirect to="/authenticated/NVT-results" />
  if (loading) return <Loader>Submitting and scoring your New Venture Questionnaire...</Loader>
  return (<AuthRequired>
    <HasPermission requiredPermissions={["nvt"]}>
      <h1 className="pageTitle">Template Questionnaire</h1>
      <p className="normalText">
        Let's analyze your venture. Each of the following questions, corresponds
      to an aspect of the <Link className="contentLink" to="/template">NVT Concepts</Link>. Read the each question and indicate, within the buttons below the
      question, whether your venture can be characterized as containing the
      concept to a low, medium, or high degree. If sufficient information is
      not available for you to evaluate your venture against a given concept,
      then mark the question as "Not Available". After completing all the
      questions, press the "Submit Questionnaire" button at the bottom of this
      page.
    </p>
      <Question
        q={q1}
        setQ={setQ1}
        questionJSX={<>
          <p className="sectionHeading3">
            1. Is it a <Link
              className="contentLink"
              to="/template/viability/new-combination"
            >
              New Combination
      </Link>
      ?
    </p>
          <p className="normalText">
            The newness of a combination varies continuously from:
      <br />
      relatively low: new for us but not for other companies;
      <br />
      to medium: a definite improvement over existing supply for present
      demand, or demand for present supply; <br />
      to high: a real breakthrough.{' '}
          </p>
        </>} />
      <Question
        q={q2}
        setQ={setQ2}
        questionJSX={<>
          <p className="sectionHeading3">
            2. Is there a <Link
              className="contentLink"
              to="/template/viability/product-market-match"
            >
              product-market match
      </Link>
      ?
    </p>
          <p className="normalText">
            The level of product market match also varies continuously from:
    </p>
          <ul>
            <li className="normalText">no or low match: no purchase orders; </li>
            <li className="normalText">
              to medium match: offers added features to the market
              [e.g.convenience] such that some orders or sales exist;
      </li>
            <li className="normalText">
              to high: matches a market want or need so well that a sales backlogs
              or large quantity purchase orders exist.
      </li>
          </ul>
        </>} />
      <Question
        q={q3}
        setQ={setQ3}
        questionJSX={<>
          <p className="sectionHeading3">
            3. Is there a{' '}
            <a
              className="contentLink"
              href="../Template/Viability/net_buyer_benefit.asp"
            >
              net buyer benefit
      </a>
      ?
    </p>
          <p className="normalText">
            Net buyer benefit refers to the intangible value of your product or
            service perceived by the customer above and beyond the price [how much
            they want it more than they want money]. Generally:
    </p>
          <ul>
            <li className="normalText">low NBB is signaled by discount pressure; </li>
            <li className="normalText">medium NBB by price stability; and </li>
            <li className="normalText">
              high NBB by "stock-outs" and price premiums.{' '}
            </li>
          </ul>
        </>} />
      <Question
        q={q4}
        setQ={setQ4}
        questionJSX={<>
          <p className="sectionHeading3">
            4. Are there <Link
              to="/template/viability/margin"
              className="contentLink"
            >
              margins
      </Link>
      ?
    </p>
          <p className="normalText">
            Margins are the "pre-tax" profits of the venture, the difference
            between price and fully absorbed cost per unit. Margins are considered
            low if under 15%; medium, if in the 16% to 30% range, and high if over
      30%.{' '}
          </p>
        </>} />
      <Question
        q={q5}
        setQ={setQ5}
        questionJSX={<>
          <p className="sectionHeading3">
            5. Is there sufficient{' '}
            <Link
              to="/template/viability/volume"
              className="contentLink"
            >
              volume
      </Link>
      ?
    </p>
          <p className="normalText">
            Sufficient volume is achieved where enough units are sold for the
            venture to meet its objectives. Rate this:
    </p>
          <ul>
            <li className="normalText">
              low, if expected volume will not be sufficient;
      </li>
            <li className="normalText">
              medium, if expected volume should be sufficient; and{' '}
            </li>
            <li className="normalText">
              high if expected volume may exceed the volume needed for the venture
              to meet its objectives.
      </li>
          </ul>
        </>} />
      <Question
        q={q6}
        setQ={setQ6}
        questionJSX={<>
          <p className="sectionHeading3">
            6. Is it{' '}
            <Link
              to="template/viability/repetitive"
              className="contentLink"
            >
              repetitive
      </Link>
      ?
    </p>
          <p className="normalText">
            Repetitive purchase is essential for venture persistence over time.
            Rate this:
    </p>
          <ul>
            <li className="normalText">low, if purchase frequency is once only;</li>
            <li className="normalText">
              medium, if the frequency of purchase is occasional; and
      </li>
            <li className="normalText">
              high, if customers make frequent purchases of this product or
              service.
      </li>
          </ul>
        </>} />
      <Question
        q={q7}
        setQ={setQ7}
        questionJSX={<>
          <p className="sectionHeading3">
            7. Is there a{' '}
            <Link
              to="template/viability/long-term-need"
              className="contentLink"
            >
              long-term need
      </Link>
      ?
    </p>
          <p className="normalText">
            The length of time over which demand for the product continues affects
            the volume levels of question 5. Rate this:
    </p>
          <ul>
            <li className="normalText">low, if the product or service is a fad;</li>
            <li className="normalText">
              medium, if the product or service satisfies a short-term need; and
      </li>
            <li className="normalText">
              high, if the product or service meets a long-term need.
      </li>
          </ul></>} />
      <Question
        q={q8}
        setQ={setQ8}
        questionJSX={<>
          <p className="sectionHeading3">
            8. Are{' '}
            <Link
              to="template/viability/resources"
              className="contentLink"
            >
              resources
      </Link>{' '}
      sufficient to sustain the venture?
    </p>
          <p className="normalText">
            Most often the amount of "backing" available for the venture plays a
            huge role in whether the ven- ture survives or not. In "Ron's Rule of
            Four" I forecast that things take 4 times as long and cost 4 times as
            much as you initially plan. It is therefore wise to have extensive
      cash and people resources. Rate this: <br />
          </p>
          <ul>
            <li className="normalText">low, is you have few, if any resources;</li>
            <li className="normalText">medium, if you have few resources; and </li>
            <li className="normalText">high, if you have many resources.</li>
          </ul></>} />
      <Question
        q={q9}
        setQ={setQ9}
        questionJSX={<>
          <p className="sectionHeading3">
            9. Is it{' '}
            <Link
              to="template/viability/non-imitable"
              className="contentLink"
            >
              non-imitable
      </Link>
      ?
    </p>
          <p className="normalText">
            Imitation is deterred when the venture is unique, and is therefore
            isolated from "copy-cats." The mechanisms that prevent imitation are
            therefore called "isolating mechanisms." Rate non-imitability:
    </p>
          <ul>
            <li className="normalText">
              low, if the product of the venture has no isolating mechanisms and
              is therefore completely imitable;
      </li>
            <li className="normalText">
              medium, if the venture is partially protected by isolating
              mechanisms (this is NOT a numerical count of the mechanisms, but
              rather is an assessment of the STRENGTH of whatever mechanisms are
              present--of course, the more the better); and
      </li>
            <li className="normalText">
              high, if isolating mechanisms are sufficiently strong to permit
              little or no imitation.
      </li>
          </ul></>} />
      <Question
        q={q10}
        setQ={setQ10}
        questionJSX={<>
          <p className="sectionHeading3">
            10. Is it{' '}
            <Link
              to="template/viability/non-substitutable"
              className="contentLink"
            >
              non-substitutable
      </Link>
      ?
    </p>
          <p className="normalText">Rate Non-substitutability:</p>
          <ul>
            <li className="normalText">
              low, when there are substitutes that directly reduce demand;{' '}
            </li>
            <li className="normalText">
              medium, when there are substitutes that indirectly reduce demand;
        and{' '}
            </li>
            <li className="normalText">is high, when there are no such products.</li>
          </ul></>} />
      <Question
        q={q11}
        setQ={setQ11}
        questionJSX={<>
          <p className="sectionHeading3">
            11. Is there no{' '}
            <Link
              to="template/viability/slack"
              className="contentLink"
            >
              slack
      </Link>
      ?
    </p>
          <p className="normalText">Rate no slack:</p>
          <ul>
            <li className="normalText">
              {' '}
        low, where there is a lot of waste and inefficiency;{' '}
            </li>
            <li className="normalText">medium, where there is some; and </li>
            <li className="normalText">high where there is none.</li>
          </ul></>} />
      <Question
        q={q12}
        setQ={setQ12}
        questionJSX={<>
          <p className="sectionHeading3">
            12. Are there no{' '}
            <Link
              to="template/viability/no-holdup"
              className="contentLink"
            >
              hold-up
      </Link>{' '}
      problems?
    </p>
          <p className="normalText">Rate no hold-up:</p>
          <ul>
            <li className="normalText">
              low, where there is a lot of small numbers bargaining power in
              suppliers and buyers;
      </li>
            <li className="normalText">medium, where there is some; and</li>
            <li className="normalText">
              high, where suppliers, buyers have little or no power over the
              venture.
      </li>
          </ul></>} />
      <Question
        q={q13}
        setQ={setQ13}
        questionJSX={<>
          <p className="sectionHeading3">
            13. Is{' '}
            <Link
              to="template/viability/uncertainty"
              className="contentLink"
            >
              uncertainty
      </Link>{' '}
      minimized?
    </p>
          <p className="normalText">Uncertainty minimization is</p>
          <ul>
            <li className="normalText">
              low, where there is no insurance on the people or the business, and
              where there is no tax planning, current tax savings accounts etc.;
      </li>
            <li className="normalText">medium, where risk management is medium; </li>
            <li className="normalText">
              high, where risks are low because of planning, insurance,
              statistical process controls, tax reserves, etc.
      </li>
          </ul></>} />
      <Question
        q={q14}
        setQ={setQ14}
        questionJSX={<>
          <p className="sectionHeading3">
            14. Is{' '}
            <Link
              to="template/viability/ambiguity"
              className="contentLink"
            >
              ambiguity
      </Link>{' '}
      reduced?
    </p>
          <p className="normalText">Ambiguity reduction should be rated</p>
          <ul>
            <li className="normalText">
              low, where there are no long-term planning processes conducted in a
              heterogeneous group setting;
      </li>
            <li className="normalText">
              medium, where some such planning is conducted; and
      </li>
            <li className="normalText">
              high, where a "mastermind alliance" (Napoleon Hill, Think and Grow
              Rich) is in regular operation.
      </li>
          </ul></>} />
      <Question
        q={q15}
        setQ={setQ15}
        questionJSX={<>
          <p className="sectionHeading3">
            15. What is the level of your <Link
              className="contentLink"
              to="template/viability/core-competence"
            >
              core competence
      </Link>
      ?
    </p>
          <p className="normalText">Rate this:</p>
          <ul>
            <li className="normalText">
              low, if you posses little or no experience and specialization in
              your business;
      </li>
            <li className="normalText">
              medium, if you have some experience and unique knowledge; and
      </li>
            <li className="normalText">
              high, if you are very familiar with your industry, have worked for
              at least 5 years therein, and can perform at least one specialized
              task that is critical to success in the business.
      </li>
          </ul>
        </>} />
      {!ableToSubmit && <h2 style={{textAlign: "center"}}>You must answer all questions prior to making your submission.</h2>}
      <section className="nvt-submission-buttons-container">
        <button className="nvt-submission-button" style={{marginRight: '2rem', backgroundColor: "#fff"}}
          onClick={() => {
            setQ1(null);
            setQ2(null);
            setQ3(null);
            setQ4(null);
            setQ5(null);
            setQ6(null);
            setQ7(null);
            setQ8(null);
            setQ9(null);
            setQ10(null);
            setQ11(null);
            setQ12(null);
            setQ13(null);
            setQ14(null);
            setQ15(null);
          }}>
          Reset Questionnaire
      </button>
        <button className="nvt-submission-button"
          disabled={!ableToSubmit}
          onClick={() => setTriggerSubmit(true)}>
          Submit Questionnaire
      </button>
      </section>
    </HasPermission>
  </AuthRequired>)
}