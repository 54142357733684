import React from 'react';

export function HighPotential(props) {
        return <><p className="sectionHeading1">High Potential Venture</p>

                <p className="normalText">This venture has medium to high ratings in all categories and as such is well balanced.
          &nbsp; There is, however, room for improvement in each area. </p>

                <p className="normalText">ADVICE: Work to increase value and decrease appropriability and security.&nbsp; This
                will increase "keeping money" =&gt; (which implies) persistence and the
                opportunity to utilize flexibility to retain and adapt core competencies and to foster
          innovation. </p>
        </>

}