import React from 'react';
import "./MatrixCard.css";

export const MatrixCard = (props) => {
    return <button className={`matrix-cards ${props.targetZone ? "target-zone" : ""} ${props.selected ? "emphasis-card" : ""}`}>
    <div className="matrix-container">
        <div className="matrix-card-title">{props.number} - {props.type}</div>
        <div className="matrix-card-percentage">{props.percent}%</div>
        <div className="matrix-card-tagline">
            {props.title}
        </div>
        <div className="matrix-card-description">
            {props.description}
        </div>
    </div>
    </button>

}