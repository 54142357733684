import React from 'react';
import { Link } from 'react-router-dom';

export const Persistence = () => {

return <section>
	<h1 className="pageTitle">The Fundamental of Persistence</h1>
      <p className="normalText">
        By now it may have occurred to you that if the answer to the question
        "Is it innovative?" is yes, and the answer to the question "Is
        it valuable?" is also yes, that a venture is a worthwhile endeavor.&nbsp;
        Ventures that have a positive answer to these first two questions and
        related sub-questions, have economic worth -- but they still may lack
        the key venture characteristic: persistence over time. In this case, these
        ventures are fads. <br/>
        <br/>
        How can you distinguish between a venture that is a fad, and one that
        has the potential to last?&nbsp; There are three sub-questions that must
        be asked to accomplish this. They are: <br/>
        <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A. <Link className="contentLink" to="/template/viability/repetitive">Is it repetitive?</Link> <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; B. <Link className="contentLink" to="/template/viability/long-term-need">Is there a long-term need?</Link> <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; C. <Link className="contentLink" to="/template/viability/resources">Are resources sufficient to sustain the venture?</Link> <br/>
        <br/>
        SUMMARY NOTE: Strength of Persistence--Commitment Between Market and Venture
        <br/>
        <br/>
        Understanding the persistence over time (sustainability) of a product
        or service is a complex undertaking.&nbsp; Not only must the venturer
        understand repetitive, long term and resource aspects of the position
        that is taken in the marketplace, but the strength of the linkage between
        market and venture must also be assessed.&nbsp; What we are really trying
        to ascertain is the level of commitment that exists between market (customer)
        and venture.&nbsp; These reciprocal commitments reduce liabilities of
        newness (Stinchcombe, 1965) which in turn enhance the sustainability of
        the venture.&nbsp; However, where commitment (which must be made in any
        event) is made to an unsound position, sustainability is in doubt. <br/>
        <br/>
        A sound position has previously been defined as repetitive purchases,
        long term need, and sufficient resources to sustain the venture.&nbsp;
        The conditions that affect the strength of the commitment, and thereby
        moderate the persistence of the venture, thus become important. <br/>
        <br/>
        At least 4 conditions influence the commitment level between market and
        venture: lock-in, lock-out, lags, and inertia (Ghemawat, 1991). In some
        of these conditions, the market extracts a commitment from the venture
        from which it is very difficult to withdraw--therefore enhancing persistence.&nbsp;
        This is the case with lock-in. Lock-in occurs as a result of sunk costs
        that arise in connection with durable, specialized, or untradable (sticky)
        assets (Ghemawat, 1991); or from non replicable assets (Grant, 1991).&nbsp;
        For example, once the market locks Boeing into the production of the 747
        with purchase orders, Boeing has such high exit costs that delivering
        the orders has the lowest cost of all alternatives. <br/>
        <br/>
        Lock-out, also extracts a commitment from the venture that benefits the
        customer.&nbsp; Lock-out commits the venture because opportunity costs
        are incurred when a course of action is taken, that commit and restrict
        the company in future choices (Ghemawat, 1991). <br/>
        <br/>
        Lags, on the other hand, extract commitments from customers that benefit
        the venture.&nbsp; Lags caused by the lead times inherent in the acquisition
        and deployment of specific strategic assets (Williamson, 1985; Ghemawat,
        1991) contribute to the commitment and therefore sustainability of a venture,
        since first mover advantages accrue to leaders (Rumelt, 1987). <br/>
        <br/>
        Lastly, inertia which arises as a result of the infrastructure within
        an organization (e.g. structure, attitudes, and culture) also contributes
        to the persistence of a venture over time (Ghemawat, 1991).&nbsp; Here
        the venture benefits (or suffers) from the organizational decisions and
        understandings that occur at or near the time of founding.&nbsp; Where
        inertial aspects of an organization "fit" the market--the venture
        is "selected for." Where they do not--the venture is "selected
        against."&nbsp; Either way, commitment is high, and the only way
        to persist over time is to be "selected for."&nbsp; Hence, in
        addition to determining that an entrepreneurial discovery is repetitive,
        long term, and has sufficient resources, venturers may assess each of
        these factors to determine the level of mutual commitment present.</p>
</section>
}