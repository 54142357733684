import React from 'react';

export const Resources = (props) => {
	return <section>
		<h1 className="pageTitle">Viability - Are Resources Sufficient?</h1>
		<p className="normalText">Think about it.&nbsp; At this point in the development of your venture, if you have managed to create a venture which qualifies
		as a YES to all of the preceding questions, you will have on your hands a venture that is innovative, valuable, and is both repetitive
		and long term in its level of persistence.&nbsp; What is going to be happening if this thing is up and running?&nbsp; Your telephone
		will be "ringing off the hook"; your order desk will be backlogged; production will be behind; and every bottleneck in your
		system will be screaming for attention.&nbsp; What kind of attention?&nbsp; More of everything.&nbsp; More raw materials.&nbsp; More
		production capacity.&nbsp; More office help.&nbsp; More people for support services.&nbsp; When you get this far you will need resources--lots
		of resources.&nbsp; You will need growth capital. </p>
		<p className="normalText">So it is very wise to plan for adequate resources in advance.&nbsp; Remember, most of the new ventures that die, die due to mismanaged
		finances.&nbsp; And, because growth places a severe strain on finances, some ventures die of their own accomplishments.&nbsp; It is
		in the evaluation of "resource sufficiency" that the business plan, staple of the financing community, is invaluable.&nbsp;
		A business plan is invaluable for two reasons (1) it helps you to clearly think through the resources question, and
		(2) it helps you to clearly communicate to potential sources of those resources (mainly financing sources) who you are, what you are
		trying to do, and the likelihood that you can actually do it. <br /><br />If you don't know how to write a business plan, find someone
		who does.&nbsp; Many Small Business Development Centers specialize in helping here.&nbsp; Also universities and community colleges
		have specialists and courses that can teach you the skills needed to produce a plan that will tell both you, and your potential backers,
		what you need to know about the resources required, and the rewards that are available for those who provide them. </p>
		<p className="normalText">Although somewhat standard within the venturing community, the business plan format is different from the six-question template being
		used here. Figure C below is a type of "path diagram" that maps the applicable sub-questions of the NEW VENTURE TEMPLATE™
		to the standard sections of the business plan:</p>
		<div style={{display: "flex", justifyContent: "center", marginBottom: "1rem"}}>
			<img src={require('../../assets/resources-path-diagram.gif')} alt="Resources Path Diagram"></img>
		</div>
		<p className="normalText">It should be clear from Figure C, that the key section of the business plan is the marketing section.&nbsp; Notice
		how many of the key sub-questions in the first three template questions depend upon the marketing plan for answers. </p>
		<p className="normalText">Thus, if the resources are available to ensure that venture growth yields life v. death, then the answer to the sub-question: Are there
		sufficient resources?, can be yes. If not, then the answer to Question C: Is it persistent over time? is NO--which means DON'T GO ON if
		you are trying to start a new venture unless this can be fixed, and means DANGER if you are evaluating an ongoing business operation. </p>
		<p className="normalText">The first step away from danger is taken by writing a business plan.&nbsp; With the right business plan one can
		find the necessary backers and then "go on" with the venture.&nbsp; Without sufficient resources, we transmit to
		our new venture's birth, defects that are often almost impossible to overcome.&nbsp; Like it or not, most new
        ventures carry with them, permanent structures, attitudes, and cultures that arise consequent to resources that were available at the time of founding. </p>
		<p className="normalText">So don't proceed with a congenital flaw in a venture, when it can be avoided by a little work up front.&nbsp;
		Planning for sufficient resources to sustain the venture is a form of "venture genetic engineering."&nbsp; Given all
		the evidence that points to the importance of sufficient resources, venturers who "plug in" their ventures before
		the "resource ducks are in a row," are imprudent, to say the least.</p>
	</section>
}