import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const CEAG = (props) => {
    return <section>
	<h1 className="pageTitle">CEAG - In Over Their Head</h1>
    <p className="normalText">The CEAG is an entrepreneur by desire and resources operating in uncharted 
        waters.&nbsp; Generally this fairly rare situation (preliminarily under 
        1% in the business population) occurs where a venturer strays outside 
        his or her "target zone of extraordinary capability" in an expansion 
        or diversification effort.&nbsp; Where entrepreneurial instincts served 
        well in a familiar target zone, they are of little use in an area which 
        requires additional or radically different expertise.&nbsp; Accordingly, 
        the CEAG resorts to guesswork instead of discernment, which is handicapped 
        by compelling but contradictory knowledge.</p>
	<p className="normalText">As Will Rogers is reputed to have said, "It's 
        not what you don't know that hurts you, it's what you know that ain't 
        so!"&nbsp; CEAG's are subject to thinking errors of this type, exacerbated 
        by frustration, discouragement and genuine surprise that the old formula 
        doesn't work in the new territory, all of which are obstacles to completing 
        a re-write of the expert script required for navigating in these new waters.&nbsp; 
    </p>
    <MatrixDiagram selected="CEAG" />
	<p className="sectionHeading1">WHAT TO DO:</p>
	<p className="normalText">If you're already there -- chin up and keep swimming -- <b>tenacity</b> and persistence have great value in such situations.&nbsp; If you're not there -- don't jump in at all, at 
		least until you have developed the knowledge framework necessary for you to be able to discern when the type 
		of venture you are interested in has "strayed off course".</p>
    <p className="normalText">A note for those who are "swimming" already: call in an experienced strategic <b>expert</b> to help you to determine the boundary of your own target zone, and the appropriate strategic moves 
		to either optimize the situation (create the expert script for a success), or to cut your losses (abandon the 
		venture).&nbsp; If you are, for example, an expert in another field, consider getting back to your own 
		"knitting", where the coordinates for success are clear and all the guesswork is not required.</p>
    </section>
}
