import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const IEAD = (props) => {
    return <section>
	<h1 className="pageTitle">IEAD - Intuitive Expert</h1>
    <p className="normalText">
        IEAD's are expert venturers by intuition, desire and resources.&nbsp; 
        IEAD's properly belong within the venturing "target zone".&nbsp; 
        They are willing, able, and have a specialized understanding of venturing, 
        most often in a very specific domain, but there are relatively few IEAD's.&nbsp; 
        Preliminary indications are that approximately 1% of people fit this category.</p>
                            
    <p className="normalText">IEAD's should be wary of venturing outside 
        their area of "extraordinary expertise" though, since once they 
        are "beyond their limits", they become gamblers (IEAG's).&nbsp; 
        Another weak point arises where information is asymmetric.&nbsp; Without 
        consciously realizing it they can find themselves "fighting fires" 
        which could have been avoided through conscious application of an expert 
        script.</p>
    <MatrixDiagram selected="IEAD" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">Because an EAD possesses expertise which is intuitive, it is in some 
        sense unreliable.&nbsp; Hence, an IEAD can sometimes end up in a zone 
        outside the reach of their intuition, without really knowing it.&nbsp; 
        Accordingly, it may pay for an IEAD to obtain the evaluation of an experienced 
        operator in the domain of interest before initiating a new venture in 
        that industry or industry segment.</p>
                            
    <p className="normalText">Though the "hunches" of IEAD's may 
        prove to be right some of the time, they can just as often be accompanied 
        by surprise side-effects and unanticipated consequences which must be 
        dealt with before a venture can be a success.&nbsp; </p>
                            
    <p className="normalText">Obtaining&nbsp; a first, <b>second</b>, and 
        third <b>opinion</b> from experienced operators in the industry of interest 
        (if not an area of the IEAD's <i>own</i> expertise), should pay for itself 
        over the long run.</p>
    </section>
}
