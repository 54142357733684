import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const CEAD = (props) => {
    return <section>
        
	<h1 className="pageTitle">CEAD - Classic Expert Entrepreneur</h1>
	<p className="normalText">Generally, the CEAD lives up to his or her venturing potential.&nbsp; 
              These venturers make up the group from which many great success 
              stories come. They are venturing experts by training, awareness, 
              desire, resource availability, and discernment.</p>
                            
    <p className="normalText">Although random setbacks can trouble 
              even CEAD's, they generally have the mix of attributes which permits 
              them to surmount them.&nbsp; A CEAD is successful because he or 
              she "has the bases covered".&nbsp; As experts they have 
              a much more complete, detailed and extensive set of success patterns 
              (scripts) than do novices, or other "expert" venturers 
              (IEAD, IRAD, CRAD).</p>
                            
    <p className="normalText">Although many other venture types can 
              be successful, this type is more successful in both magnitude and 
              longevity.&nbsp; This does not mean that the CEAD is invincible; 
              only that the chance that they will falter is generally lower.</p>
    <MatrixDiagram selected="CEAD" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">First, <b>keep it up</b>: keep right on venturing.&nbsp; You create the jobs and opportunities 
		for the rest of the economy.&nbsp; Classic Expert Entrepreneurs are only found (preliminarily) in 4% of the 
		business population.&nbsp; This means that for every one of you there are twenty four who could benefit from 
		your expertise.&nbsp; Which leads to the second suggestion: <b>volunteer</b>.</p>
    <p className="normalText">Become active in your local or regional venture group, get involved in the Entrepreneurial 
		Center at a nearby college or university; or make yourself available to a Small Business Development Center.&nbsp; 
		Not that there is nothing left to learn for you; but there is much more for you to share.&nbsp; Don't be 
		stingy -- and now that you have read this, beware of overconfidence.</p>
    </section>
}
