import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const CRLG = (props) => {
    return <section>
	<h1 className="pageTitle">CRLG - Armchair Quarterback</h1>
    <p className="normalText">This venture type is an artifact -- found to be present in the case of most phenomena.&nbsp; Although the CRLG is 
		aware that the general entrepreneurial script exists, that's just about it.&nbsp; The CRLG can appear 
        "entrepreneurial", and is often adept at describing the expert script -- but he or she is NOT an 
		entrepreneur or venturing expert.</p>
	<p className="normalText">Found in about 1% of the business population, a CRLG is risk averse, lacks resources, and may 
		not appreciate of the elaborate schema or depth of discernment necessary for a clear 
        understanding of the specialized world of the new venture.</p>
    <p className="normalText">When a CRLG holds a position of power such as bank officer, attorney, CPA, trust 
		executor/executrix, etc., they may feel all too free to give advice, expecting it to be taken.&nbsp; Where 
        this is the case, tact and diplomacy, plus a little selected deafness might be in order. (Note: Please consult 
		your attorney before acting on this suggestion).</p>
    <MatrixDiagram selected="CRLG" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">If you are a CRLG and wish to become involved in or with a new venture, the main drawback to 
		overcome lies with the need for guesswork.&nbsp; To enhance or improve your ability to discern the 
        compliance of a venture with the patterns necessary for success, you should consider taking a course in 
		entrepreneurship, preferably one taught using the "<b>experiential method</b>".</p>
    <p className="normalText">If you have a position of influence with respect to a new or ongoing venture, pay particular 
		attention to the nuances and fine points of the strategy and tactics of building a new business.&nbsp; Identify 
		experts who may be able to help you to refine your "antenna", and invite their assistance.&nbsp; You 
		may soon find that you are becoming a CRAD (Institutional Expert), which should be a much more satisfying 
		venture vantage point.</p>
    </section>
}
