import React from 'react';
import { Link } from 'react-router-dom';

export const Innovation = () => {

return <section>
<h1 className="pageTitle">The Fundamental of Innovation</h1>
      <p className="normalText">
        Drucker (1985) defines entrepreneurship as an act of innovation that involves
        endowing existing resources with new wealth-producing capacity.&nbsp;
        The noted Austrian economist, Joseph Schumpeter, labels the innovative
        acts that endow old resources with new value "new combinations"
        (Schumpeter, 1936). These new combinations achieve wealth producing capacity
        when product and market are matched. Thus the two sub-questions: <br/>
        <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1. <Link className="contentLink" to="/template/viability/new-combination">Is it a new combination?</Link> <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. <Link className="contentLink" to="/template/viability/product-market-match">Is there a product-market match?</Link></p>
</section>
}