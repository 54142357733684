import React from 'react';

export function StrugglingProprietary(props){
  return <><p className="sectionHeading1">Struggling Venture</p>

  <p className="normalText">The struggling proprietary type venture experiences the paradox of being so closely
          held (i.e. having such unique core competence or proprietary secrets, etc.) that it fails
          to generate sufficient "business." </p>

  <p className="normalText">Hence, one can see that all the "can you keep it?" variables (scarcity,
          non-appropriability, and flexibility) are high, while all the "is it a
          business?" variables (innovation, value &amp; persistence) are lower--more in the
          medium range.&nbsp; Examples of the struggling proprietary type of venture include the
          inventor- or engineer-based business that has a protected technology, but is not utilizing
          the technology to produce a fully commercial product. </p>

  <p className="normalText">ADVICE: The key here is marketing (increasing PMM), with the logic going as follows:
          Increased PMM implies increased NBB, which implies increased volume, which in turn implies
          the potential to increase margins.&nbsp; This, then, further implies increases in
          persistence, represented by purchase frequency, product longevity, and access to
          resources.&nbsp; In short, this approach can turn the struggling proprietary venture into
          one with much higher potential, because it adds base business strength to already existing
          strategic strength. </p>
          </>

}