import React, {useState, useEffect} from 'react';
import BullsEye from '../svg/BullsEye.component';
import {SelectNVT} from './SelectNVT.component';
import {rootUrl} from '../utils/rootUrl';
import "./TemplateResultsWheel.css";
import {Link} from 'react-router-dom';
import {Loader} from '../Loader/Loader.component';
import {AuthRequired} from '../utils/AuthRequired.component';
import {HasPermission} from '../utils/HasPermission.component';
import prototypes from '../data';





export function TemplateResultsWheel(props) {
    const [nvtResults, setNVTResults] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(() => { const saved = localStorage.getItem('nvt.template_id');
    const initialValue = JSON.parse(saved);
    return initialValue || null;
    });
    const [selectedComparisonPrototype, setSelectedComparisonPrototype] = useState()
    const [bullsEyeConfig, setBullsEyeConfig] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState([]);

    useEffect(() => {
        if (loading) {
            getNVTResults()

            async function getNVTResults() {
                try {
                    const response = await fetch(`${rootUrl}/api/nvt`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME)}`
                        },
                    })
                    if (!response.ok) {throw new Error(response.error)}
                    const parsedResponse = await response.json()
                    setNVTResults(parsedResponse)
                    return setLoading(false);
                } catch (error) {
                    setError(error)
                }
            }
        }
    }, [loading])

    useEffect(() => {
        if (nvtResults && nvtResults.length > 0 && !selectedTemplateId) {
            const selected = nvtResults[nvtResults.length - 1].template_id
            return setSelectedTemplateId(selected)
        }
    }, [nvtResults, selectedTemplateId])

    useEffect(() => {
        if (selectedTemplateId) {
            const [nvt] = nvtResults.filter(nvt => nvt.template_id === selectedTemplateId)
            if (nvt) {
                const prototype = prototypes[nvt.prototype_id]?.buttonLabel;
                return setSelectedComparisonPrototype(prototype);
            }
        }
    }, [nvtResults, selectedTemplateId])

    useEffect(() => {
        if (nvtResults && nvtResults.length > 0 && selectedTemplateId) {
            const selectedNVT = nvtResults.filter(nvt => nvt.template_id === selectedTemplateId)[0];
            let bullsEyeConfig = [
                selectedNVT.q15,
                selectedNVT.q1,
                selectedNVT.q2,
                selectedNVT.q3,
                selectedNVT.q4,
                selectedNVT.q5,
                selectedNVT.q6,
                selectedNVT.q7,
                selectedNVT.q8,
                selectedNVT.q9,
                selectedNVT.q10,
                selectedNVT.q11,
                selectedNVT.q12,
                selectedNVT.q13,
                selectedNVT.q14,
            ];
            setBullsEyeConfig(bullsEyeConfig)
        }
    }, [selectedTemplateId, nvtResults])

    return <AuthRequired>
        <HasPermission requiredPermissions={["nvt"]}>
            {loading && <Loader>Loading your NVT results</Loader>}
            {!loading && nvtResults.length === 0 && <h2>You have not taken the NVT yet. <Link to="/authenticated/NVT">Click here</Link> to take the assessment.</h2>}
            {!loading && nvtResults && nvtResults.length > 0 && <>
                <h1>Analysis: Template Results Wheel</h1>
                {error && error.length > 0 && <span>{"An error occurred: " + JSON.stringify(error)}</span>}
                <p className="normalText">The following diagram maps your Template scores with the scores of a given Venture-Type. Your scores are represented by the little ‘+’ signs. The shaded area indicates the scores of the selected Venture-Type.</p>
                <p className="normalText">If your scores fall to the outside of the shaded ring, then your scores are lower, "off target", than those corresponding to the selected Venture-Type, while higher scores are drawn toward the center of the target. This view helps you to see at a glance how your venture compares ("is on target with") a given Venture-Type.</p>
                <SelectNVT nvtResults={nvtResults} setSelectedNVT={setSelectedTemplateId} selectedTemplateId={selectedTemplateId} />
                <div style={{display: "flex", justifyContent: "flex-start", marginTop: "2rem"}}>
                    <BullsEye userSubmission={bullsEyeConfig} prototype={selectedComparisonPrototype} />
                    <SelectComparisonPrototype 
                        selectedComparisonPrototype={selectedComparisonPrototype} setSelectedComparisonPrototype={setSelectedComparisonPrototype} />
                </div>
            </>}
        </HasPermission>
    </AuthRequired>
}

const SelectComparisonPrototype = (props) => {
    const prototypeLabels = Object.keys(prototypes).map(p => prototypes[p].buttonLabel)
    return <section className="change-prototype-buttons-container">
        {prototypeLabels.map(prototype => {
            return <button 
                key={prototype} 
                className={`${`change-prototype-button ${prototype === props.selectedComparisonPrototype ? 'active-prototype-button' : ''}`}`}
                onClick={() => props.setSelectedComparisonPrototype(prototype)}>{prototype}</button>
        })}
        <button className="change-prototype-button" onClick={() => props.setSelectedComparisonPrototype("None")}>Hide Comparison</button>
    </section>
}