import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';

export const ModelVenture = () => {
    return <section>
	<h1 className="pageTitle">The Model Venture Prototype</h1>
	<p className="normalText">When a venture is built so that each of the key elements is optimal, it is a Model Venture.&nbsp; This does not mean, however, that the venture is perfect. Instead, it means that the venture is optimally situated to fully take advantage of (1) venturer expertise, and (2) stakeholder support.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 27: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="mv" />
    <p className="sectionHeading2">Figure 28: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype="Model Venture" />
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">Model Ventures by definition are self-observing, self-evaluating, and self-reinforcing.&nbsp; Hence, these behaviors should be encouraged.
    </p>
    <p className="normalText">Also, entrepreneurs should utilize the foundation provided by a Model Venture to support refinements in venturer expertise, and in better managing relationships within their stakeholder environment.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Sultan Circuits -- <i>A Model Venture</i></p>
	<p className="normalText">In a thick, Hungarian accent, Nick Habsburg addressed a smartly dressed group of MBA students in a
        classroom at the University of California, Berkeley campus, in 1993.&nbsp;&nbsp; "Japan is staged to turn the US into a
		third-rate techno-colony", he declared.</p>
    <p className="normalText">His fears were well founded. His company, Sultan Circuits, had pioneered
        D-RAM chips – but a number of Japanese firms had managed to reverse-engineer
        his product to the point that D-RAM chips had become an everyday commodity.
        Sultan Circuits' Board of Directors demanded that the Company take decisive
        action before they lost more profits.&nbsp; Sultan Circuits was left with
        no other option but to abandon the market that they had pioneered in search
        of more favorable climes.&nbsp; The company felt that the computer industry
        was turning toward microprocessor circuits.&nbsp; As a result, Sultan
        made a high stakes gamble to pursue that emerging market, and it seemed
        to be paying off.&nbsp; </p>
	<p className="sectionHeading2">BACKGROUND</p>
	<p className="normalText">Twenty year-old Nick Habsburg arrived on American soil in 1956, after fleeing civil and political unrest in
                                Hungary.&nbsp; When he stepped off the ship he had $20 in his pocket, the clothes on his back, and a shaving
                                razor.&nbsp; Nick found work quickly, and managed to support himself while he earned an engineering degree.&nbsp;
                                After completing the degree, Nick worked on a number of small electrical engineering projects.&nbsp; In 1964, Nick teamed up with fellow classmates Gino Mardi and Stephen Loyce, to work on a concept that would
                                become revolutionary.&nbsp; Nick, Gino, and Stephen successfully created the first integrated circuit.&nbsp; Recognizing
                                this as a monumental accomplishment, the threesome set up Sultan Circuits to market the products.&nbsp; Sultan was quickly taken under the wing of IBM, as their main supplier of integrated circuits.&nbsp; </p>
	<p className="sectionHeading2">TODAY</p>
	<p className="normalText">Sultan Circuits developed a simple strategy: lead the market by force,
        speed, and technological development.The Japanese, the clone makers, Congress,
        and the rest of Silicon Valley listened when Nick spoke. When required,
        his firm successfully fought legal battles and suppressed firms that infringed
        upon Sultan's copyrights.&nbsp; </p>
    <p className="normalText">The Sultan Board shuddered at the thought of a proposed merger between IBM and Apple to create a new
                                crop of chips called RISC- for 'Reduced Instruction Set Computing' - to be used in desktop workstations.&nbsp;
                                In response to the threat, Sultan designers developed the Selenium line of chips, which integrated all of the
                                RISC features, and successfully halted the advance.&nbsp; Today, at least 70% of all new computers sold, contain
                                a Selenium microprocessor, and every computer on the market has at least one chip made by Sultan.&nbsp; </p>
    <p className="normalText">Microprocessor development was a lucrative business.&nbsp; Microprocessor speeds increased as the number of
                                transistors on the chip increased.&nbsp; To accomplish this, the transistors had to be packed closer and closer
                                together.&nbsp; This required intensive engineering research and design.&nbsp; Sultan was the market leader, introducing
                                a new line of microprocessors every three years.&nbsp; The blistering pace left clone makers further and further
                                behind with each Sultan advancement.&nbsp; By the time the clone makers reverse engineered the chip in an attempt to catch up with Sultan, a new line was ready to go to market. Each new chip was faster, more
                                reliable, and cheaper to produce than the last; factors which erected larger and larger barriers to would-be clone manufacturers.&nbsp;&nbsp; </p>
    <p className="normalText">Sultan's development and technological refinement of the microprocessor even outpaced IBM, the former
                                industry leader. The new Selenium chips put the power of a mainframe into a desktop system.&nbsp; To keep IBM and other computer manufacturers on their side, Sultan spearheaded a new combination in the
                                computer industry.&nbsp; A series of promotions developed name brand recognition for Sultan's line of
                                microprocessors.&nbsp; A 'Sultan inside' sticker adorned all desktop systems using the latest technology.&nbsp; The
                                name brand recognition made this a key selling feature; consumers wouldn't purchase anything else.&nbsp; Sultan's components became the industry standard, with few other competitors.</p>
    <p className="normalText">Sultan had it critics though.&nbsp; A number of competitors alleged that strategic alliances Sultan had made with
                                desktop manufacturers were restraining competition.&nbsp; The Federal Trade Commission investigated this and
                                numerous other complaints.&nbsp; Sultan pointed to its superior research and development divisions, and the fact
                                that their microprocessors were significantly better than those of the competition.&nbsp; The strategic alliances
                                benefited both Sultan and the manufacturers. They ensured manufacturers access to the limited supply of the
                                fastest microprocessors on the market, and gave Sultan the financing and futurity to continue developing the technology.&nbsp; Sultan argued that their 70% share of the market was natural; that it was due to their
                                technological leadership, and not to anti-competitive practices.&nbsp; The courts agreed.</p>
    <p className="normalText">In 1996, Stephen Loyce was killed in a skiing accident in Switzerland.&nbsp; The initial market reaction was rather
                                harsh, but quickly corrected itself upon realizing the precautions Sultan had made for such a calamity.&nbsp; The
                                ownership was able to recover quickly, and management was relatively unaffected.&nbsp; Sultan completed the year with profits up 20% over the previous year.</p>
    <p className="normalText">Sultan's share price doubled in nine months. Sultan outspent all other microprocessor manufacturers,
                                domestic and foreign alike.&nbsp; In 1993, they poured $2.5 billion (about 43% of 1992 revenues) into plant improvements, research and development.&nbsp; When talking about the highflying share price, one Sultan
                                employee summed up the atmosphere like this, 'The higher the stock price goes, the harder I seem to work.'&nbsp;
                                This commitment is paying off.&nbsp; Today, Nick Halsburg smiles when he comments on his statements five years
                                ago.&nbsp; In a classic understatement, he admits, 'Perhaps I was a bit pessimistic.'&nbsp; Sultan seems poised for a
                                bright future, as a leading Silicon Valley tech firm, garnering greater market share, and shinning in the eyes of its shareholders, employees, and Nick Halsburg himself.</p>
    </section>
}