import React from 'react';
import "./NVTemplateResultsGraph.css";
import { NVTemplateResultsBase } from './NVTemplateResultsBase.component';
import prototypes from '../data';

const legend = (props) => {
    const { coef, selectedPrototype } = props;

    const prototypeB = prototypes && prototypes[selectedPrototype] && prototypes[selectedPrototype].b;
    const prototypeK = prototypes && prototypes[selectedPrototype] && prototypes[selectedPrototype].k;

    return <svg x="0%" y="45%" viewBox="0, 0, 600, 50">
        <path className="cross-mark" d="M9.077,25.99h14v14c0,0.553,0.448,1,1,1s1-0.447,1-1v-14h14c0.552,0,1-0.447,1-1s-0.448-1-1-1h-14v-14c0-0.553-0.448-1-1-1  s-1,0.447-1,1v14h-14c-0.552,0-1,0.447-1,1S8.525,25.99,9.077,25.99z" transform="translate(0, -25)"></path>
        <text x="10%" y="0%" className="graph-legend">(B, K) = ({coef.b}, {coef.k})</text>
        <text x="10%" y="35%" className="graph-legend">Wt. Avg. of Ratings</text>
        <text x="55%" y="0%" className="graph-legend">(B, K) = ({prototypeB}, {prototypeK})</text>
        <text x="55%" y="35%" className="graph-legend">Highest Correlation</text>

        <circle cx="50%" cy="4%" r="2%" className="graph-selected-prototype"></circle>
    </svg>
}

export const NVTemplateResultsGraph = (props) => {
    const { b, k, selectedPrototype } = props

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "600px", height: "600px" }}>
                <svg
                    className="nvt-results"
                    viewBox="-50 -25 600 600"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <defs>
                        <marker
                            id="line-arrow"
                            viewBox="0 0 20 20"
                            refX="5"
                            refY="5"
                            markerWidth="6"
                            markerHeight="6"
                            orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z" className="line-marker" />
                        </marker>
                    </defs>
                    <NVTemplateResultsBase k={k ? k : ""} b={b ? b : ""} selectedPrototype={selectedPrototype} />
                    <text x={`8%`} y="85%" className="text-anchor-start axis-title">Is it a business (B)?</text>
                    <text x="-76%" y="2%" className="text-anchor-start axis-title" transform="rotate(270)">(K) Can you keep it?</text>

                    <line x1="35%" y1="84%" x2="75%" y2="84%" className="graph-line"></line>
                    <line x1="1%" y1="48%" x2="1%" y2="9%" className="graph-line" stroke="#000" markerEnd="url(#klineEnd)" strokeWidth=".5%" fill="none"></line>
                    {legend({ coef: { b, k }, selectedPrototype })}
                </svg>
            </div>
        </div>
    );
}