import React from 'react';

export const NonImitability = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Is it Non-imitable?</h1>
	<p className="normalText">When imitators can be kept out of the marketplace, supply can be restricted, 
        and the market imperfection "demand without supply" can be preserved.&nbsp; 
        Imitators attempt to add supply by entering the marketplace as new players 
        in the economic game.&nbsp; Imitators include all those firms that offer 
        products or services that do essentially the same thing in the same way.&nbsp; 
        Thus, the goal: to prevent firms that do the same thing, in a similar 
        way, from entering into the competitive arena. </p>
    <p className="normalText">But this prevention does not remove value from the market.&nbsp; This is in contrast to permanent market imperfections that are either imposed upon the market by monopolists, or which 
                                result from the employment of political advantage to permanently alter the rules of the game to the disadvantage of all but one player.&nbsp; This type of scarcity preservation is brought about by productivity 
                                enhancing mechanisms that isolate the venture from its competitors without permanently damaging competition. </p>
	<p className="normalText">The positive market imperfections, or "isolating mechanisms" (Rumelt, 1987) occur 
		because of something extraordinary that is: </p>
	<ol type="1">
		<li className="normalText">done within the venture to obtain and keep customer loyalty (usually customers do not give their loyalty without perceiving added 
                                value to themselves in the relationship);<br/>&nbsp;</li>
		<li className="normalText">done or permitted by society to encourage entrepreneurial discovery; or <br/>&nbsp;</li>
		<li className="normalText">a natural timing feature of a not quite perfect market working its way toward equilibrium.</li>
	</ol>
	<p className="normalText">Type 1 isolating mechanisms are customer driven.&nbsp; They include the effect of 
                                reputation for product or service quality, and communication of positive features of product or service (an advertising first mover advantage). 
                                </p>
	<p className="normalText">Type 2 isolating mechanisms are driven by incentive.&nbsp; Of course one of the most powerful incentives comes from ownership of property rights.&nbsp; Human beings tend to care a lot for the 
                                "things" that they "own."&nbsp; In a Type 2 isolating mechanism, society rewards certain types of entrepreneurial discovery, encouraging more research and discovery.&nbsp; These incentives 
                                consist of patents, copyrights and other types of intellectual property protection.&nbsp; Other incentives come from the market itself, through such isolating mechanisms as economies of scale--where the first 
                                efficiently-sized producer has a cost-based advantage, since additional producers (if efficiently-sized) will find prices to be depressed below full cost. </p>
	<p className="normalText">Type 3 isolating mechanisms are driven by timing 
                                differences that arise as a result of imperfect information. Type 3 differences include first mover advantages, buyer switching costs, informational asymmetry, buyer evaluation costs, advertising and channel 
                                crowding, producer learning, and response lags. </p>
	<p className="normalText">According to Rumelt: </p>
	<p className="normalText">The isolating mechanisms that protect entrepreneurial rents (profits from discovery, emphasis added) from imitative competition 
                                normally appear as first mover advantages.&nbsp; That is, they are asymmetries, usually derived from informational inequalities or the costs of creating and enforcing complex multiparty contingent contracts, that, 
                                other things equal, make it increasingly costly for followers to duplicate an innovator's position (1987: 146). </p>
	<p className="normalText">Barney (1991) cites other factors leading to non-imitability, such as history, causal 
                                ambiguity, and social complexity.&nbsp; To the extent that the venturer can recognize and utilize these isolating mechanisms, the profit-creating scarcity--that is lost when imitators copy the innovation--can be 
                                preserved. </p>
	<p className="normalText">The big idea here, is that there are tools that can be used to preserve the scarcity of your innovations.&nbsp; They center upon uniqueness, in customer relationships, in the incentives 
                                surrounding an innovation, and in timing. </p>
	<p className="normalText">Venturers who investigate each possibility for enhancing and preserving these unique elements of the innovation, usually find that one or more of these mechanism can 
                                help to "isolate" their innovation from imitators.&nbsp; When this happens, scarcity and profit can be the result. </p>
	<p className="normalText">Thus, where isolating mechanisms are in place to prevent imitation, then the 
                                answer to the sub-question: Is it non-imitable?, can be YES.&nbsp; If not, then the answer to Question D: Is it Scarce? is NO--which implies DON'T GO ON until this is fixed if this is a start-up venture. </p>
	<p className="normalText">If you are assessing an ongoing business, however, you can begin to effect scarcity by looking 
		at reputation--especially at the level of service.&nbsp; Next, check the level of quality.&nbsp; (There does 
		not appear to be a business that cannot isolate [differentiate] itself on the basis of product or service 
		quality.) </p>
	<p className="normalText">After checking these two "no brainers," then examine the remaining elements in 
		three types of isolating mechanisms to see which other mechanisms can be applied to preserve the scarcity of 
		the entrepreneurial discovery.&nbsp; When you work on venture scarcity, you add the type of value to the 
		economic system that begins as a market imperfection, and ends in the form of profit.&nbsp; Through customer 
		driven, incentive, or timing difference-based restriction of supply, you take the first step toward the 
		perpetuation of your business&nbsp; The next step is to prevent decreases in demand that occur when 
		substitute products enter the marketplace.</p>
    </section>
}