import React, {useEffect, useState} from 'react';
import { rootUrl } from './utils/rootUrl';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import {Landing} from './Landing/Landing.component';
import {Login} from './Login/Login.component';
import {EULA} from './Login/EULA.component';
import {Navigation} from './Navigation/Navigation.component';
import {TemplateOverview} from './Template/TemplateOverview.component';
import {NVTQuestionsOverview} from './Template/Questions/NVTQuestions.component';
import {NVTResultsOverview} from './Template/Results/NVTResultsOverview';
import {PrototypesOverview} from './Template/Prototypes/PrototypesOverview.component';
import {FundamentalsOverview} from './Template/Fundamentals/FundamentalsOverview.component';
import {ViabilityOverview} from './Template/Viability/ViabilityOverview.component';
import {TemplateReferences} from './Template/TemplateReferences.component';
import {BuyAJob} from './Template/Prototypes/BuyAJob.component';
import {TemplateQuestionnaire} from "./Template/TemplateQuestionnaire.component";
import {Charity} from './Template/Prototypes/Charity.component';
import {CompetenceBasedSuccess} from './Template/Prototypes/CompetenceBasedSuccess.component';
import {CompetenceBasedTroubled} from './Template/Prototypes/CompetenceBasedTroubled.component';
import {Fad} from './Template/Prototypes/Fad.component';
import {HighPotential} from './Template/Prototypes/HighPotential.component';
import {Hobby} from './Template/Prototypes/Hobby.component';
import {Hostage} from './Template/Prototypes/Hostage.component';
import {LifestyleSmallBusiness} from './Template/Prototypes/LifestyleSmallBusiness.component';
import {LowCompetence} from './Template/Prototypes/LowCompetence.component';
import {ModelVenture} from './Template/Prototypes/ModelVenture.component';
import {ResearchProject} from './Template/Prototypes/ResearchProject.component';
import {StrugglingProprietary} from './Template/Prototypes/StrugglingProprietary.component';
import {TechnologyBasedSuccess} from './Template/Prototypes/TechnologyBasedSuccess.component';
import {Flexibility} from './Template/Fundamentals/Flexibility.component';
import {Innovation} from './Template/Fundamentals/Innovation.component';
import {NonAppropriability} from './Template/Fundamentals/NonAppropriability.component';
import {Persistence} from './Template/Fundamentals/Persistence.component';
import {Scarcity} from './Template/Fundamentals/Scarcity.component';
import {Value} from './Template/Fundamentals/Value.component';
import {Ambiguity} from './Template/Viability/Ambiguity.component';
import {LongTermNeed} from './Template/Viability/LongTermNeed.component';
import {Competence} from './Template/Viability/Competence.component'
import {Margins} from './Template/Viability/Margins.component';
import {NetBuyerBenefit} from './Template/Viability/NetBuyerBenefit.component';
import {NewCombination} from './Template/Viability/NewCombination.component';
import {NoHoldup} from './Template/Viability/NoHoldup.component';
import {NoSlack} from './Template/Viability/NoSlack.component';
import {NonImitability} from './Template/Viability/NonImitability.component';
import {NonSubstitutible} from './Template/Viability/NonSubstitutible.component';
import {ProductMarketMatch} from './Template/Viability/ProductMarketMatch.component';
import {Repetitive} from './Template/Viability/Repetitive.component';
import {Resources} from './Template/Viability/Resources.component';
import {Uncertainty} from './Template/Viability/Uncertainty.component';
import {Volume} from './Template/Viability/Volume.component';
import {ProfileOverview} from './Profile/ProfileOverview.component';
import {EntrepreneurTypesOverview} from './Profile/Types/EntrepreneurTypesOverview.component';
import {EntrepreneurRolesOverview} from './Profile/Roles/EntrepreneurRolesOverview.component';
import {ProfileReferences} from './Profile/ProfileReferences.component';
import {CEAD} from './Profile/Types/CEAD.component';
import {CEAG} from './Profile/Types/CEAG.component';
import {CELD} from './Profile/Types/CELD.component';
import {CELG} from './Profile/Types/CELG.component';
import {CRAD} from './Profile/Types/CRAD.component';
import {CRAG} from './Profile/Types/CRAG.component';
import {CRLD} from './Profile/Types/CRLD.component';
import {CRLG} from './Profile/Types/CRLG.component';
import {IEAD} from './Profile/Types/IEAD.component';
import {IEAG} from './Profile/Types/IEAG.component';
import {IELD} from './Profile/Types/IELD.component';
import {IELG} from './Profile/Types/IELG.component';
import {IRAD} from './Profile/Types/IRAD.component';
import {IRAG} from './Profile/Types/IRAG.component';
import {IRLD} from './Profile/Types/IRLD.component';
import {IRLG} from './Profile/Types/IRLG.component';
import {Advisor} from './Profile/Roles/Advisor.component';
import {Incubator} from './Profile/Roles/Incubator.component';
import {Initiator} from './Profile/Roles/Initiator.component';
import {Investor} from './Profile/Roles/Investor.component';
import {Mentor} from './Profile/Roles/Mentor.component';
import {NonVenturer} from './Profile/Roles/NonVenturer.component';
import {Promoter} from './Profile/Roles/Promoter.component';
import {Venturer} from './Profile/Roles/Venturer.component';
import ScrollToContentTop from './Navigation/ScrollToContentTop';
import {ProfileQuestionnaire} from "./Profile/ProfileQuestionnaire.component";
import {Authenticated} from "./Login/Authenticated.component";
import {TemplateResultsOverview} from './Template/TemplateResultsOverview.component';
import {TemplateResultsWheel} from './Template/TemplateResultsWheel.component';
import {ProfileReport} from './Profile/ProfileReport.component';
import {ListClasses} from './Admin/ViewClasses.component';
import {CreateUsers} from './Admin/CreateUsers.component';
import {ManageAdmins} from './Admin/ManageAdmins.component';
import {AuthenticationContext} from './context';
import { format } from 'date-fns';


function App() {
  const [jwtVerified, setJwtVerified] = useState(false);
  const [jwt, setJwt] = useState('');
  const [user, setUser] = useState({});
  const [shouldVerifyJwt, setShouldVerifyJwt] = useState(true);
  const [isVerifyingJwt, setIsVerifyingJwt] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (shouldVerifyJwt && !isVerifyingJwt) {
      const jwt = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME)
      if (jwt) verify();
      async function verify() {
        try {
          setIsVerifyingJwt(true);

          const response = await fetch(`${rootUrl}/api/verify`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${jwt}`
            },
            body: JSON.stringify({
              token: jwt
            })
          })

          if (!response.ok) {
            if (response.statusText === "Forbidden") {
              console.error("User needs to login.")
              setJwtVerified(false);
            }
          }
          const parsedResponse = await response.json();
          if (parsedResponse.user) {
            setUser(parsedResponse.user.user);
            setJwtVerified(true);
            setLoggedIn(true);
          }
          setShouldVerifyJwt(false);
          return setIsVerifyingJwt(false);

        } catch (err) {
          console.error("Error validating JWT", err);
          setShouldVerifyJwt(false);
          setIsVerifyingJwt(false);
          setJwtVerified(false);
        }
      }
    }
  }, [shouldVerifyJwt, isVerifyingJwt]);

  const logout = () => {
    localStorage.removeItem(
      process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
    );
    setLoggedIn(false);
    setJwt('');
    setUser({});
    setJwtVerified(false);
    setShouldVerifyJwt(true);
    setIsVerifyingJwt(false);
  }

  const authenticationState = {
    jwt,
    isVerifyingJwt,
    setIsVerifyingJwt,
    jwtVerified,
    setJwtVerified,
    user,
    setUser,
    loggedIn,
    setLoggedIn,
    setShouldVerifyJwt,
    logout,
  }

  return (
    <div className="App">
      <header id="App-header" className="App-header">
        <div>New Venture Technologies</div>
      </header>
      <section className="content-section">
        <AuthenticationContext.Provider value={authenticationState}>
          {!window.location.pathname.includes('eula') && <Navigation user={user} loggedIn={loggedIn} />}
          <section className="content-container">
            <ScrollToContentTop />
            <Switch>
              <Route exact path="/" render={Landing} />
              <Route path="/login" exact component={Login} />
              <Route path="/eula" exact component={EULA} />
              <Route path="/authenticated/NVT" exact><TemplateQuestionnaire /></Route>
              <Route path="/authenticated/NVT-results" exact><TemplateResultsOverview /></Route>
              <Route path="/authenticated/NVT-results-wheel" exact component={TemplateResultsWheel} />
              <Route path="/authenticated/NVP" exact component={ProfileQuestionnaire} />
              <Route path="/authenticated/NVP-report" exact component={ProfileReport} />
              <Route path="/authenticated" exact><Authenticated /></Route>

              <Route path="/authenticated/admin/classes" exact component={ListClasses} />
              <Route path="/authenticated/admin/create-users" exact component={CreateUsers} />
              <Route path="/authenticated/admin/manage-admins" exact component={ManageAdmins} />

              <Route path="/template/questions" render={NVTQuestionsOverview} />
              <Route path="/template/results" render={NVTResultsOverview} />
              <Route path="/template/prototypes" exact render={PrototypesOverview} />
              <Route path="/template/prototypes/buy-a-job-small-business" exact render={BuyAJob} />
              <Route path="/template/prototypes/charity" exact render={Charity} />
              <Route path="/template/prototypes/competence-based-success" exact render={CompetenceBasedSuccess} />
              <Route path="/template/prototypes/competence-based-troubled" exact render={CompetenceBasedTroubled} />
              <Route path="/template/prototypes/fad" exact render={Fad} />
              <Route path="/template/prototypes/high-potential" exact render={HighPotential} />
              <Route path="/template/prototypes/hobby" exact render={Hobby} />
              <Route path="/template/prototypes/hostage" exact render={Hostage} />
              <Route path="/template/prototypes/lifestyle-small-business" exact render={LifestyleSmallBusiness} />
              <Route path="/template/prototypes/low-competence" exact render={LowCompetence} />
              <Route path="/template/prototypes/model-venture" exact render={ModelVenture} />
              <Route path="/template/prototypes/research-project" exact render={ResearchProject} />
              <Route path="/template/prototypes/struggling-proprietary" exact render={StrugglingProprietary} />
              <Route path="/template/prototypes/technology-based-success" exact render={TechnologyBasedSuccess} />
              <Route path="/template/fundamentals" exact render={FundamentalsOverview} />
              <Route path="/template/fundamentals/flexibility" exact render={Flexibility} />
              <Route path="/template/fundamentals/innovation" exact render={Innovation} />
              <Route path="/template/fundamentals/non-appropriability" exact render={NonAppropriability} />
              <Route path="/template/fundamentals/persistence" exact render={Persistence} />
              <Route path="/template/fundamentals/scarcity" exact render={Scarcity} />
              <Route path="/template/fundamentals/value" exact render={Value} />
              <Route path="/template/viability" exact render={ViabilityOverview} />
              <Route path="/template/viability/ambiguity" exact render={Ambiguity} />
              <Route path="/template/viability/core-competence" exact render={Competence} />
              <Route path="/template/viability/long-term-need" exact render={LongTermNeed} />
              <Route path="/template/viability/margin" exact render={Margins} />
              <Route path="/template/viability/net-buyer-benefit" exact render={NetBuyerBenefit} />
              <Route path="/template/viability/new-combination" exact render={NewCombination} />
              <Route path="/template/viability/no-holdup" exact render={NoHoldup} />
              <Route path="/template/viability/slack" exact render={NoSlack} /> {/* Found out when fixing links that I had made this one /slack instead of /no-slack... kept in case it's in use but TODO: remove this */}
              <Route path="/template/viability/no-slack" exact render={NoSlack} />
              <Route path="/template/viability/non-imitable" exact render={NonImitability} />
              <Route path="/template/viability/non-substitutible" exact render={NonSubstitutible} />
              <Route path="/template/viability/product-market-match" exact render={ProductMarketMatch} />
              <Route path="/template/viability/repetitive" exact render={Repetitive} />
              <Route path="/template/viability/resources" exact render={Resources} />
              <Route path="/template/viability/uncertainty" exact render={Uncertainty} />
              <Route path="/template/viability/volume" exact render={Volume} />
              <Route path="/template/references" exact render={TemplateReferences} />
              <Route path="/template" exact render={TemplateOverview} />

              <Route path="/profile/entrepreneur-types" exact render={EntrepreneurTypesOverview} />
              <Route path="/profile/entrepreneur-types/CEAD" exact render={CEAD} />
              <Route path="/profile/entrepreneur-types/CEAG" exact render={CEAG} />
              <Route path="/profile/entrepreneur-types/CELD" exact render={CELD} />
              <Route path="/profile/entrepreneur-types/CELG" exact render={CELG} />
              <Route path="/profile/entrepreneur-types/CRAD" exact render={CRAD} />
              <Route path="/profile/entrepreneur-types/CRAG" exact render={CRAG} />
              <Route path="/profile/entrepreneur-types/CRLD" exact render={CRLD} />
              <Route path="/profile/entrepreneur-types/CRLG" exact render={CRLG} />
              <Route path="/profile/entrepreneur-types/IEAD" exact render={IEAD} />
              <Route path="/profile/entrepreneur-types/IEAG" exact render={IEAG} />
              <Route path="/profile/entrepreneur-types/IELD" exact render={IELD} />
              <Route path="/profile/entrepreneur-types/IELG" exact render={IELG} />
              <Route path="/profile/entrepreneur-types/IRAD" exact render={IRAD} />
              <Route path="/profile/entrepreneur-types/IRAG" exact render={IRAG} />
              <Route path="/profile/entrepreneur-types/IRLD" exact render={IRLD} />
              <Route path="/profile/entrepreneur-types/IRLG" exact render={IRLG} />
              <Route path="/profile" exact render={ProfileOverview} />

              <Route path="/profile/entrepreneur-roles/advisor" exact render={Advisor} />
              <Route path="/profile/entrepreneur-roles/incubator" exact render={Incubator} />
              <Route path="/profile/entrepreneur-roles/initiator" exact render={Initiator} />
              <Route path="/profile/entrepreneur-roles/investor" exact render={Investor} />
              <Route path="/profile/entrepreneur-roles/mentor" exact render={Mentor} />
              <Route path="/profile/entrepreneur-roles/non-venturer" exact render={NonVenturer} />
              <Route path="/profile/entrepreneur-roles/promoter" exact render={Promoter} />
              <Route path="/profile/entrepreneur-roles/venturer" exact render={Venturer} />
              <Route path="/profile/entrepreneur-roles" exact render={EntrepreneurRolesOverview}></Route>

              <Route path="/profile/references" exact render={ProfileReferences} />
            </Switch>
            <footer className="copyright">&copy; 2003, { format(new Date(), 'y') }  Ronald K. Mitchell, CPA, Ph.D.<br/>
            (For Educational Purposes Only)
            </footer>
          </section>
        </AuthenticationContext.Provider>
      </section>
    </div>
  );
}

export default App;
