export const profiles = [
    {
        number: "1",
        type: "IELG",
        percent: "6",
        title: "Over-Eager",
        description: "Needs advisor and expertise",
    },
    {
        number: "2",
        type: "IEAG",
        percent: "3",
        title: "Gambler",
        description: "Needs expert help to increase odds",
    },
    {
        number: "3",
        type: "IRAG",
        percent: "5-6",
        title: "Cautious SB 1st Venturer",
        description: "Needs expert, VC partner or internship",
    },
    {
        number: "4",
        type: "IRLG",
        percent: "25-28",
        title: "New Venture Novice",
        description: "Needs systematic knowledge structure enhancements",
    },
    {
        number: "5",
        type: "IELD",
        percent: "8",
        title: "Under-equipped",
        description: "Needs VC partner-enhanced new venture script consciousness",
    },
    {
        number: "6",
        type: "IEAD",
        percent: "1",
        title: "Intuitive Expert",
        description: "Needs second opinion",
    },
    {
        number: "7",
        type: "IRAD",
        percent: "3",
        title: "Small Business Expert",
        description: "Needs to stick to EEZ",
    },
    {
        number: "8",
        type: "IRLD",
        percent: "25",
        title: "Dreamer",
        description: "Needs experiential learning",
    },
    {
        number: "9",
        type: "CELD",
        percent: "4",
        title: "Resource Poor",
        description: "Needs expert advisory board",
    },
    {
        number: "10",
        type: "CEAD",
        percent: "4",
        title: "Classic Expert Entrepreneur",
        description: "Needs to keep it up! Volunteer!",
    },
    {
        number: "11",
        type: "CRAD",
        percent: "4",
        title: "Institutional Expert",
        description: "Needs to orchestrate resource flow",
    },
    {
        number: "12",
        type: "CRLD",
        percent: "7",
        title: "Idea Person",
        description: "Needs accountability and milestones",
    },
    {
        number: "13",
        type: "CELG",
        percent: "<1",
        title: "Apprentice",
        description: "Needs an internship or SCORE advisor",
    },
    {
        number: "14",
        type: "CEAG",
        percent: "<1",
        title: "In Over Their Head",
        description: "Needs tenacity, expert advice and more domain specific knowledge",
    },
    {
        number: "15",
        type: "CRAG",
        percent: "<1",
        title: "Cautious Backer",
        description: "Needs better information",
    },
    {
        number: "16",
        type: "CRLG",
        percent: "1",
        title: "Armchair Quarterback",
        description: "Needs experiential learning",
    },
]