import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const IRLG = (props) => {
    return <section>
	<h1 className="pageTitle">IRLG - New Venture Novice</h1>
    <p className="normalText">IRLG's are a "fish out of water" in the new venture area.&nbsp; This is because they have not had enough experience with new ventures to gain the knowledge and practice needed to run a new venture 
                                successfully.&nbsp; IRLG's contribute best when applying their talents to the improvement or enhancement of an existing business.&nbsp; Without expertise enhancements, they should not seriously consider venturing, 
                                or investing in new ventures. </p>
    <p className="normalText">If they have resources (such as an inheritance, retirement savings, proceeds from sale of a home), their lack of knowledge and practice may prove to be a danger to themselves and perhaps to 
                                others who depend upon them for security or support, if they commit these resources to a new venture.&nbsp; Because they have little knowledge or understanding of "entrepreneurial scripts", they can be 
                                prey for the opportunistic being prone to "over-trusting" the potential of an investment in a new venture.</p>
    <p className="normalText">Although reluctant and conservative, IRLG's may be hurt both financially and in relationships, when the unscrupulous play upon social connections and/or feelings which IRLG's value, to 
                                convince them to enter a realm which is perilous to their economic health.&nbsp; If new venture novices wish to undertake a new business, specific preparations are required to improve readiness to venture, to avoid 
                                wasting time and resources which could otherwise be preserved.</p>
    <MatrixDiagram selected="IRLG" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">Preliminary indications show that 25 to 28% of people fit into the new venture novice category, perhaps even more 
		as new information is gathered.&nbsp; If you are an IRLG, and are interested in venturing, 
        the first step to take is to enhance your consciousness of the new venture expert "script", and 
		begin to acquire the knowledge structure which can help you to discern when a venture is "in" 
		or "out" of compliance with that pattern of success.&nbsp; This enhancement may be started using 
		processes which act as a "catalyst" to "jump-start" your own venturing knowledge framework.
		&nbsp; As consciousness and discernment increase, you will then begin to see yourself as more "able" 
		to venture.&nbsp; This, in turn, can create a more willing venturing frame of mind.</p>
    <p className="normalText">If you are an IRLG who does not wish to venture, then look for ways to apply your talents toward 
		building or enhancing a more established organization.&nbsp; Seek relatively more stable avenues for 
		investment.&nbsp; Treasury bills, mutual funds, company sponsored pension plans with professional advisors, 
        and insurance purchases seem to make more sense for this profile type, although even for these investments 
		appropriate caution should be exercised by an IRLG.&nbsp; A trusted advisor is helpful here, as 
        long as the trust comes from his or her <b>track record</b>, rather than from a relationship or unsupported 
		word-of-mouth references.</p>
    </section>
}
