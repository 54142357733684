import React from 'react'
import { Link } from 'react-router-dom';

export const NVTQuestionsOverview = (props) => {
  return <>
  <h1 className="pageTitle">The New Venture Template™ Questions</h1>
<p className="normalText">This section summarizes the questions asked in the New Venture Template™, describes the key objective of the question, and outlines the criteria for evaluation. </p>
<h2>"Is It a Business?"</h2>
<p className="normalText">The first eight questions drive market strategy and ultimately answer the question, "Is it a Business?" </p>
<p className="sectionHeading2">A. Innovation</p>
<p class="sectionHeading3">Question #1: Is it a New Combination?</p>
<p className="normalText">This question hinges on the degree to which new entrepreneurial discovery has taken place in order to take advantage of excess supply or excess demand. Entrepreneurial discovery occurs when an imperfection in the
 market can be identified and exploited.&nbsp; There are four ways in which a new combination can be discovered. These discoveries come in at least five categories or types. In new venture technology, the ultimate
 measure of the degree or strength of a new combination is as follows: </p>
<table align="center" id="Table1" border="1px solid black" cellspacing="3" cellpadding="1" width="100%">
   <tbody><tr>
<td width="14%" class="sectionHeading3">Low</td>
<td width="85%" className="normalText">If the discovery is new for us, but not for other companies</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">If the discovery provides a definite improvement over existing
  supply for present demand, or demand for present supply</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">If the discovery is a real breakthrough</td>
  </tr>
</tbody></table>

<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/new-combination">New Combination</Link>)</p>
<p class="sectionHeading3">Question #2: Is there a Product-Market Match?</p>
<p className="normalText">In the world of venturing (as opposed to the world of invention) a new combination does not
in itself determine that a product is innovative.&nbsp; For true innovation to occur, someone has to be
willing to buy the product created in the new combination.&nbsp; Therefore, this question seeks to identify
the degree to which customers, or potential customers, will commit to purchase the product.&nbsp; The question
of product-market match is a key in the world of venturing and the allocation of investment funds.&nbsp; The
higher the capital requirement for market entry, the more scrutiny this question must be given. In new
venture technology, the ultimate measure of the degree or strength of a product-market match is as follows: </p>
<table align="center" id="Table2" border="1" cellspacing="3" cellpadding="1" width="100%">
<tbody><tr>
<td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">If there are no purchase orders</td>
  </tr>
  <tr>
      <td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">Offers added features to the market (e.g. convenience) such that
  some orders or sales exist</td>
  </tr>
  <tr>
      <td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">Matches a market want or need so well that sales backlogs or large
  quantity purchase orders exist</td>
  </tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/product-market-match">Product-Market  Match</Link>)</p>
<p className="normalText"></p>
<p className="sectionHeading2">B. Value</p>
<p class="sectionHeading3">Question #3: Is there a Net Buyer Benefit?</p>
<p className="normalText">This question of net buyer benefit centers on the drivers of customer demand for the product, and the
                          relative relationship of perceived price and perceived product differentiation (i.e. is the product "worth the
                          money" or "a rip-off"?)&nbsp; Generally, is the value-added of the product to the customer such that they would
                          rather have the product, than money in their pocket?&nbsp; In new venture technology, the strength of net buyer benefit is measured as follows: </p>
<table align="center" id="Table3" border="1" cellspacing="3" cellpadding="1" width="100%">
<tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">If there is price discount pressure</td>
  </tr>
  <tr>
      <td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">If there is price stability</td>
  </tr>
  <tr>
      <td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">If there are "stock-outs" and price premiums</td>
  </tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link>)</p>
<p className="normalText"></p>
<p class="sectionHeading3">Question #4: Are there Margins?</p>
<p className="normalText">As net buyer benefit defines value to the customer, margins define value to the venture.&nbsp; For the purpose of
                          new venture technology, the question of margins focuses on what level of margin-per-unit can be expected on a fully-absorbed cost basis.&nbsp; The key comparisons should be based on realistic industry performance
                          and expectations. In new venture technology, the ultimate measure of the degree or strength of margins is as follows: </p>
<table align="center" id="Table4" border="1" cellspacing="3" cellpadding="1" width="100%">
<tbody><tr>
<td width="15%" class="sectionHeading3">Low</td>
      <td width="84%" className="normalText">If the expected margins for the venture are far below (typically less
  than 15%) documented industry averages and/or expectations</td>
  </tr>
  <tr>
      <td width="15%" class="sectionHeading3">Medium<p></p></td>
      <td width="84%" className="normalText">If the expected margins for the venture are in a similar range
  (typically between 16% and 30%) to documented industry averages and/or expectations</td>
  </tr>
  <tr>
      <td width="15%" class="sectionHeading3">High<p></p></td>
      <td width="84%" className="normalText">If the industry margins for the venture far exceed (typically
  over 30%) the documented industry averages and/or expectations</td>
  </tr>
</tbody></table>

<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/margin">Margins</Link>)</p>
<p className="normalText"></p>
<p class="sectionHeading3">Question #5: Is Volume sufficient?</p>
<p className="normalText">Just as product-market match is to innovation, volume is a critical test in the discussion of value.&nbsp; This
                          question looks at the degree to which anticipated volume of the new venture achieves its expectations and
                          goals.&nbsp; A comparison of venture objectives to absolute margin is often useful in this analysis. In new venture technology, the ultimate measure of the degree or strength of sufficient volume is as follows: </p>
<table align="center" id="Table5" border="1" cellspacing="3" cellpadding="1" width="100%">
<tbody><tr>
<td width="15%" class="sectionHeading3">Low</td>
      <td width="84%" className="normalText">If the expected volume is not sufficient to achieve venture objectives</td>
  </tr>
  <tr>
      <td width="15%" class="sectionHeading3">Medium</td>
      <td width="84%" className="normalText">If the expected volume should be sufficient to achieve venture objectives</td>
  </tr>
  <tr>
<td width="15%" class="sectionHeading3">High</td>
      <td width="84%" className="normalText">If the expected volume far exceeds venture objectives</td>
  </tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/volume">Volume</Link>)</p>
<p className="normalText"></p>
<p className="sectionHeading2">C. Persistence Over Time</p>
<p class="sectionHeading3">Question #6: Is it Repetitive?</p>
<p className="normalText">This question hinges on the degree to which the product will be needed regularly (or on an ongoing basis) or
                          that other strategic practices that drive repetitive product sales are prevalent and acceptable in the industry
                          and are part of the express strategy of the venture for this product.&nbsp; The evaluation of a product's placement
                          on the need/alternative use model is often useful in determining the repetitiveness of an entrepreneurial
                          discovery.&nbsp; In new venture technology, the ultimate measure of the degree or strength of repetitiveness is as follows: </p>
<table align="center" id="Table6" border="1" cellspacing="3" cellpadding="1" width="100%">
<tbody><tr>
<td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">A "once-only" purchase, or extremely sporadic and unpredictable</td>
  </tr>
  <tr>
      <td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">Purchases are occasional</td>
  </tr>
  <tr>
      <td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">Purchases are frequent and reasonably predictable</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/repetitive">Repetitive</Link>)</p>
<p class="sectionHeading3">Question #7: Is there a Long-Term Need?</p>
<p className="normalText">The question of long-term need evaluates the extent to which the benefits of repetitiveness can be expected
                          over time.&nbsp; This question hinges largely on an understanding of where the product (as a new combination)
                          falls in the product lifecycle, and the relative speed of the lifecycle.&nbsp; This is often understood only through
                          study of the lifecycle of similar innovations.&nbsp; Additionally, the ability to apply new venturing strategies to
                          establish a clear two-way relationship with the customers is critical to long-term need.&nbsp; In new venture technology, the ultimate measure of the degree or strength of long-term need is as follows: </p>
<table align="center" id="Table7" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">If the new discovery (product/service) is a fad with limited future</td>
  </tr>
  <tr>
      <td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">If the product/service need extends only over the short term</td>
  </tr>
  <tr>
      <td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">If the there is a foreseeable long-term need for the product/service</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/long-term-need">Long-term Need</Link>)</p>
<p className="normalText"></p>
<p class="sectionHeading3">Question #8: Are Resources Sufficient?</p>
<p className="normalText">This question really looks at resources in financial, management, knowledge, and time sufficient to get the
                          product to market.&nbsp; This view goes beyond short-term "start-up", to an evaluation of resource availability in
                          the face of growth and other indicators of success unique to new venture formation and growth.&nbsp; The "Rule
                          of 4" (it takes four times as long and costs four times as much as planned) plays into the evaluation of
                          resources.&nbsp; In new venture technology, the general measure of the degree or strength of resource sufficiency is as follows: </p>
<table align="center" id="Table8" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">If resources are effectively non-existent or limited</td>
  </tr>
<tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">If resources are few, or at risk if growth exceeds plans</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
<td width="85%" className="normalText">If resources are plentiful and anticipated to be readily available in the future</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/resources">Resources</Link>)</p>
<p className="normalText"></p>
<h2>"Can You Keep It?"</h2>
<p className="normalText">The next six questions drive competitive strategy and ultimately answer the question, "Can You Keep It?" </p>
<p className="sectionHeading2">D. Preserving Economic Scarcity</p>
<p class="sectionHeading3">Question #9: Is it Non-Imitable?</p>
<p className="normalText">
                          Once a venture has achieved a level of innovation, the question arises as to whether or not the innovation can be maintained.&nbsp; This question hinges on the degree to which new entrepreneurial discovery can be
                          imitated by competitors.&nbsp; Imitators (as opposed to substitutes) would do essentially the same thing as the
                          venture, and in the same way. Scarcity can be preserved by incorporating one or more of various types of isolating mechanisms into the venture, a key strategic skill employed by successful entrepreneurs.&nbsp;
                          Maintaining non-imitability focuses on preventing new entrants from introducing additional supply to fill
                          existing&nbsp; demand. In new venture technology, the ultimate measure of non-imitability of a new combination is as follows: </p>
<table align="center" id="Table9" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">Easily imitated, no isolating mechanisms in place</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">Partially protected by isolating mechanisms (this is NOT a numerical
  count of the mechanisms, but rather is an assessment of the STRENGTH of whatever mechanisms are present--
  of course, the  more the better)</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">Isolating mechanisms are sufficiently strong so as to permit little or
  no imitation</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/non-imitable">Non-imitable</Link>)</p>
<p className="normalText"></p>
<p class="sectionHeading3">Question #10: Is it Non-Substitutable?</p>
<p className="normalText">This question explores the degree to which substitutes exist (or can be created by competitors) for a new
                          entrepreneurial discovery.&nbsp; Substitutes reduce demand for a product by doing something in a clearly distinct
                          and different way.&nbsp; The remedies to block substitutes are not the same as those that act as barriers to entry
                          to imitators. In new venture technology, the ultimate measure of non-substitutability of a new combination is as follows: </p>
<table align="center" id="Table10" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">There are substitutes that directly reduce product demand</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">There are substitutes that indirectly reduce product demand</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">There are no substitutes</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/non-substitutible">Non-Substitutable</Link>)</p>
<p className="normalText"></p>
<p className="sectionHeading2">E. Failure to Prevent the Appropriation of Created Value</p>
<p class="sectionHeading3">Question #11: Is there No Slack?</p>
<p className="normalText">The second way that value is appropriated is through slack.&nbsp; Slack is really inefficiency and waste in the
                          product delivery process from the beginning to the end of the vertical supplier-customer chain.&nbsp; More generally, slack occurs whenever economic actors shrink the size of a venture's "pie" without ever
                          discussing it with the venture.&nbsp; The key to reducing slack is appropriate structuring of incentives, a key skill
                          of successful entrepreneurs.&nbsp; In new venture technology, the ultimate measure of the degree or strength of slack is as follows: </p>
<table id="Table11" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">There is a lot of waste and inefficiency</td>
</tr>
  <tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">There is some waste and inefficiency</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">There is little or no waste and inefficiency</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/no-slack">No Slack</Link>)</p>
<p className="normalText"></p>
<p class="sectionHeading3">Question #12: Is There No Holdup?</p>
<p className="normalText">Appropriation of value occurs in two different instances.&nbsp; The first is when economic players use one of the
                          many types of available power to force a venture to give them part of its financial gains.&nbsp; This is called
                          holdup and is best viewed as thieves or bandits taking advantage of the fact that the venture has been built
                          with few or no economic bargaining options, called small numbers bargaining.&nbsp; In new venture technology, the ultimate measure of the degree or strength of the potential for holdup is as follows: </p>
<table align="center" id="Table12" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">There is a lot of small numbers bargaining power in suppliers or buyers</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">There is some small numbers bargaining power in suppliers and buyers</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">Suppliers or buyers have little or no economic power over the venture
  through small numbers bargaining</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/no-holdup">No Holdup</Link>)</p>
<p className="normalText"></p>
<p className="sectionHeading2">F. Failure to Maintain Flexibility</p>
<p class="sectionHeading3">Question #13: Is Uncertainty minimized?</p>
<p className="normalText">This question hinges on the preparation of the organization for things that we know will happen in the future
                          to affect the venture; but we don't know when, or the magnitude of the event(s).&nbsp; Minimizing uncertainty in a
                          venture revolves around forward planning and risk management processes.&nbsp; In new venture technology, uncertainty is evaluated as follows: </p>
<table id="Table13" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">There is no insurance on the key people or the business, no tax
  planning, current tax savings accounts, forward planning etc.</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">Some level of indirect risk management is present that will affect
  the venture</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">Risks are low because of planning, insurance, statistical control
  processes etc.</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link>)</p>
<p className="normalText"></p>
<p class="sectionHeading3">Question #14: Is Ambiguity reduced?</p>
<p className="normalText">Ambiguity results when future events are unknown, meaning that the venture knows neither the nature, timing, nor magnitude of the event.&nbsp; In new ventures, the one certainty is that there will be a great deal of
                          ambiguity.&nbsp; Because the market weeds out unfit ventures, understanding inertia, creating decision structures,
                          and organizing to manage ambiguity are critical. In new venture technology, ambiguity is evaluated as follows: </p>
<table align="center" id="Table14" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">There is an absence of long-term planning and adaptation processes
  conducted in a heterogeneous group setting</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">Some planning and adaptability-preparedness is undertaken</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">A rich "mastermind alliance" (Napoleon Hill, Think and
  Grow Rich) is in operation directly relating to the venture</td>
</tr>
</tbody></table>
<p className="normalText">(For more detail and examples, see Topic Review Notes: <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link>)</p>
<p></p>
<h2>"Can You Do It?"</h2>
<p class="sectionHeading3">Question #15: What is your level of Core Competence?</p>
<p className="normalText">
                          Core competence obviously revolves around a venturing team's experience and specialization in the venture, as well as in venturing.&nbsp; These are two distinct sets of skills and abilities.&nbsp; Competence comes in the form of
                          the ability to perform the key task required for the venture's success in whatever functional area that may be. In new venture technology, the measure of the degree or strength of core competence is as follows: </p>
<table align="center" id="Table15" border="1" cellspacing="3" cellpadding="1" width="100%">
  <tbody><tr>
    <td width="14%" class="sectionHeading3">Low</td>
      <td width="85%" className="normalText">If members of the venturing team possess little or no experience and
  specialization in the business</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">Medium</td>
      <td width="85%" className="normalText">If the venturing team has some experience and unique knowledge in the
  business</td>
  </tr>
  <tr>
<td width="14%" class="sectionHeading3">High</td>
      <td width="85%" className="normalText">If the venturing team is familiar with the industry and has worked
  for at least five years therein and can perform specialized tasks critical to the venture's success</td>
</tr>
</tbody></table>
</>
}