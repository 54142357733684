import React from 'react';

export const Repetitive = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Is it Repetitive?</h1>
	<p className="normalText">With rare exceptions, the only way to achieve the volume target required as a part of building value, is for the product or service 
		to be needed over and over again.&nbsp; This is how the demand arises for toothpaste, for gasoline, and for food to name a few examples.&nbsp; 
		The need to have the customer make repetitive purchases drives the concepts of (1) "planned obsolescence," and 
		(2) the phasing out of products where the interval between purchases is so long that the firm that produces the 
        product cannot survive on the low rate of purchases. </p>
	<p className="normalText">An example of a product that falls into the second category is a particular antiperspirant.&nbsp; For several years, this product was 
		available in a small bottle that would last (given daily use) for 1-2 years.&nbsp; Though the price was somewhat high--it was worth 
		it because the stuff lasted "forever."&nbsp; Eventually, the product was phased out and replaced with a formulation that would 
		run out every two or three months.&nbsp; Why?&nbsp; Apparently someone realized that for the venture to be persistent over time, a certain 
		rate of repetitive purchase of their product is necessary.&nbsp; They adjusted the characteristics of their product accordingly. </p>
	<p className="normalText">Another approach to enhancing repetitive purchases is to identify alternative uses that can permit the achievement 
        of volume targets.&nbsp; Take the baking soda example.&nbsp; For years, people only purchased baking soda for baking.&nbsp; So, a 
		box of baking soda would last a very long time.&nbsp; Then, someone made an entrepreneurial discovery--people were using baking soda to 
		eliminate household refrigerator odor.&nbsp; An advertising campaign that encouraged odor elimination use, and replacement every six months, 
		multiplied sales many fold.&nbsp; Instead of selling one box of baking soda every two or three years per household, the venture achieved a 
		substantial increase in its repetitive purchase index, selling an average five or six 
        boxes per household over the same period of time. </p>
	<p className="normalText">So, for an entrepreneurial discovery to meet the repetitiveness test, it must either: </p>
	<ul>
		<ol>
			<li className="normalText">be something that runs out but is needed on an ongoing basis, </li>
			<li className="normalText">be something with a useful life that can be deliberately limited thus encouraging 
				repeat purchase, or </li>
			<li className="normalText">be something that though limited in its level of repetition, can have its 
				repetitiveness extended through alternative uses. </li>
		</ol>
	</ul>
	<p className="normalText">In a new venture, if the purchase pattern for the product or service is not repetitive, the answer to Question C: Is it 
		Persistent Over Time? is NO--which means DON'T GO ON until the issue of repetitiveness is resolved. </p>
	<p className="normalText">If you are evaluating a business that is already running, then you should work to find a (legal and ethical) way to create repetitive 
		purchases using some of the suggestions previously noted to raise the level of repetitive purchase.</p>
    </section>
}