import React from 'react';

export const NoHoldup = (props) => {
    return <section>
        <h1 className="pageTitle">Viability - Are There No Hold-up Problems?</h1>
        <p className="normalText">The second appropriability problem faced by "our gold-rush miner"
            is the risk from outright thieves (those who do not find the gold, but
            attempt to shrink the size of the slice kept by the miner).&nbsp; You,
            as the builder of a venture based upon entrepreneurial discovery, are
            the miner.&nbsp; The "thieves" are those actors both in, and
            outside the industry who can use their power to cut down the size of your
            profits.&nbsp; These actors "hold up" the venture for a larger
            slice size by using either coercive or economic power to extract resources
            (usually cash) from the venture. </p>
        <p className="sectionHeading1">Coercive Power</p>
        <p className="normalText">Outside the game, the government possesses coercive power to compel payments for taxes and licenses, and require compliance
            to a variety of regulations that affect the cost structure of the business.&nbsp; When the size of the slice that
            governments take increases, the size of your profit slice is decreased or "appropriated." </p>
        <p className="sectionHeading1">Economic Power</p>
        <p className="normalText">Within the game, economic power is based upon small numbers bargaining--a feature of the free market system.&nbsp; Where the number
            of suppliers available to the venture are few, those in the game can "hold your venture hostage," because they
            have a small numbers bargaining advantage over you.&nbsp; If they wish to increase the price of goods and services provided to you,
            they can "hold you up," because you have nowhere else to turn.&nbsp; Where there are many suppliers, and your venture is
            one of few customers to the supplier group, your venture can turn the tables and demand price concessions.</p>
        <p className="normalText">Conversely, where the number of buyers available is small, then the customers have a small numbers bargaining advantage over the
            venture.&nbsp; Where the number of buyers is large, then the venture has many options if "held up" for a discount by a
            customer.&nbsp; The venturer can say no to the hold-up because it cannot be held hostage in the economic relationship.&nbsp; Thus,
            where hold-up is low, appropriability is low.&nbsp; Where hold-up is high, appropriability is high, and something must be done to
            resist the "theft" of profits.&nbsp; The venture needs to ensure that it has a small numbers bargaining advantage over
            both suppliers and buyers in vertical relationships.</p>
        <p className="normalText">What can be done to limit hold-up in vertical relationships?&nbsp; This question introduces to venturing the "messiest" of
            the strategies needed for keeping your venture, by preventing its failure.&nbsp; In dealing with hold-up problems, the venture
            faces "wolves in sheep's clothing."&nbsp; Though the message may be delivered by immaculately dressed executives in an elegant
            conference room, the consequences are the same: Stick 'em up! (it's a hold-up nonetheless). <br /><br />The approach to minimizing hold-up
            is to make sure that your small numbers bargaining house is in order.&nbsp; Four methods are available to accomplish this. They
            include the use of: <br/></p>
            <p className="normalText">1. Norms</p>
            <p className="normalText">2. Negotiation</p>
            <p className="normalText">3. Contracts, and</p>
            <p className="normalText">4. Posturing</p>

            <p className="normalText">Each of these approaches to limiting hold-up is discussed briefly below: </p>
            <p className="sectionHeading2"><i>Norms</i></p>
            <p className="normalText">If, in setting up relationships in the production/distribution system, you can establish norms of conduct that ensure reasonable
                profits for all players, and establish these standards as norms of the relationships, you go a long way toward limiting hold-up
                problems.&nbsp; Many firms use norms exclusively to limit appropriability, because the trust that is built and maintained by norms,
                not only reduces hold-up risks, but also reduces slack as well.</p>
            <p className="normalText">Also, it is wise to establish the norm of dealing with more than one supplier for each critical component
                to your product or service, and with many customers.&nbsp; Times change, and with these changes the relationships upon which trust is built--especially where ownership changes occur.&nbsp; Keeping the small
                numbers bargaining odds in your favor as a perennial "fall back plan" is an astute precaution. </p>
            <p className="sectionHeading2"><i>Negotiation</i></p>
            <p className="normalText">Where norms are difficult
                to establish or are still forming, it may be necessary to enter into negotiation processes to ensure that you minimize your hold-up risks.&nbsp; Much excellent literature exists to describe approaches to
                successful negotiation.&nbsp; Your objective, regardless of approach, is to arrive at an understanding that permits you a small numbers bargaining advantage, where possible, while providing sufficient incentive
                to the customers or suppliers (as the case may be) to minimize the risk of hold-up actions on their part. </p>
            <p className="sectionHeading2"><i>Contracts</i></p>
            <p className="normalText">Written or verbal contracts can limit hold-up actions in the
                vertical relationship.&nbsp; Contracts, written especially, spell out the rights and duties of both parties to the relationship.&nbsp; Where such contracts are consistent with the norms and negotiations that
                characterize the balance of the relationship, they can be expected to limit hold-up actions by the other party. </p>
            <p className="normalText">However, it must be emphasized, that the terms of the contract generally reflect the small
                numbers bargaining position of the parties.&nbsp; This means that if you permit your small numbers bargaining position to become weak, you may expect (except in the most rare of circumstances) that your
                contracts will come under pressures for revision, and will eventually reflect that weakness.&nbsp; There is no substitute for a strong economic (small numbers bargaining) position when contracting. </p>
            <p className="sectionHeading2"><i>Posturing</i></p>
            <p className="normalText">Where other methods fail, the approach taken to hold-up minimization often devolves into a "posturing" battle.&nbsp; Here threats and innuendo,
                move and counter move, assertion and rebuttal become commonplace.&nbsp; This is a most dangerous situation from an appropriability standpoint.&nbsp; It implies that the parties either have no understanding of
                the actual status of the small numbers bargaining balance, or that the balance is relatively equal. </p>
            <p className="normalText">When well done, posturing can be effective in limiting hold-up.&nbsp; But the "odds" of
                staring down a desperate gunman in a robbery, approximate the odds of long term success in appropriability reduction through posturing.&nbsp; As a short term measure, posturing may work on occasion.&nbsp; As a
                long term measure it cannot be relied upon.&nbsp; Attention to the actual small numbers bargaining position is the key.&nbsp; Then, should the other party truly have no understanding of the bargaining situation,
                some well placed posturing can be most effective.</p>
            <p className="normalText">So, where norms, negotiations, contracts, or posturing are used to reduce hold-up, then the answer to the sub-question: Are there NO hold-up problems?, can be YES.</p>
            <p className="normalText">If you find appropriability problems in an
                ongoing business, then look for the possibility to establish norms, bargains, or contracts in the vertical (supplier/buyer) relationship that will minimize the propensity for the other party to engage in hold-up
                activities.</p>
    </section>
}