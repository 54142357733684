import React from 'react';
import { Link } from 'react-router-dom';

export const Value = () => {

return <section>
	<h1 className="pageTitle">The Fundamental of Value</h1>
	<p className="normalText">The second question in The NEW VENTURE TEMPLATE is: Is it valuable?&nbsp; Recalling that the "it" in our set of questions is an entrepreneurial discovery, the answer that we wish to know next is: Can "it" produce value?&nbsp; In a new venture, as in more established business, value is bilateral.&nbsp; That is, for an entrepreneurial discovery to have value to a venture, it must produce a benefit for both the customer and for the company.&nbsp; In addition, the volume of valuable transactions must be sufficient to meet the financial objectives of the venturer. <br/><br/>The determination of an answer to the question of value, then, lies in a set of relationships among the benefits (of the product/service to buyers) and costs (to the company) on one hand; and the margin and volume on the other.&nbsp; This is a complex calculation, although it can be simplified by answering these three sub-questions: <br/><br/>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A. <Link className="contentLink" to="/template/viability/net-buyer-benefit">Is there net buyer benefit</Link> (Ghemawat, 1991)? (... a difference between gross buyer benefit--what the customer gets--and the price you charge)
                                <br/>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; B. <Link className="contentLink" to="/template/viability/margin">Are there sufficient margins?</Link> (... a difference between price charged and fully absorbed costs) <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; C. <Link className="contentLink" to="/template/viability/volume">Is there sufficient volume?</Link> (... to support the venture) <br/><br/>Your final determination of value can then be summarized
                                using Figure B below as a guide tempered by your judgment about volume sufficiency given your venturing objectives:
	</p>
	{/* <table width="100%" border="0" cellspacing="0" cellpadding="0" nof="TE">
		<tbody><tr><td align="CENTER"><img id="Picture16" height="284" width="400" src="../images/NBB.gif" border="0" /></td></tr>
    </tbody></table> */}
    <div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>

    <img src={require('../../assets/net-buyer-benefit.gif')} alt="Net Buyer Benefit"></img>
    </div>
    <p className="normalText">&gt;Value to the customer (net buyer benefit) is mainly determined by creating and managing
		customer perceptions. Value to the venture (margin) is mainly determined by minimizing costs relative to the
		price that you can charge, given customer perception.</p>
</section>
}