import React from 'react';

export function TechnologyBasedSuccess(props){
  return <>
  <p className="sectionHeading1">Technology-based Venture</p>
  <p className="normalText">The success of this type of venture comes from high levels of core competence, PMM,
  NBB, margins, volume, purchase frequency, long-term need, and access to resources. &nbsp;
  Ironically, the reason that it is not higher on the "keep it" axis stems from
  its source of strength: technology.&nbsp; And, since the pace at which many technologies
  change is rapid, levels of scarcity and non-appropriability erode as the
  "newness" of an initial new combination" fades.&nbsp; Thus, this type of
  venture most likely will have medium levels of NC and ambiguity, supported by quite low
  levels of uncertainty (insurable risk). </p>

  <p className="normalText">ADVICE: To foster the longevity of successful technology-based ventures, the key is to
  utilize presently plentiful resources to work "on" the business--creating a
  venture that encourages more NC-based innovation, which in turn forms the foundation of
  scarce, non-appropriable products and/or services.&nbsp; This approach starts with an
  aggressive approach toward the management of ambiguity, which if addressed while the
  venture is thriving, will continue to generate adaptations of the venture to its rapidly
  changing environment. </p>
  </>

}