import React from 'react'

export function TemplateOverview(props) {
  return <>
  <h1 className="pageTitle">The New Venture Template™</h1>
  <p className="normalText">When undertaking new ventures, a degree of luck is involved in the ultimate success or failure of the business.&nbsp; But it is
also widely recognized that building high-potential business ventures is a very specialized field that utilizes unique knowledge.
&nbsp; Although the luck factor cannot be totally eliminated from any venture, a systematic process can be applied during the
evaluation and development stage of the venture to increase venture potential and improve the probability of ultimate success.</p>
<p className="normalText">The <b>New Venture Template</b>™ approach (<i>developed by Ronald K. Mitchell, CPA, Ph.D.</i>) to new venture formation is similar to the model used in the medical community. Over the years,
  consistent identification of known causes of disease and death has led to the systematic development of cures, immunizations and treatments to prolong life and alleviate suffering.&nbsp; From a business perspective, similar
  preventive care and ongoing maintenance are required both in the formation stage and in the on-going operation of new ventures.</p>
<p className="normalText">Over the past 25 years, two disciplines:&nbsp; (1) the discipline of business <b>planning</b>, and&nbsp; (2) the discipline of business <b>strategy</b> have developed a set of concepts that when combined, offer a powerful template to help BUILD A HIGH-POTENTIAL BUSINESS VENTURE.&nbsp; From "business planning" we can compile a list of criteria that answer the question
  <b>: "Is it a business</b>?"&nbsp; The field of "business strategy" contributes criteria that answer the question: <b>"Can you keep it</b>?"&nbsp; Together these criteria form a venture
  "template" that is especially useful to new ventures because of the step-by-step ordering of the questions.&nbsp; It can also be a very powerful tool to help to <b>diagnose and improve</b> ongoing or troubled ventures.
</p>
<p className="normalText">To know whether an idea will support a "business," (or if already operating whether the "business" is being optimized)
you should ask the questions:</p>
<p className="normalText"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A.&nbsp; Is it <i>Innovative</i>?</b></p>
<p className="normalText"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; B.&nbsp; Is it <i>Valuable</i>?</b></p>
<p className="normalText"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; C.&nbsp; Is it <i>Persistent over time</i>?</b></p>
<p className="normalText">To determine whether you can "keep" the fruits of your labors, you should ask the questions:</p>
<p className="normalText"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; D.&nbsp; Is it<i> Scarce</i>?</b></p>
<p className="normalText"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E.&nbsp; Is it<i> Non-appropriable (protectable)</i>?</b></p>
<p className="normalText"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; F.&nbsp; Is it <i>Flexible</i>?</b></p>
<p className="normalText">In a perfect world these fundamentals of a well-built venture would be in place at the outset.&nbsp;</p>
</>
}