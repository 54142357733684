import React from 'react';

export const NetBuyerBenefit = (props) => {
    return <section>
        <h1 className="pageTitle">Viability - Is There a Net-Buyer Benefit?</h1>
        <p className="normalText">In the end, your customers must want your product or service MORE than they want money.&nbsp; As emphasized in the last
        topic review note, a product-market match is the foundation of the business.&nbsp; And customer value is the
		foundation of the product-market match.</p>
        <p className="normalText">The difference between the benefit that your customer sees in your product/service
        (call it gross buyer benefit) and the price that you wish to charge, measures
        the net benefit to your customer.&nbsp; For ease of reference, this "customer"
        value is termed net buyer benefit (Ghemawat, 1991): </p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <img src={require('../../assets/net-buyer-benefit.gif')} alt="Net Buyer Benefit"></img>
        </div>
        <p className="normalText">When the net buyer benefit for your product/service is large, you have room to raise the price if you need
        to.&nbsp; Here high demand prevents raised prices from decreasing the quantity sold by very much.&nbsp; Economists
        refer to this situation as inelastic demand.&nbsp; When the net buyer benefit is small, you will feel "price
                                pressure" i.e. you will either not be selling, or your customers will be demanding a discount.&nbsp; Here price is "elastic," since any increase in the price dramatically reduces demand. <br /><br />
                                Customer value (net buyer benefit) is created when you persuasively communicate to your customers the differences your product/service possesses when compared to others on the market.&nbsp; As customers
                                clearly perceive these differences, gross buyer benefit goes up.&nbsp; As gross buyer benefit goes up, the amount of customer value (net buyer benefit) increases accordingly.&nbsp; When viewed this way, net buyer
                                benefit can be seen in its most simple form.&nbsp; Net buyer benefit exists ONLY because of customer perception. <br /><br />
                                Your first job as an entrepreneur, then, is to create and manage the way that customers "see" or "perceive"
                                your product/service.&nbsp; This job done well is the key to customer value, and to a profitable business. <br /><br />
                                If the net buyer benefit is large, then the value to the customer is high.&nbsp; If the amount of net buyer benefit is low, the answer to Question B: Is it valuable? is NO--which means DON'T GO ON with a new venture
                                until the low net buyer benefit situation is remedied.&nbsp; If you have an operating business, and the amount of
                                Net Buyer Benefit is low, the key to raising it is to "differentiate" your product or service. </p>
    </section>
}