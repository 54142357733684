import React from 'react';
import { Link } from 'react-router-dom';

export const EntrepreneurRolesOverview = (props) => {
    return <section>
        <h1 className="pageTitle">Venture Profile Roles</h1>
        <p className="normalText">The <b><i>Individual Expertise Assessment</i></b> also suggests your likely venturing "Role", given your venturing "Type".&nbsp; The questionnaire that you completed also
            evaluated three main constructs that have been linked to individuals' venturing roles.&nbsp; The role constructs derive from
            the differences in scriptbased "cue recognitions" (Mitchell, 1995) that have been documented between experts and
            novices, and help to map the type of involvement or "role" that an individual is likely to play in the new
            venture formation arena.&nbsp; The questionnaire asks questions that determine the level of:
	</p>

        <p><img id="Picture3" height="356" width="406" src={require('../../assets/entrepreneur-roles-venn-diagram.gif')} align="middle" border="0" alt="Roles Venn Diagram" /></p>
        <p className="normalText">Venturing <b>Arrangements</b> (in place to support script "entry")</p>
        <p className="normalText">Venturer <b>Willingness</b> (to motivate script "entry")</p>
        <p className="normalText">Venturer <b>Ability</b>&nbsp; (to enable the "doing" required to take full advantage of venture opportunities by taking action consistent with script requirements)</p>
        <p className="normalText">Leddo and Abelson (1986) provide theory that helps to explain the three constructs used for the venturerole analysis.&nbsp; They explain that the avoidance of script failure
                                    (the exercise of expertise) depends upon an individual's properly performing the actions that are most central to a given script.&nbsp; Specifically, Leddo and Abelson assert
                                that two script functions (Entry, and Doing) are central, as follows:</p>
        <p className="normalText">These privileged functions we label Entry and Doing; the former occurs early in the script, and the latter
                                    near the end.&nbsp; Entry presupposes the success of script entry arrangements . . . Doing presupposes the actor's willingness and the ability to carry out the action serving the main goal of the script. (1986, p.
                                121) (emphasis added)</p>
        <p className="normalText">The <b>"Arrangements"</b> construct means having funds, a trend of performance increases, technology,
                                and experience.&nbsp; Venturers who possess these preparations to venture have made the arrangements necessary to facilitate script entry.</p>
        <p className="normalText">The <b>"Willingness"</b> construct refers to willingness to take risks, to act versus miss opportunity, and to
                                    go after a piece of the big money; accompanied by enormous drive, an attraction to actiontakers, a
                                propensity to invest, to want a "say," and to venture versus recreate.&nbsp; The willing individuals are more likely to "enter" the venturing script.</p>
        <p className="normalText">When attempting to label the third construct according to expert theory, it becomes necessaryif the
                                    label "Ability" is to be considered as suggested in Leddo and Abelson (1986)to define the kind of ability that is necessary in new venture formation.&nbsp; Stevenson, Roberts and Grousbeck (1994) assert
                                that it is the ability to recognize, capture and protect opportunity that characterizes success in new venture formation.</p>

        <p className="normalText">When this definition is applied to "Ability," new venture <b>"Opportunity
        - Ability"</b> is the result.&nbsp; The ability to protect a new
            venture with knowledge or with entry barriers, the ability represented
            by knowledge of specific industry scripts and success scenarios, and the
            ability to know how to solve new venture problems with specialized new
        venture knowledge are some of the concepts embodied in this construct.</p>

        <p className="normalText">The various combinations of these three constructs results in the following
        venturing role types:</p>
        <ul>
            <ul>
                <p className="normalText"><b>(1) <Link className="contentLink" to="/profile/entrepreneur-roles/investor">Investor</Link> (2) <Link className="contentLink" to="/profile/entrepreneur-roles/promoter">Promoter</Link>  (3) <Link className="contentLink" to="/profile/entrepreneur-roles/advisor">Advisor</Link> (4) <Link className="contentLink" to="/profile/entrepreneur-roles/initiator">Initiator</Link></b></p>
                <p className="normalText"><b>(5) <Link className="contentLink" to="/profile/entrepreneur-roles/mentor">Mentor</Link> (6) <Link className="contentLink" to="/profile/entrepreneur-roles/incubator">Incubator</Link> (7) <Link className="contentLink" to="/profile/entrepreneur-roles/venturer">Venturer</Link> (8) <Link className="contentLink" to="/profile/entrepreneur-roles/non-venturer">Non-Venturer</Link></b></p>
            </ul>
        </ul>
    </section>
}