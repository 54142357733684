import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const StrugglingProprietary = () => {
    return <section>
	<h1 className="pageTitle">The Struggling Proprietary Prototype</h1>
	<p className="normalText">The Struggling Proprietary type venture experiences the paradox of being so closely held
		(i.e. having such unique <Link className="contentLink" to="/template/viability/core-competence">Core Competence</Link> or proprietary secrets, etc.) that it fails to generate sufficient "business."</p>
    <p className="normalText">Hence, when viewing the prototype "Bulls-eye" diagram, one can see that all the "
		<b>Can You Keep It</b>?" variables (
		<Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link>, <Link className="contentLink" to="/template/fundamentals/non-appropriability">Non-Appropriability</Link>, and <Link className="contentLink" to="/template/fundamentals/flexibility">Flexibility</Link>) are high, while all the "<b>Is It a Business</b>?" variables (<Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link>, <Link className="contentLink" to="/template/fundamentals/value">Value</Link> and <Link className="contentLink" to="/template/fundamentals/persistence">Persistence</Link>) are lower--more in the medium range.</p>
    <p className="normalText">Examples of the Struggling Proprietary type of venture include the inventor- or engineer-based business that has a protected technology, but is not utilizing the technology to produce a fully commercial product.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 23: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="sp" />
    <p className="sectionHeading2">Figure 24: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype="Struggling Proprietary" />
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">The key here is marketing (increasing <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>), with the logic going as follows:</p>
	<p className="normalText">Increased <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link> implies increased <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link>, which implies increased <Link className="contentLink" to="/template/viability/volume">Volume</Link>, which in turn implies the potential to increase <Link className="contentLink" to="/template/viability/margin">Margins</Link>.&nbsp; This, then, further implies increases in <Link className="contentLink" to="/template/fundamentals/persistence">Persistence</Link>, represented by <Link className="contentLink" to="/template/viability/repetitive">Purchase Frequency</Link>, <Link className="contentLink" to="/template/viability/long-term-need">Product Longevity</Link>, and access to <Link className="contentLink" to="/template/viability/resources">Resources</Link>.</p>
	<p className="normalText">In short, this approach can turn the Struggling Proprietary venture into one with much higher potential, because it adds base business strength to already existing strategic strength.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Eisen Internet Publishing -- <i>Struggling Proprietary</i></p>
	<p className="normalText">Iris M. Eisen had been intrigued with Internet and its potential 15 years ago when she worked for a
        catalogue-publishing house.&nbsp; Her job at the time was to coordinate catalogue production for Falcon Chemical, a large distributor of chemical products throughout the country.&nbsp; She worked closely with Falcon
        in designing their catalogue layout, organizing the final camera-ready product, and supervising printing
        activities.&nbsp; Iris also oversaw the distribution of the catalogs to thousands of purchasing agents throughout
        North America.&nbsp; After 5 years on the job she knew Falcon Chemical management, their products, and their distribution networks.</p>
    <p className="normalText">As electronic commerce and on-line catalogues gained popularity she approached her boss and founder of
                                the company Joe Doherty about moving into the electronic catalogue business to leverage their catalogue
                                expertise.&nbsp; Joe wasn't interested; he was nearing retirement and didn't want to complicate his life.&nbsp; His
                                company had been in the publishing business for 35 years and catalogues only made up a small percentage of his revenues.</p>
    <p className="normalText">Upset about the rebuttal, Iris talked to her husband that evening about her ideas on how Falcon Chemical,
                                with her knowledge of both the chemical business and Internet based commerce, could move forward.&nbsp;
                                Iris's Internet hobby could potentially turn into a lucrative career.&nbsp; After several long family discussions about
                                her plans, Iris left the publishing company and approached Falcon with her idea for an online catalogue.&nbsp; She
                                leveraged her pitch on the ease with which updates could be incorporated, the freedoms of keeping prices
                                current to deal with variations in inventories and volume, and the security she could offer individual clients in
                                downloading the most current prices.&nbsp; As a result of her knowledge and contacts, it didn't take long to
                                convince Falcon to change catalog systems.&nbsp; Within a year she had a signed contract with Falcon to organize a new e-catalogue.&nbsp; </p>
    <p className="normalText">Iris started small, soliciting off-hours help from family and friends to get things going.&nbsp; By the time she got
                                Falcon up to full speed, e-commerce was big business and there were software packages in the market enabling large firms to do much of the work in house if they so desired.&nbsp; Iris's big advantage was her
                                knowledge of Falcon's business.&nbsp; As a result, she was a low cost supplier.</p>
    <p className="normalText">Iris had some concerns about her reliance on a single client.&nbsp; She knew that her contract security with Falcon
                                was rooted in remaining a low cost supplier.&nbsp; She often thought about expanding and attracting other clients,
                                however it would be difficult.&nbsp; She would be faced with new, steep learning curves, which made it hard to
                                compete with the in house capabilities of these new clients.&nbsp; She also had to dedicate most of her time to
                                Falcon to meet their needs.&nbsp; This left her with little free time for new clients, preventing her from increasing
                                volume to a level that could generate the high profits she anticipated upon leaving the publishing company.</p>
    <p className="normalText">Falcon was aware of Iris's position, and sometimes used it to their advantage by working her price down.&nbsp;
                                Falcon did recognize that the services offered by Iris were going to be needed for a long time, however they
                                had a few alternative options available.&nbsp; Software development had improved to the point where completing
                                the tasks in house was viable if the appropriate training systems were put in place, and two other companies
                                were knocking at Falcon's door offering similar services.&nbsp; Iris wasn't particularly concerned about loosing
                                the contract to a competitor, due to her intimate knowledge of Falcon Chemical.&nbsp; She was sure that Falcon
                                wouldn't consider publishing it on their own, due to the automation support systems that would have to be set up within the company.&nbsp; </p>
    <p className="normalText">Iris was making reasonable money, but privately she felt a bit constrained.&nbsp; She couldn't really look for new
                                clients, due to the time required for the Falcon contract.&nbsp; The money from the contract brought her a
                                reasonable living, and the freedom she wanted to work at home.&nbsp; She was proud of her little business, and satisfied with her accomplishments.</p>
    </section>
}