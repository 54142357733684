import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const IEAG = (props) => {
    return <section>
	<h1 className="pageTitle">IEAG - Gambler</h1>
    <p className="normalText">
        The IEAG uses intuition and guesswork to risk resources for an uncertain 
        return.&nbsp; These venturers (preliminary indications indicate that about 
        3% fit this profile), are not without ability or courage; they just lack 
        an understanding of how highly sophisticated venturing works, or rely 
        on rapidity of action and flexibility to "get in" and "get 
        out".&nbsp; For this reason, the IEAG may tend to be an unreliable 
        long term partner in venturing.</p>
                            
    <p className="normalText">&nbsp;IEAG's should beware of "blind spots", 
        disguised information which is often "fed" to them to keep them 
        under-informed so that they will not withdraw at the first sign of trouble.&nbsp; 
        This may result in a set of "thinking errors" moderated by intuition.&nbsp; 
        The basic gambling instincts are hard to eliminate, but additions to the 
        knowledge structure of an IEAG should reduce blind spots and channel venturing 
        energy.</p>
    <MatrixDiagram selected="IEAG" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">If you are an IEAG, the most likely way for you to improve your new venture 
        profile is to enhance your discernment capability.&nbsp; Consider finding <b>expert help to increase the odds</b> of success.&nbsp; With an expert 
        guide, you can systematically develop a list of key questions to ask, 
        reports to request, and patterns to recognize which will decrease your 
        need to guess (gamble) in a new venture. </p>
                            
    <p className="normalText">Maximum exposure to existing principles of new venture success should 
        be your objective.&nbsp; Experts in new venture evaluation include venture 
        capitalists, expert venturers (contact your local or regional venture 
        group), or institutional experts (contact your local university).&nbsp; 
        Input from these specialists should prove to be valuable over the long 
        term to mitigate the "peaks" and "valleys" related 
        to the "blind spots" in the IEAG's evaluation process.</p>
    </section>
}
