import React from 'react';
import { Link } from 'react-router-dom';

export const ViabilityOverview = (props) => {
  return (<>
  <h1 className="pageTitle">New Venture Template™ Viability</h1>
<Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link><br/>
<Link className="contentLink" to="/template/viability/core-competence">Core Competence</Link><br/>
<Link className="contentLink" to="/template/viability/long-term-need">Long Term Need</Link><br/>
<Link className="contentLink" to="/template/viability/margin">Margin</Link><br/>
<Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link><br/>
<Link className="contentLink" to="/template/viability/new-combination">New Combination</Link><br/>
<Link className="contentLink" to="/template/viability/no-holdup">No Holdup</Link><br/>
<Link className="contentLink" to="/template/viability/no-slack">Slack</Link><br/>
<Link className="contentLink" to="/template/viability/non-imitable">Non-Imitiable</Link><br/>
<Link className="contentLink" to="/template/viability/non-substitutible">Non-Substitutable</Link><br/>
<Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link><br/>
<Link className="contentLink" to="/template/viability/repetitive">Repetitive</Link><br/>
<Link className="contentLink" to="/template/viability/resources">Resources</Link><br/>
<Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link><br/>
<Link className="contentLink" to="/template/viability/volume">Volume</Link><br/>
</>)
}