import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const IELD = (props) => {
    return <section>
	<h1 className="pageTitle">IELD - Under-equipped</h1>
    <p className="normalText">An IELD has unique capabilities because of specialized knowledge about 
        venturing, generally in a particular field.&nbsp; An example might be 
        a technical specialist or engineer in a large company who has been able 
        to observe corporate venturing first hand as an employee vs. entrepreneur.&nbsp; 
        When this expertise is combined with the willingness to venture, it provides 
        opportunities which should not be undervalued.&nbsp; However, the IELD 
        lacks entrepreneurial resources, and may not be aware of the importance 
        of his/her expertise to discern whether a venture in his/her area of specialty 
        is "on" or "off' course.</p>
    <p className="normalText">This venture type can sometimes reveal a candidate for early-stage backing: to take a 
		talented specialist who is willing to venture, and get a new business off the ground.</p>
    <MatrixDiagram selected="IELD" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">The best thing for an IELD to do if intending to get involved in a new 
        venture, is to locate a <b>venture capital partner</b>.&nbsp; Without 
        such a partner it is unlikely that either the resources or the conscious 
        connection to the entrepreneurial success patterns will be available to 
        you.&nbsp; A partnership or other arrangement with an individual, entity, 
        or group which possesses the money and expertise to back an IELD, fills 
        the "holes" in this venturing profile.&nbsp; This linkage with 
        experts and resources can improve the prospects for success.</p>
                            
    <p className="normalText">If an IELD wishes to improve his or her own venture profile, then taking 
        steps which enhance "consciousness" of the elements of the new 
        venture success script is the place to start.&nbsp; Preliminary indications 
        are that approximately 8% of people fit this profile.</p>
    </section>
}
