import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const LowCompetence = () => {
    return <section>
	<h1 className="pageTitle">The Low Competence Prototype</h1>
	<p className="normalText">The Low-Competence venture is a trap.&nbsp; Because inexperienced venturers can see a
		glimmer of each of the key elements of a venture, (see prototype diagram of this venture type) they
        often mistakenly assume that with only a little bit of help this very weak project can be "turned
		around."</p>
    <p className="normalText">But sadly, this venture is "congenitally flawed."&nbsp; It is often the case
		that ventures that are formed with insufficient <Link className="contentLink" to="/template/viability/resources">Resources</Link>, or in environments (niches) that are
		tightly packed with rivals, PERMANENTLY bear the scars of their weak condition at founding.&nbsp; They are most likely to be "selected out" of the market (fail).</p>
    <p className="normalText">Efforts to try and prolong their life most often tend to be futile.&nbsp; If life is prolonged, the venture tends to be a "grind" to operate.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 3: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="lc" />
    <p className="sectionHeading2">Figure 4: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype="Low Competence" />
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">Although this may seem harsh, the best course of action with a Low-Competence venture is
		to "cut your losses," which means to ABANDON the venture as inexpensively as possible.&nbsp; This
		is because the effort that is required to move all 15 variables into an acceptable zone is better spent in
		searching for and screening ventures that have more going for them.&nbsp; Use the <b>New Venture Template™</b> to help in the screening process.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">The Anti-Hacking Software -- <i>Low Competence</i></p>
	<p className="normalText">Roger Moor, chairman of the Computer Security Company, called in the major shareholders for an
        emergency meeting on July 18, 1993.&nbsp; The company appeared to be facing serious problems as a result of Joe Clark's resignation.
		Joe originated a piece of new anti-hacking software in late 1991, and the company
        had been formed to develop and market this product.&nbsp; Unfortunately, after a year of hard work and $500,000 in investment,
		the project had failed and it was necessary to discuss business termination.</p>
    <p className="normalText">Roger was a well-known and reputable financial expert with an outstanding academic and professional
                                background.&nbsp; He was the financial manager of Kate Land Development Company in Los Angeles for nearly 10 years.&nbsp; Kate was a privately owned company, and Roger was closely associated with the owner/CEO,
                                who passed away in January 1992.&nbsp; Roger didn't feel the same kind of respect for the new CEO, and was
                                contemplating quitting his job.&nbsp; About the same time Joe, his neighbor, approached Roger to discuss a new product idea.</p>
    <p className="normalText">Roger remembered Joe as an interesting and enthusiastic person with many technical claims.&nbsp; Their wives
                                were close friends.&nbsp; Joe was interested in developing computer security software.&nbsp; Joe told Roger that he
                                and his experienced colleagues had successfully designed a software program that was able to prevent computer hacking.&nbsp; He claimed that they already had made a prototype, which was successfully tested at a
                                small company.&nbsp; He presented Roger with a letter from the company indicating some promising, but preliminary, results.&nbsp; Roger was impressed with Joe's prototype and the results.&nbsp; He felt that computer
                                security could develop into a major business concern, and that products serving this purpose would have a promising future.&nbsp; Eventually he decided to leave Kate Land Development Co. to pursue a new venture with
                                Joe. </p>
    <p className="normalText">Roger formed the Computer Security Company with a working capital of $500,000.&nbsp; He invested his
                                savings and a sizable termination payment from Kate Co.&nbsp; He also approached some of his relatives and
                                friends to invest in this venture.&nbsp; Computer Security Company bought the prototype software, and planned
                                to begin marketing the prototype for limited usage.&nbsp; They hoped to gradually develop its application, and
                                expand production in the future.&nbsp; Joe had a vision to start research on other computer security products for future applications.</p>
    <p className="normalText">Immediately the program began to show signs of significant technical difficulties.&nbsp; The customers expressed
                                serious concerns.&nbsp; They were unable to test the software, it didn't inform them when a hacker was trying to
                                break into the system, and the program itself was relatively untested.&nbsp; No one knew how the software would
                                react to different hackers' approaches.&nbsp; The wide variety of computer hardware and software combinations
                                required testing in a number of different computing environments, which could not be simulated by Computer
                                Security Company.&nbsp; At first it seemed that budget was the main constraint to simulating these environments,
                                but upon closer analysis, it became clear that Joe himself didn't understand the systems and programming at
                                a level that could allow the company to test the software.&nbsp; Joe wasn't able to provide reasonable solutions
                                and appeared technically incompetent.&nbsp; Roger later realized that Joe didn't have any post secondary school
                                education and his theoretical knowledge was indeed limited.&nbsp; He had a neat idea that was underdeveloped, and Roger realized that it in fact did not constitute a new product. </p>
    <p className="normalText">Roger's trust in Joe's technical know-how was a mistake.&nbsp; Contrary to his previous claims, Joe had never
                                developed an applicable product, or a production process, or an assembly line.&nbsp; Roger felt cheated but
                                hoped to come up with solution with a few additional investments.&nbsp; However, these additional costs only
                                increased the product price which made it even less attractive for the customers.&nbsp; The marketing research indicated that there were a few other competitors experiencing limited success.&nbsp; Computer Security
                                Company's high software development costs and lack of production volume increased their price well over the competition. </p>
    <p className="normalText">There were some indications that even if the software succeeded as an 'after-market' product, it would not
                                have a sustainable market in the future.&nbsp; A number of computer manufacturers were developing built-in anti-hacking technologies.&nbsp; Roger hoped to apply their limited technology to the software side of the
                                computer business, and hoped to raise funds by offering an IPO on the public market.&nbsp; A market slowdown
                                eliminated any hope that this act would bail out the company, and Roger faced critical financial problems as a consequence. </p>
    <p className="normalText">Upon realizing the financial difficulties, Joe abandoned the Computer Security Company and left with his
                                limited technical know-how.&nbsp; On the way out, Joe accused Roger of incompetence, claiming that had Roger
                                been able to keep the money flowing, everything would have been fine.&nbsp;&nbsp; Roger was upset about the outcome, and the complete waste of money, time and energy.&nbsp; Roger was more concerned though about the
                                damage to his reputation amongst his financial colleagues, relatives and friends.</p>
    </section>
}