import React from 'react';

export function Charity(props){
  return <><p className="sectionHeading1">Charity Venture</p>

  <p className="normalText">When a venture lacks many of the elements that make it a business, but still has
          "staying power" in society, we generally find a charity.&nbsp; A charity is
          uneconomic without help because, though a long-term benefit to society (notice that NBB is
          medium to high) there is insufficient volume and margins to make it a self-sustaining
          business (evidenced by low PMM). </p>

  <p className="normalText">So we find as charities organizations that vary from opera and symphony companies to
          other community, educational, or religious groups that exist because they depend upon the
          "charity" of society to make up the shortfalls that come from lack of profitable
          delivery of their product or service. </p>

  <p className="normalText">You will also notice in the diagram that (1) the innovation level is immaterial to
          marketability, being insufficient to propel the product/service into profit- making
          status; (2) scarcity and appropriability are medium, due to weak economic standing; (3)
          uncertainty is medium due to community support, with ambiguity somewhere in the medium
          range due to the basically uneconomic nature of the profit-making lack in the
          organization. </p>

  <p className="normalText">ADVICE: To turn a charity into a business, it is necessary to address the fundamental
          problems that render the organization unable to exist without help from society. &nbsp;
          These are: (1) problems with the product/market match [there's just not a big market for
          this stuff]; (2) because of the low PMM there are problems with the margins [although
          people value the product/service, the costs are much higher than the few who value it
          highly can fund by themselves]; which explains (3) problems with the volume. &nbsp; Though
          many "purists" resist, the solution is to "commercialize" the product
          or service, i.e. make it accessible to and desirable for larger portions of society. </p>

  <p className="normalText">Where an organization is likely to depend upon society over the long term, it is an
          error to promise supporters a return on their contributions beyond that of
          "recognition" for their help.&nbsp; If used properly, the "sale" of
          recognition is a "second product" that if properly marketed, can guarantee a
          charity the Margins and Volume that come from this NEW product/market match.&nbsp; The key
          is to fund such organizations as charities, and NOT as businesses. </p>
</>
}