import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const IRAD = (props) => {
    return <section>
	<h1 className="pageTitle">IRAD - Small Business Expert</h1>
	<p className="normalText">Along with the other AD's, the IRAD is properly classified in the venturing expert "target zone".&nbsp; In this case, the IRAD is most often not conscious of acquired expertise, and tends to be risk 
                                averse.&nbsp; The main things going for an IRAD are resources, and experience (usually over a long period of time).</p>
    <p className="normalText">Although the entrepreneurial expert script does not exist for the IRAD, this is not usually 
		a hindrance, since his or her general reluctance to venture acts as a control on venturing outside 
        the IRAD's target zone of "extraordinary capability".&nbsp; IRAD's appear (preliminarily) in 
		approximately 3% of the population.</p>
    <MatrixDiagram selected="IRAD" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">If you are classified in the IRAD new venture type, be sure to stick to tried and true 
		atterns for success: stay in your <b>extraordinary expertise zone (EEZ)</b>.&nbsp; Venturing outside familiar 
		patterns should be viewed as a return to "1st time venturer" status.</p>
    <p className="normalText">If you are thinking about becoming involved in a venture which is outside the business domain 
		where you feel confident in evaluating the nuances of operating results, do not hesitate to call in an 
		expert to help.</p>
    </section>
}
