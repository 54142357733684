import React, { useEffect, useContext } from 'react';
import { Loader } from '../Loader/Loader.component';
import { Redirect } from 'react-router-dom';
import { AuthenticationContext } from '../context';

export function AuthRequired(props) {
    const { jwtVerfied, setShouldVerifyJwt, isVerifyingJwt, loggedIn } = useContext(AuthenticationContext);

    useEffect(() => {
        setShouldVerifyJwt(true);
    }, [setShouldVerifyJwt]);

    if (isVerifyingJwt) return props.showLoader ? <Loader>Authenticating . . . </Loader> : null;
    if (!jwtVerfied && !loggedIn) return <Redirect to="/login" />;
    if (jwtVerfied || loggedIn) return props.children
}
