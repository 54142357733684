const prototypes = {
  bajsb: {
    k: 3,
    b: 3,
    matchSelectedPrototypeName: 'bajsb',
    buttonLabel: 'Buy-a-job',
    labelText: 'Buy-a-job small business',
    labelPosition: {
      initial: 'start',
      adjustK: 2,
      adjustB: 2,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  ch: {
    k: 5,
    b: 1,
    matchSelectedPrototypeName: 'ch',
    buttonLabel: 'Charity',
    labelText: 'Charity',
    labelPosition: {
      initial: 'start',
      adjustK: -2,
      adjustB: 1,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  cbs: {
    k: 7,
    b: 9,
    matchSelectedPrototypeName: 'cbs',
    buttonLabel: 'Competence-based Success',
    labelText: 'Competence-  based "Success"',
    labelPosition: {
      initial: 'end',
      adjustK: -1,
      adjustB: 9,
      multiLineB: 3,
      multiLineK: 4
    }
  },
  cbt: {
    k: 7,
    b: 5,
    matchSelectedPrototypeName: 'cbt',
    buttonLabel: 'Competence-based Troubled',
    labelText: 'Competence-based  "Troubled"',
    labelPosition: {
      initial: 'end',
      adjustK: -2,
      adjustB: 0,
      multiLineB: -4,
      multiLineK: 5
    }
  },
  f: {
    k: 2,
    b: 9,
    matchSelectedPrototypeName: 'f',
    buttonLabel: 'Fad',
    labelText: 'Fad',
    labelPosition: {
      initial: 'start',
      adjustK: -2,
      adjustB: -3,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  hp: {
    k: 7,
    b: 7,
    buttonLabel: 'High Potential',
    matchSelectedPrototypeName: 'hp',
    labelText: 'High  potential',
    labelPosition: {
      initial: 'middle',
      adjustK: -6,
      adjustB: 0,
      multiLineB: 0,
      multiLineK: 4
    }
  },
  hob: {
    k: 9,
    b: 1,
    matchSelectedPrototypeName: 'hob',
    buttonLabel: 'Hobby',
    labelText: 'Hobby',
    labelPosition: {
      initial: 'start',
      adjustK: 4,
      adjustB: 1,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  hos: {
    k: 2,
    b: 5,
    matchSelectedPrototypeName: 'hos',
    buttonLabel: 'Hostage',
    labelText: 'Hostage',
    labelPosition: {
      initial: 'start',
      adjustK: 2,
      adjustB: 2,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  lsb: {
    k: 5,
    b: 5,
    matchSelectedPrototypeName: 'lsb',
    buttonLabel: "Lifestyle Small Business",
    labelText: 'Lifestyle  small business',
    labelPosition: {
      initial: 'middle',
      adjustK: -6,
      adjustB: 0,
      multiLineB: 0,
      multiLineK: 4
    }
  },
  lc: {
    k: 2,
    b: 2,
    matchSelectedPrototypeName: 'lc',
    buttonLabel: "Low Competence",
    labelText: 'Low  Competence',
    labelPosition: {
      initial: 'start',
      adjustK: -2,
      adjustB: 2,
      multiLineB: 0,
      multiLineK: 4
    }
  },
  mv: {
    k: 9,
    b: 9,
    matchSelectedPrototypeName: 'mv',
    buttonLabel: "Model Venture",
    labelText: 'Model  Venture',
    labelPosition: {
      initial: 'middle',
      adjustK: 4,
      adjustB: 0,
      multiLineB: 0,
      multiLineK: 4
    }
  },
  rp: {
    k: 1,
    b: 1,
    matchSelectedPrototypeName: 'rp',
    buttonLabel: "Research Project",
    labelText: 'Research project',
    labelPosition: {
      initial: 'start',
      adjustK: -1,
      adjustB: 1,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  sp: {
    k: 9,
    b: 5,
    matchSelectedPrototypeName: 'sp',
    buttonLabel: 'Struggling Proprietary',
    labelText: 'Struggling proprietary',
    labelPosition: {
      initial: 'middle',
      adjustK: 5,
      adjustB: 0,
      multiLineB: 0,
      multiLineK: 0
    }
  },
  tbs: {
    k: 5,
    b: 9,
    matchSelectedPrototypeName: 'tbs',
    buttonLabel: 'Technology-based Success',
    labelText: 'Technology-  based success',
    labelPosition: {
      initial: 'middle',
      adjustK: 5,
      adjustB: -2,
      multiLineB: 0,
      multiLineK: 4
    }
  }
};

export default prototypes;