import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const CELD = (props) => {
    return <section>
	<h1 className="pageTitle">CELD - Resource Poor</h1>
    <p className="normalText">An expert in venturing by training and knowledge of the "entrepreneurial 
        script" (preliminarily found in about 4% of the population), the 
        CELD is nevertheless in need of resources.&nbsp; If all the pieces were 
        in place, this venturer would be a classic entrepreneur (CEAD).&nbsp; 
        But when the pieces aren't in place, knowing the reasons why they are 
        not is crucial to the success of a CELD.&nbsp; Often CELD's cannot really 
        explain the reasons why they are resource poor, because such reasons often 
        derive from a "blind spot".&nbsp; CELD's often attribute their 
        lack of resources to unavoidable events, but the paucity of resources 
        may only be the "tangible evidence" that a CELD has a limited 
        ability to execute the entrepreneurial success script.&nbsp; For example, 
        past ventures may not have been properly "harvested" to enable 
        future venturing, a CELD may be a "lone wolf" who has not learned 
        how to mobilize others, or he or she may not have recognized their area 
        of extraordinary expertise, and hence be "barking up the wrong tree".</p>
	<p className="normalText">Many classic and institutional expert entrepreneurs 
        (CEAD'S and CRAD's) have "done their time" in the CELD zone.&nbsp;&nbsp; 
        Times of scarcity appear to be a natural part of the new venturing experience.&nbsp; 
        The CELD profile is unique for this reason.&nbsp; Within this group are 
        opportunities waiting to be refined.&nbsp; Hence, the plans of CELD's 
        should not be taken lightly.&nbsp; But they should be thoroughly scrutinized. 
    </p>
    <MatrixDiagram selected="CELD" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">Help from other experts, or from appropriate consultants might speed 
        the CELD's return to venturing, or enable the first venture to proceed.&nbsp; 
        If you are a CELD, you probably need an <b> expert advisory board</b>.&nbsp; 
        Essentially, the needs of the CELD <i>appear</i> to be resources, and 
        in fact they partly are, but they also include the need for close examination 
        by venture capitalists, institutional experts (e.g. within a bank small 
        business department or a university), or by expert venturers to identify 
        more subtle impediments to the resource flow.</p>
                            
    <p className="normalText">If you are a CELD who is intent on undertaking a new venture, try to 
        humble yourself, find an extra measure of patience, put yourself into 
        a listening frame of mind, and start seeking detailed, specific input 
        regarding the approach which you are taking to your venture.&nbsp; It 
        is most likely you will face the obstacle of over-optimism on your own 
        part, attempting to refute or challenge the advice you get as irrelevant 
        or lacking in optimism.&nbsp; Because you are dealing with a potential 
        "blind spot" you are likely to misinterpret the information 
        which you receive.&nbsp; Hence, the need for several sources of input 
        (triangulation), and for patience, humility, and open-mindedness.&nbsp; 
        What you may discover is worth the wait!</p>
    </section>
}
