import React from 'react';

export function LowCompetence(props){
  return <>
  <p className="sectionHeading1">Low Competence Venture</p>

  <p className="normalText">The low competence venture is a trap.  Because the inexperienced venturer can see a glimmer of
          each of the key elements of a venture, s/he often mistakenly assumes that with only a little
          bit of help this very weak project can be "turned around."</p>

  <p className="normalText">But sadly, this venture is "congenitally flawed."  It is often the case that ventures that are
          formed with insufficient resources, or in environments (niches) that are tightly packed with rivals,
          PERMANENTLY bear the scars of their weak condition at founding.  They are most likely to be
          "selected out" of the market (fail).  Efforts to try and prolong their life most often tend to be futile.
          If life is prolonged, the venture tends to be a "grind" to operate.</p>

  <p className="normalText">ADVICE: Although this may seem harsh, the best course of action with a low comptence venture is to
          "cut your losses," which means to ABANDON the venture as inexpensively as possible.  This is because
          the effort that is required to move all 15 variables into an acceptable zone is better spent in
          searching for and screening ventures that have more going for them. Use this program to help in the
          screening process.</p>
          </>

}

// <td align="top" className="normalText"><span className="sectionHeading1">Correlation Coefficients</span><br>
//       &nbsp;&nbsp;100.0% Low Competence<br>
//       &nbsp;&nbsp;67.5% Charity<br>
//       &nbsp;&nbsp;61.9% Buy-job<br>
//       &nbsp;&nbsp;59.4% Competence-based Troubled<br>
//       &nbsp;&nbsp;52.4% Fad<br>
//       &nbsp;&nbsp;51.5% Hobby<br>
//       &nbsp;&nbsp;50.4% Struggling Proprietary<br>
//       &nbsp;&nbsp;50.0% Lifestyle<br>
//       &nbsp;&nbsp;49.7% Hostage<br>
//       &nbsp;&nbsp;42.8% Competence-based Success<br>
//       &nbsp;&nbsp;41.2% Technology-based Success<br>
//       &nbsp;&nbsp;40.8% High Potential<br>
//       &nbsp;&nbsp;35.4% Model<br>
//       &nbsp;&nbsp;13.7% Research<br>
//       &nbsp;&nbsp;B=2&nbsp;K=2<br>
// </td>
