import React from 'react';
import { Link } from 'react-router-dom';

export const NVTResultsOverview = (props) => {
  return <>
<h1 className="pageTitle">The New Venture Template™ Results</h1>
<p className="normalText">By applying <b><i>Time</i></b>, <b><i>Resources</i></b>, and <b><i>Relationships</i> toward improving specific venture attributes you can establish and maintain a "Success Trajectory" where the venture is positioned Up
                          </b> from, or to the <b>Right</b> of (<i>preferably both</i>), its closest-correlation business-model type.</p>
<p className="normalText">Listed in the following tables are the exact coordinates for your company's <b><i>actual position</i></b>, which identify the degree to which your venture is viable.</p>
<p className="sectionHeading1">B Scale: "Is it a Business?"</p>
<table align="center" id="Table34" border="1" cellSpacing="3" cellPadding="1" width="100%" className="normalText">
<tbody className="normalText"><tr>
     <td width="15%"><b>Score</b></td>
      <td width="100%"><p style={{textAlign: "center"}}><b>Description</b></p></td>
  </tr>
  <tr>
  <td width="15%" className="normalText">7-9</td>
  <td width="85%">This company is <b>strong in most (if not all) of
  the fundamental variables that make a well-built "Business"</b> (<Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link>, <Link className="contentLink" to="/template/fundamentals/value">Value</Link>, <Link className="contentLink" to="/template/fundamentals/persistence">Persistence</Link>) and as such, possesses the ability, or likely ability, to generate significant revenue.
    </td>
  </tr>
  <tr>
  <td width="15%" className="normalText">5-7</td>
  <td width="85%">The venture has <b>crossed the threshold of being
  a "Business"</b>, but definitely needs work to improve certain variables
  that contribute to generating adequate revenue.
      </td>
  </tr>
  <tr>
  <td width="15%" className="normalText">3-5</td>
  <td width="85%">This company <b>possesses some characteristics
  essential for "Business" activity</b>—it is (or can get) "off the ground"—but
  without significant improvement of several key variables, the company will not likely create jobs
  or generate wealth.
      </td>
  </tr>
  <tr>
  <td width="15%" className="normalText">0-3</td>
  <td width="85%">The venture is <b>weak in most (if not all) of
  the fundamental "Business" variables</b>. While success might "appear" to be just
  around the corner, this venture is not ready to be operated as a business.
      </td>
  </tr>
</tbody></table>
<p></p>
<p className="sectionHeading1">K Scale: "Can You Keep It?"</p>
<table align="center" id="Table35" border="1" cellSpacing="3" cellPadding="1" width="100%">
<tbody><tr>
    <td width="15%" className="normalText">7-9</td>
  <td width="85%"><p className="normalText"><b>The venture is strong in most (if not all) of the fundamental "Keeping" elements</b> (<Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link>, <Link className="contentLink" to="/template/fundamentals/non-appropriability"><i></i>Non-Appropriability</Link>, <Link className="contentLink" to="/template/fundamentals/flexibility"><i></i>Flexibility</Link>) and as such, has the potential to operate well into the future.</p>
    </td>
  </tr>
  <tr>
  <td width="15%" className="normalText">5-7</td>
  <td width="85%"><p className="normalText">This company needs to <b>work on various "Keeping"
  elements</b> which, if not fixed, will make it increasingly difficult for it to establish and
  maintain staying power.</p>
      </td>
  </tr>
  <tr>
  <td width="15%" className="normalText">3-5</td>
  <td width="85%" className="normalText">The venture has some <b>"Keeping" characteristics</b>
  to help it harvest some (perhaps substantial) revenue--but not for very long, and with
  ever-increasing difficulty.
      </td>
  </tr>
  <tr>
  <td width="15%" className="normalText">0-3</td>
  <td width="85%" className="normalText"> This company is <b>weak in most (if not all)
  fundamental "Keeping" elements</b>, suggesting caution before
  serious investment of time and resources.
      </td>
  </tr>
</tbody></table>
</>
}