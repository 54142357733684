import React from 'react';

export function LifestyleSmallBusiness(props){
  return <><p className="sectionHeading1">Lifestyle Venture</p>

  <p className="normalText">Some people prefer lifestyle to money, and choose the vehicle of a small business to
          get them there.&nbsp; For example, someone might want to live in a picturesque location
          and choose a venture that permits them this lifestyle choice, such as owning and operating
          a small hotel in a hamlet near the ocean.&nbsp; Essentially, the NVT illustrates the
          venture characteristics of such businesses. </p>

  <p className="normalText">As shown, the prototype lifestyle small business depends upon purchase frequency and
          product longevity (e.g. the need for a bed and breakfast in a scenic location) for its
          viability. </p>

  <p className="normalText">As also shown, some sacrifices are required in all other areas, trading money for
          lifestyle.&nbsp; Especially important to the lifestyle small business, however, is the
          opportunity to vary the level of innovation and the level of imitability. </p>

  <p className="normalText">ADVICE: If you want a lifestyle small business that produces a bit of extra money too,
          then it may pay to give attention to innovation and imitability in the product / service.
          &nbsp; Protectable innovations are the sure way to distinguish your business, and justify
          higher volumes, better margins, etc. </p>
          </>

}