import React, { useContext } from 'react';
import { NavLink, Route, withRouter } from 'react-router-dom';
import './Navigation.css';
import { AuthRequired } from '../utils/AuthRequired.component';
import { HasPermission } from '../utils/HasPermission.component';
import { AuthenticationContext } from '../context';

const TopLevel = props => {
  return (
    <li className="navigation-menu-item">
      <NavLink to={props.to}>{props.label || props.children}</NavLink>
    </li>
  );
};

const SecondLevelLink = props => {
  return (
    <li className="navigation-menu-item navigation-menu-level-2">
      <NavLink activeStyle={{ color: '#343434' }} to={props.to}>
        {props.label}
      </NavLink>
    </li>
  );
};

const ThirdLevelLink = props => {
  return (
    <li className="navigation-menu-item navigation-menu-level-3">
      <NavLink activeStyle={{ color: '#343434' }} to={props.to}>
        {props.label}
      </NavLink>
    </li>
  );
};

const Nav = props => {
  const { user, loggedIn } = props;
  return (
    <nav className="navigation-container">
      <ul>
        <TopLevel to="/login" label="Login" />
        <Route path="/authenticated" render={() => AuthenticatedMenu} />
        <TopLevel to="/template" label="New Venture Template" />
        <Route path="/template" render={() => TemplateMenu} />
        <TopLevel to="/profile" label="New Venture Profile" />
        <Route path="/profile" render={() => ProfileMenu} />
      </ul>
      {user && user.user_type && user.user_type.includes('admin') && <AdminMenuWithRouter />}
      {loggedIn ? <LogoutButtonWithRouter /> : null}
    </nav>
  );
};

const LogoutButton = (props) => {
    const { logout } = useContext(AuthenticationContext);
    return <button
      className="navigation-logout-button"
      onClick={() => {
        logout();
        return props.history.push('/login');
      }}
    >
      Logout
    </button>
}

const LogoutButtonWithRouter = withRouter(LogoutButton);


const AuthenticatedMenu = (
  <ul>
    <SecondLevelLink to="/authenticated/NVT" label="Template Questionnaire" />
    <SecondLevelLink to="/authenticated/NVT-results" label="Template Results" />
    <SecondLevelLink
      to="/authenticated/NVT-results-wheel"
      label="Analysis: Template Results Wheel"
    />
  </ul>
);

const TemplateMenu = (
  <ul>
    <SecondLevelLink to="/template/questions" label="Evaluation Questions" />
    <SecondLevelLink to="/template/results" label="Interpreting Results" />
    <SecondLevelLink to="/template/prototypes" label="Prototypes" />
    <Route
      path="/template/prototypes"
      render={() => (
        <ul>
          <ThirdLevelLink
            to="/template/prototypes/buy-a-job-small-business"
            label="Buy-a-job Small Business"
          />
          <ThirdLevelLink to="/template/prototypes/charity" label="Charity" />
          <ThirdLevelLink
            to="/template/prototypes/competence-based-success"
            label="Competence-based Success"
          />
          <ThirdLevelLink
            to="/template/prototypes/competence-based-troubled"
            label="Competence-based Troubled"
          />
          <ThirdLevelLink to="/template/prototypes/fad" label="Fad" />
          <ThirdLevelLink
            to="/template/prototypes/high-potential"
            label="High Potential"
          />
          <ThirdLevelLink to="/template/prototypes/hobby" label="Hobby" />
          <ThirdLevelLink to="/template/prototypes/hostage" label="Hostage" />
          <ThirdLevelLink
            to="/template/prototypes/lifestyle-small-business"
            label="Lifestyle Small Business"
          />
          <ThirdLevelLink
            to="/template/prototypes/low-competence"
            label="Low-Competence"
          />
          <ThirdLevelLink
            to="/template/prototypes/model-venture"
            label="Model Venture"
          />
          <ThirdLevelLink
            to="/template/prototypes/research-project"
            label="Research Project"
          />
          <ThirdLevelLink
            to="/template/prototypes/struggling-proprietary"
            label="Struggling Properietary"
          />
          <ThirdLevelLink
            to="/template/prototypes/technology-based-success"
            label="Technology-based Success"
          />
        </ul>
      )}
    ></Route>
    <SecondLevelLink to="/template/fundamentals" label="Fundamentals" />
    <Route
      path="/template/fundamentals"
      render={() => (
        <ul>
          <ThirdLevelLink
            to="/template/fundamentals/flexibility"
            label="Flexibility"
          />
          <ThirdLevelLink
            to="/template/fundamentals/innovation"
            label="Innovation"
          />
          <ThirdLevelLink
            to="/template/fundamentals/non-appropriability"
            label="Non-Appropriability"
          />
          <ThirdLevelLink
            to="/template/fundamentals/persistence"
            label="Persistence"
          />
          <ThirdLevelLink
            to="/template/fundamentals/scarcity"
            label="Scarcity"
          />
          <ThirdLevelLink to="/template/fundamentals/value" label="Value" />
        </ul>
      )}
    ></Route>
    <SecondLevelLink to="/template/viability" label="Viability" />
    <Route
      path="/template/viability"
      render={() => (
        <ul>
          <ThirdLevelLink
            to="/template/viability/ambiguity"
            label="Ambiguity"
          />
          <ThirdLevelLink
            to="/template/viability/core-competence"
            label="Core Competence"
          />
          <ThirdLevelLink
            to="/template/viability/long-term-need"
            label="Long-term Need"
          />
          <ThirdLevelLink to="/template/viability/margin" label="Margin" />
          <ThirdLevelLink
            to="/template/viability/net-buyer-benefit"
            label="Net Buyer Benefit"
          />
          <ThirdLevelLink
            to="/template/viability/new-combination"
            label="New Combination"
          />
          <ThirdLevelLink
            to="/template/viability/no-holdup"
            label="No Holdup"
          />
          <ThirdLevelLink to="/template/viability/slack" label="Slack" />
          <ThirdLevelLink
            to="/template/viability/non-imitable"
            label="Non-Imitable"
          />
          <ThirdLevelLink
            to="/template/viability/non-substitutible"
            label="Non-Substitutible"
          />
          <ThirdLevelLink
            to="/template/viability/product-market-match"
            label="Product Market Match"
          />
          <ThirdLevelLink
            to="/template/viability/repetitive"
            label="Repetitive"
          />
          <ThirdLevelLink
            to="/template/viability/resources"
            label="Resources"
          />
          <ThirdLevelLink
            to="/template/viability/uncertainty"
            label="Uncertainty"
          />
          <ThirdLevelLink to="/template/viability/volume" label="Volume" />
        </ul>
      )}
    ></Route>
    <SecondLevelLink to="/template/references" label="References" />
  </ul>
);

const ProfileMenu = (
  <ul>
    <SecondLevelLink
      to="/profile/entrepreneur-types"
      label="Entrepreneur Types"
    />
    <Route
      path="/profile/entrepreneur-types"
      render={() => (
        <ul>
          <ThirdLevelLink to="/profile/entrepreneur-types/CEAD" label="CEAD" />
          <ThirdLevelLink to="/profile/entrepreneur-types/CEAG" label="CEAG" />
          <ThirdLevelLink to="/profile/entrepreneur-types/CELD" label="CELD" />
          <ThirdLevelLink to="/profile/entrepreneur-types/CELG" label="CELG" />
          <ThirdLevelLink to="/profile/entrepreneur-types/CRAD" label="CRAD" />
          <ThirdLevelLink to="/profile/entrepreneur-types/CRAG" label="CRAG" />
          <ThirdLevelLink to="/profile/entrepreneur-types/CRLD" label="CRLD" />
          <ThirdLevelLink to="/profile/entrepreneur-types/CRLG" label="CRLG" />
          <ThirdLevelLink to="/profile/entrepreneur-types/IEAD" label="IEAD" />
          <ThirdLevelLink to="/profile/entrepreneur-types/IEAG" label="IEAG" />
          <ThirdLevelLink to="/profile/entrepreneur-types/IELD" label="IELD" />
          <ThirdLevelLink to="/profile/entrepreneur-types/IELG" label="IELG" />
          <ThirdLevelLink to="/profile/entrepreneur-types/IRAD" label="IRAD" />
          <ThirdLevelLink to="/profile/entrepreneur-types/IRAG" label="IRAG" />
          <ThirdLevelLink to="/profile/entrepreneur-types/IRLD" label="IRLD" />
          <ThirdLevelLink to="/profile/entrepreneur-types/IRLG" label="IRLG" />
        </ul>
      )}
    ></Route>
    <SecondLevelLink
      to="/profile/entrepreneur-roles"
      label="Entrepreneur Roles"
    />
    <Route
      path="/profile/entrepreneur-roles"
      render={() => (
        <ul>
          <ThirdLevelLink
            to="/profile/entrepreneur-roles/advisor"
            label="Advisor"
          />
          <ThirdLevelLink
            to="/profile/entrepreneur-roles/incubator"
            label="Incubator"
          />
          <ThirdLevelLink
            to="/profile/entrepreneur-roles/initiator"
            label="Initiator"
          />
          <ThirdLevelLink
            to="/profile/entrepreneur-roles/investor"
            label="Investor"
          />
          <ThirdLevelLink
            to="/profile/entrepreneur-roles/mentor"
            label="Mentor"
          />
          <ThirdLevelLink
            to="/profile/entrepreneur-roles/non-venturer"
            label="Non-Venturer"
          />
          <ThirdLevelLink
            to="/profile/entrepreneur-roles/promoter"
            label="Promoter"
          />
          <ThirdLevelLink
            to="/profile/entrepreneur-roles/venturer"
            label="Venturer"
          />
        </ul>
      )}
    ></Route>
    <SecondLevelLink to="/profile/references" label="References" />
  </ul>
);

const AdminMenu = props => {
  return (
    <AuthRequired>
      <HasPermission
        showLoader={false}
        showErrorMessage={false}
        requiredPermissions={['admin']}
      >
        <div className="navigation-admin-menu">
        <b>Admin</b>
          <ul>
            <TopLevel to="/authenticated/admin/create-users">
              Create Classes
            </TopLevel>
            <TopLevel to="/authenticated/admin/classes">View Classes</TopLevel>
            <br />
            <TopLevel to="/authenticated/admin/manage-admins">Manage Admins</TopLevel>
          </ul>
        </div>
      </HasPermission>
    </AuthRequired>
  );
};

const AdminMenuWithRouter = withRouter(AdminMenu)

export const Navigation = withRouter((props) => Nav(props));
