import React from 'react';

export function CompetenceBasedTroubled(props) {
        return <><p className="sectionHeading1">Competence-based Troubled Venture</p>

                <p className="normalText">Somewhat like a "charity," the competence-based venture in trouble combines
                HIGH COMPETENCE with low PMM, margins, and volume, for something with a long-term need, in
                an environment of uncertainty and ambiguity.&nbsp; This venture is UNLIKE a charity in
                that innovation is high, as is scarcity--with substantial control of appropriability.
          &nbsp; Also, a PMM is possible in this type of venture. </p>

                <p className="normalText">ADVICE: Focus on PMM using NBB as leverage, until a market is identified and proven.
                &nbsp; This, then, should set the stage for higher margins and volume.&nbsp; It should
                also permit the incentives and the small numbers bargaining advantage necessary to
                decrease appropriability, uncertainty, &amp; ambiguity, and move closer to the model
          venture.</p>
        </>
}