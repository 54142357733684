import React from 'react';

export function Fad(props){
  return <>
	<p className="sectionHeading1">Fad Venture</p>

	<p className="normalText">A "fad" type venture is distinguished from other types of venture almost
        entirely by its lack of persistence over time.&nbsp; Quite often a fad-based venture can
        yield substantial profits--these profits are just limited-lived.&nbsp; Essentially in a
        fad, a strong core competence yields an improved product or service, with strong PMM, NBB,
        margins and volume, which in turn produce plentiful resources and low uncertainty. </p>

	<p className="normalText">But, because scarcity is doubtful, and holdup almost certain given time, the longevity
        of the product/service is doubtful, hence ambiguity is high. </p>

	<p className="normalText">ADVICE: With the understanding that comes from the utilization of the template
        criteria, comes the realization that fads, though not permanent, may nevertheless be very
        lucrative.&nbsp; Accordingly, ventures that are fad-based should be run as such. &nbsp;
        Attempts to prolong the venture beyond its short life-span waste resources. </p>

	<p className="normalText">Instead, venturers are advised to minimize fixed and overhead-type obligations, and to
        concentrate on "profit-taking."&nbsp; In short, "Make hay while the sun
        shines!" </p>
				</>

}