import React from 'react';
import { Disclaimer } from './Disclaimer.component';

export const Investor = (props) => {
    return <section>
	<h1 className="pageTitle">Investor Role</h1>
        <img src={require('../../assets/roles-investor.gif')} alt="Investor" />
	<p className="normalText">
        By demonstrating a high score on the "Arrangements" scale, this
        type of individual reveals possession of a strong venturing infrastructure,
        in the absence of the willingness and opportunity-ability necessary to
        be a venturer.&nbsp; If interested in New Venture Formation (NVF), this
        type of individual would need to team up with other individuals who have
        the willingness and training to actualize a venture.</p>

    <p className="normalText">The role of investor or backer is often appropriate
        in this circumstance.&nbsp; In terms of Expert Information Processing
        Theory (EIPT), this type of individual is able to accomplish NVF script
        "entry," but not script "doing."</p>
    <p className="normalText">&nbsp;</p>
        <Disclaimer />
    </section>
}