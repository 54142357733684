import React from 'react';

export const Uncertainty = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Is Uncertainty Minimized?</h1>
	<p className="normalText">Because uncertainty arises due to the variability (randomness) of known eventualities, it can be managed, and therefore minimized.&nbsp; 
		The discipline of statistical analysis has arisen to assist with the problem of quantifying and thereby controlling and minimizing 
		random variation in uncertain situations.&nbsp; For example, actuaries use statistical analysis to develop the tables used by life 
		insurance companies to quantify the risk that an individual with a given age and health record will die.&nbsp; On the basis of these 
		tables, insurance businesses can charge customers enough to pay "estimated" claims plus operating costs plus profits.&nbsp; 
		In another example, quality control professionals can set upper and lower control limits on manufacturing processes, to ensure that 
		the percentage of defective products is no greater than a certain proportion. </p>
	<p className="normalText">In other words, uncertainty can be managed where the shape of the probability curve is known (Ghemawat, 1991).&nbsp; Uncertainty 
		management is also known as risk management for this reason.&nbsp; Risk management is the flexible solution to uncertainty.&nbsp; 
		Wherever the probability of an almost certain occurrence can be computed, or the probability of a given magnitude of occurrence can 
		be estimated, then uncertainty can and should be managed.&nbsp; Where uncertainty is managed, and is minimized through the risk 
		management processes to be discussed, venture flexibility is enhanced.&nbsp; Two risk management processes enhance flexibility: 
		(1) insurance, and (2) advance planning.</p>
	<p className="normalText">An old adage states that there is nothing more certain than death and taxes.&nbsp; This is a good place to start when managing risk.&nbsp; 
		Remember, when the event is certain, but we don't know when, how much, or both, then the risk can be managed.&nbsp; The following 
		paragraphs discuss the insurance and advance planning implications for minimizing the uncertainties that arise due to "death" 
        and "taxes."</p>
	<p className="sectionHeading1">Death</p>
	<p className="normalText">There are two types of "death events" that, when managed, will minimize uncertainty, and thereby increase venture 
       flexibility.&nbsp; The first involves the actual death of key members of the venture team.&nbsp; The second involves the occurrence 
	   of events that are of such magnitude that they, by themselves, obliterate the venture. </p>
   	<p className="sectionHeading2">Death of a key person</p>
	<p className="normalText">The loss of a key technical person, or of the entrepreneur him or herself, can often mean the demise of the 
        	venture.&nbsp; Risk management requires that both insurance and planning for such an eventuality be utilized.</p>
	<p className="normalText">The first type of insurance that is needed in a venture to minimize the impact of the death 
        	of key individuals has been referred to as "keyman" insurance (perhaps now "keyperson").&nbsp; Here, life 
			insurance policies are purchased that are sufficiently large to ensure that a viable replacement for that individual can be found, 
			and that the damage to the venture from the untimely loss of a key person (down time, a new learning curve, etc.) is repaired.&nbsp; 
			Though cash cannot substitute completely for the absence of a valued associate, it can help to minimize the economic losses incurred.</p>
	<p className="normalText">The second type of insurance that can enhance venture flexibility in the face of the death of a key person on the venture team, is 
			(so called) "buy-sell" insurance.&nbsp; Often key persons are also owners of the venture, and are therefore important to the 
			venture because of the decision power that they possess as owners.&nbsp; When ownership rights in a venture pass to individuals who 
			are unfamiliar with the venture aims, or who have differing economic goals, a great deal of damage can be done beyond that 
            caused by the key person's absence alone, when decisions are made contrary to the best interests of the business.</p>
	<p className="normalText">For this reason it is wise for the owners to make it possible for the continuing owners to "buy out" the heir(s) to the 
			ownership interest.&nbsp; This is done through the drafting of a buy-sell agreement that is funded by life insurance--a flexibility 
			plan that uses both insurance and advance planning.&nbsp; Ventures that have this buy-sell mechanism in place are much more flexible 
			in case of the death of a key player on the venture team.</p>
   	<p className="sectionHeading2">Obliteration of the venture</p>
	<p className="normalText">The second type of death is venture death itself, caused by a catastrophic event.&nbsp; Some catastrophes, such as a building fire, 
			earthquake, or flood, can deal such a severe blow to a venture that it does not recover.&nbsp; For example, one report indicates 
			that only 30 percent of the businesses destroyed in the Los Angeles riots of 1992 have reopened. </p>
	<p className="normalText">Earthquakes, embezzlements, fires, floods, hurricanes, product liability damage awards, riots, and other disasters will overtake a 
			certain percentage of businesses every year.&nbsp; Those overtaken are most often overwhelmed, and cease operation as a 
			result--unless they have planned ahead and provided for insurance against these "death dealing" events. </p>
	<p className="sectionHeading1">Taxes</p>
	<p className="normalText">There is simply no excuse for venture inflexibility due to taxes.&nbsp; But unfortunately, most first-time venturers do not adequately plan for and 
                                manage their tax responsibilities.&nbsp; Depending upon the type of venture, liabilities arise for income, employment, excise, and sales taxes in federal and local jurisdictions. <br/><br/>It is a most unwelcome 
                                surprise for the venturer to discover that what s/he thought was profit, was in fact not, because they had failed to do their homework on their tax responsibilities.&nbsp; Sadly, this unwelcome surprise occurs more 
                                often than not.&nbsp; And it most often occurs approximately 18 to 24 months into the venture, and will very likely be triggered through the "audit" of the venture by one of the taxing agencies 
                                mentioned.&nbsp; And very often, one taxing agency has agreements with others to inform them of new small businesses that have escaped the mandated filing requirements. <br/><br/>Then, once the cycle of "forced 
                                compliance" gets into motion, the attention of all concerned are distracted from the main aims of the venture, and must focus on highly unproductive reconstruction of events, analysis and updating of records, 
                                meetings with tax officials, the hiring of skilled professionals (at least accountants and lawyers), and a host of other non-productive activities.&nbsp; So a word to the wise:&nbsp; Plan ahead for your taxes. <br/>
                                <br/>Planning ahead for taxes means four things: (1) Know which types of tax that you are likely to owe, (2) Estimate the taxes on an ongoing basis utilizing the services of a skilled and knowledgeable accountant, 
                                (3) price your products or services such that you can still hit your profit targets after taxes, and (4) set aside the cash to pay the taxes, matching the tax liabilities as they build. <br/><br/>Thus, in all our 
                                businesses, uncertainty is certain.&nbsp; But it can be managed, and through the use of statistically-based processes, minimized.&nbsp; So, where insurance and advance planning are used to decrease the uncertainties 
                                that arise due to "death and taxes", the answer to the sub-question: Is uncertainty minimized?, can be YES. If not, then DON'T GO ON until this is resolved.&nbsp; Insurance and advance planning make the 
                                correction of this weakness practical if not easy, and thereby makes the venture more flexible.&nbsp; Use them.&nbsp; Those who try to beat known and unfavorable odds are known as gamblers. With any amount of 
                                uncertainty, gambling is foolish.</p>
    </section>
}