import React from 'react';
import { Link } from 'react-router-dom';

export const FundamentalsOverview = (props) => {
  return <section>
	<h1 className="pageTitle">The New Venture Template™ Fundamentals</h1>
	<p className="normalText">
    There are six fundamentals to a well-built venture.&nbsp; They are:</p>
    <ul style={{ listStyle: "inside", marginLeft: "2rem", listStyleType: "disc", }}>
        <li className="normalText">Innovation </li>
        <li className="normalText">Value </li>
        <li className="normalText">Persistence</li>
        <li className="normalText">Scarcity </li>
        <li className="normalText">Appropriability</li>
        <li className="normalText">Flexibility&nbsp; </li>
    </ul>
    <p className="normalText">When identified and addressed properly, the odds of growing a successful venture are increased exponentially.&nbsp; Each fundamental builds upon the proceding one.&nbsp; This means that there is no reason to explore 
                                the Value fundamental until the Innovation fundamental has been addressed and resolved satisfactorily.</p>
    <p className="normalText"><Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link><br/>
		Is it a new idea?&nbsp; A new way to do something?&nbsp; Is anyone willing to pay for this new product or service?&nbsp; 
		These are the fundamental questions that must be reviewed before going on to the other sections.</p>
	<p className="normalText"><Link className="contentLink" to="/template/fundamentals/value">Value</Link><br/>Is there value for the Buyer of your new product or service?&nbsp; Are margins sufficient for the venture to make a per 
                                unit profit?&nbsp; Is there enough market to reach the financial needs and goals of this endeavor?</p>
    <p className="normalText"><Link className="contentLink" to="/template/fundamentals/persistence">Persistence</Link><br/>
		Is this a one-time only purchase?&nbsp; Or will customers come 
        back time and again to make additional purchases?&nbsp; Can the product or service be sold forever or is it the new "Pet Rock" a fad with a limited life-cycle?&nbsp; Are there sufficient resources to see this 
        venture through?</p>
	<p className="normalText"><Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link><br/>
		If the venture is really a business, can you keep it?&nbsp; Are there sufficient safeguards to protect your new market from 
		imitators?&nbsp; Can it be protected from from substitutions?</p>
	<p className="normalText"><Link className="contentLink" to="/template/fundamentals/non-appropriability">Appropriability</Link><br/>
		Is your operation efficient?&nbsp; How about the distribution system?&nbsp; Your suppliers?&nbsp; Are you paying more 
		than you should?&nbsp; Can the government collect more taxes / fees?</p>
	<p className="normalText"><Link className="contentLink" to="/template/fundamentals/flexibility">Flexibility</Link><br/>
		Who's going to mind the store?&nbsp; What is the management team's experience?&nbsp; What measures are in place to offset uncertainty?</p>
  </section>
}