import React, {useState, useEffect} from 'react';
import {rootUrl} from '../utils/rootUrl';
import './CreateUsers.css';
import {Redirect} from 'react-router-dom';
import {Loader} from '../Loader/Loader.component';
import {AuthRequired} from '../utils/AuthRequired.component';
import {HasPermission} from '../utils/HasPermission.component';

export function CreateUsers(props) {
  const [numUsersToCreate, setNumUsersToCreate] = useState(0);
  const [optionsArray, setOptionsArray] = useState([]);
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [error, setError] = useState([]);
  const [formValidationError, setFormValidationError] = useState(false);
  const [userNameSeeds, setUserNameSeeds] = useState(['loading']);
  const [selectedUserNameSeed, setSelectedUserNameSeed] = useState('');
  const [nvtAccess, setNVTAccess] = useState('Yes');
  const [nvpAccess, setNVPAccess] = useState('Yes');
  const [classId, setClassId] = useState('');
  const [loading, setLoading] = useState(false);
  const [resultsReady, setResultsReady] = useState(false);

  useEffect(() => {
    if (triggerSubmit) {
      setTriggerSubmit(false);
      if (classId.trim() === "") return setFormValidationError({message: "Class name cannot be blank."})
      _createUsers();

      async function _createUsers() {
        try {
          setLoading(true);
          const response = await fetch(`${rootUrl}/api/admin/users/add`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME)}`
            },
            body: JSON.stringify({
              numberToCreate: numUsersToCreate,
              stub: {selectedUserNameSeed, nvtAccess, nvpAccess, classId}
            })
          })
          if (!response.ok) {
            throw new Error("Problem creating users");
          } else {
            setLoading(false)
            return setResultsReady(true)
          }
        } catch (error) {
          console.error(error);
          setError(error);
          setLoading(false);
        }
        return null;
      }
    }

  }, [triggerSubmit, numUsersToCreate, selectedUserNameSeed, nvtAccess, nvpAccess, classId]);



  useEffect(() => {
    getUserNameSeeds();

    async function getUserNameSeeds() {
      try {
        const response = await fetch(`${rootUrl}/api/admin/username-seeds`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
            )}`
          }
        });
        if (!response.ok) {
          if (response.status === 403) throw new Error("There was a problem retrieving the username seeds because you are not recognized as an administrator. If you feel you have reached this in error, try logging out and logging back in, and then contact the person who created your administrator account.");
          else throw new Error("There was a problem retrieving the username seeds. Try reloading this page in a few minutes.")
        } else {
          const parsedResponse = await response.json();
          setUserNameSeeds(parsedResponse.usernames);
          setSelectedUserNameSeed(parsedResponse.usernames[0])
        }
      } catch (err) {
        setError(err);
      }
    }
  }, []);

  useEffect(() => {
    const options = [];
    let max = 500;
    while (max--) {
      options[max] = max + 1;
    }
    setNumUsersToCreate(1);
    return setOptionsArray(options);
  }, [])


  if (resultsReady) return <Redirect to="/authenticated/admin/classes" />
  return (
    <AuthRequired>
      <HasPermission requiredPermissions={["admin"]} >
      <section>
        {loading && <Loader>Creating new users . . . </Loader>}
        {error && error.length > 0 && (
          <p>{error.message}</p>
        )}
        <h1>Create Classes</h1>
        <FormItem
          step="1."
          question="How many username/password combinations do you need?"
          input={
            <select
              value={numUsersToCreate}
              className="input-font-size"
              onChange={event => setNumUsersToCreate(event.target.value)}
            >
              {optionsArray.map(option => <option key={option} value={option}>{option}</option>)}
            </select>
          }
          notes={
            'Usernames and passwords combinations will be generated automatically. You may add a few extra in anticipation of students adding/dropping etc. You can also create more users later.'
          }
        />
        <FormItem
          step="2."
          question="Username seed:"
          input={
            <select
              value={selectedUserNameSeed}
              className="input-font-size"
              onChange={event => {
                return setSelectedUserNameSeed(event.target.value)
              }
              }
            >
              {userNameSeeds.map(seed => <option key={seed} value={seed}>{seed}</option>)}
            </select>
          }
          notes={
            'All usernames generated will begin with the seed phrase you select. (They will be followed by numbers.)'
          }
        />
        <FormItem
          step="3."
          question="New Venture Template Access?"
          input={
            <select
              value={nvtAccess}
              className="input-font-size"
              onChange={event => setNVTAccess(event.target.value)}
            >
              {['Yes', 'No'].map(seed => {
                return <option key={seed} value={seed}>{seed}</option>;
              })}
            </select>
          }
          notes={'Whether these users should be granted NVT access.'}
        />
        <FormItem
          step="4."
          question="New Venture Profile Access?"
          input={
            <select
              value={nvpAccess}
              className="input-font-size"
              onChange={event => setNVPAccess(event.target.value)}
            >
              {['Yes', 'No'].map(seed => {
                return <option key={seed} value={seed}>{seed}</option>;
              })}
            </select>
          }
          notes={'Whether these users should be granted NVP access.'}
        />
        <FormItem
          step="5."
          question="Class Name:"
          input={
            <input
              type="text"
              value={classId}
              className="input-font-size"
              onChange={event => setClassId(event.target.value)}
              maxLength={180}
              size="60"
            />
          }
          notes={
            'This is an identifier for your use that will be applied to the username/passwords generated. You will be able to access a list of generated usernames and passwords by this identifier to distribute to students. (It is not visible to the students). 180 characters max.'
          }
        />
        <h2 style={{textAlign: 'right'}}>{formValidationError && formValidationError.message}</h2>
        {error && error.length < 1 && <div className="create-class-submission-button">
          <button className="nvt-submission-button" style={{marginTop: "1.5rem"}} onClick={() => setTriggerSubmit(true)}>{loading ? "Please wait..." : "Create Usernames and Passwords"}</button>
        </div>}
      </section>
      </HasPermission>
    </AuthRequired>
  );
}

const FormItem = props => {
  return (
    <div className="create-users-form-item-container">
      <label>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{marginLeft: '2rem'}}>
            <h2 style={{marginTop: '1rem'}}>{props.step} {props.question}</h2>
          </div>
          <div style={{marginLeft: '1rem'}}>{props.input}</div>
        </div>
        <div className="create-users-form-notes">{props.notes}</div>
      </label>
    </div>
  );
};
