import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const Fad = () => {
  return <section>
    <h1 className="pageTitle">The Fad Prototype</h1>
    <p className="normalText">
        A "Fad" type venture is distinguished from other types of ventures almost entirely by its lack of <Link className="contentLink" to="/template/fundamentals/persistence">Persistence Over Time</Link>.&nbsp; Quite often a fad-based venture can yield
        substantial profits--these profits are just limited-lived.&nbsp; Essentially in a Fad, a strong <Link className="contentLink" to="/template/viability/core-competence">Core Competence</Link> yields an improved product or service, with strong <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>, <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link>, <Link className="contentLink" to="/template/viability/margin">Margins</Link> and <Link className="contentLink" to="/template/viability/volume">Volume</Link>, which in turn produce plentiful <Link className="contentLink" to="/template/viability/resources">Resources</Link> and low <Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link>.</p>
    <p className="normalText">But, because <Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link> is doubtful, and <Link className="contentLink" to="/template/viability/no-holdup">Hold-up</Link> almost certain given time, the <Link className="contentLink" to="/template/viability/long-term-need">Longevity</Link> of the product/service is doubtful, hence <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link> is high.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 7: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="f" />
    <p className="sectionHeading2">Figure 8: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype={"Fad"}/>
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">With the understanding that comes from the utilization of the <b>New Venture Template™</b> criteria, comes the realization that Fads, though not permanent, may nevertheless be
		very lucrative.&nbsp; Accordingly, ventures that are fad-based should be run as such.&nbsp; Attempts to prolong the venture beyond its short life-span waste <b><i>Resources</i></b>.</p>
    <p className="normalText">Instead, venturers are advised to minimize fixed and overhead-type obligations, and to concentrate on "profit-taking."&nbsp; In short, "Make hay while the sun shines!"</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Gum 'Drop' Earrings -- <i>A Fad Venture</i></p>
	<p className="normalText">Pamela Nosivad was lying on a Caribbean beach, savoring the sound of gentle waves lapping on the sandy
        shore.&nbsp; Her husband, Gary, and 11-year old twin daughters were snorkeling in the green-blue water.&nbsp; As
		Pamela sipped a fruity cocktail, she reflected on the astonishing events of the past year.</p>
    <p className="normalText">It was a warm Friday afternoon in late August 1997, when Pamela was seething with frustration as she
                                caught the girls sticking their chewed up bubble gum onto the living room coffee table again.&nbsp; Lately, the girls
                                had developed a nasty habit of leaving their 'trademark' all over the house: inside desk drawers, under chair
                                legs, on doorknobs to name a few places.&nbsp; It seemed to be a phase her daughters were going through that no amount of cajoling or yelling from Pamela or Gary could break.</p>
    <p className="normalText">As Pamela boiled with anger, an old saying rang through her head: 'If you can't beat them, join them.'&nbsp;
                                Suddenly, Pamela had an inspiration.&nbsp; If the girls were intent on sticking their gum to things, why not make something constructive from it?</p>
    <p className="normalText">Since the used gum had a shape like a nugget of gold or turquoise, Pamela thought of making jewelry.&nbsp; What
                                if the girls could design earrings from their chewed gum?&nbsp; After getting the girls to remove their trademarks
                                from the furniture, she shared her idea, and took them to a local arts and craft store in Kelowna to pick up
                                the materials they would need for the tacky (pardon the pun) jewelry.&nbsp; The girls were really excited about it.&nbsp;
                                They thought their mom was really cool too, for thinking up something so far out!&nbsp; That evening, the girls
                                chewed their gum, stuck the chewed pieces onto the ends of earring drop sticks instead of the furniture, and then dipped the gum 'drops' into different colors of paint and clear or tinted shellac.</p>
    <p className="normalText">The girls wore their new earrings to a program held at the community center that weekend.&nbsp; On the way
                                home after the evening's events, the girls told their parants about how all their girlfriends just loved the cool
                                looking earrings and wanted a pair too.&nbsp; The girls had promised to make earrings for all their friends as
                                back-to-school presents.&nbsp; Pamela and Gary were pleased that the gum-sticking diversion had worked and would possibly save their meager furniture collection from any more "child" abuse.</p>
    <p className="normalText">That was basically the start of the amazing turn of events in the Nosivad family.&nbsp; Soon after school began in
                                September, the girls were flooded with 100 purchase orders for gum 'drop' earrings of this size and that
                                color.&nbsp; In order to teach the girls a lesson in economics, Pamela began charging for the earrings; pricing them
                                at $4.00 a pair including a 50% profit margin.&nbsp; The $200 anticipated profit could be used to pay down some of the credit card debt that Pamela and Gary had accumulated since the slowdown at the plant where both
                                did shift-work.</p>
    <p className="normalText">A couple of weeks later, Pamela received a phone call from a buyer with a national fashion jewelry chain,
                                asking for a supply of 1,000 units in a week's time!&nbsp; The chain had heard about the tacky idea and was
                                encouraged by its popularity to sell the earrings on a trial run in a few of their stores.&nbsp; Pamela and Gary
                                consulted with a family friend, Tom, who had his own manufacturing business.&nbsp; Tom advised them that if the
                                earrings sold well in the stores, Pamela and Gary might just have a winning idea on their hands.&nbsp; There was
                                money to be made if the family set the venture up properly, as a fad.&nbsp; The first step was to provide the chain with the initial supply, and then wait for the consumers' response.</p>
    <p className="normalText">Since the girls could not possibly chew enough gum to make 1,000 earrings in a week, the Nosivad's would
                                have to outsource the 'job' to increase productivity.&nbsp; The girls invited several of their friends for 'gum-chewing parties' where they would be paid by the 'gumwork.'&nbsp; Some friends, failed to show because
                                of homework or lack of a commitment to chewing gum for hours on end all week.&nbsp; The Nosivad's small house was transformed into a temporary factory, with everyone chewing up a storm of earrings.</p>
    <p className="normalText">The 1,000 units of Gum 'Drop' Earrings hit the shelves in five locations and sold like wildfire to teenagers
                                and young girls in the first two weeks of distribution.&nbsp; The Nosivad's received purchase orders from the
                                national chain for 50,000 units to be supplied through the Christmas holiday.&nbsp; The Nosivad's agreed to supply the promised units and consulted with their friend Tom again.&nbsp; Tom advised that the cost of
                                automation was too high, given the short-lived production.&nbsp; Outsourcing the entire job to a factory would
                                also be too costly.&nbsp; So the Nosivad's set about filling the orders as they had done for the initial order, adhering to the appropriate employment law issues.&nbsp; Tom advised against key person insurance, but
                                recommended liability insurance in the unlikely event that kids would harm themselves with the product.</p>
    <p className="normalText">The national chain agreed to "net 30 day" terms at a $6 wholesale price, which provided a 40% profit
                                margin.&nbsp; Cash inflows to the Nosivad's covered their outflows.&nbsp; The profits from the original 1,000 units
                                were spent to purchase materials required to get the next set of purchase orders into production.&nbsp; This worked out perfectly for the Nosivad's, since they did not want to run up their credit cards any further.
                            </p>
    <p className="normalText">Friends and family got involved in the production.&nbsp; Sometimes production fell a little short because of
                                common children ailments and lack of commitment.&nbsp; Some people even thought about copying the idea since
                                the earrings were so easy to make and they were not patented.&nbsp; But the undertaking lost its appeal to the people after second thoughts.</p>
    <p className="normalText">While there were walls of fashion earrings competing for the target market's dollars, the teenagers and young
                                girls loved the fashion statement they made with the Gum 'Drop' Earrings.&nbsp; Consumers readily paid the $12 per pair charged in the stores.</p>
    <p className="normalText">So the Gum 'Drop' Earrings became a one-season wonder, bringing in profits of over $100,000 to the
                                Nosivad's.&nbsp; Pamela and Gary paid off their credit cards, moved into a larger house and splurged on the Caribbean vacation.&nbsp; As Pamela watched her family emerge from the water, she noticed that one of her
                                daughters was carrying a little rock in her hand.&nbsp; A flashback from the 70s came to mind; Pamela suddenly had another inspiration....</p>
                                </section>
}