import React from 'react';
import { format, parseISO } from 'date-fns';

export function SelectNVT(props) {
  const results = props.nvtResults.map(nvt => {
    let ISODate = parseISO(nvt.created_at)
    return <button
      key={nvt.template_id}
      className={`nvt-submission-selector-button ${props.selectedTemplateId === nvt.template_id ? "nvt-submission-selected-button" : "w"}`}
      onClick={() => {
        props.setSelectedNVT(nvt.template_id);
        localStorage.setItem('nvt.template_id', nvt.template_id);
      }}
    >
      {format(ISODate, 'PPPPp')}
    </button>
  })

  return <section className="template-results-select-section">
    <h2>Select an NVT submission to view:</h2>
    <div className="template-results-buttons-container">
      {results}
    </div>
  </section>
};