import React from "react";
import { MatrixCard } from './MatrixCard.component';
import { profiles } from './Profiles';
import "./MatrixDiagram.css";
import { Link } from 'react-router-dom';

export const MatrixDiagram = (props) => {

    return <div className="flex fd-c">
        <div className="flex">
            <Link to="/profile/entrepreneur-types/IELG"><MatrixCard {...profiles[0]} selected={props.selected === "IELG"} /></Link>
            <Link to="/profile/entrepreneur-types/IEAG"><MatrixCard {...profiles[1]} selected={props.selected === "IEAG"}/></Link>
            <Link to="/profile/entrepreneur-types/IRAG"><MatrixCard {...profiles[2]} selected={props.selected === "IRAG"}/></Link>
            <Link to="/profile/entrepreneur-types/IRLG"><MatrixCard {...profiles[3]} selected={props.selected === "IRLG"}/></Link>
        </div>
        <div className="flex">
            <Link to="/profile/entrepreneur-types/IELD"><MatrixCard {...profiles[4]} selected={props.selected === "IELD"}/></Link>
            <Link to="/profile/entrepreneur-types/IEAD"><MatrixCard {...profiles[5]} targetZone selected={props.selected === "IEAD"}/></Link>
            <Link to="/profile/entrepreneur-types/IRAD"><MatrixCard {...profiles[6]} targetZone selected={props.selected === "IRAD"}/></Link>
            <Link to="/profile/entrepreneur-types/IRLD"><MatrixCard {...profiles[7]} selected={props.selected === "IRLD"}/></Link>
        </div>
        <div className="flex">
            <Link to="/profile/entrepreneur-types/CELD"><MatrixCard {...profiles[8]} selected={props.selected === "CELD"}/></Link>
            <Link to="/profile/entrepreneur-types/CEAD"><MatrixCard {...profiles[9]} targetZone selected={props.selected === "CEAD"}/></Link>
            <Link to="/profile/entrepreneur-types/CRAD"><MatrixCard {...profiles[10]} targetZone selected={props.selected === "CRAD"}/></Link>
            <Link to="/profile/entrepreneur-types/CRLD"><MatrixCard {...profiles[11]} selected={props.selected === "CRLD"}/></Link>
        </div>
        <div className="flex">
            <Link to="/profile/entrepreneur-types/CELG"><MatrixCard {...profiles[12]} selected={props.selected === "CELG"} /></Link>
            <Link to="/profile/entrepreneur-types/CEAG"><MatrixCard {...profiles[13]} selected={props.selected === "CEAG"} /></Link>
            <Link to="/profile/entrepreneur-types/CRAG"><MatrixCard {...profiles[14]} selected={props.selected === "CRAG"} /></Link>
            <Link to="/profile/entrepreneur-types/CRLG"><MatrixCard {...profiles[15]} selected={props.selected === "CRLG"} /></Link>
        </div>
</div>
}