import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const CompetenceBasedSuccess = () => {

  return <section>
	<h1 className="pageTitle">The Competence-based Success Prototype</h1>
	<p className="normalText">In this type of venture, high <Link className="contentLink" to="/template/viability/core-competence">Competency</Link>, accompanied by <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>, <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link>, <Link className="contentLink" to="/template/viability/margin">Margins</Link>, <Link className="contentLink" to="/template/viability/volume">Volume</Link>, <Link className="contentLink" to="/template/viability/repetitive">Purchase Frequency</Link>, and the availability of <Link className="contentLink" to="/template/viability/resources">Resources</Link> indicate long-term success potential.</p>
    <p className="normalText">Medium <Link className="contentLink" to="/template/viability/long-term-need">Product Longevity</Link> implies increased <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link> and medium <Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link>, with a DECAYING level of <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link>.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 19: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="cbs" />
    <p className="sectionHeading2">Figure 20: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype={"Competence-based Success"}/>
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">Need to build a business that replicates key behaviors and competences.&nbsp; This means that these successful entrepreneurs should spend
		sufficient time working "on" the business (v. "in" the business) to ensure that the
        factors that have lead to its success can be replicated as circumstances change.</p>
    <p className="normalText">Because the business is based upon key <Link className="contentLink" to="/template/viability/core-competence">Competences</Link>, there should be ample effort expended (while the <Link className="contentLink" to="/template/viability/resources">Resources</Link> are still plentiful), on continuing education and the upgrading of skills--that in turn lead to ongoing <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link>.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Bond &amp; Bond Plumbing Solutions -- <i>A Competence-Based Success</i></p>
	<p className="normalText">Larry Bond scribbled down the information that his receptionist was relaying over his cell phone while he
        was still under the sink.&nbsp; The plumbing job over at the Carter's would have to wait for a couple of days
        because of the backlog of service calls.&nbsp; This was typical though, because Bond &amp; Bond was a father and
        son partnership whose services were high in demand.&nbsp; There was more than enough work to keep both men busy all year long.</p>
    <p className="normalText">Larry and his father Jerry, had over 60 years of combined plumbing experience between them.&nbsp; Both
        partners could diagnose problems quickly and accurately.&nbsp; Independently on small jobs, and together on larger ones, father and son worked competently.&nbsp; When Larry was six years old, he had flushed his toy
        alligator down the toilet.&nbsp; His father took that opportunity to teach Larry how to unplug the toilet.&nbsp; Larry and
        Jerry had the talent to find the source of hidden leaks.&nbsp; Some of Larry's friends had even dubbed him the 'Water Detective' when he joined his father's company straight out of high school.</p>
    <p className="normalText">Because their reputation preceded them, Bond &amp; Bond were able to charge twice as much as their
        competitors, who took twice as long to complete jobs.&nbsp; Bond &amp; Bond's clients appreciated being able to
        get on with the rest of their day, and knew that it would be unlikely that they would encounter the same problem once Larry or Jerry had fixed it.</p>
    <p className="normalText">Of course there were some people who claimed that Larry's price was outrageously high and would try to
        bargain it down to other plumbers' quotes.&nbsp; When Larry refused to negotiate, these individuals would either 'do it themselves' or go with the lower quote. </p>
    <p className="normalText">However, there had been enough people who were willing to pay dearly for the services that the partnership
        had been bringing in six figure revenues for the last 20 years.&nbsp; Larry could easily retire today, at age 38, on
        the handsome nest egg that he had saved up, but Larry was not quite ready to give up his working life yet.&nbsp; He really enjoyed his job, fixing up mundane problems in people's lives.</p>
    <p className="normalText">Aside from insuring the business practice from the usual type of liabilities, and buying a reasonable amount of
    	insurance for both partners, Larry tried not to trouble himself with thinking too much about 'what if' questions.&nbsp; For instance, what if pipe design and commercial plumbing products improved so much that
        plumbers would be out of work in the long term?&nbsp; To Larry, plumbing was a simple business.&nbsp; As long as
        there were pipes in the house, plumbers would be needed.&nbsp; Larry could not see what there was to anticipate going wrong, so he just focused on doing what he did best.</p>

    </section>
}