export function getPrototypeConfig(prototype){
    switch(prototype){
        case 'Model Venture':
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // New Combination
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product/Market Match
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Margins,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Volume,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Resources,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Imitable,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Substitutible,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Slack
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Holdup
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Ambiguity,
            ];

        case 'Buy-a-job':
            return [
                // first array element describes whether center of bullseye is selected
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Core Competence
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // New Combination
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Product/Market Match
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Net Buyer Benefit
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Margins,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Volume,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Resources,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Imitable,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Substitutible,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // No Slack
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Holdup
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Uncertainty,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Ambiguity,
            ];

        case 'Charity':
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 1, 1, 1, 1, 1, 1, 1, 1, 1], // New Combination
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Product/Market Match
                [ 1, 1, 1, 1, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Margins,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Volume,
                [ 0, 0, 0, 0, 1, 1, 1, 1, 1], // Purchase Frequency
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Resources,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Imitable,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Substitutible,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Slack
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Holdup
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Ambiguity,
            ];
        
        case "Competence-based Success":
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 0, 1, 0, 0, 0, 0, 0, 0, 0], // New Combination
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product/Market Match
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Margins,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Volume,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Product Longevity
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Resources,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Imitable,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Substitutible,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Slack
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Holdup
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Ambiguity,
            ];
        
        case "Competence-based Troubled":
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // New Combination
                [ 0, 0, 0, 0, 0, 0, 1, 0, 0], // Product/Market Match
                [ 1, 1, 1, 1, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Margins,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Volume,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Purchase Frequency
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Resources,
                [ 0, 1, 0, 0, 0, 0, 0, 0, 0], // Imitable,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Substitutible,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Slack
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Holdup
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Uncertainty,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Ambiguity,
            ];

        case "Fad":
            return [
                // first array element describes whether center of bullseye is selected
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Core Competence
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // New Combination
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product/Market Match
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Margins,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Volume,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Purchase Frequency
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Product Longevity
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Resources,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Imitable,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Substitutible,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // No Slack
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Holdup
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Ambiguity,
            ];
        
        case "High Potential":
            return [
                // first array element describes whether center of bullseye is selected
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // New Combination
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Product/Market Match
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Margins,
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Volume,
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Resources,
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Imitable,
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Substitutible,
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // No Slack
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // No Holdup
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 1, 0, 0, 0, 0, 0, 0], // Ambiguity,
            ];

        case "Hobby":
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 1, 1, 1, 1, 1, 1, 1, 1, 1], // New Combination
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Product/Market Match
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Net Buyer Benefit
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Margins,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Volume,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Product Longevity
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Resources,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Imitable,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Substitutible,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Slack
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Holdup
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Ambiguity,
            ]

        case "Hostage":
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // New Combination
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product/Market Match
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Margins,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Volume,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Resources,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Imitable,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Substitutible,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // No Slack
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // No Holdup
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Ambiguity,
            ];
        
        case "Lifestyle Small Business":
            return [
                // first array element describes whether center of bullseye is selected
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Core Competence
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // New Combination
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Product/Market Match
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Net Buyer Benefit
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Margins,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Volume,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Purchase Frequency
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Resources,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Imitable,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Substitutible,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Slack
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Holdup
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Ambiguity,
            ]
        
        case "Low Competence":
            return [
                // first array element describes whether center of bullseye is selected
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Core Competence
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // New Combination
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Product/Market Match
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Net Buyer Benefit
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Margins,
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Volume,
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Purchase Frequency
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Product Longevity
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Resources,
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Imitable,
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Substitutible,
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // No Slack
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // No Holdup
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Uncertainty,
                [ 0, 0, 0, 0, 0, 0, 0, 1, 0], // Ambiguity,
            ];

        case "Model Venture":
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // New Combination
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product/Market Match
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Margins,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Volume,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Resources,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Imitable,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Substitutible,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Slack
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Holdup
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Ambiguity,
            ];

        case "Research Project":
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 1, 1, 1, 1, 1, 1, 1, 1, 1], // New Combination
                [ 0, 0, 0, 0, 0, 0, 0, 0, 1], // Product/Market Match
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Margins,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Volume,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Resources,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Imitable,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Substitutible,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // No Slack
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // No Holdup
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Ambiguity,
            ];

        case "Struggling Proprietary":
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 1, 1, 1, 1, 0, 0, 0, 0, 0], // New Combination
                [ 1, 0, 0, 0, 1, 0, 0, 0, 0], // Product/Market Match
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Net Buyer Benefit
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Margins,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Volume,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Purchase Frequency
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Resources,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Imitable,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Substitutible,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Slack
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // No Holdup
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Ambiguity,
            ];

        case "Technology-based Success":
            return [
                // first array element describes whether center of bullseye is selected
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // New Combination
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product/Market Match
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Margins,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Volume,
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Resources,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Imitable,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Substitutible,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Slack
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // No Holdup
                [ 1, 0, 0, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 0, 0, 1, 0, 0, 0, 0], // Ambiguity,
            ];

        default:
            return [
                // first array element describes whether center of bullseye is selected
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Core Competence
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // New Combination
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Product/Market Match
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Net Buyer Benefit
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Margins,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Volume,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Purchase Frequency
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Product Longevity
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Resources,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Imitable,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Substitutible,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // No Slack
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // No Holdup
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Uncertainty,
                [ 0, 0, 0, 0, 0, 0, 0, 0, 0], // Ambiguity,
            ]
    }
}