import React from 'react';

export const ProfileOverview = (props) => {
    return <section>
	<h1 className="pageTitle">The New Venture Profile™</h1>
    <p className="normalText">The <b><i>Individual Entrepreneurial Expertise Assessment</i></b>, is determined by the <b><i>New Venture Profile™</i></b> technology.&nbsp; These tools have been created to assist the entrepreneur, whether already in business, or just beginning to think about becoming involved with a new business.&nbsp; The completed questionnaire and this guide to interpretation have been&nbsp; designed especially to evaluate the individual's <b><i>readiness</i></b> to venture, but may be useful to a wider range of interested parties including a partner, venturing team member, spouse,&nbsp; financial backers, potential investors, and bankers.&nbsp; Each should find the information provided in this assessment to be of interest and potentially valuable when dealing with the very specialized requirements of a new venture.&nbsp; It may also illuminate the person's "blindspots" in venturing, perhaps saving valuable time, money, or both.
                </p>
	<p className="sectionHeading1">Two Sets of Results</p>
    <p className="sectionHeading2">(1)&nbsp; The Venturing&nbsp; "TYPE"</p>
                <ul>
                    <p className="normalText">The key difference between the venturing profile type and the more commonly recognized "personality type" is that a venturing type <b><u>can change</u>, whereas personality types generally remain 
                        relatively static.&nbsp; The venturing profile type defines only the starting point in the venture expertise enhancement process, and will change as expertise develops.</b></p>
                    <p className="normalText">This assessment maps 16 venturing types.&nbsp; The assessment results will identify the basic outline of the individual's personal venturing posture, as well as what needs to be done to enhance 
                        entrepreneurial expertise and, therefore, improve chances for success.</p>
                </ul>
    <p className="sectionHeading2">(2)&nbsp; Projected Venturing&nbsp; "ROLE"</p>
                <ul>
                    <p className="normalText">There are eight defined roles in the venturing process.&nbsp; The assessment results will indicate the role in which the person is presently most likely to be successful.&nbsp; The real value of the 
                        projected venturing role is in assisting individuals to understand their strengths and in evaluating areas where they might need to involve others with additional expertise, or seek training to increase their chances of 
                        success.&nbsp; This projected role is a "snapshot" that helps to define a starting point in the enhancement of their personal venturing expertise.<br/>&nbsp;</p>
                </ul>
    </section>
}