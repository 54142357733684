import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const HighPotential = () => {
    return <section>
	<h1 className="pageTitle">The High Potential Prototype</h1>
    <p className="normalText">
        The High Potential venture is an emerging company that is venture-backed,
        with a seasoned management team that possesses relevant experience.&nbsp;
        This type of venture is growing rapidly, and is characterized by proven
        market acceptance (significant sales).&nbsp; With growth comes the need
        to fund continued product development and marketing.</p>
    <p className="normalText">While this venture type has medium to high ratings in all categories, and as such is well balanced, there is room for improvement in each area.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 17: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="hp"/>
    <p className="sectionHeading2">Figure 18: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype={"High Potential"}/>
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">Work to increase <Link className="contentLink" to="/template/fundamentals/value"><b><i>Value</i></b></Link> and decrease <Link className="contentLink" to="/template/fundamentals/non-appropriability">Appropriability</Link>. This will increase "keeping money" =&gt; (which implies) <Link className="contentLink" to="/template/fundamentals/persistence">Persistence</Link> and the opportunity to utilize <Link className="contentLink" to="/template/fundamentals/flexibility">Flexibility</Link> to retain and adapt <Link className="contentLink" to="/template/viability/core-competence">Core Competencies</Link> and to foster <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link>.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">'Value Plus Heating &amp; Air Conditioning' -- <i>A High Potential Venture</i></p>
	<p className="normalText">Bill Detsneb was working feverishly in front of his home computer when his old friend and business associate, Peter Ganj, called on the phone.&nbsp; Detsneb, a CPA, had worked with Ganj on many projects in
        the past and thought highly of him.&nbsp; Over the years, Ganj had developed a unique talent for raising new
        venture capital. After a friendly greeting, Ganj asked for Bill's help in developing a business plan for a new
        venture to be called 'Value Plus Heating &amp; Air Conditioning.'&nbsp; The new business was to be run by a group
        of highly competent and motivated heating, ventilation, and air conditioning (HVAC) specialists.&nbsp; </p>
	<p className="sectionHeading2">BACKGROUND</p>
	<p className="normalText">Value Plus Heating &amp; Air Conditioning arose from the ashes of a company formerly known as 'Prime
                                Ventilation.'&nbsp; Prime, headed by David Sonne, had been a mid-sized, unionized HVAC company in Ohio that
                                had just recently filed for bankruptcy.&nbsp; Ironically, the company's successful bid for a large job in the eastern
                                United States led to its untimely demise.&nbsp; In April 1991, Prime secured a large contract at a site in New
                                York.&nbsp; From the onset, however, the job was plagued with problems.&nbsp; The unionized workers at the site,
                                representing the concrete, carpentry, electrical, and plumbing trades resented the Prime employees; they were perceived as a direct threat from outside the New York area to the stability and high wages that the
                                local unions had fought to gain for years.&nbsp; Their own the union workers made it very difficult for Prime to
                                operate efficiently.&nbsp; Sabotage, vandalism, verbal disputes, and even physical violence on the site hindered the
                                company from completing the job on time and on budget.&nbsp; Ultimately, supply damages and low worker
                                productivity dramatically increased working capital costs.&nbsp; After several months, Prime had little money left to pay its creditors, and the company filled chapter 11 bankruptcy.&nbsp; </p>
    <p className="normalText">Shortly afterward in Ohio State Bankruptcy Court, Prime Ventilation was poised for&nbsp; dissolution, when
                                David Sonne and a team of five lower-level Prime managers rushed in with a last minute proposal.&nbsp; They presented a business plan to the court judge for a new HVAC company.&nbsp; The company was to be called
                                Value Plus. It would be a new and improved HVAC firm – one with equal ownership, profit sharing, and continuing education.&nbsp; It was a refined model, born out of experience, and advanced by a group of diehard
                                employees who saw great potential in the existing company.&nbsp; Sonne and his team knew the HVAC industry well and saw a long-term need for the installation, replacement, maintenance, and update of HVAC
                                equipment.&nbsp; The group proposed many interesting changes, and they were determined to show the bankruptcy court that they had the skills and capabilities to make their idea work.&nbsp; The old organization was
                                highly structured, inefficient, and plagued by union hold-ups and poor decision-making.&nbsp; The new company on the other hand, was designed to overcome these drawbacks: better management, more effective
                                decision-making, no company union, and a flexible, cost-efficient, team- and-incentive-based work approach would make Value Plus a driving force in the HVAC industry.&nbsp; </p>
    <p className="normalText">With Detsneb's help in presenting the new venture, Sonne and his team were granted a second chance to
                                create a better, more competitive HVAC company.&nbsp; The six new partners agreed to mortgage their homes
                                to pay off the Prime creditors.&nbsp; The new venture, under the name of Value Plus Heating &amp; Air Conditioning,
                                became a legal entity, with the six team members retaining equal ownership.&nbsp; All experience requirements for
                                insurance were carried over from the previous company, and 'key-man' insurance was purchased to protect each of the newly-leveraged partners and their families from misfortune.&nbsp; Lower costs, greater overall
                                efficiency, greater control, and a highly skilled, competent, and non-unionized workforce gave Value Plus a competitive edge.&nbsp; The company became an immediate success, winning its very first bid for a lucrative
                                $280,000 contract in Ohio.&nbsp; Since costs for the project were only $40,000, Value Plus had banked almost a
                                quarter million before any work had even begun.&nbsp; Today, the company has doubled in size and, aside from one of the partners selling out, has remained extremely healthy.&nbsp; </p>
	<p className="sectionHeading2">THE CONCEPT</p>
	<p className="normalText">The HVAC business concept refined by Sonne and his team combined a number of new features, unique to the industry, to give the proposed company a competitive advantage in the marketplace.&nbsp; First, the new team
                                was made up of middle- and lower-level managers who witnessed all of the mistakes in Prime but were powerless to make any changes.&nbsp; Once elevated to the decision-making level, Sonne and the new managers
                                immediately replaced all of the unproductive upper-level people who did not understand the industry and/or did not know how to manage a HVAC company effectively.&nbsp; In addition, an outside board of directors was
                                created to advise on strategic and other issues affecting the company's survival, and to help ensure that it be
                                kept flexible and adaptable in times of change.&nbsp; Many of the mistakes in the old company occurred because management had a very limited and narrow strategic outlook.&nbsp; The new Value Plus executives were
                                committed to taking control; they wanted to secure their livelihoods and become a dominant force in the
                                industry, so they looked at every little thing that was wrong and fixed it.&nbsp; The new board, to which Detsneb
                                was invited to sit, was their attempt to create a diverse 'seedbed of vision' for the future, and included several of the owners' wives&nbsp; and a few other interested parties.&nbsp;&nbsp;&nbsp;&nbsp; </p>
    <p className="normalText">With the decision-making/planning issue resolved, the new managing team sought to reorganize the work
                                specificity of the company.&nbsp; They knew exactly how the work teams functioned and understood all of their
                                intricacies and complexities.&nbsp; With a little thought, the managers were able to put together a workforce that
                                could perform significantly better jobs.&nbsp; The assembly and installation processes were modified, and departmental communication was enhanced through cross-functional teams.&nbsp; Moreover, since state
                                government did not enforce unionization in the industry, Value Plus, unlike any other company in the industry,
                                was allowed to function without union interference.&nbsp; In turn, union hold-up problems were minimized.&nbsp; Even
                                though employee wage rates were freed from union intervention, they were set to be highly competitive and flexible. </p>
    <p className="normalText">Once the union issue was eliminated, employee remuneration could be made job-specific.&nbsp; An
                                incentive-based profit sharing plan was implemented which effectively lowered production costs.&nbsp; Before
                                bidding on a job, management would gather everyone together to decide on its individual worth.&nbsp; Employees then, would receive hourly rates of pay that were significantly lower than those paid out by a unionized
                                competitor.&nbsp; </p>
    <p className="normalText">Greater efficiency of work and lower hourly employee wages substantially reduced production costs.&nbsp; Value
                                Plus was now in a position to bid low on various contracts while still providing equal value to the customer.&nbsp;
                                When the job was complete and the profits&nbsp; shared by all members of the company, employees would receive their respective shares of any remaining money based upon hours worked and the nature of the
                                activity performed.&nbsp; Ultimately, the non-unionized Value Plus employees would earn more money than their
                                unionized counterparts.&nbsp; This incentive in turn reinforced the morale and productivity of the workers, allowing
                                the company to minimize costs further and distribute even greater profits to all within the company.&nbsp; By
                                minimizing their bounded rationality through effective planning and decision-making, Value Plus management
                                was able to fulfill the promise to their stakeholders.&nbsp; They were able to modify the work specificity of their
                                company and, in turn, make it cut-throat competitive.&nbsp; The end result was an operation significantly different from any other in the HVAC industry.</p>
    </section>
}