import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const IRLD = (props) => {
    return <section>
	<h1 className="pageTitle">IRLD - Dreamer</h1>
    <p className="normalText">The IRLD is generally quite perceptive, usually has some level of acquaintance with business problems in general, but lacks some of the new venture basics and the resources necessary for success in a new 
                                venture.&nbsp; The IRLD may have a sense that his or her venturing knowledge should be more lucrative than present rewards provide, but generally responds to these realizations with a "one of these days I'll 
                                get out there on my own" reaction.&nbsp; Based upon the predispositions of an IRLD, it would be disastrous if this were tried without some attention to enhancing expertise.</p>
    <p className="normalText">It is possible that many of the failed ventures which didn't survive their first year result from the abortive attempts of IRLD's to "get out of the nest".&nbsp; On occasion this 
                                may result from an IRLD being "pushed" by a "significant other", to make good on often expressed dreams.&nbsp; Until an active program is undertaken to improve new venture expertise, IRLD's 
                                (preliminary indications show that approximately 25% of people fit into this category), will likely find much less trauma in letting the venturing be undertaken by others. </p>
    <MatrixDiagram selected="IRLD" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">If you are an IRLD, and intend to undertake a new venture, there are several steps which should be taken before you venture.&nbsp; As a beginning, you should become acquainted with the new venture 
                                "success script".&nbsp; This could start with reading the biographies and case studies of both successful and 
                                unsuccessful entrepreneurs.&nbsp; It should include taking a course in entrepreneurship or new venturing, joining the local chapter of the entrepreneurs' association or venture group, and spending time learning 
                                from successful new venturers, venture capitalists, or institutional experts.</p>
    <p className="normalText">In short, undertake a program of <b>experiential learning</b>.&nbsp; Without this consciousness-enhancing 
		exposure, the chances of failure in a new business started by an IRLD remain unreasonably high.&nbsp; The 
        IRLD should try to create a model for a successful venturer&nbsp; -- or a success script, in his or her mind.&nbsp; 
        One way to do this is to begin to compile a new venture "do" and "don't" list to use as a basis for building the well organized knowledge structure required for success in a new business.</p>
    </section>
}
