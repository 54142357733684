import React from 'react';
import { Disclaimer } from './Disclaimer.component';

export const Mentor = (props) => {
    return <section>
	<h1 className="pageTitle">Mentor Role</h1>
        <img src={require('../../assets/roles-mentor.gif')} alt="Mentor" />
	<p className="normalText">
        An individual who scores high on both the "Arrangements" scale
        and the "Opportunity-Ability" scale, but low on the "Willingness"
        scale demonstrates a high New Venture Formation (NVF) infrastructure and
        knowledge, without necessarily possessing the motivation to sustain involvement
        in a new venture.&nbsp; As a result, this type of individual can make
        an invaluable contribution to new venture (NV) initiation as a mentor--helping
        with capital, contacts and sometimes technology, as well as with NV knowledge-based
        advice.</p>

    <p className="normalText">When this type of individual initiates a venture, it may be more conceptually
        and resource driven.&nbsp; At times this type of venture may be found
        to lack staying power, because of the missing motivational component (indicated
        by a low score on the Willingness scale).&nbsp; In terms of Expert Information
        Processing Theory (EIPT), this type of individual can likely accomplish
        script "entry," and can begin but not necessarily complete the
        "doing" requirements of a NVF expert script.</p>
    <p className="normalText">&nbsp;</p>
        <Disclaimer />
    </section>
}