import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {NVTemplateResultsGraph} from '../svg/NVTemplateResultsGraph.component';
import {rootUrl} from '../utils/rootUrl';
import {Loader} from '../Loader/Loader.component';

import "./TemplateResultsOverview.css";
import {BuyAJob} from './TemplateResultsAdvice/BuyAJob.component';
import {Charity} from './TemplateResultsAdvice/Charity.component';
import {CompetenceBasedSuccess} from './TemplateResultsAdvice/CompetenceBasedSuccess.component';
import {CompetenceBasedTroubled} from './TemplateResultsAdvice/CompetenceBasedTroubled.component';
import {Fad} from './TemplateResultsAdvice/Fad.component';
import {HighPotential} from './TemplateResultsAdvice/HighPotential.component';
import {Hobby} from './TemplateResultsAdvice/Hobby.component';
import {Hostage} from './TemplateResultsAdvice/Hostage.component';
import {LifestyleSmallBusiness} from './TemplateResultsAdvice/LifestyleSmallBusiness.component';
import {LowCompetence} from './TemplateResultsAdvice/LowCompetence.component';
import {ModelVenture} from './TemplateResultsAdvice/ModelVenture.component';
import {ResearchProject} from './TemplateResultsAdvice/ResearchProject.component';
import {StrugglingProprietary} from './TemplateResultsAdvice/StrugglingProprietary.component';
import {TechnologyBasedSuccess} from './TemplateResultsAdvice/TechnologyBasedSuccess.component';
import {AuthRequired} from '../utils/AuthRequired.component';
import {HasPermission} from '../utils/HasPermission.component';
import {SelectNVT} from './SelectNVT.component';


export function TemplateResultsOverview(props) {
    const [nvtResults, setNVTResults] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(() => { const saved = localStorage.getItem('nvt.template_id');
    const initialValue = JSON.parse(saved);
    return initialValue || null;
    });
    const [b, setB] = useState("");
    const [k, setK] = useState("");
    const [selectedPrototypeId, setSelectedPrototypeId] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState([]);

    useEffect(() => {
        if (loading) {
            getNVTResults()

            async function getNVTResults() {
                try {
                    const response = await fetch(`${rootUrl}/api/nvt`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME)}`
                        },
                    })
                    if (!response.ok) {throw new Error(response.error)}
                    const parsedResponse = await response.json()
                    setNVTResults(parsedResponse)
                    return setLoading(false);
                } catch (error) {
                    setError(error)
                }
            }
        }
    }, [loading])

    useEffect(() => {
        if (nvtResults && nvtResults.length > 0 && !selectedTemplateId) {
            const selected = nvtResults[nvtResults.length - 1].template_id
            return setSelectedTemplateId(selected)
        }
    }, [nvtResults, selectedTemplateId])

    useEffect(() => {
        if (nvtResults && nvtResults.length > 0 && selectedTemplateId) {
            const selected = nvtResults.filter(nvt => nvt.template_id === selectedTemplateId)[0]
            setB(selected.b_value)
            setK(selected.k_value)
            setSelectedPrototypeId(selected.prototype_id)
        }
    }, [selectedTemplateId, nvtResults])

    return <AuthRequired>
        <HasPermission requiredPermissions={["nvt"]}>
            {loading && <Loader>Loading your NVT results</Loader>}
            {!loading && nvtResults.length === 0 && <h2>You have not taken the NVT yet. <Link to="/authenticated/NVT">Click here</Link> to take the assessment.</h2>}
            {!loading && nvtResults && nvtResults.length > 0 && <>
                <h1>Template Results</h1>
                {error && error.length > 0 && <span>{"An error occurred: " + JSON.stringify(error)}</span>}
                <SelectNVT nvtResults={nvtResults} setSelectedNVT={setSelectedTemplateId} selectedTemplateId={selectedTemplateId} />
                <div style={{display: "flex", flexWrap: "wrap", alignItems: "baseline"}}>
                    <CorrelationsTable nvtResults={nvtResults} selectedTemplateId={selectedTemplateId} />
                    <div><h2>Graph</h2>
                        <NVTemplateResultsGraph b={b} k={k} selectedPrototype={selectedPrototypeId} />
                    </div>
                    <div style={{display: "flex", flexDirection: "column", flexWrap: "wrap", alignItems: "baseline"}}>
                        <ExplanatoryText selectedTemplateId={selectedTemplateId} nvtResults={nvtResults} selectedPrototypeId={selectedPrototypeId} />
                    </div>
                </div></>}
        </HasPermission>
    </AuthRequired>
}




const CorrelationsTable = (props) => {
    const {nvtResults, selectedTemplateId} = props;
    if (nvtResults.length === 0) return ""
    const relevantNVT = nvtResults.filter(nvt => nvt.template_id === selectedTemplateId)[0];
    const coeffs = {
        "Buy-job": relevantNVT.bajsb_coef,
        "Charity": relevantNVT.ch_coef,
        "Low Competence": relevantNVT.lc_coef,
        "Competence-based Troubled": relevantNVT.cbt_coef,
        "Fad": relevantNVT.f_coef,
        "Hobby": relevantNVT.hob_coef,
        "Hostage": relevantNVT.hos_coef,
        "Struggling Proprietary": relevantNVT.sp_coef,
        "Lifestyle": relevantNVT.lsb_coef,
        "Competence-based Success": relevantNVT.cbs_coef,
        "Technology-based Success": relevantNVT.tbs_coef,
        "High Potential": relevantNVT.hp_coef,
        "Model": relevantNVT.mv_coef,
        "Research Project": relevantNVT.rp_coef,
    }

    return <div className="correlation-table-container"><h2>Correlation Coefficients</h2>
        <table>
            <tbody>

                {Object.keys(coeffs).sort((a, b) => {
                    return Number(coeffs[a]) < Number(coeffs[b]) ? 1 : -1
                }).map((coeff, i) => {
                    return <tr key={coeff} className={i === 0 ? "top-prototype-correlation" : ""}>
                        <td>{coeffs[coeff] + "%"}</td>
                        <td>{coeff}</td>
                    </tr>
                })}
                <tr key="b,k">
                    <td colSpan="2">(B, K) = ({relevantNVT.b_value}, {relevantNVT.k_value})</td>
                </tr>
            </tbody>
        </table>
    </div>
};

const ExplanatoryText = (props) => {
    const {selectedPrototypeId} = props

    if (selectedPrototypeId === "bajsb") return <BuyAJob />
    if (selectedPrototypeId === "ch") return <Charity />
    if (selectedPrototypeId === "cbs") return <CompetenceBasedSuccess />
    if (selectedPrototypeId === "cbt") return <CompetenceBasedTroubled />
    if (selectedPrototypeId === "f") return <Fad />
    if (selectedPrototypeId === "hp") return <HighPotential />
    if (selectedPrototypeId === "hob") return <Hobby />
    if (selectedPrototypeId === "hos") return <Hostage />
    if (selectedPrototypeId === "lsb") return <LifestyleSmallBusiness />
    if (selectedPrototypeId === "lc") return <LowCompetence />
    if (selectedPrototypeId === "mv") return <ModelVenture />
    if (selectedPrototypeId === "rp") return <ResearchProject />
    if (selectedPrototypeId === "sp") return <StrugglingProprietary />
    if (selectedPrototypeId === "tbs") return <TechnologyBasedSuccess />

    return 'No prototype ID calculated for this venture, or no venture is selected for viewing.'
};