import React from 'react';

export function Hobby(props) {
        return <><p className="sectionHeading1">Hobby Venture</p>

                <p className="normalText">Based on interest in an area that borders on or is "love," a hobby is a high
                core competence activity.&nbsp; Innovation levels for hobbies vary depending upon the
          individual and the hobby. </p>

                <p className="normalText">Because of the uniqueness of each individual, scarcity and appropriability are
          virtually non-existent and uncertainty and ambiguity are low. </p>

                <p className="normalText">But a hobby is NOT a business because there is no PMM, NBB, margin, volume, frequency,
          long-term need, or BUSINESS resources. </p>

                <p className="normalText">ADVICE: To convert a hobby into a venture, the 1st three subquestions need to be
                addressed.&nbsp; In other words, the BUSINESS aspects of the activity must be the focus.
                &nbsp; Start with NBB and PMM.&nbsp; If there is a market, then you can start to focus on
                the cost / volume / profit calculation.&nbsp; Once these are attended to, you can then
                work on the "persistence" items of frequency, long-term need, and ongoing
          resources. </p>
        </>


}
