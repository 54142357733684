import React from 'react'
import { Disclaimer } from './Disclaimer.component';

export const Incubator = (props) => {
    return <section>
	<h1 className="pageTitle">Incubator Role</h1>
        <img src={require('../../assets/roles-incubator.gif')} alt="Incubator" />
			  <p className="normalText">
        An individual who scores high on both the "Willingness" scale
        and the "Opportunity-Ability" scale, but low on the "Arrangements"
        scale demonstrates a high New Venture Formation (NVF) motivation and knowledge
        unaccompanied by the resource infrastructure necessary to ensure new venture
        (NV) actualization.&nbsp; Such an individual will have knowledge, ideas
        and motivation, but will often lack the resources necessary to bring about
        the formation of a new venture.&nbsp; Thus, for this type of individual,
        venture opportunities are "incubated" or put on hold until the
        resources are located--but while in incubation, intense effort to make
        the "arrangements" can be expected to be underway.</p>

      <p className="normalText">In terms of Expert Information Processing Theory (EIPT), this type of
        individual can accomplish the "doing" requirements of a NVF
        expert script, but will be held up due to lack of the necessary "entry"
        arrangements.</p>
      <p className="normalText">&nbsp;</p>
        <Disclaimer />
    </section>
}