import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';
import "./EntrepreneurTypesOverview.css";
import { Link } from 'react-router-dom';


export const EntrepreneurTypesOverview = (props) => {
    return <section>
        <h1 className="pageTitle">Venture Profile Types</h1>
        <p className="normalText">The questionnaire evaluates four main constructs which are linked to new venture expertise.&nbsp; They derive from the differences in knowledge structure which have been documented between experts and novices.&nbsp; This knowledge structure is termed the "expert script".&nbsp; The questionnaire asks questions which determine:
	</p>
    <div className="capitals-section">
        <div>

        <div className="capitals-container">
            <div className="capitals-left-word-container">
                <span className="sectionHeading1">C</span><span>ONSCIOUSNESS</span>
            </div>
            <div className="capitals-vs-container">vs</div>
            <div className="capitals-right-word-container">
                <span className="sectionHeading1">I</span><span >NTUITION</span>
            </div>
            <div className="capitals-description-container">with respect to the entrepreneurial script;</div>
        </div>
        <div className="capitals-container">
            <div className="capitals-left-word-container">
                <span className="sectionHeading1">E</span><span>AGERNESS</span>
            </div>
            <div className="capitals-vs-container">vs</div>
            <div className="capitals-right-word-container">
                <span className="sectionHeading1">R</span><span>ELUCTANCE</span>
            </div>
            <div  className="capitals-description-container">to "enter" the new venture success script;
            </div>
        </div>
        <div className="capitals-container">
            <div className="capitals-left-word-container">
                <span className="sectionHeading1">A</span><span >BILITY</span>
            </div>
            <div className="capitals-vs-container">vs</div>
            <div className="capitals-right-word-container">
            <span className="sectionHeading1">L</span><span >IMITATION</span>
            </div>
            <div className="capitals-description-container">
            to execute the requirements of the script;
            </div>
        </div>
        <div className="capitals-container">
            <div className="capitals-left-word-container">
                <span className="sectionHeading1">D</span><span >ISCERNMENT</span></div>
            <div className="capitals-vs-container">vs</div>
            <div className="capitals-right-word-container"><span className="sectionHeading1">G</span><span >UESSING</span></div>
            <div className="capitals-description-container">to determine whether a new venture is "on" or "off" course.</div>
        </div>
    </div>
    </div>
        <p className="normalText">The matrix which follows combines each of these four constructs to indicate the sixteen possible new venture types.&nbsp;
           The new venturers who possess a higher number of the "C", "W", "A" and "D" profile elements tend to be more "expert"
	   in new venturing.&nbsp; These types are shown in the <b>TARGET ZONE</b> at the center of the matrix.&nbsp; The potential
                 new venturers who possess fewer of these profile elements are shown on the perimeter of the matrix.&nbsp; They tend to be
	   more in the <b>RISK ZONE</b>.
	</p>
        <MatrixDiagram />
        <p className="sectionHeading1">VENTURE "TYPES"</p>
        <div style={{ display: "flex", justifyContent: "space-around"}}>
            <div>
            <p className="normalText">(1) <Link className="contentLink" to="/profile/entrepreneur-types/IELG">I E L G</Link> -&nbsp; Over eager</p>
        <p className="normalText">(2) <Link className="contentLink" to="/profile/entrepreneur-types/IEAG">I E A G</Link>-&nbsp; Gambler</p>
        <p className="normalText">(3) <Link className="contentLink" to="/profile/entrepreneur-types/IRAG">I R A G</Link>-&nbsp; Cautious SB 1st Venturer</p>
        <p className="normalText">(4) <Link className="contentLink" to="/profile/entrepreneur-types/IRLG">I R L G</Link>-&nbsp; New Venture Novice</p>
        <p className="normalText">(5) <Link className="contentLink" to="/profile/entrepreneur-types/IELD">I E L D</Link>-&nbsp; Under-equipped</p>
        <p className="normalText">(6) <Link className="contentLink" to="/profile/entrepreneur-types/IEAD">I E A D</Link>-&nbsp; Intuitive Expert</p>
        <p className="normalText">(7) <Link className="contentLink" to="/profile/entrepreneur-types/IRAD">I R A D</Link>-&nbsp; SB Expert</p>
        <p className="normalText">(8) <Link className="contentLink" to="/profile/entrepreneur-types/IRLD">I R L D</Link>-&nbsp; Dreamer</p>
            </div>
            <div><p className="normalText">(9) <Link className="contentLink" to="/profile/entrepreneur-types/CELD">C E L D</Link>-&nbsp; Resource Poor</p>
        <p className="normalText">(10) <Link className="contentLink" to="/profile/entrepreneur-types/CEAD">C E A D</Link>- Classic Expert Entrepreneur</p>
        <p className="normalText">(11) <Link className="contentLink" to="/profile/entrepreneur-types/CRAD">C R A D</Link>-&nbsp; Institutional Expert</p>
        <p className="normalText">(12) <Link className="contentLink" to="/profile/entrepreneur-types/CRLD">C R L D</Link>-&nbsp; Idea Person</p>
        <p className="normalText">(13) <Link className="contentLink" to="/profile/entrepreneur-types/CELG">C E L G</Link>-&nbsp; Apprentice</p>
        <p className="normalText">(14) <Link className="contentLink" to="/profile/entrepreneur-types/CEAG">C E A G</Link>-&nbsp; In Over Their Head</p>
        <p className="normalText">(15) <Link className="contentLink" to="/profile/entrepreneur-types/CRAG">C R A G</Link>-&nbsp; Cautious Backer (<i>Angel</i>)</p>
        <p className="normalText">(16) <Link className="contentLink" to="/profile/entrepreneur-types/CRLG">C R L G</Link>-&nbsp; Armchair Quarterback</p></div>
        </div>
       
       
        <p className="sectionHeading1">SOME IMPLICATIONS OF THE <i>NEW VENTURE PROFILE™</i> TYPES</p>
        <p className="normalText">A <b>"C</b>" most likely indicates that you are aware or <b>conscious</b> of the common patterns which occur in entrepreneurial activity.&nbsp; Realization that there is a venturing "script" is a key ingredient in overall
               entrepreneurial expertise.&nbsp; This could come as a result of training, or from a general awareness which comes from experience.
	</p>
            <p className="normalText">A <b>"E</b>" signifies that you probably have
        a high desire or <b>eagerness</b> to be involved in business venturing,
        that you are ready to take action to make this a reality. <b>E</b> 's
                    generally have been "primed" to venture, most often from past
                    experiences either good or bad, and from their perception of their ability
                    to succeed in a particular deal or deal environment.&nbsp; They would
                    rather "plunge in" than miss a great opportunity.
	</p>
            <p className="normalText">An <b>"A</b>" probably means that you have resources (cash, technology, contacts etc.) <u>and</u> acumen which makes you <b>able</b> to be a "player" in the new venture arena.&nbsp; Often <b>A</b>'s assume that because they are "able", they are "capable", which is not always the case, and results in losses incurred but experience gained.&nbsp; <b>A</b>'s also have, or should understand that some kind of "bargaining advantage" is required for long-term venture success.
	</p>
            <p className="normalText">A <b>"D</b>" most often describes the level of capability you possess to <b>discern</b> whether conditions within and surrounding the venture <i>fit</i> the entrepreneurial success script.&nbsp;
                                <b>D</b>'s generally have a significant level of experience in a particular new business domain, and may often recognize more quickly what novices in this domain take a while to see.&nbsp; Often a <b>D</b> can have a larger, more-differentiated knowledge structure, with less biased recall of information relevant to particular new venture problems.
	</p>
            <p className="normalText">An <b>"I</b>" typically is unaware of and may not believe that an "entrepreneurial expert script" is possible.&nbsp;
                                I's usually make their venturing judgments based upon intuition, <b>instinct or their knowledge of human nature</b>.&nbsp; They may be unaware of the expert script which they actually follow when the script is
"acquired but unconscious".
	</p>
            <p className="normalText">An <b>"R</b>" means that you are <b>reluctant</b> to venture, perhaps more risk averse or cautious in economic endeavors.&nbsp; <b>R</b>
                's would rather not have to worry about venture risk, preferring instead to spend time and resources elsewhere.&nbsp; An <b>R</b> prefers to be thoroughly informed, and would rather get through life
                financially in one piece, than to "make it big".&nbsp;
	</p>
            <p className="normalText">An <b>"L</b>" probably means that you are somewhat <b>limited</b> in your capability to participate in new venture
                                activity.&nbsp; This may result from lack of sufficient resources, or from personal preferences and sphere of influence which are not as closely associated with the entrepreneurial community.&nbsp; An <b>L</b> may also indicate the lack of a venturing track record or small numbers bargaining advantage in competitive situations.
	</p>
            <p className="normalText">A <b>"G</b>" indicates that some <b>guesswork</b> is a part of your new venture profile.&nbsp; The odds go against
                                those who attempt a new business in "uncharted waters".&nbsp; Accordingly <b>G</b>'s should carefully consider the
                                risks associated with not "sticking to their knitting" (Peters &amp; Waterman, 1982).&nbsp; Also, a <b>G</b> might
indicate the propensity for you to unwisely consider diversification outside your "target zone" of extraordinary expertise, to be overconfident of your venturing ability, or to be a bit out of touch with
                                venturing realties.<br />&nbsp;
	</p>
    </section>
}
