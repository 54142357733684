import React from 'react';

export const ProductMarketMatch = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Is there a Product-market Match?</h1>
	<p className="normalText">Without a product-market match (PMM), an idea or product/service is NOT the basis for a business.&nbsp; Instead, product (or service) 
		without a market is the basis for either a research project or a charity.</p>
    <p className="normalText">This does not discount the value of research projects or charities. In fact, society is better off with these institutions.&nbsp; The problem occurs when an enterprise tries to continue without a match in the market 
                                for its product; when it insists that it be represented as a "business." </p>
    <p className="normalText">The critical test of a PMM is often quite simple. It begins with the question: Where is the purchase order? </p>
    <p className="normalText">A purchase order shows something important. It shows that someone in the "real" marketplace values your product/service more than they value their money (more on this in the next section).&nbsp; If you 
                                cannot show a purchase order, you do not have a business. </p>
    <p className="normalText">Now, the number of reasons, justifications, and excuses that novice venturers can concoct to avoid answering this question is almost infinite.&nbsp; But the point remains -- unless someone is willing to place an 
                                order for a product/service at an asking price, the product or service is not the basis for a business. </p>
    <p className="normalText">Therefore, be honest with yourself. A highly realistic assessment of product-market match minimizes the cost of unproductive new venture failure.&nbsp; If you answer this sub-question wisely and objectively, you can 
                                save yourself from emotional and financial anguish that results from venture failure.&nbsp; Resources can be conserved.&nbsp; Money and enthusiasm can be saved for the day when they can create wealth, instead of 
                                waste.&nbsp; And, if an entrepreneurial discovery is still in the research project stage -- then it can be funded as such, without the broken promises and disappointments that come from treating a research project 
                                as a business. </p>
    <p className="normalText">How does one go about determining whether there is a product-market match?&nbsp; The answers are as varied as the number of product and service ideas that are generated.&nbsp; There are, however, two or three 
                                standards to use you can conduct a "smell test."&nbsp; They are as follows: </p>
    <ul>
		<ol>
            <li className="normalText">If you wouldn't buy it yourself--there probably isn't a product-market match. <br/>&nbsp;</li>
            <li className="normalText">If a focus group of potential customers from the target market wouldn't buy it--there probably isn't a product-market match. <br/>&nbsp;</li>
			<li className="normalText">If customers in a test-market won't buy it, or if the purchasing agent of a distributor won't issue a purchase order--there probably isn't a product market match. </li>
        </ol>
    </ul>
    <p className="normalText">This is not to say that counter-examples (exceptions that prove these three tests wrong) don't exist. They do.&nbsp; But are you willing to bet at 100:1 odds (or worse) that you will get lucky despite the evidence -- 
                                or do you want to have your efforts and capital count for something? </p>
    <p className="normalText">If the answer to the product-market match sub-question is not yes, then don't go on. STOP.&nbsp; Fix the problem.&nbsp; And if the problem can't be fixed, then walk away (because you don't have a business anyway), or 
                                operate the effort as the research project or charity that it truly is.&nbsp; If you want a business, then find a product or service where there is a match with the market. </p>
    <p className="normalText">Yes, it is possible that someone else will take "your idea" and strike it rich.&nbsp; But the reality, if this happens, is that their situation and yours were not the same. </p>
    <p className="normalText">Ventures work because opportunities and entrepreneurs somehow fit each other.&nbsp; Realistically, in a lifetime each of us has several such "opportunity fits" presented to us. </p>
    <p className="normalText">So our job is not to pry ourselves into a deal in a "forced-fit" mode.&nbsp; The force-fit approach is a lot like trying to force the "ugly step-sister's foot" into Cinderella's glass 
                                slipper.&nbsp; It still doesn't fit despite all the effort and embarrassment.&nbsp; Instead, our job as venturers is to prepare for the time where the fit is right . . . and the key leading indicator is that the 
                                answer to the product-market match question is YES!&nbsp; If you have a product-market match you can go on and assess the other elements of the venture in logical order, confident that your further efforts will not 
                                be wasted. </p>
    <p className="normalText">The second sub-question (Remember, the first was: Is it a new-combination?) under Innovation is: Is there a product-market match?&nbsp; If the answer is not YES! (and your venture is a start-up) DON'T GO ON!&nbsp; 
                                Fix the problem or cut your losses. </p>
    <p className="normalText">However, if you have an ongoing business and the PMM rating is low, then how much of a business do you really have?&nbsp; Could you better spend your time on something else?&nbsp; Is this really a research 
                                project?&nbsp; Is it a charity, or is it a hobby?&nbsp; If so, then consider reorienting your efforts. </p>
    <p className="normalText">If time is better spent elsewhere, then cut your losses on this venture, and begin a new venture "search." </p>
    <p className="normalText">If you really do have a research project, then apply for the grants and acquire the assets needed to answer the research question(s). </p>
    <p className="normalText">If your project is a charity (i.e. it generates some revenue, but needs public support to keep going) then redirect your efforts to obtain tax-exempt status, and seek public support. </p>
    <p className="normalText">If your project is a hobby, then enjoy it as such--reap the "psychological income," and enjoy your achievements with other interested hobbyists. </p>
    <p className="normalText">But, if you don't want to reorient, and you want to build a better business venture than the one that is presently operating, then START WORK to find a better PMM.&nbsp; Examine each of the other questions in the New 
                                Venture Template I, to see where ideas that enhance PMM can be found.&nbsp; Can you add more value? more scarcity? etc. </p>
    <p className="normalText">But also, remember the risks.&nbsp; Lack of purchase orders (lack of PMM) means that the business is in trouble.&nbsp; There are too many stories of venturers who "cashed it in" (had to quit) because they 
                                spent too much time and energy on a no-win PMM problem. So if in doubt -- cut your losses now, and build a better venture on a sound PMM foundation.</p>
    </section>
}