import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import {NVTemplateResultsGraph} from '../../svg/NVTemplateResultsGraph.component';
import {Link} from 'react-router-dom';

export const CompetenceBasedTroubled = () => {

  return <section>
    <h1 className="pageTitle">The Competence-based Troubled Prototype</h1>
    <p className="normalText">Somewhat like a "Charity," the Competence-based venture in trouble combines HIGH <Link className="contentLink" to="/template/viability/core-competence">COMPETENCE</Link> with low <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>, <Link className="contentLink" to="/template/viability/margin">Margins</Link>, and <Link className="contentLink" to="/template/viability/volume">Volume</Link>, for something with a <Link className="contentLink" to="/template/viability/long-term-need">Long-term Need</Link>, in an environment of <Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link> and <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link>.</p>
    <p className="normalText">This venture is UNLIKE a Charity in that <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link> is high, as is <Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link>--with substantial control of <Link className="contentLink" to="/template/fundamentals/non-appropriability">Appropriability</Link>.&nbsp; Also, a <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link> is possible in this type of venture.</p>
    <div className="prototype-diagrams">
      <p className="sectionHeading2">Figure 25: "B/K" Diagram</p>
      <NVTemplateResultsGraph selectedPrototype="cbt" />
      <p className="sectionHeading2">Figure 26: Target "Bulls-eye" Diagram</p>
      <BullsEye prototype={"Competence-based Troubled"} />
    </div>
    <p className="sectionHeading1">ADVICE:</p>
    <p className="normalText">Focus on <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link> using <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link> as leverage, until a market is identified and proven.&nbsp; This, then, should set the stage for higher <Link className="contentLink" to="/template/viability/margin">Margins</Link> and <Link className="contentLink" to="/template/viability/volume">Volume</Link>.&nbsp; It should also permit the incentives and the small numbers bargaining advantage necessary to decrease <Link className="contentLink" to="/template/fundamentals/non-appropriability">Appropriability</Link>, <Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link>, and <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link>, and move closer to the Model Venture.
	</p>
    <p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
    <p className="sectionHeading2">Automated Translator -- <i>A Competence-Based Troubled Venture</i></p>
    <p className="normalText">Automated Translator had an ambitious vision: Automate language translation by creating translation
    software, and open the corners of the earth by breaking down language barriers.&nbsp; The undertaking began as
    a research project at an eastern university in the United States during the 1970s, on a $2 million donation.&nbsp;
    The development team was comprised of extremely intelligent people who were passionate about how such a translator could be a
		catalyst to open up communication and enhance information transfer.</p>
    <p className="normalText">In the 1980s, the team approached Matt Perrier, an experienced entrepreneur, to help obtain more funding.&nbsp;
    Matt knew that individuals would continue to need translation work periodically, and understood that global
                                communication would always be important.&nbsp; Matt decided to take the project under his wing, expecting to fund the venture over the next few years as the products were developed.</p>
    <p className="normalText">$15 million was raised initially, and the business hired several employees and executives to work on the
    project.&nbsp; By the mid 1980s, the team had created several math algorithms that could reliably translate
    characters and words into different languages.&nbsp; People showed interest in the product.&nbsp; The time saving
    benefits were obvious. By having a computer perform the bulk of the translations, the document could be left
    for higher level edit.&nbsp; As the product reached the market, Automated Translator realized a significant lack of
    purchase orders.&nbsp; Their new software could not run on IBM machines, and people were unwilling to purchase special hardware for the Automated Translator.&nbsp; The Automated Translator team did not plan for
                                the importance of programming in a language that could run on IBM machines.&nbsp; They didn't care for the IBM environment, and they found working in it 'restrictive'.</p>
    <p className="normalText">Money was running out, but Matt managed to replenish the coffers with investments from wealthy friends to
    keep the project going.&nbsp; Matt did not worry about substitute products flooding the market, because Automated Translator had a first mover advantage.&nbsp; 'Me too!' products would be about a dozen years
    behind in development, and their companies didn't have the skill composition of Automated Translator's technical team.&nbsp; After all, a good and accurate translator would be irreplaceable when translation was
                                necessary.&nbsp; Given that line of reasoning, no steps were taken to patent the software program.</p>
    <p className="normalText">The venture was not taking off as well as had been anticipated, mainly because of the IBM compatibility
    oversight.&nbsp; The programmers were unhappy about changing over to an IBM compatible programming language, but Matt finally persuaded them after a long and difficult battle.&nbsp; Internally, Matt also discovered
    that some members of the new staff members were not working very hard or producing enough to warrant
    their salaries.&nbsp; It was costly to fire them; several had huge 'golden parachutes' written into their contracts.&nbsp;
    Matt didn't want to keep slackers on the payroll for obvious reasons.&nbsp; He also had to make provisions for
    key person insurance, as the team had neglected this important step.&nbsp; In the end, the unique idea to open up
    worldwide inter-language communications failed to attract the needed volume.&nbsp; The team was so passionate
                                about the work that no contingency plans were made for the possibility that others might not hold the project in the same regard.</p>
  </section>
}