import React from 'react'
import { Disclaimer } from './Disclaimer.component';

export const Initiator = (props) => {
    return <section>
	<h1 className="pageTitle">Initiator Role</h1>
        <img src={require('../../assets/roles-initiator.gif')} alt="Initiator" />
	<p className="normalText">
        An individual who scores high on both the "Arrangements" scale
        and the "Willingness" scale, but low on the "Opportunity-Ability"
        scale demonstrates a high New Venture Formation (NVF) infrastructure and
        motivation.&nbsp; Thus, venture initiation by such individuals is likely
        to occur.&nbsp; It is possible, however, that without the deep NVF knowledge
        (indicated by the "Opportunity-Ability" score), venture initiation
        may be somewhat premature, with the possible waste of NVF resources as
        the result.</p>

    <p className="normalText">If this type of individual is intent upon venturing, it would appear
        to be wise to undertake expertise enhancement activities.&nbsp; In terms
        of Expert Information Processing Theory (EIPT), this type of individual
        can likely accomplish script "entry," and can begin but not
        necessarily complete the "doing" requirements of a NVF expert
        script.</p>
    <p>&nbsp;</p>
        <Disclaimer />
    </section>
}