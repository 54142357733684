import React from 'react';
import { Disclaimer } from './Disclaimer.component';

export const NonVenturer = (props) => {
    return <section>
	<h1 className="pageTitle">Non-Venturer Role</h1>
        <img src={require('../../assets/roles-non-venturer.gif')} alt="Non-Venturer" />
	<p className="normalText">
        When neither a New Venture (NV) infrastructure, NV willingness, nor NV
        knowledge are present in an individual, the likelihood that such a person
        will successfully initiate a venture is slim.&nbsp; However, some new
        ventures that succeed are started by individuals in this novice group.&nbsp;
        In these cases, however, the "learning-curve" can be daunting,
        and many times NV failure is unavoidable.&nbsp; Individuals in this group
        have many options to improve their expertise before venturing.</p>

    <p className="normalText">Most often, the motivation to venture occurs first--often initiated by
        an acquaintance or family member who possesses and transfers the willingness
        to consider venturing.&nbsp; In such cases, the time and attention that
        is invested in building a venturing infrastructure, and in gaining new
        venture knowledge are well spent, since the validity of such motivational
        information may be questionable.&nbsp; In terms of Expert Information
        Processing Theory (EIPT), individuals in this group are novices--generally
        not prepared to either "enter" or to "do" the things
        required by NV expert scripts.</p>
    <p className="normalText">&nbsp;</p>
        <Disclaimer />
    </section>
}