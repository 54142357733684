import React from 'react';
import { Disclaimer } from './Disclaimer.component';

export const Promoter = (props) => {
    return <section>
	<h1 className="pageTitle">Promoter Role</h1>
        <img src={require('../../assets/roles-promoter.gif')} alt="Promoter" />
	<p className="normalText">
        With a high score on the "Willingness" scale, this type of individual
        shows high New Venture Formation (NVF) motivation in the absence of a
        NVF infrastructure and the specialized knowledge represented the "Opportunity
        - Ability" scale.&nbsp; If interested in NVF, this highly motivated
        individual would likely be able to contribute by emphasizing their action
        orientation.&nbsp; This is often accomplished in the role of NVF promoter
        (Stevenson et al., 1994).</p>
    <p className="normalText">In terms of Expert Information Processing Theory (EIPT), this type of
        individual is partially able to actualize the "doing" portion
        of a NVF script, but not necessarily "entry."</p>
    <p className="normalText">&nbsp;</p>
        <Disclaimer />
    </section>
}