import React from 'react';

export const NonSubstitutible = (props) => {
    return <section>
	<h1 className="pageTitle">Viability - Is it Non-Substitutable?</h1>
	<p className="normalText">Substitute products are those products that replace yours.&nbsp; Like imitators, 
        they do essentially the same thing as your product or service.&nbsp; Unlike imitators, they do it in a different way.&nbsp; 
		For example, a Chevrolet imitates a Ford, but an airplane substitutes for a Ford.&nbsp; Or, 
        a Reebok athletic shoe imitates a Nike, but bare feet substitute for a Nike.&nbsp; Generally substitute products operate 
		in a different industry, which is why they decrease demand.</p>
	<p className="normalText">The difference between an imitator and a substitute is critical, because the type of action that you must take changes, 
		depending upon whether the scarcity reducer is an imitator, or a substitute.&nbsp; As previously discussed, imitators can 
        be hindered by using isolating mechanisms.&nbsp; However, isolating mechanisms have little impact on substitutes, because 
		there are features of substitutes that remove demand from the industry.&nbsp; Here, then, is 
        the key point.&nbsp; Where imitators divide up the industry into smaller market shares, substitutes shrink the entire size of the industry. </p>
	<p className="normalText">So when truck transportation became popular in the 1960's and 70's, the demand for rail transportation dropped.&nbsp; 
		And even though a rail road had an excellent reputation in the rail business (an isolating mechanism within the industry) for example, 
		this did little (if anything) to hinder the reductions in demand caused by the increasing use of highway-borne freight.&nbsp; 
		What then can be done to protect demand from reduction due to substitutes? </p>
	<p className="normalText">Substitutability is curtailed through "positioning" in strategic relationships.&nbsp; Where position boundaries have 
		the possibility of permeability (Ghemawat, 1991) a strategic alliance with potential competitors can forestall 
        reduction in demand due to substitutes.&nbsp; That is, where you can somehow participate in the profits of the demand-reducing 
		substitute, you can effectively position your venture to prevent demand reduction. </p>
	<p className="normalText">This approach was successfully demonstrated by the railroads in our prior example which, when faced with demand reduction from 
		the highway transportation substitute, "permeated" or entered into the truck transportation industry by offering 
		"piggy-back" service transportation of semi-trailers on flat cars.&nbsp; These railroads effectively prevented the 
		erosion of demand for their service by co-opting (effectively appropriating) a portion of the business in the trucking industry.&nbsp; 
		The boundary between industries was permeable, because marginal long-haul costs for railroads are lower than for trucks. </p>
	<p className="normalText">Therefore, where the strategic alliances that prevent substitution are entered into, then the answer to the sub-question: 
		Is non-substitutable?, can be yes. </p>
	<p className="normalText">If you are evaluating an ongoing business, and substitutability is high, look for the possibility for a strategic alliance.&nbsp; 
		Begin by looking at the cost and efficiency structure, to ascertain where value can be added to products in the substitute 
        industry.&nbsp; Utilization of "value chain analysis" (Porter, 1985) is particularly helpful, since it highlights 
		the possibilities for strategic alliances in substitute industries.</p>
    </section>
}