import React, {useEffect, useState} from 'react';
import {rootUrl} from '../utils/rootUrl';
import './ViewClasses.css';
import {Loader} from '../Loader/Loader.component'
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import {Link} from 'react-router-dom';
import {HasPermission} from '../utils/HasPermission.component';
import {AuthRequired} from '../utils/AuthRequired.component';

export function ListClasses(props) {
  const [loading, setLoading] = useState(true);
  const [classList, setClassList] = useState([]);
  const [error, setError] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [selectedClassDetails, setSelectedClassDetails] = useState(null)
  const [loadingSelectedClass, setLoadingSelectedClass] = useState(true);
  const [triggerFetchClassList, setTriggerFetchClassList] = useState(false);

  useEffect(() => {
    if (loading) {
      getClasses();

      async function getClasses() {
        try {
          const response = await fetch(`${rootUrl}/api/admin/classes`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
              )}`
            }
          });
          if (!response.ok) {
            throw new Error(response.error);
          }
          const parsedResponse = await response.json();
          setClassList(parsedResponse.filter(c => c.class !== null));
          setLoading(false);
        } catch (error) {
          setError(error);
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    getStudentList();
    async function getStudentList() {
      if (selectedClass || triggerFetchClassList) {
        try {
          const response = await fetch(`${rootUrl}/api/admin/class`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
              )}`
            },
            body: JSON.stringify({
              classId: selectedClass
            })
          });
          if (!response.ok) {
            throw new Error(response.error);
          }
          const parsedResponse = await response.json();
          setSelectedClassDetails(parsedResponse);
          setLoadingSelectedClass(false);
        } catch (error) {
          setError(error);
        } finally {
          return setTriggerFetchClassList(false);
        }
      }
    }
  }, [selectedClass, triggerFetchClassList]);

  useEffect(() => {
    if (selectedStudent.username) {
      disableStudent()
      async function disableStudent() {
        try {
          const response = await fetch(`${rootUrl}/api/admin/students/disable`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_LOCAL_STORAGE_TOKEN_NAME
              )}`
            },
            body: JSON.stringify(selectedStudent)
          });
          if (!response.ok) {
            throw new Error(response.error);
          }
          setTriggerFetchClassList(true);
        } catch (error) {
          setError(error);
        } finally {
          return setSelectedStudent({});
        }
      }
    }
  }, [selectedStudent]);

  return (<AuthRequired>
    <HasPermission requiredPermissions={["admin"]}>
      {error && error}
      <section className="class-list-page-container">
        <h1>Your Classes</h1>
        <section className="class-list-page-main-container">
          <section className="class-list-buttons-container">
            <Link to="/authenticated/admin/create-users" className="class-list-general-button create-new-class-button">
              Create New
          </Link>
            {classList.map(classId => {
              // if (!classId.class) return null;
              return <button
                key={classId.class}
                className={`class-list-general-button class-list-button ${classId.class === selectedClass ? 'class-list-button-active' : undefined}`}
                onClick={() => setSelectedClass(classId.class)}
              >
                {classId.class}
              </button>
            })}
          </section>
          <section className="class-list-students-container">
            {!selectedClass && <p>Select a class to view students.</p>}
            {selectedClass && loadingSelectedClass && <Loader>Loading list for {selectedClass}</Loader>}
            {selectedClass && !loadingSelectedClass && (<>
              <h1>Viewing: {selectedClass}</h1>
              <table style={{width: "100%"}}>
                <tbody>
                  <tr>
                    <td>UserName</td>
                    <td>Password</td>
                    <td>NVT Access?</td>
                    <td>NVP Access?</td>
                    <td>Expires</td>
                    <td>Disable</td>
                  </tr>
                  {selectedClassDetails && selectedClassDetails.map(classMember => {
                    const ISODate = parseISO(classMember.expiration)
                    return <tr key={classMember.user_id}>
                      <td>{classMember.username}</td>
                      <td>{classMember.password}</td>
                      <td>{classMember.nvt_access ? "Yes" : "No"}</td>
                      <td>{classMember.nvp_access ? "Yes" : "No"}</td>
                      <td>{format(ISODate, 'MM/dd/yyyy')}</td>
                      <td>{classMember.disabled ? 'Disabled' : <button onClick={() => setSelectedStudent(classMember)}>
                        Disable
              </button>}</td>
                    </tr>
                  })}
                </tbody>
              </table></>)}
          </section>
        </section>
      </section>
    </HasPermission>
  </AuthRequired>
  );
}