import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthRequired } from '../utils/AuthRequired.component';

export function Authenticated(props){
	const hasAcceptedEULA = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_EULA_ACCEPTED) === 'true';
  if (!hasAcceptedEULA) return <Redirect to="/eula" />;
	return <AuthRequired>
	<h1 className="pageTitle">New Venture User Area</h1>
	<p className="normalText">Welcome to the New Venture Web Site User Area.  From these pages, you can:
		</p><ul>
			<li className="normalText">fill out the <Link className="contentLink" to="/authenticated/NVT">template questionnaire</Link></li>
			<li className="normalText">see your <Link className="contentLink" to="/authenticated/NVT-results">template analysis</Link></li>
			<li className="normalText"><Link className="contentLink" to="/authenticated/NVT-results-wheel">compare</Link> your venture against the high-potential venture</li>
			{/* <li className="normalText">fill out the <Link className="contentLink" to="/authenticated/NVP">profile questionnaire</Link></li> */}
			{/* <li className="normalText">see your <Link className="contentLink" to="/authenticated/NVP-results">profile results</Link></li> */}
		</ul>
    </AuthRequired>


}