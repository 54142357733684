import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const ResearchProject = () => {
    return <section>
	<h1 className="pageTitle">The Research Project Prototype</h1>
    <p className="normalText">The Research Project is not a business, nor is it intended to be a business.&nbsp; This is evident when you examine the prototype diagram:</p>
    <p className="normalText">First, because this is research, there is a lot that is not known, hence the blanks that appear for many of the sub-questions that are illustrated in the prototype diagram.</p>
    <p className="normalText">Second, the New Venture Template™ still reveals some interesting relationships.&nbsp;
		For example, ratings that show strong <Link className="contentLink" to="/template/viability/core-competence">Core Competence</Link> and level of <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link>, explain some of the reasons that research may be stimulated.</p>
    <p className="normalText">However, no <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link>
		=&gt; (implies) that this is NOT a business, and should not be treated, thought of, or managed as such.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 1: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="rp" />
    <p className="sectionHeading2">Figure 2: Target "Bulls-eye" Diagram</p>
    <BullsEye prototype="Research Project" />
    </div>
	<p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">Fund as a Research Project until
		Product Market Match
		is established. This means that the effort should NOT be
		represented as a business to investors.&nbsp; It also means that the effort could enjoy substantial funding,
        but funding that is NOT based upon any implied rate of return.</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">Richard Wazny -- <i>The Research Project</i></p>
    <p className="normalText">Richard Wazny was working on a doctorate degree in economics
        at the Harvard University.&nbsp; He had been awake for almost twenty-four
        hours and was ready to collapse.&nbsp; He had never been so excited about
        anything in his life.&nbsp; He was making some last minute modifications
        to his doctoral thesis – an examination of transaction cost economics
        that he started nearly nineteen months earlier.&nbsp; His thesis defense
        date was quickly approaching and he wanted everything to be perfect for
        his final presentation.&nbsp; Richard was confident that his research
        would not only pass, accrediting him with a Ph.D. designation, but also
        win him the respect of his peers globally.&nbsp; He was on the verge of
        something big.&nbsp; </p>
	<p className="sectionHeading2">THE WORK</p>
	<p className="normalText">Richard had dedicated years
        of his life exploring the conditions under which principles of transaction
        cost economics could be productively applied to the transition of command
        economies from the hierarchy to the market system. He discovered during
        his research, that systematic strategic action at three levels in a command
        society – the societal assumptions, the institutional, and the firm –
        may reduce human asset specificity and thus encourage a successful transition.
        Otherwise, asset specificity would compromise potential first order economic
        gains and in turn inhibit a successful transition.&nbsp; Richard argued,
        that firms need not fail when the basic market structure shifts from a
        command economy to a market system.</p>
    <p className="normalText">The implications of Richard's research were far and reaching.&nbsp; Easing the transition of a command economy
                                to a market system could potentially save nations from billions if not trillions in losses, not to mention complete degradation of the social and political landscape.&nbsp; Richard was ecstatic about contributing
                                something so significant to such a great cause.&nbsp; But what reward would <i>he</i> reap for his contribution to humankind?&nbsp; What compensation did <i>he</i> deserve for his extreme competence, hard work, and undeniable
                                perseverance?&nbsp; This was an aspect of academia that Richard had struggled with throughout his graduate and
                                post-graduate life.&nbsp; Aside from the honor, prestige, and perhaps the possibility of one-day winning a Nobel
                                Prize or equivalent award, Richard's magnificent contribution to posterity would go unrewarded.&nbsp; How could something so important and theoretically valuable to the world be worth so little in terms of
                                remuneration?&nbsp; It just didn't seem fair.&nbsp;&nbsp;&nbsp;&nbsp; </p>
    <p className="normalText">But Richard Wazny made a promise to himself before choosing to attend
        Harvard: he vowed that he would devote his talents not toward matters
        of material or financial gain but toward the betterment of the human race.&nbsp;
        This noble philosophical underpinning ultimately drove Richard in his
        pursuit of understanding.&nbsp; And understanding it was, indeed. Richard
        internalized his motives and, with the skill of highly qualified surgeon,
        meticulously dissected the wealth of information known in academic spheres
        as transaction cost economics.&nbsp; </p>
    <p className="normalText">Advancing upon the findings of leading authorities in the field, Richard combined a spectrum of knowledge
                                and understanding into a new strategic cohesive whole.&nbsp; It left nothing to sell, nothing to purchase, and
                                nothing to imitate; there was no substitute for it, no physical form to it, and no resource generated by it; it did
                                not have to plan for the future, nor did it need to be insured against the unplanned; there was no risk.&nbsp; It was
                                simply information – a higher level of understanding, always changing and evolving, and born out of a commitment from not one but many exceptional individuals over time.</p>
    <p className="normalText">Richard Wazny had no regrets.&nbsp; Understanding was <i>his</i> reward.</p>
    </section>
}