import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToContentTop({ history }) {
  useEffect(() => {
    const appHeaderHeight = document.getElementById("App-header").clientHeight
    const unlisten = history.listen(() => {
      window.scrollTo(0, appHeaderHeight || 0); // use height of top part of the page
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return (null);
}

export default withRouter(ScrollToContentTop);