import React from 'react';

export const ProfileReferences = (props) => {
    return <section>
	<p className="normalText">Following, are a few of the references cited, for those who wish to explore the research or theory upon which this software is based.</p>
    <p className="normalText">&nbsp;</p>
    <ul>
        <li className="normalText">Leddo, J., &amp; Abelson, R.P. (1986).&nbsp; The nature of&nbsp; explanations In J. A. Galambos, R. P. Abelson, &amp; J. B. Black (Eds.), Knowledge Structures 
			(pp. 103-122).&nbsp; Hillsdale NJ: Lawrence Erlbaum and Associates, Inc.</li>
    </ul>
    <p className="normalText">&nbsp;</p>
    <ul>
        <li className="normalText">Mitchell, R.K., &amp; Chesteen, S.A. (1995). Enhancing entrepreneurial expertise: Experiential pedagogy and the entrepreneurial expert script. Simulation Gaming, 26(3), 288-306.</li>
    </ul>
    <p className="normalText">&nbsp;</p>
    <ul>
		<li className="normalText">Stevenson, H.H., Roberts, M.J., &amp; Grousbeck, H.I. (1989, 1994). New Business Ventures and the Entrepreneur. Homewood, IL: Irwin.<br/>&nbsp;
    </li></ul>
    </section>
}