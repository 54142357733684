import React from 'react';
import BullsEye from '../../svg/BullsEye.component';
import { NVTemplateResultsGraph } from '../../svg/NVTemplateResultsGraph.component';
import { Link } from 'react-router-dom';

export const BuyAJob = () => {

  return <section>
	<h1 className="pageTitle">The Buy-A-Job Prototype</h1>
	<p className="normalText">Though this business appears to be perilously close to the "Low Competence" venture, it is really substantially different.&nbsp;
		Whereas the Low Competence venture is weak in all areas, this prototype venture is strong in the <Link className="contentLink" to="/template/fundamentals/persistence">Persistence</Link> variables, of <Link className="contentLink" to="/template/viability/repetitive">Frequency</Link> and  <Link className="contentLink" to="/template/viability/long-term-need">Product Longevity</Link>.&nbsp; Yet it should be emphasized that this strength does NOT come from the entrepreneur, but rather is a feature of the marketplace. This is where the "self-employed" jobs fit in.&nbsp; Thus, anyone who operates a personal service small business might fit into this type of venture.</p>
    <p className="normalText">This type of venture is referred to as a "Buy-A-Job" small business because of its weaknesses, which lead to the mere substitution with a long-hours "grind" working for oneself (one low-paying customer at a time), as opposed to working for a company (one low-paying boss at a time). This is a useful "fallback" plan for times of high unemployment, but it is not entrepreneurial, because it does not create jobs or wealth for others.</p> <p className="normalText">In a Buy-A-Job venture, weak <Link className="contentLink" to="/template/viability/core-competence">Core Competence</Link> =&gt; (implies) low <Link className="contentLink" to="/template/viability/new-combination">New Combination</Link>,
    low <Link className="contentLink" to="/template/fundamentals/scarcity">Scarcity</Link>,
    high <Link className="contentLink" to="/template/viability/no-holdup">Hold-up</Link>, <Link className="contentLink" to="/template/viability/uncertainty">Uncertainty</Link> and <Link className="contentLink" to="/template/viability/ambiguity">Ambiguity</Link> with medium <Link className="contentLink" to="/template/viability/no-slack">Slack</Link>.&nbsp; Low <Link className="contentLink" to="/template/fundamentals/innovation">Innovation</Link> and medium <Link className="contentLink" to="/template/viability/product-market-match">Product Market Match</Link> lead to low <Link className="contentLink" to="/template/viability/net-buyer-benefit">Net Buyer Benefit</Link> and <Link className="contentLink" to="/template/viability/margin">Margins</Link>.</p>
    <div className="prototype-diagrams">
    <p className="sectionHeading2">Figure 9: "B/K" Diagram</p>
    <NVTemplateResultsGraph selectedPrototype="bajsb" />
    <p className="sectionHeading2">Figure 10:&nbsp; Target "Bulls-eye" Diagram</p>
	<p className="normalText"><BullsEye prototype={"Buy-a-job"}/></p></div>
  <p className="sectionHeading1">ADVICE:</p>
	<p className="normalText">Start by increasing <Link className="contentLink" to="/template/viability/core-competence"><b><i>Core Competence</i></b></Link> and with it <Link className="contentLink" to="/template/fundamentals/innovation"><b><i>Innovation</i></b></Link>, to move (at a minimum) to a Lifestyle venture. This essentially involves "pruning" the rough edges of the Buy-A-Job business (examine the outer rings of the target diagram that should be moved toward the "bull's eye.")
	</p>
	<p className="sectionHeading1">CASE STUDY EXAMPLE:</p>
	<p className="sectionHeading2">David Ennis Lawn Maintenance -- <i>Buy-a-Job</i></p>
	<p className="normalText">David Ennis flopped down on the couch.&nbsp; He'd just finished a long and tiring fifteen hour day.&nbsp; The day had
                                started with a call from an angry customer whose lawn was two days past due for its weekly cut.&nbsp; The
                                customer's mother-in-law was expected to arrive that morning to stay for the weekend.&nbsp; Last time David cut
                                it, he accidentally took out a wild rose that was growing beside the lawn.&nbsp;&nbsp; He got an earful!&nbsp; The phone call
                                was the culmination of what had been an exceptionally bad week, and it left little impression on him, because
                                he just didn't care anymore.&nbsp; One thing David had realized about being his own boss, he could tell off any
                                customer he wanted.&nbsp; In his mind, this personal freedom and independence still outweighed his disdain of working for others.</p>
	<p className="sectionHeading2">BACKGROUND</p>
	<p className="normalText">David Ennis finished high school in the spring of 1996, and entered the Radio and Television Broadcasting
                                program at the Southern Alberta Institute of Technology (S.A.I.T.) that fall.&nbsp;&nbsp; Living at home kept his
                                expenses down, and the food was far better than he could make on his own.&nbsp; David had worked for a couple of contracting companies the past two summers, doing mostly general labor.&nbsp; During the school year
                                David spent a significant amount of time in the weight room, working out and gaining size.&nbsp; Over the last two
                                years, he added 50 lbs. of pure muscle.&nbsp; Working outside allowed him to show off this proud achievement, improving his tan in the process. </p>
    <p className="normalText">After losing his job when his landscaping employer folded early in the summer of 1998, David decided to
                                venture out on his own.&nbsp; David remembered canvassing the neighborhood with a shovel after heavy snowfalls, moving snow for pocket cash.&nbsp; The same thing would likely work for lawn maintenance, he
                                figured.&nbsp; David's family had an old electric lawn mower, a WeedEater, and a hedge trimmer.&nbsp; He thought, 'If
                                I can get $12 to cut a lawn, and it takes me an hour to do it, I'm making $12 an hour. I'm going out on my
                                own!'&nbsp; Jack put on a nice shirt, combed his hair, and drove about 10 minutes to Rosedale, an upper middle class neighborhood overlooking downtown Calgary. </p>
    <p className="normalText">David decided to start with a nice house on a well-treed block.&nbsp; The lawn was a little out of control, and at a
                                glance he thought he could do a lot around the place.&nbsp; He knocked on the door, and when the owner answered David introduced himself and said, 'I'm cutting grass for summer work.&nbsp; Could I cut your lawn for
                                you tomorrow?'&nbsp; The owner looked at him with a sideways glance, shook his head and said, 'No thanks,
                                my son does it.'&nbsp; David took the rejection gracefully, and continued down the block.&nbsp; After about five more
                                similar rejections, David was reaching the corner.&nbsp; One more house, then he'd go back and re-think his
                                strategy.&nbsp; A smiling face opened the door, and listened intently to his spiel.&nbsp; She said, 'well, yes, I do need
                                someone to do my lawn.&nbsp; Do you do hedges and gardens too?'&nbsp; David was on a roll.&nbsp; He walked back to
                                his car with a $100 job to cut the grass, trim the hedges, weed the beds, put away some storm windows, and clean the rain gutters.&nbsp; David started at 8:00 Monday morning, took a half-hour for lunch, and finished
                                the last flower bed at 6:30 PM.&nbsp; During the early evening, a neighbor walking his dog stopped by and asked
                                David for a quote to trim some hedges.&nbsp; David told him $24, figuring on about two hours to do the work.&nbsp; Nothing was going to stop him now.</p>
    <p className="normalText">The summer proceeded very much the same way.&nbsp; Work all day, come home around 6:00 pm, get cleaned
                                up. David went canvassing for new work three to four nights a week. Some nights he'd come home empty handed, but most nights he'd find at least one or two people who needed some work done, and were willing
                                to pay his princely $12/hour rates.&nbsp; Sometimes he'd bargain with the cheap customers to make sure he had
                                work the next day.&nbsp; One night a guy asked him to quote an entire interlocking brick walkway.&nbsp; David went
                                home and spent six hours that evening working out a quote.&nbsp; Although David had never built a walkway on
                                his own before, the client hired him to start that afternoon.&nbsp; David felt uncomfortable asking for cash up front,
                                since the client was already being generous and trusting enough to let David take on the job knowing he had
                                no previous experience.&nbsp; So he scraped up his cash and max'd his Visa to purchase the materials.&nbsp; David
                                planned for the job to take four days, but in fact it took five and a half; David wanted to ensure the product was the best he could do.&nbsp; He only billed the client for four days work, as he stated in the quote.
    </p>
    <p className="normalText">At the end of June, David looked at his bank account and decided he had enough cash to go out and get
                                some better tools.&nbsp; The old electric mower was making a strange noise, and one wheel had fallen off just last
                                week.&nbsp; The line trimmer was old and slow, and the hedge shears had zapped him one too many times while
                                working in the rain.&nbsp; Hauling all this stuff around in an 82' Honda Civic was a little rough too.&nbsp; Pieces of
                                grass clippings and hedge trimmings were all over the car.&nbsp; David had managed to find time for a date last
                                weekend, but before going out he had to spend a good two hours cleaning the car out.&nbsp; The last lawn of the
                                day belonged to lady with a big dog, and the mower hit something in the grass while David wasn't watching.&nbsp; A foul smell permeated everything in the car, and it took two pine tree air fresheners to cover it.</p>
    <p className="normalText">One of David's customers, a widow who lost her husband two years earlier, had a 1979 Ford F250 sitting
                                on a pad behind the house.&nbsp; The truck hadn't moved in ages; the husband lost his license three years before
                                he died.&nbsp; It was full of crud and junk.&nbsp; David negotiated a deal with the customer to cut her grass for the rest
                                of the summer, and to do some gardening and hedge clipping in exchange for the truck. David paid a mechanic $300 to do the brakes, and then completed the rest of the work on the truck himself at a cost of about $345.
    </p>
    <p className="normalText">David went to the local lawn mower shop.&nbsp; He picked a quality commercial hedge trimmer.&nbsp; The shop
                                owner offered to take $15 off the price of the hedge trimmer.&nbsp; He stated, 'Well, after you take the $15 off the top, you're down to $484, and you're gonna get half that back from the government anyway with your
                                tax return, plus the GST.'&nbsp; David left with the new hedge trimmer, confident that he'd made the right decision.&nbsp; His tools were his livelihood, and business was taking off big time.</p>
    <p className="normalText">July is one of the nicest months in Calgary.&nbsp; The Stampede comes to town during the first half of the month,
                                and everything grinds to a halt.&nbsp; Nobody does any work in this bustling town for 9 days.&nbsp; It's impossible to
                                find people in their offices, as many are volunteering, partying and visiting the fairgrounds.&nbsp; Events take place
                                all over the city.&nbsp; Anyone who can get out of the house before 7 am will have their choice of several free
                                pancake breakfasts, and tail gate beef on a bun dinners are nearly as plentiful.&nbsp; The slow down impacted
                                David as well.&nbsp; Just when he geared up with the right tools for the job, the work started slowing down.&nbsp; Canvassing was useless because no one was home, and when he did find someone they usually had
                                company and were uninterested in his spiel.&nbsp; For the first two weeks of July, David only found $400 worth
                                of work.&nbsp; By this time he had about 7 customers with contracts to cut their lawns on a weekly basis, which added $92 a week in revenues.</p>
    <p className="normalText">As he was drove home from home from an unsuccessful night of canvassing, a vibration developed in the
                                rear of the truck.&nbsp; David ignored it by turning up the radio, and smiling at a few girls who were admiring his
                                well-tanned biceps.&nbsp; After a few blocks, a thunderous crack followed by a slam and a loss of power
                                occurred.&nbsp; The truck ground to a halt at the side of the road.&nbsp; David hopped out and looked under the truck.&nbsp;
                                The driveshaft front U-joint had expired, and the driveshaft lay bent in the middle of the road 50 yards back.&nbsp;
                                Little greasy chunks of metal were still stuck to it.&nbsp; The bearings had rusted out in the two years the truck sat
                                unused.&nbsp; David wondered what else might be lurking mechanically speaking, as he walked to a pay phone to call a tow truck.</p>



    </section>
}