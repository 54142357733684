import React from 'react';
import { Disclaimer } from './Disclaimer.component';

export const Advisor = (props) => {
    return <section>
	<h1 className="pageTitle">Advisor Role</h1>
        <img src={require('../../assets/roles-advisor.gif')} alt="Advisor" />
	<p className="normalText">
				A high score on the "Opportunity-Ability" scale in the absence of high scores on the "Arrangements" or "Willingness"
                                scales demonstrates that this type individual has a high level of new venture formation (NVF) knowledge, unaccompanied by either the NVF infrastructure, or the motivation to actualize a new venture.</p>
    <p className="normalText">Accordingly, this type of individual could capably serve as an advisor to a venture without having to commit resources, or sustain venture motivation.&nbsp; Once again, in
                                terms of expert information processing theory (EIPT), this type of individual is partially able to actualize the "doing" portion of a NVF script, but not "entry."</p>
    <p className="normalText">&nbsp;</p>
   <Disclaimer />
    </section>
}