import React from 'react';
import { Link } from 'react-router-dom';

export const NonAppropriability = () => {

return <section>
<h1 className="pageTitle">The Fundamental of Non-Appropriability</h1>
<p className="normalText">
When a venture qualifies for a "yes" answer to each of the preceding
four questions, it is analogous to a producing mining claim in the California
"gold rush."&nbsp; The venture is so valuable, that in the free
enterprise system (generally a market free-for-all), those who are aware
of its value begin immediately, to try and "appropriate" the
profits for themselves. Miners in the gold rush had at least two sources
of appropriation risk: (1) the risk from "claim-jumpers" (those
who attempted to decrease or eliminate the size of the pie held by the
miner), and (2) the risk from outright thieves (those who did not find
the gold, but attempted to shrink the size of the slice kept by the miner).
<br/>
<br/>
So it is with venture profits.&nbsp; Once a venture is innovative, valuable,
persistent over time, and scarce, it is in danger from the claim jumpers
and thieves that lie in wait to appropriate the profits of the venture.&nbsp;
Unless the venture can be built to resist the demands of these powerful
market actors, the answer to question E: Is it Non-appropriable?"
will be NO--meaning don't go on, because you cannot keep your profits.&nbsp;
You must first take the steps to keep both the size of the pie, and the
size of your slice intact. <br/>
<br/>
Accordingly, the two questions that should be asked about venture appropriability
are: <br/>
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A. <Link className="contentLink" to="/template/viability/no-slack">Is there slack (waste &amp; inefficiency)?</Link> <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; B. <Link className="contentLink" to="/template/viability/no-holdup">Are there hold-up problems?</Link> <br/>
<br/>
Here a few citations from the academic literature on the subject of appropriability
may be helpful.&nbsp; The reader is invited to consult the articles referenced
for additional insight on this most difficult problem.&nbsp; As previously
noted, appropriability arises from at least two crucial circumstances:
<br/>
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1."slack" (Ghemawat, 1991) which
decreases the rents from a strategic position (waste &amp; inefficiency
making a smaller pie), and <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2."hold-up" (Williamson, 1985;
Ghemawat, 1991) which re-distributes gains among constituencies (decreasing
the size of the remaining slice to the company). <br/>
<br/>
Hold-up may be controlled through various social/relationship mechanisms
which include the establishment or invocation of norms, negotiation, contracting,
and posturing (Ghemawat, 1991).</p>
</section>
}