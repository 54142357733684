import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const CRAG = (props) => {
    return <section>
	<h1 className="pageTitle">CRAG - Cautious Backer</h1>
    <p className="normalText">Cautious Backers or Angels are traditional in the lore of entrepreneurship.&nbsp; Preliminarily 
		found in only 1% of the business population, these (usually private) investors know of the existence of the 
		venturing success script, generally, and also have the resources (including both money and/or time available 
		in their career track) to venture.&nbsp; Often they may become involved with a new venture on a whim, hunch, or 
        guess that it "just might fly".</p>
	<p className="normalText">Although conservative in nature, the presence of resources, a general knowledge of the 
		venturing sphere of activity, combined with a belief in the value of an "educated guess", 
        makes them good investors.&nbsp; They don't ask too many questions at a time where there are few answers which 
		aren't "fluff".&nbsp; They are often of high value to a venture.</p>
    <MatrixDiagram selected="CRAG" />
	<p className="sectionHeading1">WHAT TO DO:</p>
	<p className="normalText">If you are a CRAG, and desire to venture, you have many of the skills already present to do 
		so.&nbsp; You have a general knowledge of the entrepreneurial script, and most importantly you have the ability 
		to undertake new venture activity should you so desire.&nbsp; Your natural reluctance should drive you toward 
		acquiring <b>better information</b> to help you to optimize your educated guessing.</p>
    <p className="normalText">Eventually over time CRAG's may become CRAD's (Institutional Experts) and are invaluable to 
        venturers as advisors, cautious backers etc.&nbsp; If you choose to stay involved in venturing, your presence 
		within the venturing community should always be welcome.</p>
    </section>
}
