import React from 'react';

export function ModelVenture(props){
  return <><p className="sectionHeading1">Model Venture</p>

  <p className="normalText">When a venture is built so that each of the key elements is optimal, it is a model
          venture.&nbsp; This does not mean, however, that the venture is perfect.&nbsp; Instead, it
          means that the venture is optimally situated to fully take advantage of (1) venturer
          expertise, and (2) stakeholder support. </p>

  <p className="normalText">ADVICE: Model ventures by definition are self-observing, self-evaluating, and self-
          reinforcing.&nbsp; Hence, these behaviors should be encouraged.&nbsp; Also, entrepreneurs
          should utilize the foundation provided by a model venture to support refinements in
          venturer expertise, and in better managing relationships within their stakeholder
          environment. </p>
          </>
}