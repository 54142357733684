import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';

export const CELG = (props) => {
    return <section>
	<h1 className="pageTitle">CELG - Apprentice</h1>
	<p className="normalText">The CELG is a type of new venture novice whose learning curve hasn't 
      yet flattened out.&nbsp; This profile type is referred to as an "apprentice" 
      because of the interest and willingness on the part of a CELG to acquire 
      venturing skills.&nbsp; In essence, the CE orientation makes this type a 
      potential expert, but because the capability to discern the progress of 
      a new venture is missing, most wise CE's leave the venturing to others until 
      this capability is more developed.&nbsp; Hence, very few people are classified 
      in this profile type (preliminarily less than 1%).</p>
	<p className="normalText">The CELG is unaware of the detailed and highly 
        complex patterns of knowledge required for success in venturing, and hence 
        has insufficient discernment to detect the compliance of a venture with 
        a venture's "success script".&nbsp; Accordingly, the first ventures 
        of CELG's are predicted to be troublesome -- some even failing, because 
        the gaps in expertise are filled with guesswork, and the fund of resources 
        (experience included) is low.&nbsp; However, should the CELG persist, 
        and learn from his or her mistakes, there is great hope that understanding 
        and ability will develop.</p>
    <MatrixDiagram selected="CELG" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">This is a most promising venture type, especially 
        where a mentor can help to mitigate the scale and scope of the failures 
        during the learning process.&nbsp; If you are a CELG, and wish to become 
        involved in a new venture, your best course of action would be to ally 
        yourself with an expert and "learn the ropes" <b>before</b> you undertake a new venture.</p>
    <p className="normalText">An <b>internship</b> provides an ideal vehicle to facilitate this process.&nbsp; If you 
		are already into a venture and are experiencing turbulence, you should form an advisory board of experienced 
		venturers (try to find advisors who show up as experts on this instrument if possible).&nbsp; Possibly an 
		experienced venturer may be available through the federally funded <b>SCORE</b> program (retired executives), 
		who would be willing to be available on a regular basis.&nbsp; Each, or a combination of all these ideas have 
		the potential to make an expert from an apprentice.</p>
    </section>
}
