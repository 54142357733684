import React from 'react';
import { MatrixDiagram } from './MatrixDiagram/MatrixDiagram.component';
import { Link } from 'react-router-dom';

export const IELG = (props) => {
    return <section>
	<h1 className="pageTitle">IELG - Over-Eager</h1>
    <p className="normalText">An IELG is more of a new business novice who should beware of moving too
        fast into a new venture.&nbsp; The IELG is a real enthusiast in projects
        with which he or she is familiar.&nbsp; However, due to the low level
        of experience with new ventures indicated, this profile type may not know
        the right questions to ask about a venture, let alone the answers.&nbsp;
        This can be a very dangerous posture, because willingness to venture in
        the absence of requisite elements in the knowledge structure, lead to
        "over-eagerness".</p>

    <p className="normalText">Despite their willingness then, this limited
        ability when combined with a belief in "guesswork" and "feeling
        good" about an investment or venture, leads to conditions which render
        this type particularly vulnerable to the opportunistic.&nbsp; It is inadvisable
        for an IELG to become involved in a new venture alone (or with other inexperienced
        partners).</p>
    <MatrixDiagram selected="IELG" />
	<p className="sectionHeading1">WHAT TO DO:</p>
    <p className="normalText">If you are an IELG, (preliminary indications are that approximately 6%
        fit this profile), and wish to become involved in a new venture, you should
        find a competent <Link className="contentLink" to="/profile/entrepreneur-roles/advisor">advisor</Link> or <Link className="contentLink" to="/profile/entrepreneur-roles/mentor">mentor</Link> who has
        the expertise necessary to "fill in" gaps of knowledge where
        yours is not developed.&nbsp; Or, to improve your own venturing profile:
        find and analyze case studies of both successful and unsuccessful new
        venturers; read biographies; talk to active venturers -- then prepare
        your own list of new venturing "dos" and "don'ts".</p>
    <p className="normalText">In addition, systematic exposure to individuals who have expertise can raise your
		consciousness of the success and failure elements of the new venture expert "script".&nbsp; As
		a cross-check before venturing, you may wish to take this profile questionnaire again to gauge your progress.</p>
    </section>
}
